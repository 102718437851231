import {
   getAccommodation
  } from '../hooks/useRequest';

export async function accommodation(userToken: string, accommodationId: number){
    const x = await getAccommodation(userToken, accommodationId);
    if(x.accommodation.result == null){
        return null;
    }
    return x.accommodation.result;
}
  
export function boardFromID(boardID: number){
    switch(boardID){
        case 0:
            return "Room only";
        case 1: 
            return "Self catering";
        case 2:
            return "Bed & breakfast";
        case 3:
            return "Half board";
        case 4:
            return "Full board";
        case 5:
            return "All inclusive";
    }
    return ""
}