import React, { FunctionComponent } from 'react';
import '../SummaryCard.scss';
import { useStores } from '../../../hooks/useStore';
import { RootStore } from '../../../stores/RootStore';

export interface ILuggageSummary {
  title: string;
  quanity: number;
}

export interface ToggleProps {
  title: string;
  icon: string;
  luggage?: ILuggageSummary[];
  transfer?: any;
  packExtras?: any;
}

export const ExtrasCard: FunctionComponent<ToggleProps> = ({
  title,
  icon,
  luggage,
  transfer,
  packExtras
}: ToggleProps) => {
  
  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  let transferSummary = null;
  if(transfer != null){
    transferSummary =<div className='summary-card-wrapper--body'>
    <span className={'card-title'}>Transfer</span>
        <div key={'trans'} className='summary-card-list'>
          <div className='summary-card-list--item extras-card-list--item'>
            <span>{transfer.vehicle_type}</span>
            <span></span>
          </div>
        </div>
  </div>;
  }

  const luggageSummary = [];
  if(luggage && luggage.length > 0 ){

    let c = 0;
    for(const l of luggage){
      const ls = <div key={c + 'luggageextra'} className='summary-card-list'>
          <div className='summary-card-list--item extras-card-list--item'>
            <span>{l.title}</span>
            <span>x {l.quanity}</span>
          </div>
        </div>
        c ++;
        luggageSummary.push(ls);
    }
  }

  const packageExtras = [];
  if(packExtras && packExtras.length > 0 ){

    let c = 0;
    for(const l of packExtras){

      let qtySec = <span>x {l.quanity}</span>;
      if(l.quanity == -1){
        qtySec = <span></span>;
      }
      const ls = <div key={c + 'luggageextra'} className='summary-card-list'>
          <div className='summary-card-list--item extras-card-list--item'>
            <span>{l.title}</span>
            <span>{qtySec}</span>
          </div>
        </div>
        c ++;
        packageExtras.push(ls);
    }
  }



  return (
    <div style={{borderColor: configuration.border_colour_2}} className='summary-card-wrapper'>
      <div className='summary-card-wrapper--header'>
        <img src={icon} alt='Section icon' />
        <span className={'card-title'}>{title}</span>
      </div>

      {luggageSummary.length > 0 && (
        <div className='summary-card-wrapper--body'>
          <span className={'card-title'}>Luggage</span>
          {luggageSummary}
        </div>
      )}


      {packageExtras.length > 0 && (
        <div className='summary-card-wrapper--body'>
          {packageExtras}
        </div>
      )}

      {transferSummary}
    </div>
  );
};