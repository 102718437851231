import React, { ChangeEvent, FunctionComponent, useEffect, useState, MouseEvent } from 'react';
import './PriceDatepicker.scss';
import Calendar from 'react-calendar';
import MonthSelect from './MonthSelect/MonthSelect';
import cn from 'classnames';
import dayjs from 'dayjs';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

type Prices = {
  [date: string]: number;
};

interface PriceDatepickerProps {
  searchType: number;
  datePrices?: Prices;
  value?: dayjs.Dayjs | null;
  availableDates?: string[] | null;
  availableMonths?: any | null;
  disable: boolean;
  onMonthChange?: (date: dayjs.Dayjs) => void;
  onChange: (s: dayjs.Dayjs) => void;
  selectedDate?: dayjs.Dayjs;
}

const PriceDatepicker: FunctionComponent<PriceDatepickerProps> = ({
  searchType,
  datePrices,
  value,
  onChange,
  onMonthChange,
  selectedDate,
  disable,
  availableDates = null,
  availableMonths = null,
}: PriceDatepickerProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [date, setDate] = useState<dayjs.Dayjs | null>(
    value ? value : null,
  );
  const [activeDate, setActiveDate] = useState<dayjs.Dayjs>(dayjs());
  const minPrice = Math.min(...Object.values(datePrices || []));

  useEffect(() => {
    if (value) {
      setDate(value);
      setActiveDate(value);
    } else {
      if (availableDates != null) {
        const jh = 500;
        // const d = new Date('2023-05-01')
        if(availableDates[0] != null){
          const d = dayjs(availableDates[0], 'YYYY-MM-DD');
          setDate(d);
          setActiveDate(d);
        }
      }
    }
  }, [value]);

  useEffect(() => {
    if(availableDates != null && date == null){
      if(availableDates[0] != null){
        const d = dayjs(availableDates[0], 'YYYY-MM-DD');
        setDate(d)
        setActiveDate(d);
      }
    }
  }, [availableDates]);

  const changeActualMonth = (month: string, year: string) => {
    const m = Number(month) - 1;
    const newDate = dayjs(year + '-' + month + '-1');
    setActiveDate(newDate);
    if (onMonthChange) onMonthChange(newDate);
  };

  const handleDateChange = (
    value: Value,
    event: any,
    // event: MouseEvent<HTMLButtonElement, MouseEvent<Element, MouseEvent>>,
  ) => {
    event.stopPropagation();
    if (Array.isArray(value)) {

      const d = dayjs(value[0]);
      setDate(d);
      onChange(d);
    } else {
      const d = dayjs(value);
      setDate(d);
      onChange(d);
    }
  };

  const activeMonth = activeDate?.month();
  const activeYear = activeDate?.year();

  let cheapestPrice = -1;
  const monthPrices = [];
  const monthDats: string[] = [];
  if (datePrices != null) {
    Object.keys(datePrices).forEach((key: string) => {
      const pd = new Date(key);
      if (pd.getMonth() == activeMonth && pd.getFullYear() == activeYear) {
        const d = pd.getDate();
        const mn = pd.getMonth();
        const y = pd.getFullYear();
        const strDate = y + '-' + mn + '-' + d;

        monthPrices.push(datePrices[key]);
        monthDats.push(strDate);
        if (cheapestPrice == -1) {
          cheapestPrice = datePrices[key];
        } else if (datePrices[key] < cheapestPrice) {
          cheapestPrice = datePrices[key];
        }
      }
    });
  } else if (availableDates != null) {
    for (const x of availableDates) {
      const pd = dayjs(x, 'YYYY-MM-DD').toDate();
      const d = pd.getDate();
      const mn = pd.getMonth();
      const y = pd.getFullYear();
      const strDate = y + '-' + mn + '-' + d;
      monthDats.push(strDate);
    }
  }

  return (
    <div className={'calendar-wrapper'}>
      <Calendar
        activeStartDate={activeDate?.toDate()}
        selectRange={false}
        calendarType={'US'}
        onChange={handleDateChange}
        tileDisabled={({ date, view }) => {
          let isDisabled = disable;

          if (date && view === 'month') {
            const d = date.getDate();
            const m = date.getMonth() + 1;
            const mn = date.getMonth();
            const y = date.getFullYear();
            const strDate = y + '-' + mn + '-' + d;

            // if (
            //   availableDates &&
            //   availableDates?.length >= 0 && availableDates.indexOf(strDate) == -1
            // ) {
            //   isDisabled = true;
            // }

            // if (datePrices && Object.keys(datePrices) && availableDates?.indexOf(strDate) == -1)
            // {
            //   isDisabled = true;
            // }

            if (monthDats.indexOf(strDate) == -1) {
              isDisabled = true;
            }

            if (activeMonth != mn) {
              isDisabled = true;
            }
          }

          return isDisabled;
        }}
        value={date?.toDate()}
        tileClassName={({ date, view }) => {
          let isSelected = false;
          let isDisabled = false;
          let hideDate = false;
          if (date && view === 'month') {
            const d = date.getDate();
            const m = date.getMonth() + 1;
            const mn = date.getMonth();
            const y = date.getFullYear();
            const strDate = y + '-' + mn + '-' + d;

            // if (Array.isArray(date) && date.length > 1 && date[0] && date[1]) {
            //   isSelected = date ? date >= date[0] && date <= date[1] : false;
            // }

            // if (
            //   availableDates && availableDates?.length >= 0 && availableDates.indexOf(strDate) == -1
            // ) {
            //   isDisabled = true;
            //   return 'disabled-date';
            // }

            // if (datePrices && Object.keys(datePrices) && availableDates?.indexOf(strDate) == -1) {
            //   isDisabled = true;
            //   return 'disabled-date';
            // }

            if (activeMonth != mn) {
              isDisabled = true;
              hideDate = true;
              // return cn({ 'selected-date': isSelected, 'hide-date': hideDate });
              return 'disabled-date hide-date';
            }

            if (monthDats.indexOf(strDate) == -1) {
              isDisabled = true;
              return 'disabled-date';
            }
          }

          if (selectedDate && dayjs(date).isSame(selectedDate, 'day')) {
            isSelected = true;
          }

          return cn({ 'selected-date': isSelected });
          // return cn({ 'selected-date': isSelected, 'disabled-date': isDisabled });
        }}
        tileContent={({ date }: any) => {
          // @TODO
          // Investigate why react-calendar have dropped type definitions
          // This used to be the CalendarTileProperties type

          const mn = date.getMonth();
          const dateFormat = dayjs(date).format('YYYY-MM-DD');
          const price = (datePrices && datePrices[dateFormat]) || null;

          if (activeMonth != mn) {
            return null;
          }

          return price ? (
            <div  className={cn({ 'low-price': price <= cheapestPrice })}>{`£${price}`}</div>
          ) : null;
        }}
      />
      <MonthSelect
        value={date && `${date}`}
        years={[String(dayjs().year()), String(dayjs().add(1, 'year').year())]}
        onChange={(date) => {
          changeActualMonth(date.month, date.year);
        }}
        availeMonths={availableMonths}
      />
    </div>
  );
};

export default PriceDatepicker;
