import React, { FunctionComponent, useEffect, useState, ReactNode } from 'react';

import {
    packageAvailableDeparturePoints
  } from '../../hooks/useRequest';

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

import { Button } from '../Button';

import tick from '../../components/assets/tick.svg';

import { toTitleCase } from '../../services/str';

import { Modal } from '../Modal';

export const LoaderSpinner = () => {

    const {
      RootStore: {
        configuration,
      },
    } = useStores() as { RootStore: RootStore };
  
  
  let bs = configuration.button_colour
  if(configuration.spec == '6yk1y'){;
    bs = '#01447b';
  }
  
    return (
      <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
        <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
      </div>
    );
  };



interface DeparturePointSelectorPackageProps {
    isOpen: boolean;
    rooms: string, 
    selectedDeparturePoints: string[] 
    selectedDeparturePointCodes: string[],
    duration: number, 
    departureDate: string | null | undefined, 
    selectedRegion: number[],
    selectedResort: number[],
    onClose: () => void;
    onChange: (options: any) => void;
  }
  
  const DeparturePointSelectorPackage: FunctionComponent<DeparturePointSelectorPackageProps> = ({
    isOpen = false,
    rooms, 
    selectedDeparturePoints=[], 
    selectedDeparturePointCodes=[],
    duration=7, 
    departureDate=null, 
    selectedRegion=[],
    selectedResort=[],
    onClose,
    onChange
  }) => {
    
    const {
        RootStore: {
          userToken,
          configuration,
        },
      } = useStores() as { RootStore: RootStore };



    const [options, setOptions] = useState<any>(null);
    const [lastRun, setLastRun] = useState<any>(null);
    // const [selectedOptions, setSelectedOptions] = useState<OptionObj[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    const [selectedSession, setSelectedSession] = useState<any>([]);
    const [removedSession, setRemovedSession] = useState<any>([]);


    useEffect(() =>{
        const jh = 500;

    }, [])
    useEffect(() =>{


        const xEl = document.getElementById('cloudlink-chat-overlay-buttons');
        if(xEl != null){
            if(isOpen){
            
                if(xEl != null){
                xEl.style.display = 'none';
                }
            }
            else{
                if(xEl != null){
                    xEl.style.display = 'block';
                }
            }
        }
        
        if(!isOpen){
            setSearchValue('');
            setSelectedSession([]);
            setRemovedSession([]);
            return
        }


        const params = { rooms, duration,departureDate, selectedRegion, selectedResort }
 
        if(lastRun != null){
            if(lastRun.rooms == rooms && lastRun.duration == duration && lastRun.selectedRegion == selectedRegion  && lastRun.selectedResort == selectedResort){
                return;
            }
        }

        setIsFetching(true);

        packageAvailableDeparturePoints(userToken, duration, selectedRegion, selectedResort, departureDate).then((d) => {
            setOptions(d.package_available_departure_points.result);
            setLastRun(params)
            setIsFetching(false);
        })

    }, [isOpen])

    const handleCloseButtonClick = () => {

        setSelectedSession([]);
        setRemovedSession([]);
        onClose();
    }

    const handleCancelButtonClick = () => {
        const xa = selectedDeparturePoints;
        const newxa = [];
        for(const sd of xa){
            let w = true;
            for(const x of selectedSession){
                if(sd == x){
                    w = false;
                    break;
                }
            }
            if(w){
                newxa.push(sd);
            }
        }

        for(const sd of removedSession){
            // let w = false;
            // for(const x of removedSession){
            //     if(sd.value == x.value){
            //         w = true;
            //         break;
            //     }
            // }
    
            newxa.push(sd);
            
        }

        onChange(newxa)

        setSelectedSession([]);
        setRemovedSession([]);

        onClose();

    }

    const selectOption = (code: string) => {
        const xa = selectedDeparturePoints;

        const ss = selectedSession;
        const rs = removedSession


        const ie = xa.indexOf(code);
        if(ie!= -1){
            setSearchValue('');
            onChange([]);
        }
        else{
            setSearchValue('');
            xa.push(code);
            if(searchValue.trim() == ''){
                ss.push(code);
            }
            onChange([code]);
            onClose();
        }


   
  

        // if(isCheck){
        //     const o = {value: code, label: toTitleCase(destLbl), type: destType, isChecked: true, imageIcon: ''};
        //     onChange([o]);
        //     onClose();
        // }
        // else{
        //     let i = -1;
        //     for(const x of xa){
        //         i++;
        //         if(x.value == code && x.type == destType){
        //             rs.push(x);
        //             break;
        //         }
        //     }
        //     if(i != -1){
        //         xa.splice(i, 1)

        //     }   
            
        //     onChange(xa)
        // }
    }





    if(!isOpen){
        return null;
    }

    let bc = configuration.button_colour;

    let titleColour =  configuration.button_colour;
    let cancelText = configuration.button_colour;
    let primButtonColour = configuration.button_colour;
    if(configuration.button_icon == 'dark'){
      bc = configuration.border_colour_2;
      titleColour =  configuration.border_colour_2;
    }

    let selStyle = {backgroundColor: configuration.button_colour, borderColor:configuration.border_colour_2};
  
    if(configuration.spec == '6yk1y'){
      primButtonColour = configuration.button_colour;
      cancelText = '#01447b';
      bc = '#01447b';
      titleColour =  '#01447b';
      selStyle = {backgroundColor: '#01447b', borderColor:configuration.border_colour_2};
    }

    const modalHeader: ReactNode = (
        <div className='dest-header-section'>
          <h5 style={{color: configuration.text_colour}} className='dest-header-section-text'>Departure Airport</h5>
          <span className="dest-header-section-close" onClick={() => handleCloseButtonClick()} role="button"></span>
        </div>
      );

    const modalFooter: ReactNode = (
    <div className='dest-footer-section'>
        <Button label='Cancel'  backgroundColor={primButtonColour} isTransparent onClick={() => handleCancelButtonClick()} />
        <Button label='Apply' backgroundColor={primButtonColour} borderColor={primButtonColour} primary onClick={() => handleCloseButtonClick()} />
    </div>
    );
    

    const loader =<><div className='destsel-desktop-view'><div style={{borderColor: configuration.border_colour_2, height: '400px', display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
    <div style={{width:'100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <LoaderSpinner></LoaderSpinner>
    </div>
</div></div><div className='destsel-mobile-view'><div className='datesel-mobile-con'>{modalHeader}<div style={{display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
    <div style={{width:'100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <LoaderSpinner></LoaderSpinner>
    </div>
</div></div></div></>;

    if(isFetching){
        return(loader);
    }

    if(options == null){
        return(loader);
    }


    // return(loader);

   


    const depPoints: any[] = [];
    const htmlDests = [];

    const htmlSelDests =[];

    for(const x of options){
        depPoints.push({'value': x.code, 'name': x.name, 'type': 'airports', 'hide': false,});
    }



    depPoints.sort((a, b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
        }
        if(a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
        }
        return 0;
    })

    const chkStyle = {width: '24px', height: '24px', borderStyle:'solid', borderWidth: '1px', borderRadius: '2px', cursor: 'default', borderColor: '#99b4ca'};

    const lblStyle = {marginLeft: '10px', cursor:'pointer', color: configuration.text_colour};
    const chkInputStyle = {height: '0px', width: '0px', display: 'none'};
    const optionDivStyle = {paddingTop: '12px', paddingBottom: '12px', display:'flex', alignItems:'center'}


    const spanSelected = <span style={{display: 'flex', width: '24px', height: '24px', color: 'white', alignItems: 'center', justifyContent: 'center'}}><img src={tick} style={{color: 'white'}} height='20' width='20' /></span>

    for(const x of depPoints){

        let selTick = null;
        let selected = false;
        if(selectedDeparturePointCodes.indexOf(x.value) > -1){
            selTick = spanSelected
            selected = true;
        }

        let isSessionSel = false;
        for(const ss of selectedSession){
            if(ss.value == x.value){
                isSessionSel = true;
            }
        }

        if(selected && !isSessionSel){
            htmlSelDests.push(<div style={optionDivStyle}>
                <input onChange={(e) => {selectOption(x.value)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x.value} name={'chkdst' + x.value} value={x.value} checked={selected} />
                <label style={chkStyle} htmlFor={'chkdst' + x.value}>{selTick}</label>
                <label style={lblStyle} htmlFor={'chkdst' + x.value}>{x.name}</label>
            </div>)
        }
        else{
            if(searchValue != ''){
                const strName = x.name.toLowerCase();
                if(strName.indexOf(searchValue.toLowerCase()) > -1){
                    htmlDests.push(<div style={optionDivStyle}>
                        <input onChange={(e) => {selectOption(x.value)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x.value} name={'chkdst' + x.value} value={x.value} checked={selected} />
                        <label style={chkStyle} htmlFor={'chkdst' + x.value}>{selTick}</label>
                        <label style={lblStyle} htmlFor={'chkdst' + x.value}>{x.name}</label>
                    </div>)
                }
            }
            else{
                htmlDests.push(<div style={optionDivStyle}>
                        <input onChange={(e) => {selectOption(x.value)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x.value} name={'chkdst' + x.value} value={x.value} checked={selected}  />
                        <label style={chkStyle} htmlFor={'chkdst' + x.value}>{selTick}</label>
                        <label style={lblStyle} htmlFor={'chkdst' + x.value} >{x.name}</label>
                </div>)
            }
        }
    }
    



    return(<><div className='destsel-desktop-view'><div style={{borderColor: configuration.border_colour_2, height: '400px', display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
        <div style={{width:'100%'}}>
        <div style={{width:'100%', marginBottom: '10px', border: '1px solid ' + configuration.border_colour_2, borderRadius: '6px'}}>
                <input autoFocus={true} style={{color: configuration.text_colour, border: 'none'}} className='datesel-input' placeholder='Search departure airports' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} type='text'></input>
            </div>
            {htmlSelDests}
            {htmlDests}
        </div>
    </div>
    </div>
    <div className='destsel-mobile-view'>

    <Modal
          isOpen={true}
        >

        <div className='datesel-mobile-con'>{modalHeader}
        <div style={{borderColor: configuration.border_colour_2, display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
        <div style={{width:'100%'}}>
        <div style={{width:'100%', marginBottom: '10px', border: '1px solid ' + configuration.border_colour_2, borderRadius: '6px'}}>
                <input autoFocus={true} style={{color: configuration.text_colour, border: 'none'}} className='datesel-input' placeholder='Search departure airports' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} type='text'></input>
            </div>
            {htmlSelDests}
            {htmlDests}
        </div>
        </div>
        {modalFooter}
    </div>
    </Modal>
    </div></>)



  };

  export default DeparturePointSelectorPackage;