import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

// Icons
import arrow from '../../assets/arrow.svg';
import arrowDefault from '../../assets/icons/default/01/arrow_b.svg';

// Styles
import './MonthSelect.scss';
import { useStores } from '../../../hooks/useStore';
import { RootStore } from '../../../stores/RootStore';
import { arrayOf } from 'prop-types';

type MonthSelectorProps = {
  value: string | null | undefined;
  years: string[];
  availeMonths: any | null;
  onChange: (monthYear: { month: string; year: string }) => void;
};

const MonthSelect: FunctionComponent<MonthSelectorProps> = ({
  value,
  years,
  availeMonths,
  onChange,
}: MonthSelectorProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  const [month, setMonth] = useState('');
  const [monthText, setMonthText] = useState('');
  const [year, setYear] = useState((value && dayjs(value).year() + '') || years[0]);
  const [open, setOpen] = useState(false);
  const [shouldStopPrevAction, setShouldStopPrevAction] = useState(true);
  const [shouldStopNextAction, setShouldStopNextAction] = useState(true);

  const selectRef: React.RefObject<HTMLDivElement> = useRef(null);

  const selectOption = (month: string, year: string, monthText: string) => {
    const m = Number(month);
    setOpen(false);
    setMonth(m.toString());
    setMonthText(monthText);
    setYear(year + '');
    onChange({ month, year });
    disableNextPrevOptions();
  };

  useEffect(() => {
    if (value == null) {
      return;
    }

    const wit = dayjs(value).month() + 1;
    setMonth(wit.toString());
    setYear(dayjs(value).year() + '');
    setMonthText(dayjs(value).format('MMM'));
  }, [value]);

  useEffect(() => {
    if (availeMonths == null) {
      return;
    }
  }, [availeMonths]);

  // const getCurrentMonthIndex = () => {
  //   return months.findIndex((item) => item === month);
  // };

  // const getCurrentYearIndex = () => {
  //   return years.findIndex((item) => item === year);
  // };

  const getCurrentMonthIndex = () => {
    let c = 0;

    if (availeMonths == null) {
      return 0;
    }

    if (availeMonths) {
      for (const x of availeMonths) {
        if (month == x.month.toString() && year == x.year.toString()) {
          return c;
        }
        c++;
      }
    }

    return 0;
  };

  const onArrowClick = async (type: string) => {
    // if(availeMonths != null){
    //   if(availeMonths.available_months != null){
    //     for(const x of availeMonths.available_months.result){
    const currentMonthIndex = getCurrentMonthIndex();

    if (type == 'prev' && currentMonthIndex == 0) {
      return;
    }

    if (type == 'next' && currentMonthIndex + 1 == availeMonths.length) {
      return;
    }

    if (type == 'prev') {
      const x = availeMonths[currentMonthIndex - 1];
      selectOption(x.month, x.year, x.month_text);
    }
    if (type == 'next') {
      const x = availeMonths[currentMonthIndex + 1];
      selectOption(x.month, x.year, x.month_text);
    }

    disableNextPrevOptions();

    // let currentMonthIndex = getCurrentMonthIndex();
    // const currentYearIndex = getCurrentYearIndex();

    // if (type === 'next') {
    //   if (currentMonthIndex === 11 && currentYearIndex + 1 === years.length) {
    //     return;
    //   }

    //   currentMonthIndex = currentMonthIndex === 11 ? 0 : currentMonthIndex + 1;
    //   setYear(years[currentYearIndex + (currentMonthIndex === 0 ? 1 : 0)]);
    //   setMonth(months[currentMonthIndex]);
    // } else {
    //   if (!currentYearIndex && !currentMonthIndex) {
    //     return;
    //   }

    //   currentMonthIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
    //   setYear(years[currentYearIndex - (currentMonthIndex === 11 ? 1 : 0)]);
    //   setMonth(months[currentMonthIndex]);
    // }
  };

  useEffect(() => {
    // onChange({ month, year });
    disableNextPrevOptions();
  }, [month, year]);

  useEffect(() => {
    disableNextPrevOptions();
  }, [availeMonths]);

  const disableNextPrevOptions = () => {
    if (availeMonths == null) {
      return;
    }
    const currentMonthIndex = getCurrentMonthIndex();
    if (currentMonthIndex == 0) {
      setShouldStopPrevAction(true);
      setShouldStopNextAction(false);
    } else if (currentMonthIndex + 1 == availeMonths.length) {
      setShouldStopNextAction(true);
      setShouldStopPrevAction(false);
    } else {
      setShouldStopPrevAction(false);
      setShouldStopNextAction(false);
    }
  };

  const toggleList = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useOutsideClick(selectRef, handleClickOutside);

  const availableMonthsj = [];

  if (availeMonths != null) {
    for (const x of availeMonths) {
      // availableMonthsj.push({'month': x.month, 'year': x.year, 'text': x.month_text})
      availableMonthsj.push(
        <li
          className={'hwt-custom-select--item'}
          key={`${x.month}-${x.year}`}
          onClick={() => selectOption(x.month, x.year, x.month_text)}
        >{`${x.month_text} ${x.year}`}</li>,
      );
    }
  }

  if (availeMonths == null) {
    return null;
  }

  let monthLabel = 'Loading..';
  if (monthText != '') {
    monthLabel = `${monthText} ${year}`;
  }

  let arrowIco = arrowDefault;
  if(configuration.icon_set == 'blue'){
    arrowIco = arrow;
  }

  return (
    <div className='hwt-custom-select' ref={selectRef}>
      <img
        src={arrowIco}
        className={'arrow arrow-prev'}
        style={{
          opacity: shouldStopPrevAction ? 0.5 : 1,
          cursor: shouldStopPrevAction ? 'not-allowed' : 'pointer',
        }}
        onClick={() => {
          if (!shouldStopPrevAction) onArrowClick('prev');
        }}
      />

      <div className={'hwt-custom-select--title'} style={{borderColor: configuration.border_colour_2}} onClick={toggleList}>
        <span style={{borderColor: configuration.border_colour_2}}>{monthLabel}</span>
        <img src={arrow} alt='' />

        {open && (
          <ul className={'hwt-custom-select--items'}>
            {availableMonthsj}
            {/* {years.map((y) => {
              // const monthsToShow = y === currentYear ? availableMonths : months;

              return availableMonthsj.map((m) => {
                return (
                  <li
                    className={cn('hwt-custom-select--item', {
                      active: `${m} ${y}` === `${month} ${year}`,
                    })}
                    key={`${m}-${y}`}
                    onClick={() => selectOption(m, y)}
                  >{`${m} ${y}`}</li>
                );
              });
            })} */}
          </ul>
        )}
      </div>

      <img
        src={arrowIco}
        className={'arrow arrow-next'}
        style={{
          opacity: shouldStopNextAction ? 0.5 : 1,
          cursor: shouldStopNextAction ? 'not-allowed' : 'pointer',
        }}
        onClick={() => {
          if (!shouldStopNextAction) onArrowClick('next');
        }}
      />
    </div>
  );
};

export default MonthSelect;
