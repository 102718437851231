import { FunctionComponent, useState, useEffect } from 'react';
// import { IBasket } from './Interfaces';
// import { ImageCarousel } from '../ImageCarousel';
import './Basket.scss';
import TripAdvisorLogo from '../assets/tripadvisor-logo.png';
import { Rating } from '../HolidayCard/Rating';
import dayjs from 'dayjs';
import { FlightDetails } from '../HolidaySummary/FlightDetails';
import { OfferSupplement, Product } from '../../hooks/types';
import { useSearchParams } from 'react-router-dom';
import { PaymentOptions } from '../PaymentOptions';
import { uniqueId } from 'lodash';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { removeBasketDiscount } from '../../hooks/useRequest';
import { ImageSlider } from '../ImageSlider/ImageSlider';

import {adultCountFromRooms, childCountFromRoomsExInfants } from '../../services/pax';

import { Basket as BasketData, Accommodation, Room, Flight, Transfer, SearchSelectionRoom } from '../../hooks/types';

interface IPrice {
  price: number;
}
const RenderPrice: FunctionComponent<IPrice> = ({ price }) => {


  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  return (
    <div className='basket-standout-price-container'>
      <span className='basket-standout-price-title'>Total price:</span>
      <span style={{color: configuration.jrny.basket.price_colour}} className='basket-standout-price'>£{price.toFixed(2)}</span>
    </div>
  );
};

// type Room = {
//   children: number;
//   adults: number;
// };
// type Rooms = {
//   [key: string]: Room;
// };

interface BasketProps {
  sessionId: string;
  pricePlan: any;
  children?: any;
  basketData: BasketData;
  selectedFlight: Flight | null;
  selectedRooms: Room[] | [];
  selectedTransfer: Transfer | null;
  selectedAccommodation: Accommodation;
  searchedRooms: SearchSelectionRoom[];
}

export const Basket: FunctionComponent<BasketProps> = ({
  sessionId,
  pricePlan = null,
  selectedFlight = null,
  selectedRooms = [],
  selectedTransfer = null,
  selectedAccommodation,
  children,
  basketData,
  searchedRooms
}) => {


  const [searchParams] = useSearchParams();
  const [showMore, setShowMore] = useState<boolean>(false);

  const [basket, setBasket] = useState<BasketData>(basketData);

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  useEffect(() => {
    setBasket(basketData);
  }, [basketData])

  const formatToDate = (outboundDate: string, inboundDate: string): string => {
    const format = 'ddd DD MMM YYYY';
    const inboundDateFormat = dayjs(inboundDate).format(format);
    const outboundDateFormat = dayjs(outboundDate).format(format);
  
    const dr = searchParams.get('dr');
    const drN = Number(dr);

    return `${outboundDateFormat} - ${inboundDateFormat} (${selectedRooms[0].duration} night${
      selectedRooms[0].duration  > 1 ? 's' : ''
    })`;
  };


  const removeCode = async(code: string) => {
    if (sessionId) {
      const res = (await removeBasketDiscount(userToken, sessionId, code));

      if (res?.basket_remove_discount.result) {
        setBasket(res?.basket_remove_discount.result);
      } 
      else {
        console.log('res', res);
        alert('We were unable to remove the voucher code. Please try again.');
      }
    }
  }

  const getAllRoom = () =>  {
    const data = searchParams.get('selectedRooms');

    if (data) {
      const parsedData = JSON.parse(data);
      return parsedData;
    }

    return null;
  }

  const transferProducts = () => {

    if (selectedTransfer == null) {
      return null;
    }

    let transfer = null;
    for(const x of basket.products){
       if(x.product_type.id == 3){
          transfer = x;
       }
    }

    if(transfer == null){
      return null;
    }

    return (
      <div className='basket-supplement' key={'bsk_tf' + selectedTransfer.id}>
        <span>{selectedTransfer.vehicle_type}</span>
        <span style={{color: configuration.jrny.basket.price_colour}} className='basket-supplement__price'>
          {new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          }).format(selectedTransfer.price || 0)}
        </span>
      </div>
    );
  };

  const roomSections = () => {
    const roomSecs = [];
    for (const sr of selectedRooms) {

      let childrenCount = sr.children;
      let childStr = '';
      if (childrenCount > 0) {
        if (childrenCount == 1) {
          childStr = '1 child';
        } else {
          childStr = childrenCount + ' children';
        }
      }
      roomSecs.push(
        <div style={{color: configuration.text_colour, borderColor: configuration.jrny.basket.border_colour}} className='basket-rooms__room' key={`room-${sr.room_number}`}>
          <b className='basket-rooms__room-title'>Room {sr.room_number}</b>
          <p>
            {sr.adults} Adults {childStr} - {sr.description}
          </p>
          <p>{sr.board_description}</p>
        </div>,
      );
    }

    return roomSecs;
  }

  const flightSections = () => {

    if(selectedFlight == null){
      return null;
    }
    return( <>
    {<div style={{borderColor: configuration.jrny.basket.seperator_colour}} className='basket-divider'></div>}
    <div className='basket-inbound-flights'>    
          <FlightDetails
            flightType={'Outbound'}
            airline={selectedFlight.airline}
            flightNumber={selectedFlight.number}
            departureTime={selectedFlight.departing}
            departurePlace={selectedFlight.departure_airport.name}
            arrivalPlace={selectedFlight.arrival_airport.name}
            arrivalTime={selectedFlight.arrival}
            outBound={true}
            config={configuration}
          />
    </div>
    {<div style={{borderColor: configuration.jrny.basket.seperator_colour}} className='basket-divider'></div>}
    <div className='basket-inbound-flights'>
          <FlightDetails
            flightType={'Inbound'}
            airline={selectedFlight.airline}
            flightNumber={selectedFlight.return_number}
            departureTime={selectedFlight.return_departing}
            departurePlace={selectedFlight.arrival_airport.name}
            arrivalPlace={selectedFlight.departure_airport.name}
            arrivalTime={selectedFlight.return_arrival}
            outBound={false}
            config={configuration}
          />
    </div>
  </>)
  }

  const filteredProducts = basket.products
    .filter((product: Product) => {
      return product?.product_type.id === 2;
    })
    .map((product: Product) => {
      return {
        ...product,
        count: 1,
      };
    });

  const groupedProducts = filteredProducts
    ? filteredProducts?.reduce((accumulator: any, product: Product) => {
        const description = product.description;
        const price = product.price;

        if (accumulator[description]) {
          accumulator[description].price += price;
          accumulator[description].count += 1;
        } else {
          accumulator[description] = {
            description,
            price,
            count: 1,
          };
        }

        return accumulator;
      }, {})
    : [];

  const selectedRoomsSection = roomSections();


  let isPackage = false;
  let packageDiscount = 0;
  if(basket != null){
    for(const x of basket.products){
      if(x.product_type.id == 5){
        isPackage = true;

        if(x.promo_discount > 0){
          packageDiscount = x.promo_discount;
        }

        break;
      }
  
    }
  }

  let tripDiv = null;
  if(selectedAccommodation.trip_advisor_rating != 0){
    tripDiv =   <div>
    <img className='basket-rating-trip-logo' src={TripAdvisorLogo} height={15} width={20} />
    <Rating type='circle' rating={selectedAccommodation.trip_advisor_rating || -1} />
    </div>;
  }

  const arate = Number(selectedAccommodation.rating);

  let dateSec = null;
  if(selectedFlight != null){
    dateSec = formatToDate(selectedFlight.departing, selectedFlight.return_departing)
  }
  else{
    dateSec = `${selectedRooms[0].duration} night${selectedRooms[0].duration  > 1 ? 's' : ''})`;
  }

  const adultCount = adultCountFromRooms(searchedRooms);
  const childExInfCount = childCountFromRoomsExInfants(searchedRooms);
  const totalExInfs = adultCount + childExInfCount;

  const perPerSonPrice = basket.price / totalExInfs;

  return (
    <>
      <div style={{color: configuration.text_colour, borderColor: configuration.jrny.basket.border_colour}} className='basket-container'>
        {/* <ImageCarousel images={destination.images || []} /> */}
        <ImageSlider images={selectedAccommodation.images || []} />

        <h4 className='basket-title'>{selectedAccommodation.name}</h4>
        <div className='basket-subtitle'>{selectedAccommodation.resort.regions[0].destinations[0].name}, {selectedAccommodation.resort.regions[0].name}, {selectedAccommodation.resort.name}</div>

        <div className='basket-container-rating'>
          <div>
            <Rating type='star' rating={arate} />
          </div>
          {tripDiv}
        </div>

        <div className='basket-container-date-formatted basket-container-generic-text'>
          {dateSec}
        </div>

        <div className='basket-container-generic-text basket-container-generic-lastchild'>
          <div className='basket-rooms'>{selectedRoomsSection}</div>
        </div>


        {flightSections()}
 

        <div style={{borderColor: configuration.jrny.basket.border_colour}} className='basket-extras'>
          {basket.supplements &&
            basket.supplements.length > 0 &&
            basket.supplements.map((supplement: OfferSupplement) => {
              return (
                <div className='basket-supplement' key={supplement.description}>
                  <span>{supplement.description}</span>
                  <span style={{color: configuration.jrny.basket.price_colour}} className='basket-supplement__price'>
                    Included
                  </span>
                </div>
              );
            })}


          {groupedProducts &&
            Object.keys(groupedProducts).length > 0 &&
            Object.keys(groupedProducts).map((key: string) => {
              const product = groupedProducts[key];

              return (
                <div className='basket-supplement' key={product.description}>
                  <span>
                    {product.description} x{product.count}
                  </span>
                  <span style={{color: configuration.jrny.basket.price_colour}} className='basket-supplement__price'>
                    {new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: 'GBP',
                    }).format(product.price)}
                  </span>
                </div>
              );
            })}

          {transferProducts()}

        </div>

        {basket && basket.discounts?.length > 0 && (
          <div className='basket-discounts'>
            {basket.discounts.map((discount: any) => {
              const type = discount.type === 0 ? 'amount' : 'percent';

              return (
                <div key={uniqueId()} className='basket-discount'>
                  <p className='basket-discount__label'>
                    Promo Code Applied:
                    <br />
                    <span className='basket-discount__code'>({discount.code})</span>{' '}
                  </p>
                  <p style={{color: configuration.jrny.basket.price_colour}} className='basket-discount__amount'>
                    {`-£${discount.calculated_amount.toFixed(2)}`}
                  </p>
                </div>
              );
            })}
          </div>
        )}

  

        <div className='basket-standout-price-container'>
          <span className='basket-standout-price-title basket-standout-price-title-large'>
            Price per person
          </span>
          <span style={{color: configuration.jrny.basket.price_colour}} className='basket-standout-price basket-standout-price-xl'>
            £{perPerSonPrice.toFixed(2)}
          </span>
        </div>
      

        {basket.price && (
          <>
         

            <div className='basket-standout-price-container'>
              <span className='basket-standout-price-title'>Total price:</span>
              <span style={{color: configuration.jrny.basket.price_colour}} className='basket-standout-price'>£{basket.price.toFixed(2)}</span>
            </div>

           <div className='basket-payment-options'>
              <PaymentOptions
                depositPrice={basket.deposit_price}
                depositPerPerson={basket.deposit_price_per_person}
                depositDue={basket.deposit_due}
                pricePlan={pricePlan}
                live={true}
              />
            </div>
          </>
        )}

        <div className='basket-standout-price-container'>
          <div style={{color: configuration.jrny.basket.reference_colour}} className='basket-container-center'>
            Reference : {basket.customer_reference}
          </div>
        </div>

        {children && <div style={{ padding: '20px 10px' }}>{children}</div>}
      </div>
    </>
  );
};
