import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import '../Loader/Loader.scss';
import './LoadingOverlay.scss';

type LoadingOverlayProps = {
  background?: string;
  ringColour?: string;
  buttonText?: string;
};

type Style = {
  background: string | undefined;
};

export function LoadingOverlay({ background, ringColour, buttonText }: LoadingOverlayProps) {


  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  // ringColour={configuration.button_colour} buttonText={configuration.button_text_colour}
  const style: Style = {
    background: undefined,
  };  
  
  if (background) {
    style.background = background;
  }

  const ringStyle = {backgroundColor: configuration.loader_colour};
  // if(ringColour != null){
  //   ringStyle.backgroundColor = ringColour;
  // }
  
  const bs = {borderColor: configuration.button_text_colour + ' transparent transparent transparent'}
  if(buttonText != null){
    bs.borderColor = buttonText + ' transparent transparent transparent';
  }

  if(configuration.spec == '6yk1y'){;
    bs.borderColor = 'white transparent transparent transparent';
  }


  return (
    <div className='loading-overlay' style={style}>
      <div className='loader-ring' style={ringStyle}>
        <div style={bs} />
        <div style={bs} />
        <div style={bs} />
        <div style={bs} />
      </div>
    </div>
  );
}
