import { FunctionComponent, useRef, useState, useEffect } from 'react';
import { compact, noop } from 'lodash';

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

import { SearchBarInputField } from '../SearchBarInputField';

import plane from '../assets/plane.svg';
import planeDefault from '../assets/icons/default/01/Plane.svg'

// Styles
import './SearchBar.scss';
import './AirportSelect.scss';
import { useSearchProvider } from '../../context/search';

import {DeparturePointSelector} from '../../components/DeparturePointSelector'

import {DeparturePointSelectorPackage} from '../../components/DeparturePointSelectorPackage'

import { SearchSelections } from '../../hooks/types'

import { geoDeparturePoints } from '../../geo_da';

import { roomsToString} from '../../services/pax';

import { useOutsideClick } from '../../hooks/useOutsideClick';


interface AirportsSelectProps {
  clickApplyHandler: (selectedValues: string[]) => void;
  onActivate?: () => void;
  selectedSearchParams: SearchSelections;
  clickedSearchButtonItemName?: string;
  accommodationId?: number;
  showIcons: boolean;
  checkDate?: boolean;
  validationError?: {valid: boolean, message: string};
}

const AirportsSelect: FunctionComponent<AirportsSelectProps> = ({
  clickApplyHandler,
  selectedSearchParams,
  clickedSearchButtonItemName,
  accommodationId,
  onActivate,
  showIcons = false,
  checkDate = true,
  validationError = null,
}) => {
  let rooms = JSON.stringify(['2']);
  let duration = 7;
  let accID = 0;
  const airports: string[] = [];
  const destinations: number[] = [];
  const regions: number[] = [];
  const resorts: number[] = [];
  const boards: number[] = [];
  const ratings: number[] = [];
  const tripRatings: number[] = [];

  if (selectedSearchParams != null) {
    if (selectedSearchParams.rooms != null) {
      rooms = roomsToString(selectedSearchParams.rooms);
    }

    if (selectedSearchParams.duration != null) {
      duration = selectedSearchParams.duration;
    }

    if (selectedSearchParams.boards != null) {
      for (const x of selectedSearchParams.boards) {
        boards.push(x);
      }
    }

    if (selectedSearchParams.departurePoints != null) {
      for (const x of selectedSearchParams.departurePoints) {
        airports.push(x);
      }
    }

    if (selectedSearchParams.destinations != null) {
      for (const x of selectedSearchParams.destinations) {
          destinations.push(x);
      }
    }

    if (selectedSearchParams.regions != null) {
      for (const x of selectedSearchParams.regions) {
          regions.push(x);
      }
    }

    if (selectedSearchParams.resorts != null) {
      for (const x of selectedSearchParams.resorts) {
          resorts.push(x);
      }
    }

    if (selectedSearchParams.ratings != null) {
      for (const x of selectedSearchParams.ratings) {
        ratings.push(x);
      }
    }

    if (selectedSearchParams.tripRatings != null) {
      for (const x of selectedSearchParams.tripRatings) {
        tripRatings.push(x);
      }
    }
  }

  const selectorRef = useRef<HTMLDivElement>(null);

  const [selectExpanded, setSelectExpanded] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(airports);

  const [selected, setSelected] = useState<SearchSelections>(selectedSearchParams);

  const [selectedCount, setSelectedCount] = useState<number>(airports.length);

  const { state } = useSearchProvider();

  
  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  useEffect(() => {
    if (clickedSearchButtonItemName === 'DeparturePoints' && !selectExpanded) {
      setSelectExpanded(true);
    }
  }, [clickedSearchButtonItemName]);


  // useEffect(() => {
  //   setSelected(selectedSearchParams);
  // }, [selectedSearchParams]);


  let tripType = state.option;
  if (selected?.searchType != null) {
    tripType = selected.searchType;
  }

  if (accommodationId != null) {
    accID = accommodationId;
  }

  useOutsideClick([selectorRef], () => {
    setSelectExpanded(false);
  })

  useEffect(() => {


    const c = document.getElementById('cloudlink-chat-overlay-buttons') as HTMLElement;

    if (!selectExpanded) {
      if(c != null){
        c.hidden = false;
      }
      return;
    }

    if(c != null){
      c.hidden = true;
    }


    // getAllAirports();
  }, [selectExpanded]);


  useEffect(() => {
    setSelectedCount(airports.length);
  }, [airports]);

  useEffect(() => {
    setSelectedValues(selectedSearchParams.departurePoints)
    setSelectedCount(selectedSearchParams.departurePoints.length);
  }, [selectedSearchParams.departurePoints]);

  const handleClose = () => {
    setSelectExpanded(false);
  }

  const handleApplyValues = (selectedPoints: any) => {
    setSelectedValues(selectedPoints);
    clickApplyHandler(selectedPoints);
    setSelectedCount(selectedPoints.length);
  }; 
  
  const handleReset = () => {

    setSelectedValues([]);
    clickApplyHandler([]);
    setSelectedCount(0);
  }

  const getInputFieldText = () => {

    if (tripType == 2) {
      if (selectedValues == null) {
        return 'Select Airport';
      }
      if (selectedValues.length === 0) {
        return 'Select Airport';
      } 
      else {
        const geoAirprots = geoDeparturePoints();
        let depName = selectedValues[0];
        for(const gd of geoAirprots){
          if(gd.code == selectedValues[0]){
              depName = gd.name;
              break;
          }
        }
        return depName;
      }
    } else {

      if (selectedValues == null) {
        return 'Any Airport';
      }
      if (selectedValues.length === 0) {
        return 'Any Airport';
      } 
      else {
        const geoAirprots = geoDeparturePoints();

        let depName = '';
        for(const gd of geoAirprots){
          if(gd.code == selectedValues[0]){
              depName = gd.name;
              break;
          }
        }
        if(selectedValues.length == 1){
          return depName;
        }
        else{
          return depName + ' + ' +  (selectedValues.length - 1) + ' More';
        }
      }
    }
  };

  let planeIco = planeDefault;
  if(configuration.icon_set == 'blue'){
    planeIco = plane;
  }

  const icon = <img src={planeIco} width='36' alt='airplane' />;

  let depSelector;
  if(selected.searchType == 2){
    let ap = null;
    if(airports.length > 0){
      ap = airports[0];
    }

    let regionID = -1;
    if(regions.length > 0){
      regionID = Number(regions[0])
    }
    let resortID = -1
    if(resorts.length > 0){
      resortID = Number(resorts[0])
    }

    depSelector =   <DeparturePointSelectorPackage isOpen={selectExpanded} rooms={rooms} selectedDeparturePoints={airports} selectedDeparturePointCodes={airports} duration={duration} 
    departureDate={selected.departureDate} selectedRegion={regions} selectedResort={resorts} 
    onChange={(option) => handleApplyValues(option)} onClose={() => handleClose()}  />;

  }else{
    let departing = null;
    if(checkDate){
      departing = selected.departureDate;
    }

    depSelector = (
      <DeparturePointSelector isOpen={selectExpanded} rooms={rooms} selectedDeparturePoints={airports} duration={duration}
      boards={boards} ratings={ratings} tripRatings={tripRatings} departureDate={departing}
      departureType={selected.departureDateType} tripType={selected.searchType} selectedDestinations={destinations} accommodationID={accommodationId} 
      selectedRegions={regions} selectedResorts={resorts} 
      onChange={(option) => handleApplyValues(option)} onClose={() => handleClose()}  />
    );
  }

  let val = true;
  if(validationError != null){
    val = validationError.valid;
  }


  return (
    <>
      {selectExpanded && (
        <div
          onClick={() => {
            setSelectExpanded(false);
          }}
          className='airport-select-overlay'
        ></div>
      )}
      <div ref={selectorRef} className='search-bar-input-field-autocomplete'>
        <SearchBarInputField
          valid={val}
          title='Departure Airport'
          value={getInputFieldText()}
          focus={selectExpanded}
          configuration={configuration}
          handleReset={() => {
            handleReset();
          }}
          onClick={() => {
            setSelectExpanded(!selectExpanded);

            if (onActivate) {
              onActivate();
            }
          }}
          icon={showIcons ? icon : null}
          selectedCount={selectedCount}
        />

        {depSelector}
      </div>

    </>
  );
};

export default AirportsSelect;
