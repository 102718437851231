
import { SearchSelectionRoom, LeadCustomer, Person } from '../hooks/types';
import {
    sessionUpdate,
    sessionHandOff
  } from '../hooks/useRequest';

export function adultsFromPaxString(paxString: string){
    const rooms = paxString.split('_')

    let adults = 0;
    for(const r of rooms){
        const rs = r.split('-');
        adults += Number(rs[0])
    }

    return adults;
}
export function childrenFromPaxString(paxString: string){
    const rooms = paxString.split('_')
    const ages = [];
    for(const r of rooms){
        const rs = r.split('-');
        if(rs.length > 1){
            if(rs[1] != ''){
                const as = rs[1].split(',');
                for(const a of as){
                    ages.push(Number(a));
                }
            }
        }
    }
    return ages;
}
export function childrenCountFromPaxString(paxString: string){
    const rooms = paxString.split('_')
    let children = 0;
    for(const r of rooms){
        const rs = r.split('-');
        if(rs.length > 1){
            if(rs[1] != ''){
                const as = rs[1].split(',');
                children += as.length;
            }
        }
    }
    return children;
}

export function roomsFromPaxString(paxString: string){
    const roomArr = [];
    const rooms = paxString.split('_')
  
    for(const r of rooms){

        const rs = r.split('-');
        let adults = Number(rs[0])
        let children = 0;
        if(rs.length > 1){  
            if(rs[1] != ''){
                const as = rs[1].split(',');
                children = as.length;
            }
        }
        const ages = [];
        if(rs.length > 1){
            if(rs[1] != ''){
                const as = rs[1].split(',');
                for(const a of as){
                    ages.push(Number(a));
                }
            }
        }

        roomArr.push({adults: adults, children: children, childAges: ages})
    }
    return roomArr;
}

export function roomsToString(rooms: SearchSelectionRoom[]){

  const roomString = [];

  for(const x of rooms){
    let adults = x.adults.toString();
    const childAges = [];
    for(const a of x.childAges){
        childAges.push(a);
    }
    const strChildAges = childAges.join(',');
    roomString.push(adults + '-' + strChildAges);
  }

  return roomString.join(',')
}

export function roomsToStringURL(rooms: SearchSelectionRoom[]){

    const roomString = [];
  
    for(const x of rooms){
      let adults = x.adults.toString();
      const childAges = [];
      for(const a of x.childAges){
          childAges.push(a);
      }
      const strChildAges = childAges.join(',');
      roomString.push(adults + '-' + strChildAges);
    }
  
    return roomString.join('_')
}

export function stringToGQL(strRooms: string){

    const rooms = strRooms.split('_');
    const gRooms = [];
    for(const r of rooms){

        const andc = r.split('-')

        const ages = [];
        if(andc.length > 1){
            if(andc[1] != ''){
                const as = andc[1].split(',');
                for(const a of as){
                    ages.push(Number(a));
                }
            }
        }

        let strRoom = andc[0];
        if(ages.length > 0){
            strRoom += '-' + ages.join(',');
        }

        gRooms.push(strRoom);
    }
    return gRooms;
}

export function adultCountFromRooms(rooms : SearchSelectionRoom[]){
    let adults = 0;
    for(const x of rooms){
        adults += x.adults;
    }
    return adults;
}

export function childCountFromRooms(rooms: SearchSelectionRoom[]){
    let children = 0;
    for(const x of rooms){
        children += x.childAges.length;
    }
    return children;
}
export function childCountFromRoomsExInfants(rooms: SearchSelectionRoom[]){
    let children = 0;
    for(const x of rooms){
        for(const a of x.childAges){
            if(a > 1){
                children ++;
            }
        }
    }
    return children;
}

export function childAgesFromRooms(rooms: SearchSelectionRoom[]){
    let children = [];
    for(const x of rooms){
        for(const r of x.childAges){
            children.push(r);
        }
    }
    return children;
}

export function roomCount(rooms: any[]){
    return rooms.length;
}

export function newLeadCustomer(){
    return {id:-1, age: 18, title:'', first_name: '', second_name: '', address1: '', 
    address2: '', address3: '', address4: '', post_code: '', adult: 1, date_of_birth: '', contact_telephone: '', 
    marketing_permission: 0, email_address: '', use_address_billing_address: 1, special_requests: ''} as LeadCustomer;
}

export function newPerson(){
    return {id: -1, title: '', first_name: '', second_name: '', adult: 1, age: 18, date_of_birth: '', gender: 'female'} as Person;
}

export function capturePersonEmail(leadPax: LeadCustomer, sessionId: string, userToken: string){
    if(sessionId == null){
      return
    }

    const custTitle = leadPax.title;
    const secondName = leadPax.second_name;
    const custName = custTitle + ' ' + secondName;

    const contactTelephone = leadPax.contact_telephone;

    const email = leadPax.email_address;

    if(sessionId != null){
      const so = sessionStorage.getItem('urf' + sessionId);
      if(so != null){ 
        const jso = JSON.parse(so);
        if(jso.steps.indexOf(3) == -1){
          jso.steps.push(3);
        }

        jso.contactEmail = email
        jso.contactNumber = contactTelephone,
        jso.leadName = custName;
        sessionStorage.setItem('urf' + sessionId, JSON.stringify(jso))
        sessionUpdate(userToken, sessionId, 3, 'Passengers', email, contactTelephone, custName, jso.type)

        const sho = sessionStorage.getItem('shosnt')
        if(email != '' && contactTelephone != '' && sho == null){
          sessionHandOff(userToken, sessionId, email, contactTelephone, custName, jso.type)
          sessionStorage.setItem('shosnt', '1');
        }
      }
    }
  };