import { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { Button } from '../Button';

import RoomIcon from '../assets/room.svg';

import { RoomGroup, Room } from '../../hooks/types';
import { addProduct, removeProduct } from '../../hooks/useRequest';

import './Accomodation.scss';
import { LoaderSpinnerSmall } from '../Loader/jLoader'

type AccommodationSelectionProps = {
  sessionId: string;
  selectedRooms: Room[];
  groups: [] | RoomGroup[];
  onSelect?: (rooms: Room) => void;
  disable: boolean;
  totalPax: number;
};

export function AccommodationSelection({ sessionId, selectedRooms, groups, onSelect, disable=false, totalPax}: AccommodationSelectionProps) {

  const [disableCom, setDisable] = useState(disable);
  const [selectingRoom, setSelecting] = useState({'room': -1, 'id': -1});

  const [selected, setSelected] = useState<Room[]>(selectedRooms);


  const compareRoomPrice = (selectedRoomPrice: number, room: Room ) => {
    let diff = 0;
    let strDiff = '';

    const price = selectedRoomPrice / totalPax;
    const roomPricePerPax = room.price / totalPax;

    if (room.price_per_person != null) {
      if (price > roomPricePerPax) {
        diff = Number((price - roomPricePerPax).toFixed(2));
        strDiff = '- £' + diff.toFixed(2);
      } else if (price < roomPricePerPax) {
        diff = Number((roomPricePerPax - price).toFixed(2));
        strDiff = '+ £' + diff.toFixed(2);
      } else {
        diff = 0;
        strDiff = '';
      }
    }
    return strDiff;
  };

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  useEffect(() => {
    setSelected(selectedRooms);
  }, [selectedRooms])

  useEffect(() => {
    setDisable(disable);
  }, [disable])

  async function handleSelection(group: RoomGroup, room: Room, roomIndex: number) {


    const selectedRoomNumber = room.room_number;

    if (sessionId && room?.id) {

      setDisable(true);

      setSelecting({'room': room.room_number || -1, 'id': room.id || -1})

      // remove all of the selected rooms

      const newRooms: Room[] = [room];
      for(const r of selected){
        if(r.room_number == room.room_number){
          await removeProduct(userToken, sessionId, r.id, 0);
        }
        else{
          newRooms.push(r);
        }
      }

      await addProduct(userToken, sessionId, room.id, 0, false);

  

      
    


      // const previousRoom = selectedRooms.find((room) => room.room_number === selectedRoomNumber);
      // if (previousRoom) {
      //   await addProduct(userToken, sessionId, room.id, 0, false, previousRoom.id);
      // } else {
      //   await addProduct(userToken, sessionId, room.id, 0, true);
      // }

      if (onSelect != null) {
        onSelect(room);
      }

  
      setSelected(newRooms);
      setSelecting({'room': -1, 'id': -1})
    }
  }

  let subTextColor = configuration.text_colour_secondary;
  if(configuration.spec == '6yk1y'){
    subTextColor = configuration.text_colour;
  }





  return (
    <div>
      {groups.map((group, index) => {
        let selectedPrice = 0;

        group.rooms.map((room) => {
          const selectedRoom = selected.find(
            (item) => item && item.room_number === room.room_number,
          );

          if (room.id === selectedRoom?.id) {
            if (room.price != null) {
              selectedPrice = room.price;
            }
          }
        });

        return (
          <div className='accomodation-container' key={uniqueId()} style={{ marginBottom: '1rem' }}>
            <h4 className='accomodation-title'>
              <img className='accomodation-icon' src={RoomIcon} height='20' width='20' />
              <span className='accomodation-title-text'>{`Room ${index + 1}`}</span>
            </h4>

            {group.rooms.map((room) => {
              const selectedRoom = selected.find(
                (item) =>
                  item &&
                  item.id === room.id &&
                  item.room_number === room.room_number,
              );

              const diff = compareRoomPrice(selectedPrice, room);

              const priceStyle = {color: ''}
              if(configuration != null){
                priceStyle.color = configuration.jrny.price_colour;
              }


              let diffV = null;
              if (diff != '') {
                diffV = (
                  <span style={priceStyle} className='accomodation-container-price__value'>
                    {diff} <br />{' '}
                    <span style={{color: configuration.jrny.price_colour}} className='accomodation-container-per-person'>Per Person</span>
                  </span>
                );
              }

              let isSelected = false;
              let isDisabled = false;
              const selStyle = {borderColor: '', color: configuration.text_colour};
              if(selectedRoom){
                isSelected = true;
                isDisabled = true;
                selStyle.borderColor = configuration.jrny.selected_button_border_colour;
              }

              let btn = <Button
                    hoverStyle={true}
                    isSelected={isSelected}
                    overrideClassNames={`accomodation-button ${
                      selectedRoom ? 'accomodation-button-active' : ''
                    }`}
                    isTransparent={false}
                    label={selectedRoom ? 'Selected' : 'Select'}
                    onClick={() => handleSelection(group, room, index)}
                    color={configuration.button_text_secondary_colour}
                    disabled={disableCom}
                  />

              if(disableCom){

                if(room.room_number == selectingRoom.room && room.id == selectingRoom.id){
                  btn = <div className='accomodation-button' style={{display:'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: configuration.jrny.selected_button_colour,
                  borderColor: configuration.jrny.selected_button_border_colour, color: configuration.jrny.selected_button_text_colour}}>{LoaderSpinnerSmall()}</div>
                }else{
                  btn = <Button
                  hoverStyle={true}
                  isSelected={isSelected}
                  disabled={disableCom}
                  overrideClassNames={`accomodation-button ${
                    selectedRoom ? 'accomodation-button-active' : ''
                  }`}
                  isTransparent={false}
                  label={selectedRoom ? 'Selected' : 'Select'}
                  onClick={() => handleSelection(group, room, index)}
                  color={configuration.button_text_secondary_colour}
                  />
                }
              }

              return (
                <div
                  style={selStyle}
                  key={uniqueId()}
                  className={`accomodation-container-child ${
                    selectedRoom ? 'accomodation-container-child-active' : ''
                  }`}
                >
                  <div>
                    <h4 className='accomodation-container-title'>
                      {room.description} ({room.board_description})
                    </h4>
                    <span style={{color: subTextColor}} className='accomodation-container-room'>{room.board_description}</span>
                  </div>

                  <div className='accomodation-container-price'>{diffV}</div>

                  <div>
                    {btn}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
