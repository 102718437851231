import { useEffect, useState, FunctionComponent } from 'react';
import './Footer.scss';
import './Accordion/Accordion.scss';
import facebookIcon from '../assets/facebook.svg';
import twitterIcon from '../assets/twitter.svg';
import instagramIcon from '../assets/insta.svg';
import { accordionData } from './Accordion/AccordionData';
import Accordion from './Accordion/Accordion';


import atba from '../assets/atba.svg';
import atol from '../assets/atol.svg';

import atbaDefault from '../assets/icons/default/01/ABTA.svg';
import atolDefault from '../assets/icons/default/01/TOL.svg';

import TrustBox from '../TrustPilot/TrustBox';
import { siteConfiguration } from '../../hooks/useRequest';
import { link } from 'fs';

interface FooterProps {
  userToken: string
  userConfiguration: any
}

const Footer:  FunctionComponent<FooterProps> = ({
  userToken,
  userConfiguration
}) => {


  const [trustConfig, setTrustCon] = useState<any>(null);

  useEffect(() => {  
    siteConfiguration('22',userToken).then((d) => {
      setTrustCon(d);
    });

  }, []);


  let trustCom = null;
  if(trustConfig != null){
    trustCom = <TrustBox templateID={trustConfig.templateID} businessunitID={trustConfig.businessunitID} theme={trustConfig.theme} stars={trustConfig.stars}></TrustBox>
  }

  const sp = [];
  const footerWords = userConfiguration.footer.small_print.split(' ');
  for(const x of footerWords){
    if(x.indexOf('https://') != -1){
      const jh = 500;
      sp.push(<a href={x} target='_blanc' >{x}</a>);
    }
    else{
      sp.push(x + ' ');
    }

  }

  const links = [];
  const mobLinks = [];
  const linkColor = userConfiguration.footer.menu.link_colour;

  for(const x of userConfiguration.footer.menu.columns){


    const linkItems = [];
    let ic = 0;
    for(const i of x.links){
      linkItems.push(<li key={'dlnk' + ic}>
        <a style={{color: linkColor}} href={i.url}>{i.text}</a>
        </li>);
      ic++;
    }

    links.push(<div className='accordion-item'>
      <div className='accordion-title'>
        <div style={{color: userConfiguration.text_colour}}>{x.title}</div>
        </div>
        <ul className={'accordion-content'}>
          {linkItems}
            </ul>
  </div>)

    const mobLinkItems = [];
    ic = 0;
    for(const i of x.links){
      if(ic > 2){
        break;
      }
      mobLinkItems.push(<li key={'mlnk' + ic}>
        <a style={{color: linkColor}} href={i.url}>{i.text}</a>
        </li>);
      ic ++;
    }
    mobLinks.push(<div className='accordion-item'>
        <div className='accordion-title'>
          <div style={{color: userConfiguration.text_colour}}>{x.title}</div>
          </div>
          <ul className={'accordion-content'}>
            {mobLinkItems}
              </ul>
        </div>)

  }


  let newsLetter = null;
  if(userConfiguration.newsletter_url != ''){
    newsLetter = <div style={{backgroundColor: userConfiguration.news_banner_colour}} className='footer-newsletter-container'>
    <div className='footer-newsletter'>
      <div className='footer-newsletter-copy'>
        <h3 style={{color: userConfiguration.text_colour}}>Get exclusive offers and much more</h3>
        <p style={{color: userConfiguration.text_colour}}>Sign up to our emails today...</p>
      </div>
      <a
        href={userConfiguration.newsletter_url}
        className='button button--medium button--tertiary'
      >Sign up
      </a>
    </div>
  </div>
  }

  let fbIcon = null;
  let xIcon = null;
  let instIcon = null;

  if(userConfiguration.facebook_url != ''){
    fbIcon = <a
    href={userConfiguration.facebook_url}
    target='_blank'
    rel='noreferrer noopener'
  ><img src={facebookIcon} alt='facebook page' className='footer-social-icons' /></a>
  }

  if(userConfiguration.x_url != ''){
    xIcon = <a href={userConfiguration.x_url } target='_blank' rel='noreferrer noopener'>
    <img src={twitterIcon} alt='facebook page' className='footer-social-icons' />
  </a>
  }

  if(userConfiguration.insta_url != ''){
    instIcon =  <a href={userConfiguration.insta_url} target='_blank' rel='noreferrer noopener'>
    <img src={instagramIcon} alt='facebook page' className='footer-social-icons' />
  </a>
  }


  let atolIco = atolDefault;
  let abtaIco = atbaDefault;
   
  if(userConfiguration.icon_set == 'blue'){
    atolIco = atol;
    abtaIco = atba;
  }



  return (
    <footer className='page-footer'>
      {trustCom}

      {newsLetter}
      <div className='footer-links-container'>
        <div className='footer-socials-mobile'>
          {fbIcon}
          {xIcon}
          {instIcon}
        </div>
        <div className='footer-links'>
          {links}
        </div>
        <div className='footer-links-mob'>
          {mobLinks}
        </div>
        <div className='footer-socials-desktop'>
          {fbIcon}
          {xIcon}
          {instIcon}
        </div>
      </div>
      <div style={{borderColor: userConfiguration.footer.line_colour}} className='footer-information'>
        <div className='footer-information-copyright'>
          <div className='footer-information-images'>
            <img src={abtaIco} alt='atba logo' height='25px' />
            <img src={atolIco} alt='atba logo' height='40px' />
          </div>

          <span style={{color: userConfiguration.text_colour}}>
            {userConfiguration.footer.copy_right}
          </span>
        </div>
        <div style={{color: userConfiguration.text_colour}}>
          {sp}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
