import React, { ReactNode, FunctionComponent, useState, useEffect } from 'react';
import { noop } from 'lodash';
import { Modal } from '../Modal';
import Map, { Place, Coordinates } from '../MapModal/Map';
import { HolidayTopDetails } from '../HolidayDetails';
import SimpleTabs from './SimpleTabs';


import hotelIcon from '../assets/hotel.svg';
import hoteliconDefault from '../assets/icons/default/03/Hotel.svg';

import locationIcon from '../assets/location.svg';
import locationIconDefault from '../assets/icons/default/03/Map.svg';


import tripadvisorIcon from '../assets/tripadvisor_logo.svg';

import { ImageCarousel, Image } from '../ImageCarousel';
import { TripadvisorReviews } from '../Tripadvisor';
import { getAccommodationDetail } from '../../hooks/useRequest';

import './ModalWithTabs.scss';
import { ImageSlider } from '../ImageSlider/ImageSlider';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

interface ModalWithTabsProps {
  isOpen: boolean;
  activeTabNumber?: number;
  toggle?: () => void;
  places?: Place[];
  center: Coordinates;
  hotelId: number;
  hotelName: string;
  resortName: string;
  regionName: string;
  destinationName: string;
  rating: number;
  tripadvisorRating: number;
  price: number;
  images: Image[];
  title?: string;
}

export interface TabsData {
  tabsList: {
    name: string;
    icon: string;
  }[];
  tabsContent: ReactNode[];
}

const ModalWithTabs: FunctionComponent<ModalWithTabsProps> = ({
  isOpen,
  activeTabNumber = 0,
  toggle = noop,
  places,
  center,
  hotelId,
  hotelName,
  resortName,
  regionName,
  destinationName,
  rating,
  tripadvisorRating,
  price,
  images,
  title,
}: ModalWithTabsProps) => {
  const [selectedPlace, setSelectedPlace] = useState<Place>();
  const [activeTab, setActiveTab] = useState<number>(activeTabNumber);
  const [accommodationDetail, setAccDetail] = useState<any>(null);


  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  useEffect(() => {
    if (hotelId == null) {
      return;
    }

    getAccommodationDetail(userToken, hotelId).then((d) => {
      if (d == null) {
        return;
      }
      if (d.accommodation.result == null) {
        return;
      }

      const accDetail = d.accommodation.result.detail;
      setAccDetail(accDetail);
    });
  }, [hotelId]);

  const MapContent = (
    <Map
      hideInfo
      shouldShowInfoBox={true}
      key={activeTab}
      places={places}
      center={center}
      hotelName={hotelName}
      rating={rating}
      tripadvisorRating={tripadvisorRating}
      price={price}
      onMarkerClick={(place: Place) => setSelectedPlace(place)}
    />
  );

  const HotelInformationContent = (
    <div className='hotel-information-wrapper'>
      <HolidayTopDetails
        hotelName={hotelName}
        tripadvisorRating={tripadvisorRating}
        rating={rating}
        resortName={resortName}
        regionName={regionName}
        destinationName={destinationName}
      />

      <div className='hotel-information-slider-wrapper'>
        <ImageSlider images={images} height={420} />
      </div>

      <h3>Description</h3>

      <p>{accommodationDetail}</p>
    </div>
  );

  const TripadvisorReviewsContent = (
    <TripadvisorReviews
      hotelId={hotelId}
      hotelName={hotelName}
      tripadvisorRating={tripadvisorRating}
    />
  );


  let mapIco = locationIconDefault;
  let hotelIco = hoteliconDefault;
  if(configuration.icon_set == 'blue'){
    mapIco = locationIcon
    hotelIco = hotelIcon;
  }

  const tabs = [
    { name: 'Hotel Information', icon: hotelIco },
    { name: 'Map', icon: mapIco },
  ];

  if (tripadvisorRating != -1) {
    tabs.push({ name: 'Tripadvisor Reviews', icon: tripadvisorIcon });
  }

  const tabsData: TabsData = {
    tabsList: tabs,
    tabsContent: [HotelInformationContent, MapContent, TripadvisorReviewsContent],
  };

  const modalHeader = (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>{title}</h5>
    </div>
  );

  return (
    <div className='modal-with-tabs'>
      <Modal isOpen={isOpen} toggle={toggle} header={title ? modalHeader : null}>
        <SimpleTabs
          activeTabNumber={activeTabNumber}
          tabsData={tabsData}
          onTabChange={setActiveTab}
        />
      </Modal>
    </div>
  );
};

export default ModalWithTabs;
