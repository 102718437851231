import React, { FunctionComponent } from 'react';
// import { FlightDetails, FlightDetailsState } from '../HolidaySummary/FlightDetails';
// import { Button } from '../Button';
// import cn from 'classnames';
// import { Pagination } from '../Pagination/Pagination';
import dayjs from 'dayjs';

import plane from '../assets/plane-big.svg';
import planeDefault from '../assets/icons/default/05/Plane.svg';


import ezyLogo from '../assets/airline/ezy.svg';
import tomLogo from '../assets/airline/tom.svg';
import jet2 from '../assets/airline/jt2.svg';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
// interface SelectFlightsProps {
//   title: string;
//   titleImage: string;
//   flightsDetails: FlightDetailsState[][];
//   selectedId?: number;
//   onClick?: (details: FlightDetailsState, inbounds: FlightDetailsState) => void;
// }

const PackageFlight: FunctionComponent<any> = ({
  flightData
}: any) => {


  const {
    RootStore: {
      configuration
    },
  } = useStores() as { RootStore: RootStore };

  let flightIco = planeDefault;
  if(configuration.icon_set == 'blue'){
    flightIco = plane;
  }


const flightSection = (departureDate:string, flightType: string, departurePlace: string, arrivalDate: string, arrivalPlace: string , airlineCode: string) => {
    const airlineLogoImage = <img style={{maxWidth: '120px'}} src={jet2}></img>;
    
    const depDate = dayjs(departureDate);
    const arrDate = dayjs(arrivalDate);

    

    return (<div className='flight-details-wrapper'>
    <div className='flight-details--top-section'>
      <div className='top-date-details'>
        <img src={flightIco} alt='' />
        <div className={'info-section'}>
          <span className='title'>{depDate.format('DD-MM-YY')}</span>
          <span className='sub-title'>{flightType}</span>
        </div>
      </div>
      <div className='flight-details--main-section'>
        <ul className='route-point'>
          <li className={'route-point--item'}>
            <span className='time'>{depDate.format('HH:mm')}</span>
            <span className='place-name'>{departurePlace}</span>
            <span className='flight-time'>{}</span>
          </li>
          <li className={'route-point--item'}>
            <span className='time'>{arrDate.format('HH:mm')}</span>
            <span className='place-name'>{arrivalPlace}</span>
          </li>
        </ul>
      </div>
    <div className='flight-info'>
        <span className='airline-logo-text'>{airlineLogoImage}</span>
        <span className='airline-code'>({airlineCode})</span>
    </div>
    </div>
  </div>);
}

const outboundSection = flightSection(flightData.outbound_departure, 'Outbound', flightData.departure_point.name, flightData.outbound_arrival, flightData.arrival_airport.name, flightData.airline);
const inbBoundSection = flightSection(flightData.inbound_departure, 'Inbound', flightData.arrival_airport.name, flightData.inbound_arrival, flightData.departure_point.name, flightData.airline);




  return (
    <div className={'select-flights-wrapper'}>
      <div className='select-flights-wrapper--header'>
        <img src={flightIco} alt='' />
        <span>{'Your Flight'}</span>
      </div>
      <div className='select-flights-wrapper--body'>
        <div className='select-flights-list'>

        <div className={'airline-order-section selected-airline'} key={'select-flights-row'}>
            <div className='airline-wrapper'>
            <div className={'airline-item-wrapper'} key={0}>
                {outboundSection}
                <hr style={{borderColor: configuration.card_border_colour}} className='solid'></hr>
                {inbBoundSection}
            </div>
            </div>
            <div className={'airline-order-section selected-airline'}></div>
            <div className='airline-order-section--save'></div>

            </div>
        </div>
         
        
        </div>
    </div>
  );
};

export default PackageFlight;