import React, { FunctionComponent, useState, useEffect } from 'react';
import { SearchBar } from '../../components/SearchBar';
import { BaseLayout } from '../../components/BaseLayout';
import { Menu } from '../../components/Menu/Menu';
import { noop } from 'lodash';
import './HomePage.scss';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
interface HomePageProps {
  onLogin?: () => void;
  openMenu?: () => void;
}

export const HomePage: FunctionComponent<HomePageProps> = ({ onLogin = noop, openMenu = noop }) => {
  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(true);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);


  const {
    RootStore: {
      userToken,
      configuration
    },
  } = useStores() as { RootStore: RootStore };

  
  useEffect(() => {
    sessionStorage.removeItem('session_cr');
  }, [])

  return (
    <BaseLayout
      onSearchButtonClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
      userToken={userToken}
      userConfiguration={configuration}
    >
 
      {isSearchBarOpen ? (
        <div style={{ maxWidth: 1140, margin: '0 auto' }}>
          <div className='search-bar-wrapper'>
            <SearchBar
              isBoardType={false}
            />
          </div>
        </div>
      ) : null}
      {menuOpen && (
        <div className='Fullscreen-cover'>
          <Menu onClose={() => setMenuOpen(false)} />
        </div>
      )}
    </BaseLayout>
  );
};
