import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Button } from '../Button/Button';
import { Image, ImageCarousel } from '../ImageCarousel';
import NameSection from '../HolidayCard/NameSection/NameSection';
import PackDetails from './PackDetails/PackDetails';
import dayjs from 'dayjs';
import { Coordinates } from '../MapModal';
import { ImageSlider } from '../ImageSlider/ImageSlider';

import '../HolidayCard/HolidayCard.scss';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

import { packagePaymentPlan } from '../../hooks/useRequest'

import { LoaderSpinner } from '../Loader/jLoader';

import { roomsToString} from '../../services/pax';

import ddLogo from '../../direct-debit-logo.svg';
import ddLogob from '../../direct-debit-blue.svg';
import { Package } from '../../hooks/types';

interface PackageHolidayCardProps {
  id: string;
  sessionId: string;
  packageOffer: Package
  isTotalPriceFlag: boolean;
  searchParams: URLSearchParams;
  selectedRooms: any[];
  isMap: boolean;
  price: number;
  pricePerPerson: number;
  onMapLinkClick: () => void;
  onOfferClick: (id: string) => void;
}

const PackHolidayCard: FunctionComponent<PackageHolidayCardProps> = ({
  id,
  sessionId,
  packageOffer,  
  searchParams,
  isTotalPriceFlag,
  selectedRooms,
  isMap = false,
  price,
  pricePerPerson,
  onMapLinkClick,
  onOfferClick,
}: PackageHolidayCardProps) => {

  const {
    RootStore: {
      configuration,
      userToken,
    },
  } = useStores() as { RootStore: RootStore };


  const [isDepositVisible, setIsDepositVisible] = useState<boolean>(false);
  const [isDepositVisibleMOnthly, setIsDepositVisibleMonthly] = useState<boolean>(false);
  const [paymentPlanData, setPaymentPlanData] = useState<any>(null);
  const [depositInfoReady, setIsDepositInfoRedy] = useState<boolean>(false);


  const offerDepositDetail = useRef<HTMLDivElement>(null);
  const offerDepositDetailSpan = useRef<HTMLDivElement>(null)

  const offerDepositDetailMonthly = useRef<HTMLDivElement>(null);
  const offerDepositDetailMonthlySpan = useRef<HTMLDivElement>(null);

  useEffect(() => {

    if(!isDepositVisibleMOnthly && !isDepositVisible){
      return;
    }
    if(paymentPlanData != null){
      return
    }

    // const rms = JSON.stringify(roomsToString(searchParams));
    
    const prods = []
    for(const x of selectedRooms){
      prods.push(x.id);
    }
    

    if(packageOffer.rooms){
      packagePaymentPlan(userToken, sessionId || '', Number(id), prods).then((opd: any) => {

        setPaymentPlanData(opd.package_payment_plan.result);
        setIsDepositInfoRedy(true);
      })
    }
    else{
      setIsDepositInfoRedy(true);
    }


   
  }, [isDepositVisible, isDepositVisibleMOnthly]);


  const generateDateValue = () => {
    const nightString = +packageOffer.duration > 1 ? 'nights' : 'night';
    const departingValue =  packageOffer.flight.outbound_departure;

    return `${dayjs(departingValue).format('D MMM YYYY')} - ${packageOffer.duration} ${nightString}`;
  };
  const navigate = useNavigate();

  const selectOffer = async() => {
    // const jh = 500;

    const prodIds = [];
    for (const sr of selectedRooms) {
      prodIds.push(sr.id);
    }

    const strProdIds = prodIds.join('-');

    onOfferClick(id);

  
    // sessionStorage.removeItem('js1');
    // sessionStorage.removeItem('js2');
    // sessionStorage.removeItem('js3');
    // sessionStorage.removeItem('js4');
    // sessionStorage.removeItem('js5');
    // sessionStorage.removeItem('shosnt');



    const url_dest = packageOffer.accommodation.resort.regions[0].destinations[0].name.replaceAll(' ', '-').toLowerCase();
    const url_region = packageOffer.accommodation.resort.regions[0].name.replaceAll(' ', '-').toLowerCase();
    const url_resort = packageOffer.accommodation.resort.name.replaceAll(' ', '-').toLowerCase();
    const acc_name = packageOffer.accommodation.name.replaceAll(' ', '-').toLowerCase();


    const url_i = `/package/${url_dest}/${url_region}/${url_resort}/${acc_name}/${packageOffer.accommodation.id}?package=${packageOffer.id}&pakProducts=${strProdIds}&session=${sessionId}`;
    navigate(url_i);
  };


  const showDeposit = (e: any) => {
    setIsDepositVisible(!isDepositVisible)
    setIsDepositVisibleMonthly(false);
return
}

const showDepositMonth = (e: any) => {

  setIsDepositVisibleMonthly(!isDepositVisibleMOnthly)
  setIsDepositVisible(false);
return

}

  const priceSection = () => {
    let salePrice = pricePerPerson.toFixed(0) + 'pp';
    if (isTotalPriceFlag) {
      salePrice = price.toFixed(0);
    }

    let priceSection = <span className='sale-price'>£{salePrice}</span>;
    if (isNaN(pricePerPerson)) {
      priceSection = <span className='sale-price'></span>;
    }

    let depositSection = null;
    let depositSectiondesk = null;
    let priceBtnSecClass = 'price-section-buttons only'
    let priceBtnClass = 'button price-btn';
    let wrapper = 'price-wrapper price-wrapper-s';
    
    
    if(packageOffer.rooms[0].deposit_price > 0){
    priceBtnSecClass = 'price-section-buttons only'
    priceBtnClass = 'button price-btn';
    wrapper = 'price-wrapper';
    let depOverlay = null;
    let depOverlayMonthly = null;


    let arrowDepOff = 'arrow';
    let borderDep = configuration.button_colour_tertiary;
    if(isDepositVisible){

      borderDep = configuration.border_colour;
      arrowDepOff = 'arrowon';

      depOverlay = <div className='offer-deposit-detail' ref={offerDepositDetail}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px'}}>
            {LoaderSpinner()}
          </div>
      </div>

      if(depositInfoReady){

        let monthlySection = null;

        if(paymentPlanData != null){
            const avgAmount = paymentPlanData.outstanding_balance / paymentPlanData.plan.length;
            monthlySection = <div className='offer-deposit-section'>
            <label>Pay monthly by Direct Debit</label>
            <div className='offer-deposit-line'>
                <span>Pay today</span>
                <span>£{packageOffer.rooms[0].deposit_price.toFixed(0)} pp</span>
            </div>
            <div className='offer-deposit-line'>
                <span>{paymentPlanData.plan.length} x monthly payments</span>
                <span>£{avgAmount.toFixed(0)}</span>
            </div>
        </div>
        }

        let cn = 'offer-deposit-detail';
        if(monthlySection == null){
          cn = 'offer-deposit-detail offer-deposit-detail-s'
        }

        depOverlay =  <div className={cn} ref={offerDepositDetail}>
        <div className='offer-deposit-section'>
            <label>Pay Standard deposit</label>
            <div className='offer-deposit-line'>
                <span>Pay today</span>
                <span>£{packageOffer.rooms[0].deposit_price_per_person.toFixed(0)} pp</span>
            </div>
            <div className='offer-deposit-line'>
                <span>Pay final balance</span>
                <span>{dayjs(packageOffer.rooms[0].deposit_due).format('Do MMM YYYY')}</span>
            </div>
        </div>

        {monthlySection}

        <div className='offer-deposit-section'>
            <label>Pay full amount</label>
            <div className='offer-deposit-line'>
                <span>Balance per person</span>
                <span>£{pricePerPerson.toFixed(0)}</span>
            </div>
        </div>
      </div>;
      }
    }

    let arrowDepMonthOff = 'arrow';
    let borderDepmonth = configuration.button_colour_tertiary;

    if(isDepositVisibleMOnthly){
      borderDepmonth = configuration.border_colour;
      arrowDepMonthOff = 'arrowon';
      depOverlayMonthly = <div className='offer-deposit-detail monthly' ref={offerDepositDetailMonthly}>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px'}}>
        {LoaderSpinner()}
      </div>
  </div>

      if(depositInfoReady){

    let monthlySection = null;

    if(paymentPlanData != null){
        const avgAmount = paymentPlanData.outstanding_balance / paymentPlanData.plan.length;
        monthlySection = <div className='offer-deposit-section'>
        <label>Pay monthly by Direct Debit</label>
        <div className='offer-deposit-line'>
            <span>Pay today</span>
            <span>£{packageOffer.rooms[0].deposit_price_per_person.toFixed(0)} pp</span>
        </div>
        <div className='offer-deposit-line'>
            <span>{paymentPlanData.plan.length} x monthly payments</span>
            <span>£{avgAmount.toFixed(0)}</span>
            </div>
        </div>
        }
      depOverlayMonthly = <div className='offer-deposit-detail monthly' ref={offerDepositDetailMonthly}>{monthlySection}</div>;
      }
    }

    let ddico = ddLogo;
    if(configuration.icon_set == 'blue'){
      ddico = ddLogob;
    }


    const depositSectionChilds = [];
    const depositSectiondeskChilds = [];

    depositSectionChilds.push(<div id='deposit_st' ref={offerDepositDetailSpan} onClick={(e) => showDeposit(e)} style={{borderColor: borderDep, display: 'flex', alignItems: 'center', marginBottom: '10px', height:'38px'}} className='rounded-border'>
        <span id='deposit_st_a' className='deposit_price_text'>£{packageOffer.rooms[0].deposit_price_per_person.toFixed(0)} pp deposit</span>
        <i id='deposit_st_b' className={arrowDepOff} style={{borderColor: configuration.border_colour}} title="arrow icon"></i>
        {depOverlay}
      </div>)

depositSectiondeskChilds.push(<div  id='deposit_st' ref={offerDepositDetailSpan} onClick={(e) => showDeposit(e)}  style={{borderColor: borderDep, display: 'flex', alignItems: 'center', marginRight: '20px'}} className='rounded-border'>
  <span id='deposit_st_a' className='deposit_price_text'>£{packageOffer.rooms[0].deposit_price_per_person.toFixed(0)} pp deposit</span>
  <i id ='deposit_st_b' className={arrowDepOff} style={{borderColor: configuration.border_colour}} title="arrow icon"></i>
  {depOverlay}
</div>)

    if(packageOffer.rooms[0].payment_plan_available){

      depositSectionChilds.push(<div id='deposit_month' ref={offerDepositDetailMonthlySpan} onClick={(e) => showDepositMonth(e)}  style={{borderColor: borderDepmonth, display: 'flex', alignItems: 'center', height:'38px'}} className='rounded-border'>
      <span id='deposit_month_a' className='deposit_price_text'>Pay monthly by</span>
      <span id='deposit_month_b'><img id='deposit_month_c' src={ddico} style={{width: '75px', height:'26px'}}  alt='Direct Debit Guarantee' /></span>
      <i id ='deposit_month_d' className={arrowDepMonthOff} style={{borderColor: configuration.border_colour}} title="arrow icon"></i>
      {depOverlayMonthly}
    </div>)

    depositSectiondeskChilds.push(<div id='deposit_month' ref={offerDepositDetailMonthlySpan} onClick={(e) => showDepositMonth(e)} style={{borderColor: borderDepmonth, display: 'flex', alignItems: 'center'}} className='rounded-border'>
    <span id='deposit_month_a' className='deposit_price_text'>Pay monthly by</span>
    <span id='deposit_month_b'><img id='deposit_month_c' src={ddico} style={{width: '75px', height:'26px'}}  alt='Direct Debit Guarantee' /></span>
    <i id ='deposit_month_d' className={arrowDepMonthOff} style={{borderColor: configuration.border_colour}} title="arrow icon"></i>
    {depOverlayMonthly}
    </div>)

    }

    depositSection = <div className='deposit_mobile price-wrapper'>
    {depositSectionChilds}
  </div>

depositSectiondesk = <div className='deposit_desk price-wrapper'>
    {depositSectiondeskChilds}
  </div>

  }



    return (
      <div className='price-section'>
        <div className={wrapper}>{priceSection} {depositSectiondesk}
        </div>

        {depositSection}

        <div className={priceBtnSecClass}>
          {/* <Button
            size={'medium'}
            primary={true}
            onClick={() => selectOffer(id)}
            label={'View deal'}
          /> */}
          <Button onClick={() => selectOffer()} size={'medium'} primary={true} label='View deal' borderColor={configuration.button_colour} backgroundColor={configuration.button_colour} />
        </div>
        {/* </Link> */}
      </div>
    );
  };

  const boardDesc = packageOffer.rooms[0].board_description;

  return (
    <div id={id} className='holiday-card-wrapper'>
      <ImageSlider images={packageOffer.accommodation.images} />

      <div style={{borderColor: configuration.card_border_colour}} className='holiday-card-wrapper--details'>
        <NameSection
          isMap={isMap}
          onMapLinkClick={onMapLinkClick}
          destination={packageOffer.accommodation.resort.regions[0].destinations[0].name}
          region={packageOffer.accommodation.resort.regions[0].name}
          resort={packageOffer.accommodation.resort.name}
          name={packageOffer.accommodation.name}
          rating={Number(packageOffer.accommodation.rating)}
          tripadvisorRating={packageOffer.accommodation.trip_advisor_rating}
          tripadvisorReviewCount={packageOffer.accommodation.trip_advisor_num_reviews}
          isMapLinkVisible={false}
        />
        {/* <HolidayDetails date={generateDateValue()} rooms={rooms} flights={flights} /> */}
        <PackDetails date={generateDateValue()} config={configuration} boardDescription={boardDesc} flightData={packageOffer.flight} />

        {priceSection()}
      </div>
    </div>
  );
};

export default PackHolidayCard;
