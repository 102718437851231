import { FC } from 'react';
import { ILoader } from './Interfaces';
import './Loader.scss';

import IconFlights from '../assets/loader-flights.svg';
import IconRooms from '../assets/loader-hotels.svg';
import IconExtras from '../assets/loader-extras.svg';
import IconLoaderTick from '../assets/loader-tick.svg';


import AtolIcon from '../assets/atol.svg';
import AtbaIcon from '../assets/atba.svg';

import atolIconDefault from '../assets/icons/default/04/Atol.svg';
import atbaIconDefault from '../assets/icons/default/04/abta-1.svg';
import cashIconDefault from '../assets/icons/default/04/noun-pound-1634070-222222.svg';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';


export const LoaderSpinner = () => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


let bs = configuration.button_colour
if(configuration.spec == '6yk1y'){;
  bs = '#01447b';
}

  return (
    <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
      <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
    </div>
  );
};

export const LoaderTick = () => {
  return (
    <div>
      <div className='loader-tick'>
        <img src={IconLoaderTick} height={40} width={40} />
      </div>
    </div>
  );
};

export const Loader: FC<ILoader> = ({ loading, disable, gridTemplate }) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  return (
    <div className='loader-container'>
      <div className={`loader-grid ${gridTemplate ? gridTemplate : ''}`}>
        {!disable?.flights && (
          <div>
            <div style={{color: configuration.text_colour}}>Finding all flights options {loading.flights}</div>
            <div className='loader-icons-container'>
              <img className='loader-icons' src={IconFlights} height={100} width={100} />
            </div>
            <div className='loader-icons-container'>
              {!loading.flights && <LoaderSpinner />}
              {loading.flights && <LoaderTick />}
            </div>
          </div>
        )}
        {!disable?.rooms && (
          <div>
            <div style={{color: configuration.text_colour}}>Finding all room options {loading.rooms}</div>
            <div className='loader-icons-container'>
              <img className='loader-icons' src={IconRooms} height={100} width={100} />
            </div>
            <div className='loader-icons-container'>
              {!loading.rooms && <LoaderSpinner />}
              {loading.rooms && <LoaderTick />}
            </div>
          </div>
        )}
        {!disable?.extras && (
          <div>
            <div style={{color: configuration.text_colour}}>Finding travel extras {loading.extras}</div>
            <div className='loader-icons-container'>
              <img className='loader-icons' src={IconExtras} height={100} width={100} />
            </div>
            <div className='loader-icons-container'>
              {!loading.extras && <LoaderSpinner />}
              {loading.extras && <LoaderTick />}
            </div>
          </div>
        )}
      </div>
      <span style={{color: configuration.text_colour}}>Loading may take up to 30 seconds.</span>
      <div className='abta-atol-logo'>
        <img width={150} src={AtbaIcon} />
        <img width={50} src={AtolIcon} />
      </div>
    </div>
  );
};
