import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import './HolidayDetails.scss';

import { FlightDetails } from '../../HolidaySummary/FlightDetails';

import calendarLinesDefault from '../../assets/icons/default/02/noun-calendar-2080587-222222.svg';
import calendarLines from '../../assets/calendarLines.svg';

import kitchenDefault from '../../assets/icons/default/02/noun-food-750655-222222.svg';
import kitchen from '../../assets/food.svg';

import flightDefault from '../../assets/icons/default/02/noun-flight-1606533-222222.svg';
import fic from '../../assets/flight.svg';

import { useOutsideClick } from '../../../hooks/useOutsideClick';

import { Room, Flight } from '../../../hooks/types'

interface HolidayDetailsProps {
  date: string;
  rooms: Room[];
  flight: Flight;
  config: any;
}

const HolidayDetails: FunctionComponent<HolidayDetailsProps> = ({
  date,
  rooms,
  flight,
  config
}: HolidayDetailsProps) => {
  const roomsDescription = rooms?.map((room: Room) => room.board_description).join(', ');
  // const airports = flights?.map((flight: Flight) => flight.departure_airport.name).join(', ');
  const [isFlightDetailsVisible, setIsFlightDetailsVisible] = useState<boolean>(false)

  const outsideClickCallback = () => {
    setIsFlightDetailsVisible(false);
  }

  const offerFlightRef = useRef<HTMLDivElement>(null)
  const flightsWisiblebtnRef = useRef<HTMLDivElement>(null)
  useOutsideClick([offerFlightRef, flightsWisiblebtnRef], outsideClickCallback)

  useEffect(() => {
    if (offerFlightRef.current) {
      offerFlightRef.current.scrollIntoView({ behavior: 'smooth',  block: 'center', inline: 'center' });
    }
  }, [offerFlightRef.current]);


  let calLinesIco = calendarLinesDefault;
  let kitchenIco = kitchenDefault;
  let flightIco = flightDefault;
  
  if(config.icon_set == 'blue'){
    calLinesIco = calendarLines;
    kitchenIco = kitchen;
    flightIco = fic;
  }

  return (
    <div style={{borderColor: config.card_border_colour}} className='holiday-details-wrapper'>
      <div className='holiday-details--item'>
        <img src={calLinesIco} alt='' />
        <span>{date}</span>
      </div>
      <div className='holiday-details--item'>
        <img src={kitchenIco} alt='' />
        <span>{roomsDescription}</span>
      </div>
      <div className='holiday-details--item'>
        <img className={'flight'} src={flightIco} alt='' />
        {isFlightDetailsVisible && <div ref={offerFlightRef}>

        <div className={'offer-flight-details-wrapper'}>
           <FlightDetails 
                flightType={'Outbound'}
                airline={flight.airline}
                flightNumber={flight.number}
                departureTime={flight.departing}
                departurePlace={flight.departure_airport.name}
                arrivalPlace={flight.arrival_airport.name}
                arrivalTime={flight.arrival}
                outBound={true}
                config={config} />
           <FlightDetails flightType={'Inbound'}
                airline={flight.airline}
                flightNumber={flight.return_number}
                departureTime={flight.return_departing}
                departurePlace={flight.arrival_airport.name}
                arrivalPlace={flight.departure_airport.name}
                arrivalTime={flight.return_arrival}
                outBound={false}
                config={config} />
          </div>
          </div>}
        <span>{flight.departure_airport.name} - <span ref={flightsWisiblebtnRef} onClick={() => setIsFlightDetailsVisible(!isFlightDetailsVisible)} className="flight-details">Flight Details</span></span>
      </div>
    </div>
  );
};

export default HolidayDetails;

