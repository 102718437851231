import { isNumber } from "lodash";

export function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  export function isStringEmpty(str){
    if(str == null || str.trim() == ''){
      return true;
    }
    return false;
  }

  export function isStringNumber(str){
    if(str == null || str.trim() == ''){
      return false;
    }
    for(const x of str){
      const l = Number(x) ;
      if(isNaN(l)){
        return false;
      }
    }
    return true;
  }

  export function match(a, b, caseSensative=true){

    if(caseSensative){
      if(a.trim() == b.trim()){
        return true
      }
    }
    else{
      if(a.trim().toLowerCase() == b.trim().toLowerCase()){
        return true
      }
    }

    return false;
  }