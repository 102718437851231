import React, { FunctionComponent } from 'react';
import {
  Link
} from 'react-router-dom';
import logo from '../assets/logo.svg';

interface LogoProps {
  width: number;
  height: number;
  route: string;
  configuration?: any;
}

const LogoClick = () => {
  sessionStorage.removeItem('snpOff');
  sessionStorage.removeItem('f');
  sessionStorage.removeItem('aprm');

  sessionStorage.removeItem('js1');
  sessionStorage.removeItem('js2');
  sessionStorage.removeItem('js3');
  sessionStorage.removeItem('js4');
  sessionStorage.removeItem('js5');
  sessionStorage.removeItem('shosnt');
  location.href = 'https://www.haystravel.co.uk';
}

//   if(confi)
//   location.href = 'https://www.haystravel.co.uk';
// }


export const Logo: FunctionComponent<LogoProps> = ({ width, height, route, configuration }: LogoProps) => {


  const LogoClick = () => {
    sessionStorage.removeItem('snpOff');
    sessionStorage.removeItem('f');
    sessionStorage.removeItem('aprm');

    if(configuration != null){
      location.href =  configuration.home_url;
    }
    else{
      location.href = '#'
    }
  }

  let logoSrc = 'https://booking.haystravel.co.uk/static/media/hays_logo.svg'
  if(configuration != null){
    logoSrc = configuration.logo_file;
  }

  return (
    <>
      <a href='#' onClick={LogoClick}>
        <img width={width} height={height} src={logoSrc} alt="Logo icon"/>
      </a>
    </>
  )
}
