import { FunctionComponent } from 'react';

import './SuccessIcon.scss';


import successIconDefault from '../../assets/icons/default/09/noun-tick-317767-222222.svg'
import successIcon from '../../assets/success.svg'

import pendingIconDefault from '../../assets/icons/default/09/pending.svg';
import pendingIcon from '../../assets/pending.svg'
import { useStores } from '../../../hooks/useStore';
import { RootStore } from '../../../stores/RootStore';


interface SuccessProps {
  confirmed: number
}

export const SuccessIcon: FunctionComponent <SuccessProps> = ({confirmed=true}) => {


  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  let successIco = successIconDefault;
  let pendIco = pendingIconDefault;
  let bC = 'black';

  if(configuration.icon_set == 'blue'){
    successIco = successIcon;
    pendIco = pendingIcon;
    bC =  configuration.button_colour;
  }

  if(configuration.spec == '6yk1y'){;
    bC = '#01447b';
  }

  if(confirmed == 1){
    return (
      <>
        <div className='success-icon-wrapper'>
          <div style={{borderColor: bC}} className='success-icon-img'>
            <img src={successIco} alt='' />
          </div>
          <p>Booking Confirmed</p>
        </div>
      </>
    );
  }
  else{
    return (
      <>
        <div className='success-icon-wrapper'>
          <div style={{borderColor: bC}} className='success-icon-img'>
            <img src={successIco} alt='' />
          </div>
          <p>Booking Pending</p>
        </div>
      </>
    ); 
  }

};
