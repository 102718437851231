import { SessionStore } from './SessionStore';

export class RootStore {
  constructor() {
    this.sessionStore = new SessionStore();
    this.userToken = '';
    this.searchMode = -1;
    this.showSearchTabs = true;
    this.showTimeout = false;
  }
  sessionStore: SessionStore;
  userToken: string;
  searchMode: any;
  showSearchTabs: boolean;
  configuration: any;
  showTimeout: boolean;

}
