import cn from 'classnames';
import { useRef } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { uniqueId } from 'lodash';

// Splide styles
import '@splidejs/react-splide/css';

// Site styles
import '../ImageCarousel/ImageCarousel.scss';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

type SlideImage = {
  url?: string;
  src?: string;
  alt?: string;
};

type ImageSliderProps = {
  images: SlideImage[];
  className?: string;
  height?: number;
  imgW?: number;
  imgH?: number;
  gap?: string;
  isRec?: boolean
};

export function ImageSlider({
  images,
  className = '',
  height = 365,
  imgW = 400,
  imgH = 350,
  gap = undefined,
  isRec = false
}: ImageSliderProps) {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  const ref = useRef<Splide>(null);

  if(images == null){
    return <img src='https://assets.holidaywebtech.co.uk/images/imgsoon.png' alt='Image coming soon' title='Image coming soon'></img>
  }
  if(images.length == 0){
    return <img src='https://assets.holidaywebtech.co.uk/images/imgsoon.png' alt='Image coming soon' title='Image coming soon'></img>
  }
  
  let rec = null;
  if(isRec){
    rec = <h4 style={{backgroundColor: configuration.button_colour, color: configuration.button_text_colour}} className="ribbon">Recommended</h4>;
  }
  
  return (
    <div className={cn('image-carousel', className)}>
      {rec}
      <Splide
        key={uniqueId()}
        ref={ref}
        aria-label='Hotel Images'
        options={{
          cover: true,
          perMove: 1,
          pagination: false,
          height,
          lazyLoad: 'nearby',
          preloadPages: 1,
          gap,
          breakpoints: {
            640: {
              height: 320,
            },
          },
        }}
      >
        {images.map((image, i) => (
          <SplideSlide key={uniqueId()}>
              <img data-splide-lazy={`https://assets.holidaywebtech.co.uk/${image.url}`} alt='' />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}
