import React, { ReactNode, FunctionComponent, useState } from 'react'
import { noop } from 'lodash'
import './ModalWithTabs.scss'
import { useStores } from '../../hooks/useStore'
import { RootStore } from '../../stores/RootStore'

interface TabsData {
  tabsList: {
    name: string
    icon: string
  }[]
  tabsContent: ReactNode[]
}

interface SimpleTabsProps {
  activeTabNumber?: number
  tabsData: TabsData
  onTabChange?: (index: number) => void
}

const SimpleTabs: FunctionComponent<SimpleTabsProps> = ({ tabsData, onTabChange = noop, activeTabNumber = 0 }: SimpleTabsProps) => {
  const [activeTab, setActiveTab] = useState<number>(activeTabNumber)


  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const handleClick = (index: number) => {
    setActiveTab(index)
    onTabChange(index)
  }

  

  return (
    <>
      <div className='simple-tabs-list'>
        {tabsData.tabsList.map((tab: { name: string; icon: string }, i: number) => {

          const style = {color: configuration.text_colour, borderColor: ''}
          if(activeTab === i){
            style.borderColor = configuration.container_colour;
          }
          return (
            <div
              style={style}
              key={tab.name}
              className={`simple-tabs-tab ${activeTab === i ? 'active' : ''}`}
              onClick={() => handleClick(i)}
            >
              <img className='tab-icon' src={tab.icon} alt={tab.name} height='20' />
              <span className='tab-name'>{tab.name}</span>
            </div>
          )
        })}
      </div>
      {tabsData.tabsContent.map((tabContent: ReactNode, i: number) => {
        return (
          <div
            key={`i_${i}`}
            className={`simple-tabs-tab-content tab-${i} ${activeTab !== i ? 'hidden' : 'visible'}`}
          >
            {tabContent}
          </div>
        )
      })}
    </>
  )
}

export default SimpleTabs
