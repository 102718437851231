import { noop } from 'lodash';
import dayjs from 'dayjs';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { Header } from '../Header/Header';
import './BaseLayout.scss';
import { ProgressBar, StepState } from '../ProgressBar/ProgressBar';
import ScrollToTop from '../../ScrollToTop';
import Footer from '../Footer/Footer';
import { Modal } from '../Modal';
import { useNavigate, useLocation } from 'react-router-dom';

interface BaseLayoutProps {
  children: ReactNode;
  searchParams?: any; // TODO Fix typing
  isOverlayOpen?: boolean;
  onSearchButtonClick?: () => void;
  onSearchItemClick?: (type: string) => void;
  progressBar?: StepState[];
  isBookingConfirmation?: boolean;
  isBookingConfirmed? : number;
  isNavigationVisible?: boolean;
  userToken?: string;
  userConfiguration?: any;
}

const BaseLayout: FunctionComponent<BaseLayoutProps> = ({
  onSearchItemClick = noop,
  onSearchButtonClick = noop,
  isOverlayOpen,
  searchParams,
  children,
  progressBar,
  isBookingConfirmation = false,
  isBookingConfirmed = 1,
  isNavigationVisible = true,
  userToken,
  userConfiguration,
}: BaseLayoutProps) => {


  const navigate = useNavigate();
  
  const [showTimeout, setSessionTimeout] = useState<boolean>(false); 

  const checkSession = () => {
    
    if(sessionStorage.getItem('session_cr')){

      const t = dayjs(sessionStorage.getItem('session_cr'));
      const diff = dayjs().diff(t, 'minutes');
      if(diff > 120){
        return true;
      }
    }
    return false;
  }

  const resumeSearch = () => {

    const lastHol = sessionStorage.getItem('lastHolidayPage');
    sessionStorage.removeItem('session_cr');
    if(lastHol != null){
      window.location.href = lastHol
    }else{
      navigate('/');
    }
  }

  useEffect(() => {

    setInterval(() => {
      const x = checkSession()
      if(x){
        setSessionTimeout(true);
      }
    }, 3000)

  }, []);


  if(showTimeout){

    const lastHol = sessionStorage.getItem('lastHolidayPage');
    let lastHolEl = null;
    if(lastHol != null){
      lastHolEl = <>Tap or Click <a href={lastHol}>here</a> to resume your holiday search.<br></br><br></br></>
    }  

    let logoSrc = 'https://booking.haystravel.co.uk/static/media/hays_logo.svg'
    if(userConfiguration != null){
      logoSrc = userConfiguration.logo_file;
    }

    let timeoutMessage = <><Modal isOpen={true} shouldShowCloseButton={false}>
        <div className={`loader-page-container`}>
          <img className='loader-page-logo' src={logoSrc} height={100} />
  
          <div className='loader-container'>
  
            <div onClick={resumeSearch} style={{padding: '50px 50px 50px 50px'}}>
                It looks like you've been away a while or your session with us has timed out.<br></br><br></br>
    
                Availability and prices can change, so we suggest starting again.<br></br><br></br>

                {lastHolEl}

            </div>
      </div>
  
      </div>
    </Modal></>;

    return (
      <>
        <ScrollToTop />
        <Header
          onSearchItemClick={onSearchItemClick}
          progressBar={progressBar}
          onSearchButtonClick={onSearchButtonClick}
          searchParams={searchParams}
          isBookingConfirmation={isBookingConfirmation}
          isBookingConfirmed={isBookingConfirmed}
          isOverlayOpen={isOverlayOpen}
          isNavigationVisible={isNavigationVisible}
        />
        <div style={{color:userConfiguration.text_colour}} className='content-wrapper'>{timeoutMessage}{children}</div>
        {window.location.pathname !== '/' ? <Footer userToken={userToken || ''} userConfiguration={userConfiguration} /> : ''}
      </>
      )

  }
  
  return (
    <>
      <ScrollToTop />
      <Header
        onSearchItemClick={onSearchItemClick}
        progressBar={progressBar}
        onSearchButtonClick={onSearchButtonClick}
        searchParams={searchParams}
        isBookingConfirmation={isBookingConfirmation}
        isBookingConfirmed={isBookingConfirmed}
        isOverlayOpen={isOverlayOpen}
        isNavigationVisible={isNavigationVisible}
      />
      <div style={{color:userConfiguration.text_colour}} className='content-wrapper'>{children}</div>
      {window.location.pathname !== '/' ? <Footer userToken={userToken || ''} userConfiguration={userConfiguration} /> : ''}
    </>
  );
};

export default BaseLayout;
