import { SearchSelections} from '../hooks/types'

import { geoArrayFromString, departurePointArrayFromString } from '../services/geo';
import { roomsFromPaxString } from '../services/pax';


export function sessionSetSearchParams(selectedSearchParams: SearchSelections){
  sessionStorage.setItem('selectedSearchParams', JSON.stringify(selectedSearchParams));
}

export function sessionGetSearchParams(){
  const s = sessionStorage.getItem('selectedSearchParams');
  if(s == null){
    return null;
  }
  return JSON.parse(s) as SearchSelections;
}


export function destinations(searchParams: URLSearchParams) {
    let selDests : number[] = [];
    const qsDests = searchParams.get('destinations');
    if(qsDests != null){
      for(const x of qsDests.split('-')){
          selDests.push(Number(x))
      }
    }
}

export function regions(searchParams: URLSearchParams) {
  let selRegions : number[] = [];
  const qsRegions = searchParams.get('regions');
  if(qsRegions != null){
    for(const x of qsRegions.split('-')){
        selRegions.push(Number(x))
    }
  }
  return selRegions;
}

export function resorts(searchParams: URLSearchParams) {
  let selResorts : number[] = [];
  const qsResorts = searchParams.get('resorts');
  if(qsResorts != null){
    for(const x of qsResorts.split('-')){
        selResorts.push(Number(x))
    }
  }
  return selResorts;
}

export function departuerDate(searchParams: URLSearchParams) {
  let departureDate: any = null;
  const qsDeparting = searchParams.get('departing');
  if(qsDeparting != null){
    departureDate = qsDeparting + ' 00:00';
  }

  return departureDate;
}

export function departurePoints(searchParams: URLSearchParams) {
  let selDeparturePoints: string[] = [];
  const qsDepPoints = searchParams.get('departurePoints');
  if(qsDepPoints != null){
    for(const x of qsDepPoints.split('-')){
        selDeparturePoints.push(x)
    }
  }
  return selDeparturePoints;
}

export function duration(searchParams: URLSearchParams) {
  let duration = 7;
  if(searchParams.get('duration') != null){
    duration = Number(searchParams.get('duration'));
  }
  return duration;
}

export function boards(searchParams: URLSearchParams) {
  let selBoards: number[] = [];
  const qsBaords = searchParams.get('boards');
  if(qsBaords != null){
    for(const x of qsBaords.split('-')){
      selBoards.push(Number(x))
    }
  }
  return selBoards;
}

export function ratings(searchParams: URLSearchParams) {
  let selRatings: number[] = [];
  const qsRates = searchParams.get('ratings');
  if(qsRates != null){
    for(const x of qsRates.split('-')){
      selRatings.push(Number(x))
    }
  }  
  return selRatings;
}

export function tripRatings(searchParams: URLSearchParams) {
  let selTripRatings: number[] = [];
  const qsTrRates = searchParams.get('trRatings');
  if(qsTrRates != null){
    for(const x of qsTrRates.split('-')){
      selTripRatings.push(Number(x))
    }
  }
  return selTripRatings;
}

export function packgeProductIds(searchParams: URLSearchParams) {
  let selProducts : number[] = [];
  const qsRegions = searchParams.get('pakProducts');
  if(qsRegions != null){
    for(const x of qsRegions.split('-')){
      selProducts.push(Number(x))
    }
  }
  return selProducts;
}

export function getParamsString(searchParams: URLSearchParams){
  const params = [];
  if(searchParams.get('rooms')){
    params.push('rooms=' + searchParams.get('rooms'));
  }
  if(searchParams.get('duration')){
    params.push('duration=' + searchParams.get('duration'));
  }   
  if(searchParams.get('departing')){
    params.push('departing=' + searchParams.get('departing'));
  }
  if(searchParams.get('departingType')){
    params.push('departingType=' + searchParams.get('departingType'));
  }
  if(searchParams.get('departurePoints')){
    params.push('departurePoints=' + searchParams.get('departurePoints'));
  }
  if(searchParams.get('type')){
    params.push('type=' + searchParams.get('type'));
  }


  // if(searchParams.get('boards')){
  //   params.push(searchParams.get('boards'));
  // }
  // if(searchParams.get('ratings')){
  //   params.push(searchParams.get('ratings'));
  // }
  // if(searchParams.get('trRatings')){
  //   params.push(searchParams.get('trRatings'));
  // }
  return params.join('&');
}


export function getRoomsParamsString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('rooms')){
    let a = ''
    if(append){
      a = '&';
    }

    const jh = searchParams.get('rooms');
    return a + 'rooms=' + searchParams.get('rooms');
  }
  return '';
}

export function getDepartingParamsString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('departing')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'departing=' + searchParams.get('departing');
  }
  return '';
}

export function getDepartingTypeParamsString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('departingType')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'departingType=' + searchParams.get('departingType');
  }
  return '';
}

export function getDeparturePointsParamsString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('departurePoints')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'departurePoints=' + searchParams.get('departurePoints');
  }
  return '';
}

export function getDurationParamString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('duration')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'duration=' + searchParams.get('duration');
  }
  return '';
}



export function getDestinationParamsString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('destinations')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'destinations=' + searchParams.get('destinations');
  }
  return '';
}


export function getRegionParamsString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('regions')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'regions=' + searchParams.get('regions');
  }
  return '';
}

export function getResortParamsString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('resorts')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'resorts=' + searchParams.get('resorts');
  }
  return '';
}



export function getBoardParamsString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('boards')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'boards=' + searchParams.get('boards');
  }
  return '';
}

export function getRatingParamsString(searchParams: URLSearchParams, append=true){

  if(searchParams.get('ratings')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'ratings=' + searchParams.get('ratings');
  }
  return '';
}

export function getTripRatingParamsString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('tratings')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'tratings=' + searchParams.get('tratings');
  }
  return '';
}

export function getSortParamString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('sort')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'sort=' + searchParams.get('sort');
  }
  return '';
}

export function getOfferIDParamsString(searchParams: URLSearchParams, append=true){
  if(searchParams.get('oid')){
    let a = ''
    if(append){
      a = '&';
    }
    return a + 'oid=' + searchParams.get('oid');
  }
  return '';
}

export function numberArrayFromString(str: string){
  const d = str.split('-');
  const arr = [];
  for(const x of d){
      arr.push(Number(x));
  }
  return arr;
}

export function stringArrayFromString(str: string){
  const d = str.split('-');
  const arr = [];
  for(const x of d){
      arr.push(x);
  }
  return arr;
}

export function SearchSelectionsFromUrlParams(searchParams: URLSearchParams){

  const parsedSelections: SearchSelections = { 
    duration: 7, destinations: [], regions: [], resorts: [], 
    departurePoints: [], departureDate: null, departureDateType: 0, searchType: 0, ratings: [], tripRatings: [], boards: [], rooms : [{adults: 2, children: 0, childAges: []}]}

  if(searchParams != null){
    if(searchParams.get('departing') != null){
      parsedSelections.departureDate = searchParams.get('departing') + ' 00:00';
    }   
    
    if(searchParams.get('departingType') != null){
      parsedSelections.departureDateType = Number(searchParams.get('departingType'));
    }
  
    if(searchParams.get('rooms') != null){
      parsedSelections.rooms = roomsFromPaxString(searchParams.get('rooms') || '');
    }
    if(searchParams.get('duration') != null){
      parsedSelections.duration = Number(searchParams.get('duration'));
    }
  
    if(searchParams.get('destinations') != null){
      parsedSelections.destinations = geoArrayFromString(searchParams.get('destinations'));
    }
  
    if(searchParams.get('regions') != null){
      parsedSelections.regions = geoArrayFromString(searchParams.get('regions'));
    }
  
    if(searchParams.get('resorts') != null){
      parsedSelections.resorts = geoArrayFromString(searchParams.get('resorts'));
    }
  
    if(searchParams.get('departurePoints') != null){
      parsedSelections.departurePoints = departurePointArrayFromString(searchParams.get('departurePoints'));
    }
  }

  return parsedSelections;
}
