import React, { FunctionComponent, useEffect, useState } from 'react';
import cn from 'classnames';

import PassengerInformation from './PassengerInformation';
import LeadPassengerInformation from './LeadPassengerInformation';

import {
  getSessionPeople,
} from '../../hooks/useRequest';


import personIcon from '../assets/person.svg';
import personDefault from '../assets/icons/default/07/Passengers.svg';

import attention from '../assets/attention.svg';
import attentionDefault from '../assets/icons/default/07/Attention.svg';

import './PassengerDetails.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { LoadingOverlay } from '../LoadingOverlay/LoadingOverlay';
import { SectionLoader } from '../SectionLoader/SectionLoader';
import { SearchSelectionRoom, Person, LeadCustomer } from '../../hooks/types';
import { newLeadCustomer, newPerson, capturePersonEmail } from '../../services/pax'
import { sessionGetSearchParams } from '../../services/search';


export interface PassengerDetailsProps {
  passengersData: any;
  isFormValid: boolean;
  sessionId: string;
  selectedRooms: any;
  formHasBeenSubmit: boolean;
  onLeadUpdate: (leadCustomer: LeadCustomer) => void;
  onPaxUpdate: (person: Person, index: number) => void;
  onPaxUpdateAll?: (persons: Person[]) => void;
}

const PassengerDetails: FunctionComponent<PassengerDetailsProps> = ({
  isFormValid,
  sessionId,
  selectedRooms,
  formHasBeenSubmit,
  onLeadUpdate,
  onPaxUpdate,
  onPaxUpdateAll
}) => {
  // const addLeadPerson = useAddLeadPerson(sessionId, passengersData?.leadPassenger);
  // const addAdditionalPerson = useAddAdditionalPerson(
  //   sessionId,
  //   passengersData?.additionalPassenger1,
  // );
  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const selectedSearchParams = sessionGetSearchParams();

  if(selectedSearchParams == null){
    return null;
  }


  const [loading, setLoading] = useState<boolean>(false);

  const [formSubmit, setFormSubmit] = useState<boolean>(formHasBeenSubmit);

  const [leadPaxFromSession, setLeadPaxFromSession] = useState<LeadCustomer>(newLeadCustomer());
  const [paxFromSession, setPaxFromSession] = useState<Person[]>([])

  useEffect(() => {

   
      if (sessionId == '') {
        return;
      }

      getSessionPeople(userToken ,sessionId).then((sessionResponse) => {
        let sessionResult = null;

        if (sessionResponse.session != null) {
          sessionResult = sessionResponse.session.result;
        }

        let leadPax: LeadCustomer = newLeadCustomer();
        const allPax: Person[] = [];

        if (sessionResult != null) {
          leadPax = sessionResult.basket.lead_person;
          const otherPax = sessionResult.basket.people.slice(1);
          for (const x of otherPax) {
            allPax.push(x);
          }

          setLeadPaxFromSession(leadPax);
          onLeadUpdate(leadPax);

          setPaxFromSession(allPax);

          if(onPaxUpdateAll){
            onPaxUpdateAll(allPax);
          }


        }



      });
    
  }, [sessionId]);

  useEffect(() => {

    if(!formHasBeenSubmit){
      return
    }

    setFormSubmit(formHasBeenSubmit);

  }, [formHasBeenSubmit])

  const paxSection = (
    personIndex: number,
    person: Person | null,
    age: number,
    isChild: boolean,
    personRoomCount: number,
    isValidDOB: boolean
  ) => {
    let title = 'Adult ' + personRoomCount;
    if (isChild) {
      let titleAge = ''
      titleAge = ' (Age ' + age + ')';
      
      title = 'Child ' + personRoomCount + titleAge;
    }


    let personIco = personDefault;
    let attenIco = attentionDefault;
    if(configuration.icon_set == 'blue'){
      personIco = personIcon;
      attenIco = attention;
    }

    return (
      <div key={'person' + personIndex}>
        <div style={{borderColor: configuration.jrny.border_colour}} className='additional-passenger-title'>
          <img src={personIco} width='22' alt='person' />
          <span className='additional-passenger-title-text'>{title}</span>
        </div>
        <div className='passenger-attention'>
          <span className='passenger-attention-text'>
            Please ensure that all details match those on your passport
          </span>
          <img src={attenIco} width='22' alt='attention' />
        </div>

        <PassengerInformation
          sessionId={sessionId}
          index={personIndex}
          pTitle={person?.title || null}
          pFirstName={person?.first_name || ''}
          pSecondName={person?.second_name || ''}
          pDateOfBirth={person?.date_of_birth || ''}
          isFormValid={isFormValid}
          age={age}
          isLead={false}
          isChild={isChild}
          pContactNumber={''}
          onUpdate={(p) => onPaxUpdate(p, personIndex)}
          formHasBeenSubmit={formSubmit}
        />
      </div>
    );
  };


  // const submitPax = async () => {


  //   if(selectedHoliday == null){
  //     return;
  //   }

  //   setLoading(true);

  //   // const people = [];
  //   await clearPeople(userToken, sessionId);
  //   let pIndex = 0;
  //   let roomIndex = 0;

  //   for (const room of selectedHoliday.searchedRooms) {
  //     const adults = room.adults;
  //     const childAges = Object.values(room.childAges);

  //     for (let i = 0; i < adults; i++) {
  //       if (i == 0 && roomIndex == 0) {
  //         // people.push(leadPax);
  //         pIndex++;
  //         if(leadPax != null){
  //           await addLeadPerson(userToken,sessionId, leadPax.title, leadPax.first_name, leadPax.second_name, leadPax.date_of_birth, leadPax.address1, leadPax.address2, leadPax.address3,
  //             leadPax.address4, leadPax.post_code, leadPax.contact_telphone, leadPax.email_address, leadPax.special_requests, 0, leadPax.marketing_permission);
  //         }

  //         continue;
  //       }

  //       pIndex++;
  //       const p = pax[pIndex];
  //       await addAdditionalPerson(userToken, sessionId, p.title, p.first_name, p.second_name, p.date_of_birth);
  //     }

  //     for (const x of childAges) {
  //       pIndex++;

  //       const c = pax[pIndex];
  //       await addAdditionalPerson(userToken, sessionId, c.title, c.first_name, c.second_name, c.date_of_birth);
  //     }

  //     roomIndex++;
  //   }
  //   await productCheckAll(userToken, sessionId);

  //   if(leadPax != null){
  //     capturePersonEmail(leadPax, sessionId, userToken);
  //   }

  //   getBasket(userToken, sessionId).then((bd) => {
  //     setBasket(bd.basket.result)
  //     navigate(`/payment${location.search}`);
  //   });
    
  // };

  const roomSections:any = [];

  let roomIndex = 0;

  const leadPax = (
    <LeadPassengerInformation leadCustomer={leadPaxFromSession} sessionId={sessionId} 
    isFormValid={isFormValid} onUpdate={(leadCustomer) => onLeadUpdate(leadCustomer)} formHasBeenSubmit={formSubmit} />
  ) as any;

  let leadPaxAddDetails = null;
  if (leadPaxFromSession != null) {
    leadPaxAddDetails = {
      addressLine1: leadPaxFromSession.address1,
      addressLine2: leadPaxFromSession.address2,
      addressLine3: leadPaxFromSession.address3,
      addressLine4: leadPaxFromSession.address4,
      postcode: leadPaxFromSession.post_code,
      emailAddress: leadPaxFromSession.email_address,
      marketing: leadPaxFromSession.marketing_permission,
    };
  }

  const rms = selectedSearchParams.rooms;
  let pIndex = 0;

  for (const room of rms) {
    let roomDescription = '';
    if (selectedRooms != null) {
      for (const sr of selectedRooms) {
        if (sr.room_number == roomIndex + 1) {
          roomDescription = ' - ' + sr.description;
        }
      }
    }

    let adultCount = 1;
    let childCount = 1;
    const addPax = [];
    const adults = room.adults;
    const childAges = Object.values(room.childAges);

    for (let i = 0; i < adults; i++) {
      let person = newPerson();
      if (pIndex + 1 <= paxFromSession.length) {
        person = paxFromSession[pIndex];
      }

      // if the lead pax just add them , no section required.
      if (i == 0 && roomIndex == 0) {
        adultCount++;
        // pIndex++;
        continue;
      }

      // not lead pax add section and add them to the session
      addPax.push(paxSection(pIndex, person, -1, false, adultCount, true));
      pIndex++;
      adultCount++;
    }

    for (const x of childAges) {
      let person = null;
      let valDOB = false;
      if (pIndex + 1 <= paxFromSession.length) {
        person = paxFromSession[pIndex];
        valDOB = true;
      }

      addPax.push(paxSection(pIndex, person, Number(x), true, childCount, valDOB));
      pIndex++;
      childCount++;
    }

    if (roomIndex == 0) {
      const roomSec = (
        <div style={{borderColor: configuration.jrny.border_colour}} className='lead-passenger-information'>
          <p style={{borderColor: configuration.jrny.border_colour}} className='lead-passenger-title lead-passenger-title--room'>
            <span className='lead-passenger-title-text'>
              Room {roomIndex + 1}
              {roomDescription}
            </span>
          </p>

          <div>{leadPax}</div>

          <div style={{borderColor: configuration.jrny.border_colour}} className='additional-passenger-information additional-passenger-information--no-shadow'>
            {addPax}
          </div>
        </div>
      );

      roomSections.push(roomSec);
    } else {
      const roomSec = (
        <div className='additional-passenger-information'>
          <div style={{borderColor: configuration.jrny.border_colour}} className='additional-passenger-information additional-passenger-information--no-shadow'>
            <p style={{borderColor: configuration.jrny.border_colour}} className='additional-passenger-title additional-passenger-title--room additional-passenger-title--no-border'>
              <span className='additional-passenger-title-text'>
                Room {roomIndex + 1}
                {roomDescription}
              </span>
            </p>
            {addPax}
          </div>
        </div>
      );

      roomSections.push(roomSec);
    }

    roomIndex++;
  }

  if (roomSections == null) {
    // return null;
    return (
      <div className='passengers-details-container'>
        <SectionLoader title='Loading Passengers' />
      </div>
    );
  }

  return (
    <div className={cn('passengers-details-container', { invalid: !isFormValid })}>

      {roomSections}
 

      {loading && <LoadingOverlay />}
    </div>
  );
};

export default PassengerDetails;
