// @ts-ignore
import gtag from 'ga-gtag';

import { Offer, Basket, Room, Flight, Transfer, Package } from '../hooks/types'

const packageToGoogleItem = (offer: Package, offerPrice: number) => {
    const o = {
        item_name: offer.accommodation.name,
        item_brand: 'Jet 2 Holidays',
        item_category: offer.accommodation.resort.regions[0].destinations[0].name,
        item_category2: offer.accommodation.resort.regions[0].name,
        item_category3: offer.accommodation.resort.name,
        item_category4: offer.accommodation.name,
        price: offerPrice,
        quantity: 1
    }
    return o;
}

const offerToGoogleItem = (offer: Offer) => {
    const o = {
        item_name: offer.accommodation.name,
        item_brand: '',
        item_category: offer.accommodation.resort.regions[0].destinations[0].name,
        item_category2: offer.accommodation.resort.regions[0].name,
        item_category3: offer.accommodation.resort.name,
        item_category4: offer.accommodation.name,
        price: offer.price,
        quantity: 1
    }
    return o;
}

const roomToGoogleItem = (room: Room) => {
    const r = {
        item_id: room.id,
        item_name: room.board_description + ' ' + room.description,
        item_brand: '',
        item_category: "Accommodation",
        price: room.price,
        quantity: 1
    }
    return r;
}

const flightExtrasToItems = (flight: Flight) => {
    const items: any[] = [];
    for(const x of flight.extras){
        const f = {
            item_id: x.id,
            item_name: x.description,
            item_brand: flight.airline + ' ' + flight.supplier_code,
            item_category: "Flight Extra",
            price: x.price,
            quantity: 1
        }
    }
    return items;
}

const flightToGoogleItem = (flight: Flight) => {
    const f = {
        item_id: flight.id,
        item_name: flight.airline + ' flights',
        item_brand: flight.airline + ' ' + flight.supplier_code,
        item_category: "Flights",
        price: flight.price,
        quantity: 1
    }
    return f;
}

const transferToGoogleItem = (transfer: Transfer) => {
    const t = {
        item_id: transfer.id,
        item_name: transfer.vehicle_type,
        item_brand: '',
        item_category: "Transfer",
        price: transfer.price,
        quantity: 1
    }
    return t;
}

export function viewItem(offer: Offer){
    if(gtag == null){
        return
    }

    try {
        const items:any[] = [];
        const o = offerToGoogleItem(offer);
        items.push(o);
       
        gtag("event", "view_item", {
            currency: "GBP",
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }


}

export function viewItemPackage(pakOffer: Package, price: number){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        const o = packageToGoogleItem(pakOffer, price);
        items.push(o);
       
        gtag("event", "view_item", {
            currency: "GBP",
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }

    
}



export function viewItemList(offers: Offer[]){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        for(const o of offers){
            const offer = offerToGoogleItem(o);
            items.push(offer);
        }
        gtag("event", "view_item_list", {
            currency: "GBP",
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }
}

export function selectItems(offers: Offer[]){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        for(const o of offers){
            const offer = offerToGoogleItem(o);
            items.push(offer);
        }
        gtag("event", "select_item", {
            currency: "GBP",
            items: items
        })
    }    
    catch (error) {
        console.log(error);
    }
    

}

export function selectItemsPackage(offers: Package[], price: number){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        for(const o of offers){
            const offer = packageToGoogleItem(o, price);
            items.push(offer);
        }
        gtag("event", "select_item", {
            currency: "GBP",
            items: items
        })
    }    
    catch (error) {
        console.log(error);
    }



}

export function purchase(basket: Basket, room?: Room[], flights?: Flight|null, tranfer?: Transfer|null){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        if(room != null){
            for(const r of room){
                items.push(roomToGoogleItem(r));
            }
        }
        if(flights != null){
            items.push(flightToGoogleItem(flights));
            const extras = (flightExtrasToItems(flights));
            for(const x of extras){
                items.push(x);
            }
        }
        if(tranfer != null){
            items.push(transferToGoogleItem(tranfer));
        }
       
        gtag("event", "purchase", {
            currency: "GBP",
            value: basket.price,
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }


}

export function purchasePackage(basket: Basket, pakOffer: Package){
    if(gtag == null){
        return
    }

    try{
        const items:any[] = [];
        if(pakOffer != null){
            items.push(packageToGoogleItem(pakOffer, basket.price));
        }
        gtag("event", "purchase", {
            currency: "GBP",
            value: basket.price,
            items: items
        })
    }
    catch (error) {
        console.log(error);
    }
}

