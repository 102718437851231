import { FunctionComponent, useState } from 'react';
import { applyBasketDiscount, getBasket, applyProductDiscount } from '../../hooks/useRequest';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { Basket } from '../../hooks/types';

import './VoucherForm.scss';


interface VoucherProps {
  callback: any,
  basketData: Basket,
  sessionId: string,
}


export const VoucherForm:FunctionComponent<VoucherProps> = ({
    callback,
    basketData, 
    sessionId
  }) => {
  const [voucher, setVoucher] = useState('');
  const [loading, setLoading] = useState(false);
  const [isVoucherSuccess, setIsVoucherSuccess] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [code, setCode] = useState('');

  const {
    RootStore: {
      userToken,
      configuration
    },
  } = useStores() as { RootStore: RootStore };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setVoucher(e.target.value);
  }

  async function doPack(){
    let s = false;
    if (sessionId) {

      const prodID = basketData.products[0].id;
      const prodTypeID = basketData.products[0].product_type.id;

      if(prodID == null){

        return false;
      }
  
      if(prodTypeID == null){
        return false;
      }

      const res = (await applyProductDiscount(userToken, prodID, prodTypeID, sessionId, voucher)) as any;
      if (res?.product_apply_discount.result) {

        if(res?.product_apply_discount.result.promo_discount > 0){
          setIsVoucherSuccess(true);
          setVoucher('');
          setCode(voucher);

          const b = await getBasket(userToken, sessionId);
          if(b != null){
            callback(b.basket.result);
            s = true;
          }
        }
      }
    }
    return s;
  }

  async function dohwt(){
    let s = false;
    if (sessionId) {
      const res = (await applyBasketDiscount(userToken, sessionId, voucher)) as any;
      if (res?.basket_apply_discount.result) {
        if(res?.basket_apply_discount.result.discounts.length > 0){
          setIsVoucherSuccess(true);
          setVoucher('');

          // JH attempt at just updating the discounts 
          // setDiscounts(res?.basket_apply_discount.result.discounts);
          
          const b = await getBasket(userToken, sessionId);
          if(b != null){
            callback(b.basket.result);
            setCode(voucher);
            s = true;
          }

        }      
    }
    }    
    return s;
  }


  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);

    let s = await dohwt();
    if(!s){
      s = await doPack()
    }

    let a = attempts + 1;
    setAttempts(a);
    setIsVoucherSuccess(s);
    setLoading(false);
  }

  let formSec= null;

  let isPackage = false;
  let packageDiscount = 0;
  if(basketData != null){
    for(const x of basketData.products){
      if(x.product_type.id == 5){
        isPackage = true;
        if(x.promo_discount > 0){
          packageDiscount = x.promo_discount;
        }
        break;
      }
  
    }
  }

  if(packageDiscount > 0){
    return (
      <div className='voucher-form-container'>
        <p className='voucher-form-success'>Your promo code has been applied</p>
      </div>
    );
  }

  if(basketData != null){
    if(basketData.discounts?.length > 0)
    {
      return (
        <div className='voucher-form-container'>
          <p className='voucher-form-success'>Your promo code has been applied</p>
        </div>
      );
    }
  }



  let msg = null;

  if(!isVoucherSuccess){

    
    if(attempts > 0){
      msg =  <p className='voucher-form-error'>Invalid code. Please check and try again.</p>;
    }

    formSec = <form onSubmit={handleSubmit} className='voucher-form'>
        <input
          type='text'
          name='voucher'
          value={voucher}
          onChange={handleChange}
          className='voucher-form__input'
          placeholder='Enter your voucher code here'
          style={{borderColor: configuration.border_colour, color: configuration.text_colour}}
        />
        <button style={{backgroundColor: configuration.button_colour, color: configuration.button_text_colour, fontFamily: 'GothamSSm'}} className='voucher-form__button' type='submit'>
          {loading ? 'Please wait...' : 'Apply'}
        </button>
      </form>;
  } 
  else{
    formSec = <p className='voucher-form-success'>Your promo code has been applied</p>
  }
  

  return (
    <div className='voucher-form-container'>
      {msg}
      {formSec}
    </div>
  );
}
