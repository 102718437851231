import { ExecOptionsWithStringEncoding } from 'child_process';
import React, {FunctionComponent} from 'react';


interface TrustBoxProps {
  templateID: string
  businessunitID: string
  theme: string
  stars: string
}

const TrustBox: FunctionComponent<TrustBoxProps> = ({
  templateID,
  businessunitID,
  theme='light',
  stars='4,5',
}) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if ((window as any).Trustpilot) {
      (window as any).Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  if(templateID == null){
    return null;
  }

  const tid = templateID;

  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className='trustpilot-widget' // Renamed this to className.
      data-locale='en-GB'
      data-template-id={templateID}
      data-businessunit-id={businessunitID}
      data-style-height='140px'
      data-style-width='100%'
      data-theme={theme}
      data-stars={stars}
      data-review-languages='en'
      // [ long list of data attributes...]
    >
      <a href='https://www.trustpilot.com/review/example.com' target='_blank' rel='noreferrer'>
        {' '}
        Trustpilot
      </a>
    </div>
  );
};

export default TrustBox;
