import React, { FC, useState } from 'react';
import './Stepper.scss';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { LoaderSpinnerSmall } from '../Loader/jLoader'

export interface IStepper {
  onChange: (value: number, direction: number) => void;
  maxValue: number;
  minValue: number;
  defaultValue: number;
  enabled: boolean;
}


export const Stepper: FC<IStepper> = ({
  defaultValue = 0,
  maxValue = 10,
  minValue = 0,
  onChange,
  enabled = true,
}) => {

  const {
    RootStore: {
      configuration
    },
  } = useStores() as { RootStore: RootStore };
  
  const [value, setValue] = useState(defaultValue);

  const onChangeInternal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value);
    if (val > maxValue) {
      setValue(maxValue);
    } else if (val < minValue) {
      setValue(minValue);
    } else {
      setValue(val);
    }
  };

  const stepUp = () => {
    const val = value + 1;
    if (val > maxValue) {
      setValue(maxValue);
      if (onChange) onChange(maxValue, 1);
    } else {
      setValue(val);
      if (onChange) onChange(val, 1);
    }
  };

  const stepDown = () => {
    const val = value - 1;
    if (val < minValue) {
      setValue(minValue);
      if (onChange) onChange(minValue, -1);
    } else {
      setValue(val);
      if (onChange) onChange(val, -1);
    }
  };

  let disableDown = false;
  let disableUp = false;
  if(!enabled){
    disableDown = true;
    disableUp = true;
  }
  else{

    if(minValue == value){
      disableDown = true;
    }

    if(maxValue == value){
      disableUp = true;
    }

  }

  if(!enabled){
    return (
      <div className='stepper'>
        <button
          disabled={true}
          onClick={() => {return}}
          className='stepper__button stepper__button--left'
          style={{backgroundColor: configuration.button_disabled_colour}}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={4}
            stroke='white'
            style={{ width: 12, height: 12 }}
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 12h-15' />
          </svg>
  
          <span className='sr-only'>Click here to decrease the count</span>
        </button>
  
  
        <div className='stepper__input'>
  
        <div className='stepper-loader-button' style={{display:'flex', justifyContent: 'center', alignItems: 'center', color: configuration.jrny.selected_button_text_colour}}>{LoaderSpinnerSmall()}</div>
  
        </div>
  
        <button
          disabled={true}
          onClick={() => {return}}
          className='stepper__button stepper__button--right'
          style={{backgroundColor: configuration.button_colour}}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={4}
            stroke='white'
            style={{ width: 12, height: 12 }}
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M12 4.5v15m7.5-7.5h-15' />
          </svg>
  
          <span className='sr-only'>Click here to increase the count</span>
        </button>
      </div>
    );
  }

  // return<div className='stepper-loader-button' style={{display:'flex', justifyContent: 'center', alignItems: 'center', color: configuration.jrny.selected_button_text_colour}}>{LoaderSpinnerSmall()}</div>
  
  



  return (
    <div className='stepper'>
      <button
        disabled={disableDown}
        onClick={stepDown}
        className='stepper__button stepper__button--left'
        style={{backgroundColor: configuration.button_disabled_colour}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={4}
          stroke='white'
          style={{ width: 12, height: 12 }}
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 12h-15' />
        </svg>

        <span className='sr-only'>Click here to decrease the count</span>
      </button>

      <input
        className='stepper__input'
        type='number'
        min={minValue}
        max={maxValue}
        onChange={onChangeInternal}
        value={value}
        disabled
      />

      <button
        disabled={disableUp}
        onClick={stepUp}
        className='stepper__button stepper__button--right'
        style={{backgroundColor: configuration.button_colour}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={4}
          stroke='white'
          style={{ width: 12, height: 12 }}
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M12 4.5v15m7.5-7.5h-15' />
        </svg>

        <span className='sr-only'>Click here to increase the count</span>
      </button>
    </div>
  );
};
