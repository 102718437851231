import './PanelLoader.scss';
import { PanelLoaderPops } from './types';

export function PanelLoader({ title = 'Loading' }: PanelLoaderPops) {
  return (
    <div className='panel-loader'>
      <svg viewBox='25 25 50 50'>
        <circle r='20' cy='50' cx='50'></circle>
      </svg>

      <p>{title}</p>
    </div>
  );
}
