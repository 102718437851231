import { FC, useState } from 'react';
// import { ILuggage, ILuggageBasket, ILuggageOptions } from './Interfaces';
import './Luggage.scss';
import LuggageIcon from '../assets/luggage.svg';
import { Stepper } from '../Stepper/Stepper';
// import { IBasketProducts } from '../../hooks/Interfaces';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { Extra, Basket } from '../../hooks/types';



interface LuggageProps {
  enabled: boolean
  options: Extra[];
  title?: string;
  description?: string;
  basketData: Basket;
  onChange: (value: number, direction: number, luggage: Extra,) => void;
}

const Luggage: FC<LuggageProps> = ({ options, description, onChange, enabled=true, basketData }) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  const stepperAmount = (
    value: number,
    direction: number,    
    luggageOption:  Extra,
  ) => {

    onChange(value, direction, luggageOption, );

    // basket[luggageOption.id] = {
    //   price: luggageOption.price,
    //   description: `${amount} x ${luggageOption.description} : £${(
    //     amount * luggageOption.price
    //   ).toFixed(2)}`,
    //   id: luggageOption.id,
    //   productId: luggageOption.product_type?.id,
    // };

    // for the animation.
    // setTimeout(() => {
    //   if (basket[luggageOption.id]) {
    //     basket[luggageOption.id].classNames = 'animate-toFadeInAndOut';
    //     setBasket({ ...basket });
    //   }
    // }, 1);
    
    // if (amount === 0) delete basket[luggageOption.id];
    // setBasket({ ...basket });
    // let total = 0;
    // Object.values(basket).forEach((item) => {
    //   total += qtyRequired * item.price;
    // });
    // if (total > 0) setGrandTotal(`Grand total on Luggage: £${total.toFixed(2)}`);
    // else setGrandTotal('');
    // if (onChange) {
    //   onChange(basket[luggageOption.id], direction);
    // }
  };
  
  return (
    <div className='luggage-container'>
      <h4 className='luggage-title'>
        <img className='luggage-icon' src={LuggageIcon} height='30' width='30' />
        <span>Luggage</span>
      </h4>
      <p className='luggage-description'>{description}</p>

      {options.map((luggageOption, index) => {
  
        let qtyRequired = 0;
        // for(const x of selectedHoliday.extras){
        //   if(x.id == luggageOption.id && x.product_type.id == luggageOption.product_type.id){
        //     qtyRequired++;
        //   }
        // }

        for (const x of basketData.products) {
          if (x.product_type.id == luggageOption.product_type.id && x.id == luggageOption.id) {
            qtyRequired++;
          }
        }


        return (
          <div className='luggage-container-child' key={`${luggageOption.description}-luggage-${index}`}>
            <span className='luggage-option-title'>{luggageOption.description}</span>
            {/* <div
              className={`luggage-option-info`}
            >{`You've added ${basket[luggageOption.id]?.amount || 0} x ${
              luggageOption.description
            }`}</div> */}
            <div style={{color: configuration.jrny.price_colour}} className='luggage-option-price'>
              {`£${luggageOption.price.toFixed(2)}`}
              <span style={{color: configuration.jrny.price_colour}} className='luggage-option-price-perbag'>per bag</span>
            </div>
            <div className='luggage-stepper-container'>
              <Stepper
                enabled={enabled}
                minValue={0}
                defaultValue={qtyRequired}
                maxValue={luggageOption.quantity_available}
                onChange={(value : number, direction: number) => {
                  stepperAmount(value, direction, luggageOption);
                }}
              />
              <span style={{color: configuration.jrny.price_colour}}  className='luggage-option-inline-price'>
                {`£${luggageOption.price.toFixed(2)}`}
                <div className='luggage-option-price-inline-perbag'>per bag</div>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { Luggage };
