import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import { useLockedBody } from 'usehooks-ts';
import { noop } from 'lodash';
import cn from 'classnames';
import './Modal.scss';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

interface ModalProps {
  children?: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  additionalHeaderClassName?: string;
  additionalBodyClassName?: string;
  mobileHeading?: string | ReactNode;
  shouldShowCloseButton?: boolean;
  isOpen: boolean;
  isMapModal?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  toggle?: () => void;
}

const Modal: FunctionComponent<ModalProps> = ({
  children,
  footer,
  header,
  additionalHeaderClassName,
  additionalBodyClassName,
  shouldShowCloseButton = true,
  shouldCloseOnOverlayClick = true,
  isOpen = false,
  isMapModal = false,
  toggle,
}: ModalProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);

  if (isMobile) {
    useLockedBody(isOpen, 'root');
  }

  let bc = configuration.button_colour;
  if(configuration.button_icon == 'dark'){
    bc = configuration.border_colour_2;
  }

  if(configuration.spec == '6yk1y'){
    bc = '#01447b';
  }


  return (
    <>
      {isOpen && (
        <div className='modal-overlay' onClick={shouldCloseOnOverlayClick ? toggle : noop}>
          <div onClick={(e) => e.stopPropagation()} className='modal-window'>
            <div className='modal-layout'>
              {shouldShowCloseButton && (
                <a style={{backgroundColor: configuration.container_colour}} className='modal-close modal-close--mobile' href={void 0} onClick={toggle}></a>
              )}

              <div
                className={`modal-layout__content ${
                  isMapModal ? 'modal-layout__content--map' : ''
                }`}
              >
                <div className={cn('hwt-modal-header', additionalHeaderClassName)}>
                  {shouldShowCloseButton && (
                    <a style={{borderColor: bc}} className='modal-close' href={void 0} onClick={toggle}>

<svg
          xmlns='http://www.w3.org/2000/svg'
          fill={bc}
          viewBox='0 0 24 24'
          strokeWidth={1}
          stroke={bc}
          style={{ width: 16, height: 16, verticalAlign: 'middle', marginLeft: '3px' }}
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M18.717 6.697l-1.414-1.414-5.303 5.303-5.303-5.303-1.414 1.414 5.303 5.303-5.303 5.303 1.414 1.414 5.303-5.303 5.303 5.303 1.414-1.414-5.303-5.303z' />
        </svg>

                    </a>
                  )}
                  {header}
                  <div className='modal-additional-action' />
                </div>

                <div className={cn('modal-content', additionalBodyClassName)}>{children}</div>
              </div>

              {footer && <div className='hwt-modal-footer'>{footer}</div>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
