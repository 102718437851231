import React, { FunctionComponent } from 'react';
import { noop } from 'lodash';
import './NameSection.scss';


import mapLinkIcon from '../../assets/mapLink.svg';
import mapLinkIconDefault from '../../assets/icons/default/03/Map.svg';

import tripLogo from '../../assets/tripadvisor.svg';
import { Rating } from '../Rating';
import { useStores } from '../../../hooks/useStore';
import { RootStore } from '../../../stores/RootStore';

interface NameSectionProps {
  name: string;
  rating: number;
  destination: string;
  region: string;
  resort: string;
  tripadvisorRating: number;
  tripadvisorReviewCount: number;
  isMapLinkVisible?: boolean;
  onMapLinkClick?: () => void;
  isMap?: boolean;
}

const NameSection: FunctionComponent<NameSectionProps> = ({
  name,
  rating,
  onMapLinkClick = noop,
  destination,
  isMap = false,
  region,
  resort,
  tripadvisorRating,
  tripadvisorReviewCount,
  isMapLinkVisible = true,
}: NameSectionProps) => {


  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  
  let tripRating = null;
  if (tripadvisorRating != null) {
    tripRating = (
      <div className='tripadvisor-rating'>
        <img src={tripLogo} alt='' className='trip-logo' />
        <Rating type={'circle'} rating={tripadvisorRating} />
        <span className='review-details'>{tripadvisorReviewCount} reviews</span>
      </div>
    );
  }

  let mapIco = mapLinkIconDefault;
  if(configuration.icon_set == 'blue'){
    mapIco = mapLinkIcon;
  }

  return (
    <div style={{borderColor: configuration.card_border_colour}} className='name-section-wrapper'>
      <div className='main-info-wrapper'>
        <div className='name-section'>
          <span className={'name'}>{name}</span>
        </div>

        {isMapLinkVisible && (
          <a className='map-link' onClick={onMapLinkClick}>
            <img src={mapIco} alt='' />
            <span>View map</span>
          </a>
        )}
      </div>
      <p className={'location'}>
        {destination}, {region}, {resort}
      </p>
      <div className={`rating-container ${isMap ? 'rating-container--map' : ''}`}>
        <Rating type={'star'} rating={rating} />
        {tripRating}
      </div>
    </div>
  );
};

export default NameSection;
