import React, { FunctionComponent } from 'react';
import './Rating.scss';
import star from '../../assets/star.svg';
import starDefault from '../../assets/icons/default/02/noun-star-1864272-222222.svg'
import filledCircle from '../../assets/filledCircle.svg';
import trP from '../../assets/tr-.5.svg';
import tr1 from '../../assets/tr-1.svg';
import tr1P from '../../assets/tr-1.5.svg';
import tr2 from '../../assets/tr-2.svg';
import tr2P from '../../assets/tr-2.5.svg';
import tr3 from '../../assets/tr-3.svg';
import tr3P from '../../assets/tr-3.5.svg';
import tr4 from '../../assets/tr-4.svg';
import tr4P from '../../assets/tr-4.5.svg';
import tr5 from '../../assets/tr-5.svg';
import circle from '../../assets/circle_empty.svg';
import { useStores } from '../../../hooks/useStore';
import { RootStore } from '../../../stores/RootStore';

interface HolidayCardProps {
  type: 'star' | 'circle';
  rating: number;
}

const Rating: FunctionComponent<HolidayCardProps> = ({ type, rating }: HolidayCardProps) => {


  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  const validateRating = (rating: number) => {
    if (rating < 0) {
      return 0;
    }

    if (rating > 5) {
      return 5;
    }

    return rating || 5;
  };

  const validatedRating = validateRating(rating);

  const StarsRating = () => {

    let starIco = starDefault;
    if(configuration.icon_set == 'blue'){
      starIco = star;
    }

    return (
      <div className='rating'>
        {Array(validatedRating)
          .fill(null)
          .map((item, index) => {
            return <img className={'star'} key={index} src={starIco} alt='Star image' />;
          })}
      </div>
    );
  };

  const CirclesRating = () => {
    if (validatedRating == 0.5) {
      return <img key={'tr' + validatedRating} className={'fullrate'} src={trP} alt='circle' />;
    } else if (validatedRating == 1) {
      return <img key={'tr' + validatedRating} className={'fullrate'} src={tr1} alt='circle' />;
    } else if (validatedRating == 1.5) {
      return <img key={'tr' + validatedRating} className={'fullrate'} src={tr1P} alt='circle' />;
    } else if (validatedRating == 2) {
      return <img key={'tr' + validatedRating} className={'fullrate'} src={tr2} alt='circle' />;
    } else if (validatedRating == 2.5) {
      return <img key={'tr' + validatedRating} className={'fullrate'} src={tr2P} alt='circle' />;
    } else if (validatedRating == 3) {
      return <img key={'tr' + validatedRating} className={'fullrate'} src={tr3} alt='circle' />;
    } else if (validatedRating == 3.5) {
      return <img key={'tr' + validatedRating} className={'fullrate'} src={tr3P} alt='circle' />;
    } else if (validatedRating == 4) {
      return <img key={'tr' + validatedRating} className={'fullrate'} src={tr4} alt='circle' />;
    } else if (validatedRating == 4.5) {
      return <img key={'tr' + validatedRating} className={'fullrate'} src={tr4P} alt='circle' />;
    } else if (validatedRating == 5) {
      return <img key={'tr' + validatedRating} className={'fullrate'} src={tr5} alt='circle' />;
    }
    return null;
    // return (
    //   <div className={'rating'}>
    //     {Array(5).fill(null).map((item, index) => {
    //       const isRating = index < validatedRating;
    //       return (
    //         isRating ? <img key={index} className={'circle'} src={filledCircle} alt="circle"/> :
    //           <img key={index} className={'circle'} src={circle} alt="empty circle"/>
    //       )
    //     })}
    //   </div>
    // )
  };

  return <>{type === 'circle' ? <CirclesRating /> : <StarsRating />}</>;
};

export default Rating;
