

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

export const LoaderSpinner = () => {

    const {
      RootStore: {
        configuration,
      },
    } = useStores() as { RootStore: RootStore };
  
  
    let bs = configuration.button_colour;
    if(configuration.spec == '6yk1y'){;
        bs = '#01447b';
    }
  
    return (
      <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
        <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
      </div>
    );
  };

  export const LoaderSpinnerSmall = () => {

    const {
      RootStore: {
        configuration,
      },
    } = useStores() as { RootStore: RootStore };
  
  
    let bs = configuration.button_colour;
    if(configuration.spec == '6yk1y'){;
        bs = '#01447b';
    }
  
    return (
      <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring-small'>
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
        <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
      </div>
    );
  };