import { StepState } from './ProgressBar';

export const ProgressBarPackage: StepState[] = [
  {
    title: 'Package',
    isActive: false,
    isCompleted: false,
    index: 0,
    url: '/package-select'
  },
  {
    title: 'Passengers',
    isActive: false,
    isCompleted: false,
    index: 1,
    url: '/passengers'
  },
  {
    title: 'Payment',
    isActive: false,
    isCompleted: false,
    index: 2,
    url: '/payment'
  },
];
