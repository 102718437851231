import { useState, ChangeEvent } from 'react';

type CreditCardTermsProps = {
  callback?: (isChecked: boolean) => void;
  configuration: any;
}

export function CreditCardTerms({ callback, configuration }: CreditCardTermsProps) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);

    if (callback) {
      callback(event.target.checked);
    }
  };

  return (
    <div className="credit-card-terms">
      <input
        type='checkbox'
        id='terms'
        name='terms'
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <label htmlFor='terms'>I agree to the <a href={configuration?.booking_terms} className="credit-card-terms__link" target="_blank" rel="noreferrer">terms and conditions</a></label>
    </div>
  );
}
