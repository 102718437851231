import {
    getBasket,
    getBasketFlights,
    getBasketTransfer,
    getAllRoomsFromBasket,
    getPackageRoomsFromBasket,
    getPackageDetails,
  } from '../hooks/useRequest';


export async function basket(userToken: string, sessionId: string){

    const basketResponse = await getBasket(userToken, sessionId);

    if(basketResponse.basket.result == null){
        return null;
    }

    return basketResponse.basket.result;
}

export async function basketRooms(userToken: string, sessionId: string){

    const basketRoomsResponse = await getAllRoomsFromBasket(userToken, sessionId);
    if(basketRoomsResponse.basket_rooms.result == null){
        return null;
    }

    return basketRoomsResponse.basket_rooms.result;
}

export async function basketFlights(userToken: string, sessionId: string, flightId: number){
    const basketFlightResponse = await getBasketFlights(userToken, sessionId, flightId)
    if(basketFlightResponse.basket_flight.result != null){
        return basketFlightResponse.basket_flight.result;
    }
    return null;
}

export async function getBasketDP(userToken: string, sessionId: string){

    const basketResponse = await getBasket(userToken, sessionId);

    if(basketResponse.basket.result == null){
        return null;
    }

    const b = basketResponse.basket.result;

    const basketRoomsResponse = await getAllRoomsFromBasket(userToken, sessionId);
    if(basketRoomsResponse.basket_rooms.result == null){
        return null;
    }

    const rms = basketRoomsResponse.basket_rooms.result;

    let flightId = -1;
    for(const x of b.products){
        if(x.product_type.id == 1){
            flightId = x.id;
            break;
        }
    }

    let f = null;
    if(flightId != -1){
        const basketFlightResponse = await getBasketFlights(userToken, sessionId, flightId)
        if(basketFlightResponse.basket_flight.result != null){
            f = basketFlightResponse.basket_flight.result;
        }
    }

    //do tranfers here ?
    let transferId = -1;
    for(const x of b.products){
        if(x.product_type.id == 3){
            transferId = x.id;
            break;
        }
    }

    let tr = null;
    if(transferId != -1){
        const basketTranferResponse = await getBasketTransfer(userToken, sessionId, transferId)
        if(basketTranferResponse.basket_transfer.result != null){
            tr = basketTranferResponse.basket_transfer.result;
        }
    }
    return {basket: b, rooms: rms, flight: f, transfer: tr};
}


export async function getBasketPackage(userToken: string, sessionId: string){

    const basketResponse = await getBasket(userToken, sessionId);

    if(basketResponse.basket.result == null){
        return null;
    }

    const b = basketResponse.basket.result;

    const packageRooms = await getPackageRoomsFromBasket(userToken, sessionId);

    if(packageRooms.basket_package_rooms.result == null){
        return null;
    }

    const packRooms = packageRooms.basket_package_rooms.result;

    const prodIDs: number[] = [];
    for(const x of packRooms){
      prodIDs.push(x.id);
    }

    let packDetail = null;
    const pakID = sessionStorage.getItem('selectedPackageID');
    const selectedPak = await getPackageDetails(userToken, sessionId, Number(pakID), prodIDs)
    if(selectedPak.package.result == null){
        return null;
    }

    packDetail = selectedPak.package.result

    return {basket: b, package: packDetail, rooms: packRooms};

}