import React, { FunctionComponent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import './MonthPicker.scss';


interface MonthPickerProps {
  size?: 'small' | 'medium' | 'large';
  numberYears?: number;
  selectedDate?: dayjs.Dayjs;
  onMonthClick: (month: string, label: string) => void;
  availableMonths?: {
    enabled: number;
    month: number;
    month_text: string;
    year: number;
  }[];
}

export const MonthPicker: FunctionComponent<MonthPickerProps> = ({
  size = 'large',
  onMonthClick,
  availableMonths,
  selectedDate,
}: MonthPickerProps) => {
  const [activeMonth, setActiveMonth] = useState<number | null>(null);
  const [activeYear, setActiveYear] = useState<number | null>(null);

  const months = [
    { Month: 'Jan', Value: '01' },
    { Month: 'Feb', Value: '02' },
    { Month: 'Mar', Value: '03' },
    { Month: 'Apr', Value: '04' },
    { Month: 'May', Value: '05' },
    { Month: 'Jun', Value: '06' },
    { Month: 'Jul', Value: '07' },
    { Month: 'Aug', Value: '08' },
    { Month: 'Sep', Value: '09' },
    { Month: 'Oct', Value: '10' },
    { Month: 'Nov', Value: '11' },
    { Month: 'Dec', Value: '12' },
  ];

  useEffect(() => {
    if (selectedDate) {
      const month = selectedDate.month() + 1;
      const year = selectedDate.year();

      setActiveMonth(month);
      setActiveYear(year);
    }
  }, [selectedDate]);

  const generateArray = () => {
    const tempArr: number[] = [];
    const availableYears = new Set<number>();
    if (availableMonths) {
      availableMonths.forEach((availableMonth) => {
        if (availableMonth.enabled) {
          availableYears.add(availableMonth.year);
        }
      });
    }
    for (const year of availableYears) {
      tempArr.push(year);
    }
    return tempArr;
  };

  return (
    <div className={`month-picker-wrapper month-picker-wrapper--${size}`}>
      <div className={`month-picker-wrapper--item month-picker-wrapper--item-${size}`}>
        {generateArray().map((item, index) => {
          const tempYear = item;
          const tempMonths = months.filter((month) => {
            return (
              availableMonths?.some((availableMonth) => {
                return (
                  availableMonth.enabled &&
                  availableMonth.month_text === month.Month &&
                  availableMonth.year === tempYear
                );
              }) ?? false
            );
          });

          return (
            <div key={index} className={'year-wrapper'}>
              <div className='year-title'>{tempYear}</div>
              <div className='months'>
                {tempMonths.map((month) => {
                  return (
                    <span
                      key={month.Value}
                      style={{
                        backgroundColor:
                          activeMonth === parseInt(month.Value) && activeYear === tempYear
                            ? '#01447b'
                            : '#ffffff',
                        color:
                          activeMonth === parseInt(month.Value) && activeYear === tempYear
                            ? '#ffffff'
                            : '#01447b',
                      }}
                      onClick={() => {
                        onMonthClick(`${tempYear}-${month.Value}-01`, `${month.Month} ${tempYear}`);
                      }}
                    >
                      {month.Month} {tempYear}
                    </span>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
