/* eslint-disable */
export function geoDeparturePoints() { return [
	{
		"id" : 2,
		"name" : "Belfast Intl",
		"code" : "BFS"
	},
	{
		"id" : 5,
		"name" : "Cambridge Airport",
		"code" : "CBG"
	},
	{
		"id" : 26,
		"name" : "Belfast City",
		"code" : "BHD"
	},
	{
		"id" : 27,
		"name" : "Guernsey",
		"code" : "GCI"
	},
	{
		"id" : 29,
		"name" : "Isle Of Man",
		"code" : "IOM"
	},
	{
		"id" : 31,
		"name" : "Blackpool",
		"code" : "BLK"
	},
	{
		"id" : 32,
		"name" : "Jersey",
		"code" : "JER"
	},
	{
		"id" : 33,
		"name" : "Derry",
		"code" : "LDY"
	},
	{
		"id" : 34,
		"name" : "Kirkwall",
		"code" : "KOI"
	},
	{
		"id" : 35,
		"name" : "Shtland Islands",
		"code" : "LSI"
	},
	{
		"id" : 36,
		"name" : "Storonoway",
		"code" : "SYY"
	},
	{
		"id" : 16,
		"name" : "London Southend",
		"code" : "SEN"
	},
	{
		"id" : 17,
		"name" : "London Luton",
		"code" : "LTN"
	},
	{
		"id" : 37,
		"name" : "London City",
		"code" : "LCY"
	},
	{
		"id" : 23,
		"name" : "London Heathrow",
		"code" : "LHR"
	},
	{
		"id" : 11,
		"name" : "London Gatwick",
		"code" : "LGW"
	},
	{
		"id" : 3,
		"name" : "Birmingham",
		"code" : "BHX"
	},
	{
		"id" : 9,
		"name" : "East Midlands",
		"code" : "EMA"
	},
	{
		"id" : 8,
		"name" : "Durham Tees",
		"code" : "MME"
	},
	{
		"id" : 19,
		"name" : "Newcastle",
		"code" : "NCL"
	},
	{
		"id" : 18,
		"name" : "Manchester",
		"code" : "MAN"
	},
	{
		"id" : 15,
		"name" : "Liverpool",
		"code" : "LPL"
	},
	{
		"id" : 12,
		"name" : "Glasgow",
		"code" : "GLA"
	},
	{
		"id" : 1,
		"name" : "Aberdeen",
		"code" : "ABZ"
	},
	{
		"id" : 38,
		"name" : "Prestwick",
		"code" : "PIK"
	},
	{
		"id" : 28,
		"name" : "Inverness",
		"code" : "INV"
	},
	{
		"id" : 10,
		"name" : "Edinburgh",
		"code" : "EDI"
	},
	{
		"id" : 30,
		"name" : "Southampton",
		"code" : "SOU"
	},
	{
		"id" : 24,
		"name" : "Exeter",
		"code" : "EXT"
	},
	{
		"id" : 20,
		"name" : "Newquay",
		"code" : "NQY"
	},
	{
		"id" : 6,
		"name" : "Cardiff",
		"code" : "CWL"
	},
	{
		"id" : 25,
		"name" : "Bournemouth",
		"code" : "BOH"
	},
	{
		"id" : 7,
		"name" : "Doncaster Sheff",
		"code" : "DSA"
	},
	{
		"id" : 22,
		"name" : "London Stansted",
		"code" : "STN"
	},
	{
		"id" : 39,
		"name" : "Leeds Bradford",
		"code" : "LBA"
	},
	{
		"id" : 21,
		"name" : "Norwich",
		"code" : "NWI"
	},
	{
		"id" : 4,
		"name" : "Bristol",
		"code" : "BRS"
	}
]}
    