import { FunctionComponent, useEffect, useMemo  } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import { filter, noop } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { geoDestinations } from '../../geo_d';
import { geoRegions } from '../../geo_g';
import { geoResorts } from '../../geo_s';
import { geoDeparturePoints } from '../../geo_da';
import {
    getAccommodationNameLocation,
  } from '../../hooks/useRequest';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import dayjs from 'dayjs';

interface PreFilterdProps {
  onLogin?: () => void;
  openMenu?: () => void;
}

export const LoaderSpinner = () => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  let bs = configuration.button_colour;
  if(configuration.spec == '6yk1y'){;
      bs = '#01447b';
  }

  return (
    <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}} />
      <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
      <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
    </div>
  );
};

export const PreFilterPage: FunctionComponent<PreFilterdProps> = ({
  onLogin = noop,
  openMenu = noop,
}) => {


  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const getBoardObj = (bbID: any) => {
    switch (bbID) {
      case 5:
        return { label: 'All inclusive', value: 5 };
      case 4:
        return { label: 'Full board', value: 4 };
      case 3:
        return{ label: 'Half board', value: 3 };
      case 2:
        return{ label: 'Bed & breakfast', value: 2 };
      case 1:
        return{ label: 'Self catering', value: 1 };
      case 0:
        return{ label: 'Room only', value: 0 };
    }
};

  const toTitleCase = (str: string) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  const query = useQuery();

  const navigate = useNavigate();

  useEffect(() => {


    sessionStorage.removeItem('session_cr');


    const geoDest = geoDestinations();
    const geoRegs = geoRegions();
    const geoRes = geoResorts();
    const geoAirprots = geoDeparturePoints();

    const filters = {}

    let stype = 0;
    if (query.get('pf-t')) {
        stype = Number(query.get('pf-t'));
    }

    Object.assign(filters, {'type': stype});

    const roomsArray = [];
    if (query.get('pf-rms')) {
        const rooms = query.get('pf-rms') || '';
        if(rooms != ''){
            const rs = rooms.split(',')
            for(const i of rs){
                const arrChildAges = []
                const ac = i.split('-')
                if(ac[1] != ''){
                    const childAges = ac[1].split(';')
                    for(const ca of childAges){
                        arrChildAges.push(ca.toString())
                    }
                }
                const ao =  ac[0] + '-' + arrChildAges.join(',')
                roomsArray.push(ao)
            }
        }
    }
    else{
        const ao = '2-'
        roomsArray.push(ao)
    }

    const strRooms = roomsArray.join('_');

    // const destinations = [];
    // if (query.get('pf-d')) {
    //     const d = query.get('pf-d') || '';
    //     if(d != ''){
    //         const dsts = d.split(',')
    //         for(const dst of dsts){
    //             const dstID = Number(dst);
    //             let name = '';
    //             for(const gd of geoDest){
    //                 if(gd.id == dstID){
    //                     name = gd.name;
    //                     break;
    //                 }
    //             }
    //             if(name != ''){
    //                 destinations.push({'value':dstID,'label': toTitleCase(name),'type':'destination','isCheck':true,'imageIcon':''})
    //             }
    //         }
    //     }
    // }



    // if (query.get('pf-g')) {
    //     const d = query.get('pf-g') || '';
    //     if(d != ''){
    //         const gs = d.split(',')

    //         for(const g of gs){
    //             const dstID = Number(g);
    //             let name = '';
    //             for(const gd of geoRegs){
    //                 if(gd.id == dstID){
    //                     name = gd.name;
    //                     break;
    //                 }
    //             }
    //             if(name != ''){
    //                 destinations.push({'value':dstID,'label': toTitleCase(name),'type':'region','isCheck':true,'imageIcon':''})
    //             }
    //         }
    //     }
    // }
    // if (query.get('pf-s')) {
    //     const d = query.get('pf-s') || '';
    //     if(d != ''){
    //         const gs = d.split(',')

    //         for(const g of gs){
    //             const dstID = Number(g);
    //             let name = '';
    //             for(const gd of geoRes){
    //                 if(gd.id == dstID){
    //                     name = gd.name;
    //                     break;
    //                 }
    //             }
    //             if(name != ''){
    //                 destinations.push({'value':dstID,'label': toTitleCase(name),'type':'resort','isCheck':true,'imageIcon':''})
    //             }
    //         }
    //     }
    // }


    const depAirs = [];
    if (query.get('pf-da')) {
        const d = query.get('pf-da') || '';
        if(d != ''){
            const airports = []
            const gs = d.split(',')
            for(const g of gs){
                const daCode = g.toUpperCase();
                depAirs.push(daCode);
            }
        }
    }

    let strDepAirs = '';
    if(depAirs.length > 0){
      strDepAirs = depAirs.join('-');
    }

    // if(destinations.length > 0){
    //     Object.assign(filters, {'destinations': destinations});
    // }


    let duration = '&duration=7';
    if (query.get('pf-n')) {
        const d = query.get('pf-n') || '';
        if(d != ''){
            duration = '&duration' + d;
        }
    }


    let depDate = ''
    if (query.get('pf-dd')) {
        const d = query.get('pf-dd') || '';
        if(d != ''){
            let ddType = 0;
            if (query.get('pf-mo')) {
                const dt = query.get('pf-mo') || '';
                if(dt != ''){
                    ddType = Number(dt);
                }
            }


            const fd = dayjs(d).format('YYYY-MM-DD')
            depDate = '&departing=' + fd;
        }
    }

    const filterBoards = []
    if (query.get('pf-bb')) {
        const d = query.get('pf-bb') || '';
        if(d != ''){
            const boardType = [];
            const bbs = d.split(',')
            for(const bb of bbs){
                filterBoards.push(bb);
            }
        }
    }

    let strBoards = '';
    if(depAirs.length > 0){
      strBoards = filterBoards.join('-');
    }

    // const filterRatings = []
    // if (query.get('pf-r')) {
    //     const d = query.get('pf-r') || '';
    //     if(d != ''){
    //         const rs = d.split(',')
    //         for(const r of rs){
    //             filterRatings.push(Number(r));
    //         }
    //     }
    // }
    // const filterTripRatings = []
    // if (query.get('pf-tr')) {
    //     const d = query.get('pf-tr') || '';
    //     if(d != ''){
    //         const trs = d.split(',')
    //         for(const tr of trs){
    //             filterTripRatings.push(Number(tr));
    //         }
    //     }
    // }

    // const filterObject = {'selectedDestinations': [], 'selectedRegions': [], 'selectedResorts':[], 
    // 'selectedBoards': filterBoards, 'selectedRatings': filterRatings, 'selectedTripRatings': filterTripRatings};

    if (query.get('pf-acc')) {
        const d = query.get('pf-acc') || '';
        if(d != ''){   
          getAccommodationNameLocation(userToken, Number(d)).then((ar) => {
            const a = ar.accommodation.result;
            const filterString = strRooms + + duration + strDepAirs + strBoards + depDate + '&accid=' + d;
            navigate(`/flight-and-hotel/${a.resort.regions[0].destinations[0].name.replaceAll(' ', '-').
              toLowerCase()}/${a.resort.regions[0].name.replaceAll(' ', '-').toLowerCase()}/${a.resort.name.replaceAll(' ', '-').toLowerCase()}/
              ${a.name.replaceAll(' ', '-').toLowerCase()}/${d}?${filterString}`);
              return;
            // window.location.href = `http://localhost:3000/flight-and-hotel/${a.resort.regions[0].destinations[0].name.replaceAll(' ', '-').
            //   toLowerCase()}/${a.resort.regions[0].name.replaceAll(' ', '-').toLowerCase()}/${a.resort.name.replaceAll(' ', '-').toLowerCase()}/
            //   ${a.name.replaceAll(' ', '-').toLowerCase()}/${a.id}` 
          });
        }
    }
    else{
      navigate('/');
    }




  }, []);


  return (
    <BaseLayout
      onSearchButtonClick={() => null}
      userToken={userToken}
      userConfiguration={configuration}
    >
      <div className='booking-confirmed-page booking-confirmed-page--failed'>
        <div className='booking-confirmed-page-wrapper'>
          <div className='booking-confirmed-page-wrapper--header'>
            <p className='booking-title booking-title--normal'>
              Just a moment ...
            </p>
            {LoaderSpinner()}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}