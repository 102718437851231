import React, { FunctionComponent, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'

import { noop } from 'lodash';
import { useLocationQuery } from '../../hooks/useQuery';
import { useSearchProvider } from '../../context/search';

// Icons
import search from '../../components/assets/search-icon.svg';
import searchDark from '../../components/assets/icons/dark/noun-magnifying-glass-202232-222222.svg';

import './SearchBar.scss';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { searchAdd } from '../../hooks/useRequest';
import { useSearchParams } from 'react-router-dom';
import { destinationFromID, regionFromID, resortFromID, departurePointFromCode } from '../../services/geo'
import { adultsFromPaxString, childrenCountFromPaxString } from '../../services/pax'
import { toTitleCase } from '../../services/str';

interface SearchButtonProps {
  isOverlayOpen?: boolean;
  onClick?: () => void;
  onSearchItemClick?: (type: string) => void;
}

const SearchButton: FunctionComponent<SearchButtonProps> = ({
  isOverlayOpen,
  onSearchItemClick = noop,
  onClick = noop,
}: SearchButtonProps) => {


  const [searchParams, setSearchParams] = useSearchParams();

  dayjs.extend(AdvancedFormat)

  let selDests : any[] = [];
  let selRegions : any[] = [];
  let selResorts : any[] = [];

  const qsDests = searchParams.get('destinations');
  if(qsDests != null){
    for(const x of qsDests.split('-')){
        selDests.push(Number(x))
    }
  }
  const qsRegions = searchParams.get('regions');
  if(qsRegions != null){
    for(const x of qsRegions.split('-')){
        selRegions.push(Number(x))
    }
  }
  const qsResorts = searchParams.get('resorts');
  if(qsResorts != null){
    for(const x of qsResorts.split('-')){
        selResorts.push(Number(x))
    }
  }
  let selDeparturePoints: any = [];
  const qsDepPoints = searchParams.get('departurePoints');
  if(qsDepPoints != null){
    for(const x of qsDepPoints.split('-')){
        selDeparturePoints.push(x)
    }
  }

  const rooms = searchParams.get('rooms') || '';

  const duration = searchParams.get('duration');

  let selDate: any = null;
  const qsDepDate = searchParams.get('departing');
  if(qsDepDate != null){
    selDate = qsDepDate;
  }

  let selDateType = 0;
  const qsDepDatType = searchParams.get('departingType');
  if(qsDepDatType != null){
    selDateType = Number(qsDepDatType);
  }

  if (!searchParams) return null;

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const { state } = useSearchProvider();

  let searchico = search;
  if(configuration.button_icon == 'dark'){
    searchico = searchDark
  }
  const icon = <img src={searchico} width='22' alt='calendar' />;

  const onSearchItemClickHandler = (type: string) => {
    onSearchItemClick(type);
    !isOverlayOpen && onClick();
  };

  // const testThingy = () => {


  //   setSearchParams(searchParams => {

  //     const dps = [];
  //     const dp = searchParams.get('departurePoints');
  //     if(dp != null){
  //        for(const x of dp.split(',')){
  //           dps.push(x);
  //        }
  //     }

  //     dps.push('LGW');
  //     const strDps = dps.join('-');

  //     searchParams.set('departurePoints', strDps)
  //     return searchParams
  //   });

  // }

  const selectedParams = () => {

    let strDeparturePoints = 'Any Airport';
    if(selDeparturePoints.length > 0){
      strDeparturePoints = departurePointFromCode(selDeparturePoints[0]);
      if(selDeparturePoints.length > 1){
        strDeparturePoints += ' + ' + (selDeparturePoints.length - 1) + ' More';
      }
    }

    let destTotalCount = 0;
    let strDestinations = '';

    if(selDests.length > 0){
      if(strDestinations == ''){
        strDestinations = destinationFromID(selDests[0])
      }
      destTotalCount += selDests.length;
    }
    if(selRegions.length > 0){
      if(strDestinations == ''){
        strDestinations = regionFromID(selRegions[0]);
      }
      destTotalCount += selRegions.length;
    }
    if(selResorts.length > 0){
      if(strDestinations == ''){
        strDestinations = resortFromID(selResorts[0]);
      }
      destTotalCount += selResorts.length;
    }

    if(strDestinations == ''){
      strDestinations = 'Any Destination'
    }
    else{
      strDestinations = toTitleCase(strDestinations);
      
      if(strDestinations.toLowerCase() == 'usa'){
        strDestinations = 'USA';
      }

      if(destTotalCount > 1){
        strDestinations += ' + ' + (destTotalCount - 1) + ' More';
      }
    }

    let strDepartureDate = 'Any Date'
    if(selDate != null){

      if(selDateType == 1){
        const dateObj = dayjs(selDate, 'YYYY-MM-DD')
        strDepartureDate = dateObj.format('MMM YYYY')
      }else{
        const dateObj = dayjs(selDate, 'YYYY-MM-DD')
        strDepartureDate = dateObj.format('Do MMM YYYY')
      }
 
    }

    let strPax = ''
    const adults = adultsFromPaxString(rooms);
    const childrenCount = childrenCountFromPaxString(rooms);

    if(adults == 1){
      strPax = adults + ' Adult';
    }
    else{
      strPax = adults + ' Adults';
    }

    if(childrenCount > 0){
      if(childrenCount == 1){
        strPax += ' ' + childrenCount + ' Child'
      }
      else{
        strPax += ' ' + childrenCount + ' Children'
      }
 
    }
        
    return (
      <div className='search-button-values'>
        {
          <span style={borderColour} onClick={() => onSearchItemClickHandler('DeparturePoints')}>
            {strDeparturePoints}
          </span>
        }
        {
          <span style={borderColour} onClick={() => onSearchItemClickHandler('Destination')}>
            {strDestinations}
          </span>
        }

        <span style={borderColour} onClick={() => onSearchItemClickHandler('Date')}>{strDepartureDate}</span>

        <span style={borderColour} onClick={() => onSearchItemClickHandler('Duration')}>{duration} Nights</span>
        <span style={borderColour} onClick={() => onSearchItemClickHandler('Rooms')}>{strPax}</span>
      </div>
    );
  };

  const borderColour = {borderColor: ''};
  const borderStyle = {border: ''};
  const buttonStyle = {backgroundColor: ''}
  if(configuration != null){
    borderStyle.border = 'solid 1px ' + configuration.border_colour_2;
    borderColour.borderColor = configuration.border_colour_2;
    buttonStyle.backgroundColor = configuration.button_colour;
  }

  return (
    <div style={borderStyle} className='search-button-wrapper'>
      <div className='search-button-text'>{selectedParams()}</div>
      <div style={buttonStyle} className='search-button-icon' onClick={onClick}>
        {icon}
      </div>
    </div>
  );
};

export default SearchButton;
