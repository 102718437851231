import React, { FunctionComponent } from 'react';
import './HolidayDetails.scss';


import hotelIcon from '../assets/hotel.svg';
import hoteliconDefault from '../assets/icons/default/03/Hotel.svg';

import locationIcon from '../assets/location.svg';
import locationIconDefault from '../assets/icons/default/03/Map.svg';


import tripadvisor from '../assets/tripadvisor.svg';
import { Image } from '../ImageCarousel/ImageCarousel';
import { HolidayTopDetails } from './HolidayTopDetails';
import { ImageSlider } from '../ImageSlider/ImageSlider';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

interface FiltersSidebarProps {
  hotelName: string;
  resortName: string;
  regionName: string;
  destinationName: string;
  rating: number;
  tripadvisorRating: number;
  tripadvisorReviews: string;
  images: Image[];
  isRec: boolean;
  onLinkClick: (tab: number) => void;
}

const HolidayDetails: FunctionComponent<FiltersSidebarProps> = ({
  hotelName,
  resortName,
  regionName,
  destinationName,
  rating,
  tripadvisorRating,
  tripadvisorReviews,
  images,
  isRec = false,
  onLinkClick,
}: FiltersSidebarProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  let tripSec = null;
  if (tripadvisorRating != -1) {
    tripSec = (
      <a href={'#'} className='link-wrapper' onClick={() => onLinkClick(2)}>
        <img className='link-icon weather-icon' src={tripadvisor} alt={'Tripadvisor icon'} />
        <span style={{color: configuration.text_colour, borderColor: configuration.border_colour}} className={'link-text'}>{'Tripadvisor'}</span>
      </a>
    );
  }


  let mapIco = locationIconDefault;
  let hotelIco = hoteliconDefault;
  if(configuration.icon_set == 'blue'){
    mapIco = locationIcon
    hotelIco = hotelIcon;
  }


  return (
    <>
      <div className='holiday-details-component-wrapper'>
        <HolidayTopDetails
          hotelName={hotelName}
          resortName={resortName}
          regionName={regionName}
          destinationName={destinationName}
          rating={rating}
          tripadvisorRating={tripadvisorRating}
        />
        <div className='holiday-details-component-wrapper--slider'>
          {images && images.length > 0 && <ImageSlider isRec={isRec} images={images} />}
        </div>

        <div className='holiday-details-component-wrapper--footer-links'>
          <a href={'#'} className='link-wrapper' onClick={() => onLinkClick(0)}>
            <img className='link-icon' src={hotelIco} alt={'Hotel icon'}></img>
            <span style={{color: configuration.text_colour, borderColor: configuration.container_colour}} className={'link-text'}>{'Hotel Information'}</span>
          </a>

          <a href={'#'} className='link-wrapper' onClick={() => onLinkClick(1)}>
            <img className='link-icon map-icon' src={mapIco} alt={'Map icon'}></img>
            <span style={{color: configuration.text_colour, borderColor: configuration.container_colour}} className={'link-text'}>{'Map'}</span>
          </a>

          {tripSec}
        </div>
      </div>
    </>
  );
};

export default HolidayDetails;
