import React, { FunctionComponent, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CustomDropdownIndicator } from '../Room/CustomDropdownIndicator';
import './PassengerInformation.scss';
import dayjs from 'dayjs';
import Select, { SingleValue } from 'react-select';
import { monthName } from '../../services/date';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { valueToOption } from '../../services/collections'
import { SelectOption } from '../../hooks/types'
import { Person } from '../../hooks/types'
import { isStringEmpty, isStringNumber } from '../../services/str';
import { validateDOB } from '../../services/date';

 interface PassengerInformationProps {
  sessionId: string;
  index: number;
  isFormValid?: boolean;
  pTitle: string | null;
  pFirstName: string;
  pSecondName: string;
  pDateOfBirth: string;
  age: number;
  isLead: boolean;
  isChild: boolean;
  pContactNumber: string;
  formHasBeenSubmit: boolean;
  onUpdate: (pax: Person) => void;
  onContactNumberUpdate?: (contactNumber: string) => void;

}
const PassengerInformation: FunctionComponent<PassengerInformationProps> = ({
  sessionId,
  index,
  isFormValid,
  pTitle,
  pFirstName,
  pSecondName,
  pDateOfBirth,
  age,
  isLead,
  isChild,
  pContactNumber,
  formHasBeenSubmit,
  onUpdate,
  onContactNumberUpdate,
}) => {

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  const [selectedValues, setSelectedValues] = useState<any>();


  const defaultTitle = valueToOption(pTitle)


  const defaultDOBDay = valueToOption(null);
  const defaultDOBMonth = valueToOption(null);
  const defaultDOBYear = valueToOption(null);


  const [title, setCustomerTitle] = useState<SelectOption | null>(defaultTitle);
  const [errTitle, setErrTitle] = useState<boolean>(false)
  
  const [customerFirstName, setFirstName] = useState<string>(pFirstName);
  const [errFirstName, setErrFirstName] = useState<boolean>(false)

  const [customerSecondName, setSecondName] = useState<string>(pSecondName);
  const [errSecondName, setErrSecondName] = useState<boolean>(false)

  const [customerContactTelephone, setContactTelephone] = useState<string>(pContactNumber);
  const [errContactTel, setErrContactTel] = useState<boolean>(false)
  

  const [dDay, setDobDay] = useState<SelectOption | null>(defaultDOBDay);
  const [dmonth, setDobMonth] = useState<SelectOption| null>(defaultDOBMonth);
  const [dYear, setDobYear] = useState<SelectOption| null>(defaultDOBYear);
  const [errDOB, setErrDOB] = useState<boolean>(false)

  const [yearOptions, setYearOptions] = useState<SelectOption[]>([]);
  const [titleOptions, setTitleOptions] = useState<SelectOption[]>([]);

  const [formSubmit, setFormSubmit] = useState<boolean>(formHasBeenSubmit);

  const p = {id: -1, title: pTitle, first_name: pFirstName, second_name: pSecondName, date_of_birth: '', age: 18, gender: 'male'} as Person;
  const [pax, setPax] = useState<Person>(p)

  let dobVal = false;
  if(age == -1){
    dobVal = true;
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'Decemeber',
  ];

  const dobValidate = async (dob: any) => {

    if(!isChild){
      setErrDOB(false)
      return;
    }


    const val = await validateDOB(dob, age, sessionId, userToken) as any;

    if(val == null){
      return
    }

    if(val){
      setErrDOB(false)
    }
    else{
      setErrDOB(true)
    }

  };

  const handleSelectTitleChange = (option:SelectOption | null) => {

    if(option == null){
      setErrTitle(true);
      return
    }
    setErrTitle(false);
    setCustomerTitle(option);
    pax.title = option.value;
    setPax(pax);
    onUpdate(pax)

  };

  const handleChangeFirstName = (value: string) => {

    if(isStringEmpty(value)){
      setErrFirstName(true);
    }
    else{
      setErrFirstName(false);
    }

    setFirstName(value);
    pax.first_name = value;
    setPax(pax);
    onUpdate(pax)
  };

  const handleChangeSecondName = (value: string) => {

    if(isStringEmpty(value)){
      setErrSecondName(true);
    }
    else{
      setErrSecondName(false);
    }

    setSecondName(value);
    pax.second_name = value;
    setPax(pax);
    onUpdate(pax)
  };

  const handleChangeContactTelephone = (value: string) => {

    if(isStringNumber(value)){
      setErrContactTel(false);
    }
    else{
      setErrContactTel(true);
    }

    setContactTelephone(value);
    //this is handled in Lead pax just output here....(Bit annoying)

    if(onContactNumberUpdate){
      onContactNumberUpdate(value);
    }
  };

  const handleSelectDOBDayChange = (option:SelectOption | null) => {


    if(option == null){
      setErrDOB(true);
    }
    else{
      setErrDOB(false);
    }
    
    if(option == null){
      return;
    }

    setDobDay(option);
    if(dmonth != null && dYear != null){
      const x = dYear.value  + '-' + dmonth.value  + '-' + option.value + ' 00:00';
      dobValidate(x);

      pax.date_of_birth = x;
      setPax(pax);
      onUpdate(pax)
    }
  };

  const handleSelectDOBmonthChange = (option:SelectOption | null) => {

    if(option == null){
      setErrDOB(true);
    }
    else{
      setErrDOB(false);
    }

    if(option == null){
      return;
    }

    setDobMonth(option);
    if(dDay != null && dYear != null){
      const x = dYear.value  + '-' + option.value  + '-' + dDay.value + ' 00:00';
      dobValidate(x);

      pax.date_of_birth = x;
      setPax(pax);
      onUpdate(pax)
    }
  };

  const handleSelectDOByearChange = (option:SelectOption | null) => {

    if(option == null){
      setErrDOB(true);
    }
    else{
      setErrDOB(false);
    }
    
    if(option == null){
      return;
    }

    setDobYear(option);
    if(dDay != null && dmonth != null){
      const x = option.value  + '-' + dmonth.value  + '-' + dDay.value + ' 00:00';
      dobValidate(x);

      pax.date_of_birth = x;
      setPax(pax);
      onUpdate(pax)
    }
  };

  useEffect(() => {
    if (!isChild) {
      const _years: SelectOption[] = [];
      let a = 13;
      if (isLead) {
        a = 18;
      }
      const date = dayjs().subtract(a, 'years').year();
      const minYear = dayjs().subtract(120, 'years').year() - 1;
      for (let i = date; i > minYear; i--) {
        _years.push({value: i.toString(), label: i.toString()},);
      }
      setYearOptions(_years);

      const adultTitles: SelectOption[] = [
        {value: 'Mr', label: "Mr"},
        {value: 'Mrs', label: "Mrs"},
        {value: 'Ms', label: "Ms"},
        {value: 'Mr', label: "Mr"},
      ];
      setTitleOptions(adultTitles);
    } else {

      const date = dayjs().year();
      const minYear = dayjs().subtract(17, 'years').year() - 1;
      const _years: SelectOption[] = [];
      for (let i = date; i > minYear; i--) {
        _years.push({value: i.toString(), label: i.toString()},);
      }
      setYearOptions(_years);

      const childTitles: SelectOption[] = [
        {value: 'Mr', label: "Mr"},
        {value: 'Miss', label: "Miss"},
      ];
      
      setTitleOptions(childTitles);
    }
  }, []);


  useEffect(() => {
    setFirstName(pFirstName);

    const p = pax;
    p.first_name = pFirstName;
    setPax(p);

  }, [pFirstName])

  useEffect(() => {
    setContactTelephone(pContactNumber);

  }, [pContactNumber])

  useEffect(() => {
    setSecondName(pSecondName);


    const p = pax;
    p.second_name = pSecondName;
    setPax(p);
    

  }, [pSecondName])

  useEffect(() => {
    const titleOption = valueToOption(pTitle);
    setCustomerTitle(titleOption);

    if(titleOption != null){
      const p = pax;
      p.title = titleOption.value;
      setPax(p);
    }

  }, [pTitle])

  useEffect(() => {
    
    if(pDateOfBirth == ''){
      return
    }

    const d = dayjs(pDateOfBirth);

    const defaultDOBDay = valueToOption(d.date().toString());

    const dMonth = d.month() + 1;
    const defaultDOBMonth = valueToOption(dMonth.toString(), monthName(dMonth));

    const defaultDOBYear = valueToOption(d.year().toString());

    setDobDay(defaultDOBDay);
    setDobMonth(defaultDOBMonth);
    setDobYear(defaultDOBYear);

    const x = d.year() + '-' + dMonth  + '-' + d.date() + ' 00:00';
    const p = pax;
    p.date_of_birth = x;
    setPax(p);

  }, [pDateOfBirth])

  useEffect(() => {
    setFormSubmit(formHasBeenSubmit);
  }, [formHasBeenSubmit])


  useEffect(() => {

    if(!formSubmit){
      return
    }


    if(title == null){
      setErrTitle(true);
    }
    else{
      if(isStringEmpty(title.value)){
        setErrTitle(true);
      }
      else{
        setErrTitle(false);
      }
    }



    if(isStringEmpty(firstName)){
      setErrFirstName(true);
    }
    else{
      setErrFirstName(false);
    }

    if(isStringEmpty(secondName)){
      setErrSecondName(true);
    }
    else{
      setErrFirstName(false);
    }

    if(isStringEmpty(customerContactTelephone)){
      setErrContactTel(true);
    }
    else{
      setErrFirstName(false);
    }

    if(dDay == null || dmonth == null || dYear == null){
      setErrDOB(true);
    }
    else{
      if(dDay != null && dmonth != null && dYear != null){
        const x = dYear.value  + '-' + dmonth.value  + '-' + dYear.value + ' 00:00';
        dobValidate(x)
      }
    }

  }, [formSubmit]);
  // useEffect(() => {


  //     const dob = dayjs(pDateOfBirth);
  //     const day = dob.date()
  //     let strDay = day.toString();
  //     if (day < 10) {
  //       strDay = '0' + day.toString();
  //     }
  //     const dobDay = { value: strDay, label: dob.date().toString() };
  //     setDobDay(dobDay);

  //     const month = dob.month() + 1;
  //     let strMonth = month.toString();
  //     if (month < 10) {
  //       strMonth = '0' + month.toString();
  //     }
  //     const monthName = monthNames[dob.month()];
  //     const dobMOnth = { value: strMonth, label: monthName };
  //     setDobMonth(dobMOnth);

  //     const year = dob.year();
  //     const strYear = year.toString();
  //     const dobYear = { value: strYear, label: strYear };
  //     setDobYear(dobYear);

  //     dobValidate(strYear + '-' + strMonth + '-' + strDay + ' 00:00');
    
  // }, []);

  const customStyles: any = {
    container: (base: any, state : any) => ({
      ...base,
      margin: '8px 0 20px 0',
      boxShadow: 'none',
    }),
    control: (base: any, state : any) => ({
      ...base,
      borderColor: isFormValid || state.hasValue ? configuration.border_colour_2 : 'red',
    }),
    singleValue: (base: any, state : any) => ({
      ...base,
    }),
    indicatorSeparator: (base: any, state : any) => ({
      ...base,
      marginTop: 0,
      marginBottom: 0,
    }),
    menu: (base: any, state : any) => ({
      ...base,
      backgroundColor: 'white',
      position: 'absolute',
    })
  };

  // const colourStyles: StylesConfig<ColourOption> = {
  //   control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {

  //     return {
  //       ...styles,
  //       borderColor: isSelected ? 'red' : 'black',
  //       cursor: isDisabled ? 'not-allowed' : 'default',,
  //     };
  //   },
  // };


  // let firstName = '';
  // let secondName = '';
  // let dob = null;
  // let dobDay = null;
  // let contactTelephone = '';


let firstName = customerFirstName;
let secondName = customerSecondName;

  // let dob = new dayjs();
  // const day = dob.getDate();
  // let strDay = day.toString();
  // if (day < 10) {
  //   strDay = '0' + day.toString();
  // }
  // dobDay = { value: strDay, label: dob.getDate().toString() };
  

  let valDobMsg = null;

  // if(!dobValid && (dDay != null && dmonth != null && dYear != null)){
  //   let whoAmI = 'child\'s';
  //   if(customerFirstName != null && customerFirstName != ''){
  //     whoAmI = customerFirstName;
  //     if(customerSecondName != null && customerSecondName != ''){
  //       whoAmI += ' ' + customerSecondName + '\'s';
  //     }
  //   }
  //   valDobMsg = <span className='error-message'>Please make sure {whoAmI} age is {age} on the day of return</span>
  // }

  // const tops = [];
  // for(const x of titleOptions){
  //   tops.push(<option value={x.}>{x.label}</option>)
  // }
  // let titleSelect = <select required value={title} defaultValue={'Title'} onChange={(e) => handleSelectTitleChange(e.target.value)}>
  //   <option style={{color: 'green'}} value={'default'}>Title !</option>
  //   {tops} 
  // </select>

  const dobDays: SelectOption[] = [];
  for(let i=1; i < 32; i++){
    let lbl = i.toString();
    if(i < 10){
      lbl = '0' + i;
    }
    dobDays.push({value:i.toString(), label: lbl})
  }

const dobMonths: SelectOption[] = []
for(let i=1; i < 13; i++){
  const lbl = monthName(i);
  dobMonths.push({value:i.toString(), label: lbl})
}

// const dobYears: SelectOption[] = []
// for(const i of yearOptions){
//   dobYears.push(<option value={i}>{i.toString()}</option>)
// }


let titleClass = 'dob-inputs-title';
if(errTitle){
  titleClass = 'dob-inputs-title error';
}

let firstNameClass = 'select-label passenger-first-name';
if(errFirstName){
  firstNameClass = 'select-label passenger-first-name error';
}

let secondNameClass = 'select-label passenger-last-name';
if(errSecondName){
  secondNameClass = 'select-label passenger-last-name error';
}

let dobClass = 'dob-inputs-title';
if(errDOB){
  dobClass = 'dob-inputs-title error';
}

let contactClass = 'select-label';
if(errContactTel){
  contactClass = 'select-label error';
}


  return (
    <div className='passenger-information-container'>
      <div className='passenger-main-info-desktop'>
        <label className='passenger-title'>
          <div className={titleClass}>Title*</div>
          <Select
            options={titleOptions}
            placeholder='Title'
            onChange={(option) => handleSelectTitleChange(option)}
            name={index ? 'title' + index : 'title'}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
            value={title}
            required
          />
       
          {/* <input type='hidden' id={'title' + index} value={title ? title.value : null}></input> */}
        </label>
        <label className={firstNameClass} aria-required>
          First name*
          <div className='passenger-input-wrapper'>
            <input
              style={{borderColor: configuration.border_colour_2}}
              className='passenger-info-input'
              type='text'
              name={index ? 'firstName' + index : 'firstName'}
              id={'firstName' + index}
              onChange={(e) => handleChangeFirstName(e.target.value)}
              value={customerFirstName}
              required
            />
          </div>
        </label>
        <label className={secondNameClass} aria-required>
          Last name*
          <div className='passenger-input-wrapper'>
            <input
              style={{borderColor: configuration.border_colour_2}}
              className='passenger-info-input'
              type='text'
              name={index ? 'lastName' + index : 'lastName'}
              id={'secondName' + index}
              onChange={(e) => handleChangeSecondName(e.target.value)}
              required
              value={customerSecondName}
            />
          </div>
          {valDobMsg}
        </label>
      </div>
      <div className='passenger-dob-phone'>
        <div className={dobClass}>DOB*</div>
        
        <div className='dob-wrapper'>

          <Select
            menuShouldBlockScroll={false}
            className='dob-input'
            options={dobDays}
            placeholder='Day'
            onChange={(option) => handleSelectDOBDayChange(option)}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
            name={index ? 'day' + index : 'day'}
            value={dDay}
            required
          />
          <Select
            menuShouldBlockScroll={false}
            className='dob-input'
            options={dobMonths}
            placeholder='Month'
            onChange={(option) => handleSelectDOBmonthChange(option)}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
            name={index ? 'month' + index : 'month'}
            value={dmonth}
            required
          />
          <Select
            menuShouldBlockScroll={false}
            className='dob-input'
            options={yearOptions}
            placeholder='Year'
            onChange={(option) => handleSelectDOByearChange(option)}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
            name={index ? 'year' + index : 'year'}
            value={dYear}
            required
          />
          {valDobMsg}


        </div>
        {isLead ? (
          <div className='phone-wrapper'>
            <label className={contactClass} aria-required>
              Contact number*
              <div className='passenger-input-wrapper'>
                <input
                  style={{borderColor: configuration.border_colour_2}}
                  className='passenger-info-input'
                  type='text'
                  onChange={(e) => handleChangeContactTelephone(e.target.value)}
                  id={'contactTelephone'}
                  name='phone'
                  required
                  value={customerContactTelephone}
                />
              </div>
            </label>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PassengerInformation;
