import dayjs from "dayjs";
import { checkAge } from '../hooks/useRequest';

export function monthName(month: number){
    switch(month){
        case 1:
            return 'January';
        case 2:
            return 'February';
        case 3:
            return 'March';
        case 4:
            return 'April';
        case 5:
            return 'May';
        case 6:
            return 'June';
        case 7:
            return 'July';
        case 8:
            return 'August';
        case 9:
            return 'September';
        case 10:
            return 'October';
        case 11:
            return 'November';
        case 12:
            return 'December';
    }

    return '';
}

export function hwtGraphDate(str: string){
    const x = dayjs(str);
    return x.format('YYYY-MM-DD HH:mm');
}

export async function validateDOB (dob: string, age: number, sessionId: string, userToken: string){
    const val = await checkAge(userToken, sessionId, dob, age) as any;
    if(val == null){
      return
    }
    if(val.session_check_person_age.result != null){
      if(val.session_check_person_age.result == 1){
        return true;
      }
      else{
        return false;
      }
    }
  };