import React, { FunctionComponent, useEffect, useState } from 'react';
import { Board } from './Board';
import './SelectBoard.scss';

export interface Board {
  type: string;
  price: number;
  id: number;
  diff: number;
  diffStr: string;
}

export interface SelectBoardProps {
  boards: Board[];
  selectedBoadId: number;
  onClick?: (board: Board) => void;
  disabled: boolean;
}

const SelectBoard: FunctionComponent<SelectBoardProps> = ({
  boards,
  selectedBoadId,
  onClick,
  disabled
}: SelectBoardProps) => {

  const [selectedBoard, setSelectedBoard] = useState<number>(selectedBoadId);

  // useEffect(() => {
  //   if (boards?.length > 0) {
  //     setSelectedBoard({ ...boards[0].id });

  //     // We should also set this in the basket store
  //     setBasket({ ...basket, board: { ...boards[0] } });
  //   }
  // }, []);

  const handleClick = (board: Board) => {

    if(disabled){
      return
    }
    setSelectedBoard(board.id);

    // We should also set this in the basket store


    // setBasket({ ...basket, board });

    if (onClick) onClick(board);
  };

  const getBoardPrice = (price: number) => {
    return `£${price} pp`;
  };

  return (
    <div className='select-board-wrapper'>
      {boards.map((board: Board) => {
        return (
          <Board
            key={board.type}
            type={board.type}
            priceInfo={board.diffStr ? board.diffStr : undefined}
            onClick={() => handleClick(board)}
            selected={selectedBoard === board.id}
          />
        );
      })}
    </div>
  );
};

export default SelectBoard;
