import { FC, useState } from 'react';
import { IPagination } from './Interfaces';
import './Pagination.scss';
import MenuArrow from '.././assets/menu-arrow.svg';

export const Pagination: FC<IPagination> = ({ by, onChange, count, keyValue }) => {

  const [range, setRange] = useState(0);
  const [selected, setSelected] = useState(0);

  if (!count && !by) {
    return <></>;
  }

  const amount = Math.ceil(count / by);
  const arrayOfAmount = new Array(amount).fill('').map((val, index) => {
    return index;
  });

  const decrease = () => {
      const val = selected -1;
      setSelected(val);
      onChange(val);
  };
  const increase = () => {
      const val = selected + 1;
      setSelected(val);
      onChange(val);
  };

  let a = amount;
  //max 6 pages ? 
  if(amount > 6){
    a = 6;
  }

  let secInc = null;
  const selectedPageNumber = selected + 1;
  if(selectedPageNumber != a){
    secInc =  <div className='pagination-circle' onClick={increase}>
    <span className='pagination-img'>
      <img src={MenuArrow} height='15' className='pagination-right-arrow' />
    </span>
  </div>;
  }

  let secDec = null;
  if(selectedPageNumber != 1){
    secDec = <div className='pagination-circle' onClick={decrease}>
    <span className='pagination-img'>
      <img src={MenuArrow} height='15' className='pagination-left-arrow' />
    </span>
  </div>
  }



  return (
    <div className='pagination-flex-container'>
      {secDec}
      {arrayOfAmount.slice(range, range + a).map((val, index) => {
        return (
          <div
            onClick={() => {
        
              setSelected(val);
              onChange(val);
            }}
            className={`pagination-circle ${selected === val ? 'pagination-selected' : ''}`}
            key={keyValue + index}
          >
            <span className='pagination-text'>{val + 1}</span>
          </div>
        );
      })}
      {secInc}
    </div>
  );
};
