import queryString from 'query-string';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProgressBar.css';
import cn from 'classnames';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

export interface StepState {
  index: number;
  title: string;
  isCompleted: boolean;
  isActive: boolean;
  url: string;
}

export interface ProgressBarProps {
  state: Array<StepState>;
  size?: 'small' | 'medium' | 'large';
  onStepChange?: () => void;
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  state,
  size,
  onStepChange,
}: ProgressBarProps) => {
  
  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const navigate = useNavigate();

  const navigateStep = (index: number, url: string) => {
    const params = queryString.parse(location.search);
    navigate(url + location.search);
  };

  const steps = [];


  let counter = 1;
  for(const x of state){

    const s = {backgroundColor: '', cursor: '', color: ''};

    let sep = null;
    if(x.isActive || x.isCompleted){
      s.backgroundColor = configuration.jrny.progress_bar_active_colour;
      s.color = configuration.jrny.progress_bar_active_text_colour;
      
    }
    else{
      s.backgroundColor = configuration.jrny.progress_bar_colour;
      s.color = configuration.jrny.progress_bar_text_colour;

    }

    if(x.isCompleted){
      s.cursor = 'pointer';
      sep = <div style={{position: 'absolute', content: '', borderBottom: '2px solid ' + configuration.jrny.progress_bar_active_colour, width: '100%', top: '15px', left: '50%', zIndex: '2'}}></div>
    }
    else{
      s.cursor = 'default';
      sep = <div style={{position: 'absolute', content: '', borderBottom: '2px solid ' + configuration.jrny.progress_bar_colour, width: '100%', top: '15px', left: '50%', zIndex: '2'}}></div>
    }

    if(counter == state.length){
      sep = null;
    }



    if(x.isCompleted){
      steps.push(<div
        key={x.index}
        className={'progress-bar--item'}
        onClick={() => navigateStep(x.index, x.url)}>
        <div style={s} className='progress-bar--item-counter'>{x.index + 1}</div>
        <div style={{color: configuration.text_colour}} className='progress-bar--item-name'>{x.title}</div>
        {sep}
      </div>)
    }
    else{
      steps.push(<div
        key={x.index}
        className={cn(
          'progress-bar--item',
          { active: x.isActive },
        )}>
        <div style={s} className='progress-bar--item-counter'>{x.index + 1}</div>
        <div style={{color: configuration.text_colour}} className='progress-bar--item-name'>{x.title}</div>
        {sep}
      </div>)
    }

    counter+= 1;
  }


  return (
    <div className={`progress-bar-wrapper progress-bar-wrapper--${size}`}>
      {steps}
    </div>
  );
};
