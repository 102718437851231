import React, { FunctionComponent, useState, useEffect } from 'react';
import { noop } from 'lodash';
import Select, { StylesConfig } from 'react-select';
import { CustomDropdownIndicator } from './CustomDropdownIndicator';
import { Option } from '../Autocomplete';
import './Room.scss';
import { Button } from '../Button';



import { SearchSelectionRoom } from '../../hooks/types'

// export interface Guests {
//   children: number;
//   adults: number;
//   childAge: any[];
// }


interface RoomProps {
  adultsOptions: any[];
  childrenOptions: any[];
  roomNumber: number;
  onApply: (room: SearchSelectionRoom) => void;
  removeRoom?: (num: number) => void;
  defaultValues: SearchSelectionRoom,
  config: any;
}

const ageRange: any[] = [];

// ageRange.push({label: 'Select age', value: -1})
for (let i = 0; i < 18; i++) {
  ageRange.push({
    label: i,
    value: i,
  });
}

const Room: FunctionComponent<RoomProps> = ({
  adultsOptions,
  childrenOptions,
  onApply = noop,
  roomNumber,
  defaultValues,
  removeRoom,
  config
}: RoomProps) => {


  const [room, setRoom] = useState<SearchSelectionRoom>({
    adults: defaultValues.adults || 2,
    children: defaultValues.children || 0,
    childAges: defaultValues.childAges || [],
  });

  const [paxCount, setPaxCount] = useState<number>(2);

  const [adultCount, setAdultCount] = useState<number>(0);
  const [childCount, setChildCount] = useState<number>(0);

  const [strAge, setAgeString] = useState<String>('');

  const changeNumberAdults = (adults: number) => {
    const x = room;
    x.adults = adults;
    setRoom(x);
    onApply(x);
    setPaxCount(x.adults + x.children);
    setAdultCount(x.adults);
    setChildCount(x.children);
  }

  const changeNumberChildren = (children: number) => {

    const x = room;
    x.children = children;

    if(x.childAges.length > children){
      x.childAges.length = children;
    }
    else{
      while(x.childAges.length < children){
        x.childAges.push(-1);
      }
    }

    setRoom(x);
    onApply(x);
    setPaxCount(x.adults + x.children);
    setAdultCount(x.adults);
    setChildCount(x.children);
  }


  const changeAge = (age: number, index: number) => {
    const x = room;
    x.childAges[index] = age;
    setRoom(x);
    onApply(x);
    setAgeString(x.childAges.join(','));
  };

  useEffect(() => {
    const r = room;
    if (r.childAges == null) {
      return;
    }
    const defaultAges = [];
  
    const childAges = Object.values(r.childAges);
    if (childAges.length == 0 && r.children > 0) {
      for (let i = 0; i < r.children; i++) {
        defaultAges[i] = 0;
      }
      r.childAges = defaultAges;
    } else if (r.children == 0) {
      r.childAges = [];
    }
    setRoom(r);
  }, [room]);

  // useEffect(() => {
  //   if (!remove) onApply(room);
  // }, [room, remove]);

  const customStyles: any = {
    container: (base: any) => ({
      ...base,
      marginTop: '16px',
    }),
    control: (base: any) => ({
      ...base,
      borderColor: config.border_colour_2,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: config.text_colour,
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      marginTop: 0,
      marginBottom: 0,
      borderColor: config.border_colour_2,
    }),
    menu: (base: any) => ({
      ...base,
      borderColor: config.border_colour_2,
      backgroundColor: 'white',
      position: 'absolute',
    }),
  };

  const errorStyle: any = {
    container: (base: any) => ({
      ...base,
      marginTop: '16px',
    }),
    control: (base: any) => ({
      ...base,
      borderColor: 'red',
    }),
    singleValue: (base: any) => ({
      ...base,
      color: 'red',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      marginTop: 0,
      marginBottom: 0,
      borderColor: config.border_colour_2,
    }),
    menu: (base: any) => ({
      ...base,
      borderColor: config.border_colour_2,
      backgroundColor: 'white',
      position: 'absolute',
    }),
  };
  // const childrenSelectorArray = [...Array(room.children).keys()];
  // let removeBack = config.button_colour_secondary;
  // let remove

  return (
    <div style={{borderColor: config.border_colour_2}} className='room-container'>
      {roomNumber !== 1 && (
        <Button
          label='Remove room'
          hoverStyle={false}
          onClick={() => {
            if (removeRoom) removeRoom(roomNumber);
          }}

          backgroundColor={config.button_colour_secondary}
          color={config.button_colour}
          borderColor={config.button_colour}
        />
      )}
      <h4 className='room-number'>Room {roomNumber || 1}</h4>
      <div className='room-grid-2-2'>
        <label className='select-label'>
          Select Adults
          <Select
            menuShouldBlockScroll={false}
            isSearchable={false}
            options={adultsOptions}
            value={{ value: room.adults, label: room.adults }}
            onChange={(option: any) => changeNumberAdults(Number(option.value))}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
            data-disable-touch-keyboard
          />
        </label>
        <label className='select-label'>
          Select Children
          <Select
            menuShouldBlockScroll={false}
            isSearchable={false}
            options={childrenOptions}
            value={{ value: room.children, label: room.children }}
            onChange={(option : any) => changeNumberChildren(Number(option.value))}
            styles={customStyles}
            components={{ DropdownIndicator: CustomDropdownIndicator as any }}
            data-disable-touch-keyboard
          />
        </label>
      </div>
      {room.childAges.length > 0 && (
        <div className='room-child-age-title'>Enter age of child on return date</div>
      )}
      <div className='room-grid-2-2'>
        {room.childAges.map((val: number, index) => {

          let v = null;
          let cs = errorStyle;
          if(room.childAges[index] != -1){
            cs = customStyles
            v = {
              value: room.childAges[index] || 'Select Age',
              label: room.childAges[index],
            };
          }

          return (
            <label key={`room${roomNumber}-${index}`} className='select-label'>
              Child {index + 1}
              <Select
                menuShouldBlockScroll={false}
                isSearchable={false}
                options={ageRange}
                value={v}
                placeholder='Select age'
                onChange={(option) => changeAge(Number(option?.value) || 0, index)}
                styles={cs}
                components={{ DropdownIndicator: CustomDropdownIndicator as any }}
                data-disable-touch-keyboard
              />
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default Room;
