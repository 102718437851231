/* eslint-disable */
export function fbqTrackSearch(city, region, country, check_in, check_out, content_ids, num_adults, num_children){

    const obj = {content_type: 'hotel', city: city, region: region, country: country};

    if(check_in != null){
        obj['checkin_date'] = check_in;
    }
    if(check_out != null){
        obj['checkout_date'] = check_out;
    }
    if(content_ids != null){
        obj['content_ids'] = content_ids;
    }
    if(num_adults != null){
        obj['num_adults'] = num_adults;
    }    
    if(num_children != null){
        obj['num_children'] = num_children;
    }

    try{
        fbq('track', 'Search', obj);
    }
    catch(exception){
        console.log(exception);
    }
}

export function fbqTrackView(city, region, country, check_in, check_out, content_id, num_adults, num_children){

    const obj = {content_type: 'hotel', city: city, region: region, country: country};

    if(check_in != null){
        obj['checkin_date'] = check_in;
    }
    if(check_out != null){
        obj['checkout_date'] = check_out;
    }
    if(content_id != null){
        obj['content_ids'] = content_id.toString()
    }
    if(num_adults != null){
        obj['num_adults'] = num_adults;
    }    
    if(num_children != null){
        obj['num_children'] = num_children;
    }

    try{
        fbq('track', 'ViewContent', obj);
    }
    catch(exception){
        console.log(exception);
    }
}

export function fbqIniCheckout(city, region, country, check_in, check_out, content_id, num_adults, num_children){

    const obj = {content_type: 'hotel', city: city, region: region, country: country};

    if(check_in != null){
        obj['checkin_date'] = check_in;
    }
    if(check_out != null){
        obj['checkout_date'] = check_out;
    }
    if(content_id != null){
        obj['content_ids'] = content_id.toString()
    }
    if(num_adults != null){
        obj['num_adults'] = num_adults;
    }    
    if(num_children != null){
        obj['num_children'] = num_children;
    }

    try{
        fbq('track', 'InitiateCheckout', obj);
    }
    catch(exception){
        console.log(exception);
    }
}

export function fbqPurchase(city, region, country, check_in, check_out, content_id, num_adults, num_children, value){

    const obj = {content_type: 'hotel', city: city, region: region, country: country, value: value, currency: 'GBP'};

    if(check_in != null){
        obj['checkin_date'] = check_in;
    }
    if(check_out != null){
        obj['checkout_date'] = check_out;
    }
    if(content_id != null){
        obj['content_ids'] = content_id.toString()
    }
    if(num_adults != null){
        obj['num_adults'] = num_adults;
    }    
    if(num_children != null){
        obj['num_children'] = num_children;
    }

    try{
        fbq('track', 'Purchase', obj);
    }
    catch(exception){
        console.log(exception);
    }
}