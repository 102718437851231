import { createContext, useContext, useReducer } from 'react';
import { Action, Dispatch, ProviderProps, State } from './types';

const Context = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      throw new Error('Unhandled action type in Search Context');
    }
  }
}

function SearchProvider({ children }: ProviderProps) {
  const [state, dispatch] = useReducer(reducer, {
    option: 0,
  });

  const value = { state, dispatch };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function useSearchProvider() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useSearchProvider must be used within a SearchProvider');
  }
  return context;
}

export { SearchProvider, useSearchProvider };
