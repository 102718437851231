import React, { FunctionComponent } from 'react';
import './PageLoader.scss';


interface LoaderProps {
  config?: any
}

const PageLoader: FunctionComponent <LoaderProps> = ({config=null}) => {

  
  const sbc = {borderColor: config.skeleton_colour};
  const sbg = {backgroundColor: config.skeleton_colour, borderColor: config.skeleton_colour};

  const FakeFilterToggle = () => (
    <div style={sbc} className='filter-section gradient-animation'>
      <div style={sbg} className='fake-toggle'></div>
    </div>
  );

  const FakeFilterItem = () => (
    <div style={sbc} className='filter-section gradient-animation'>
      <div style={sbg} className='fake-heading'></div>
      <div style={sbg} className='fake-paragraph fake-paragraph-s'></div>
      <div style={sbg} className='fake-paragraph fake-paragraph-m'></div>
      <div style={sbg}className='fake-paragraph fake-paragraph-l'></div>
      <div style={sbg} className='fake-paragraph fake-paragraph-xl'></div>
    </div>
  );

  const FakeHolidayCard = () => (
    <div style={sbc} className='holiday-card-wrapper gradient-animation'>
      <div style={sbg} className='fake-image'></div>
      <div style={sbc} className='holiday-card-wrapper--details'>
        <div style={sbc} className='name-section-wrapper'>
          <div style={sbg} className='fake-paragraph fake-paragraph-xl'></div>
          <div style={sbg} className='fake-paragraph fake-paragraph-m'></div>
          <div style={sbg} className='fake-paragraph fake-paragraph-l'></div>
        </div>
        <div style={sbc} className='holiday-details-wrapper'>
          <div style={sbg} className='fake-paragraph fake-paragraph-m'></div>
          <div style={sbg} className='fake-paragraph fake-paragraph-xl'></div>
          <div style={sbg} className='fake-paragraph fake-paragraph-l'></div>
        </div>
        <div style={sbc} className='price-section'>
          <div style={sbg} className='fake-heading'></div>
          <div style={sbg} className='fake-heading'></div>
          <div style={sbg} className='fake-heading'></div>
        </div>
      </div>
    </div>
  );

  return (
    <div style={sbc} className='search-bar-wrapper loader'>
      <div style={sbc} className='fake-main-heading-section'>
        <h1 className='page-loader-main-heading'>Finding your holiday...</h1>
        <div style={sbg} className='fake-toggle'></div>
      </div>

      <div className='search-results-container'>
        <div className='filters-wrapper-desktop'>
          <FakeFilterToggle />
          <FakeFilterItem />
          <FakeFilterItem />
          <FakeFilterItem />
          <FakeFilterItem />
          <FakeFilterItem />
          <FakeFilterItem />
        </div>

        <div className='holiday-card-container'>
          <FakeHolidayCard />
          <FakeHolidayCard />
          <FakeHolidayCard />
          <FakeHolidayCard />
          <FakeHolidayCard />
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
