/* eslint-disable */
export function geoRegions() { return [ 
    {
        "id" : 250,
        "name" : "abraham gonzalez international airport"
    },
    {
        "id" : 251,
        "name" : "abreojos"
    },
    {
        "id" : 1748,
        "name" : "abu dhabi"
    },
    {
        "id" : 22,
        "name" : "abu rudeis"
    },
    {
        "id" : 23,
        "name" : "abu simbel"
    },
    {
        "id" : 252,
        "name" : "acapulco"
    },
    {
        "id" : 218,
        "name" : "adiyaman"
    },
    {
        "id" : 155,
        "name" : "aeroport de la seu"
    },
    {
        "id" : 253,
        "name" : "aeropuerto del norte international airport"
    },
    {
        "id" : 219,
        "name" : "afyon"
    },
    {
        "id" : 130,
        "name" : "agadir"
    },
    {
        "id" : 156,
        "name" : "agoncillo"
    },
    {
        "id" : 220,
        "name" : "agri"
    },
    {
        "id" : 37,
        "name" : "agrinion"
    },
    {
        "id" : 254,
        "name" : "aguascalients"
    },
    {
        "id" : 255,
        "name" : "alamos"
    },
    {
        "id" : 24,
        "name" : "al arish"
    },
    {
        "id" : 25,
        "name" : "alexandria"
    },
    {
        "id" : 38,
        "name" : "alexion"
    },
    {
        "id" : 144,
        "name" : "algarve"
    },
    {
        "id" : 157,
        "name" : "algeciras"
    },
    {
        "id" : 1773,
        "name" : "amsterdam"
    },
    {
        "id" : 207,
        "name" : "andalucia"
    },
    {
        "id" : 39,
        "name" : "andravida"
    },
    {
        "id" : 131,
        "name" : "anfa"
    },
    {
        "id" : 256,
        "name" : "angel albino corzo"
    },
    {
        "id" : 221,
        "name" : "ankara esenboga"
    },
    {
        "id" : 222,
        "name" : "ankara etimes"
    },
    {
        "id" : 223,
        "name" : "antalya"
    },
    {
        "id" : 257,
        "name" : "apatzingan"
    },
    {
        "id" : 40,
        "name" : "aristoteles airport"
    },
    {
        "id" : 26,
        "name" : "assiut"
    },
    {
        "id" : 160,
        "name" : "asturias"
    },
    {
        "id" : 41,
        "name" : "astypalaia"
    },
    {
        "id" : 27,
        "name" : "aswan"
    },
    {
        "id" : 42,
        "name" : "athens"
    },
    {
        "id" : 258,
        "name" : "atizapan"
    },
    {
        "id" : 259,
        "name" : "bahia angeles"
    },
    {
        "id" : 161,
        "name" : "barcelona"
    },
    {
        "id" : 224,
        "name" : "batman"
    },
    {
        "id" : 143,
        "name" : "beja"
    },
    {
        "id" : 1774,
        "name" : "berlin"
    },
    {
        "id" : 162,
        "name" : "bilbao"
    },
    {
        "id" : 163,
        "name" : "bobadilla"
    },
    {
        "id" : 226,
        "name" : "bodrum milas"
    },
    {
        "id" : 28,
        "name" : "borg el arab"
    },
    {
        "id" : 1,
        "name" : "bourgas"
    },
    {
        "id" : 359,
        "name" : "bucharest"
    },
    {
        "id" : 353,
        "name" : "budapest"
    },
    {
        "id" : 227,
        "name" : "budrum"
    },
    {
        "id" : 164,
        "name" : "burgos airport"
    },
    {
        "id" : 165,
        "name" : "cadiz"
    },
    {
        "id" : 1770,
        "name" : "cagliari"
    },
    {
        "id" : 29,
        "name" : "cairo"
    },
    {
        "id" : 1762,
        "name" : "campania"
    },
    {
        "id" : 260,
        "name" : "campeche international"
    },
    {
        "id" : 261,
        "name" : "cananea"
    },
    {
        "id" : 262,
        "name" : "cancun"
    },
    {
        "id" : 263,
        "name" : "capitan carlos rovirosa"
    },
    {
        "id" : 228,
        "name" : "cardak"
    },
    {
        "id" : 132,
        "name" : "casablanca"
    },
    {
        "id" : 166,
        "name" : "ceuta heliport"
    },
    {
        "id" : 1726,
        "name" : "chania crete"
    },
    {
        "id" : 264,
        "name" : "chetumal"
    },
    {
        "id" : 265,
        "name" : "chichen itza"
    },
    {
        "id" : 44,
        "name" : "chios"
    },
    {
        "id" : 229,
        "name" : "cigli military arpt"
    },
    {
        "id" : 266,
        "name" : "ciudad constitucion"
    },
    {
        "id" : 267,
        "name" : "ciudad del carmen"
    },
    {
        "id" : 268,
        "name" : "ciudad mante"
    },
    {
        "id" : 269,
        "name" : "ciudad obregon"
    },
    {
        "id" : 167,
        "name" : "ciudad real"
    },
    {
        "id" : 270,
        "name" : "ciudad victoria"
    },
    {
        "id" : 271,
        "name" : "colima"
    },
    {
        "id" : 272,
        "name" : "copalar"
    },
    {
        "id" : 168,
        "name" : "cordoba"
    },
    {
        "id" : 45,
        "name" : "corfu"
    },
    {
        "id" : 169,
        "name" : "costa almeria"
    },
    {
        "id" : 158,
        "name" : "costa blanca"
    },
    {
        "id" : 173,
        "name" : "costa brava"
    },
    {
        "id" : 189,
        "name" : "costa del sol"
    },
    {
        "id" : 198,
        "name" : "costa dorada"
    },
    {
        "id" : 1771,
        "name" : "cote d'azur"
    },
    {
        "id" : 273,
        "name" : "cozumel"
    },
    {
        "id" : 1747,
        "name" : "crete"
    },
    {
        "id" : 43,
        "name" : "crete chania"
    },
    {
        "id" : 50,
        "name" : "crete heraklion"
    },
    {
        "id" : 133,
        "name" : "dakhla airport"
    },
    {
        "id" : 230,
        "name" : "dalaman"
    },
    {
        "id" : 46,
        "name" : "dimokritos"
    },
    {
        "id" : 231,
        "name" : "diyarbakir"
    },
    {
        "id" : 915,
        "name" : "djerba"
    },
    {
        "id" : 170,
        "name" : "donostia"
    },
    {
        "id" : 47,
        "name" : "drama"
    },
    {
        "id" : 1749,
        "name" : "dubai"
    },
    {
        "id" : 1779,
        "name" : "dublin"
    },
    {
        "id" : 10,
        "name" : "dubrovnik riviera"
    },
    {
        "id" : 232,
        "name" : "elazig"
    },
    {
        "id" : 48,
        "name" : "eleftherios venizelos"
    },
    {
        "id" : 921,
        "name" : "enfidha"
    },
    {
        "id" : 1772,
        "name" : "england"
    },
    {
        "id" : 274,
        "name" : "ensenada"
    },
    {
        "id" : 19,
        "name" : "ercan"
    },
    {
        "id" : 233,
        "name" : "erzincan"
    },
    {
        "id" : 134,
        "name" : "essaouira"
    },
    {
        "id" : 275,
        "name" : "federal (valle del fuerte)"
    },
    {
        "id" : 276,
        "name" : "fedl de bachigualato"
    },
    {
        "id" : 135,
        "name" : "fez sais"
    },
    {
        "id" : 1753,
        "name" : "florida-orlando"
    },
    {
        "id" : 277,
        "name" : "francisco j. mugica internacional"
    },
    {
        "id" : 278,
        "name" : "francisco p. v. y r."
    },
    {
        "id" : 279,
        "name" : "francisco sarabia"
    },
    {
        "id" : 171,
        "name" : "fuengirola"
    },
    {
        "id" : 172,
        "name" : "fuerteventura"
    },
    {
        "id" : 1765,
        "name" : "gambia"
    },
    {
        "id" : 234,
        "name" : "gaziantep"
    },
    {
        "id" : 285,
        "name" : "general mariano matamoros airport"
    },
    {
        "id" : 1776,
        "name" : "geneve"
    },
    {
        "id" : 281,
        "name" : "gen fierro villalobos"
    },
    {
        "id" : 280,
        "name" : "gen f javier mina"
    },
    {
        "id" : 282,
        "name" : "gen jose m yanez"
    },
    {
        "id" : 283,
        "name" : "gen lucio blanco"
    },
    {
        "id" : 284,
        "name" : "gen pesqueira garcia"
    },
    {
        "id" : 816,
        "name" : "goa"
    },
    {
        "id" : 146,
        "name" : "graciosa island"
    },
    {
        "id" : 174,
        "name" : "granada"
    },
    {
        "id" : 183,
        "name" : "gran canaria"
    },
    {
        "id" : 286,
        "name" : "guadajara"
    },
    {
        "id" : 287,
        "name" : "guadalupe victoria"
    },
    {
        "id" : 1777,
        "name" : "guernsey"
    },
    {
        "id" : 288,
        "name" : "guerrero negro"
    },
    {
        "id" : 136,
        "name" : "hassan i"
    },
    {
        "id" : 235,
        "name" : "hatay airport"
    },
    {
        "id" : 49,
        "name" : "hellinikon (closed)"
    },
    {
        "id" : 289,
        "name" : "hermanos serdan"
    },
    {
        "id" : 175,
        "name" : "hierro"
    },
    {
        "id" : 1756,
        "name" : "holguin"
    },
    {
        "id" : 147,
        "name" : "horta"
    },
    {
        "id" : 290,
        "name" : "huatulco"
    },
    {
        "id" : 176,
        "name" : "huelva"
    },
    {
        "id" : 177,
        "name" : "huesca-pirineos"
    },
    {
        "id" : 30,
        "name" : "hurghada"
    },
    {
        "id" : 178,
        "name" : "ibiza"
    },
    {
        "id" : 51,
        "name" : "ikaria"
    },
    {
        "id" : 225,
        "name" : "imsik airport"
    },
    {
        "id" : 291,
        "name" : "international"
    },
    {
        "id" : 292,
        "name" : "intl quetzalcoatl"
    },
    {
        "id" : 52,
        "name" : "ioannina"
    },
    {
        "id" : 293,
        "name" : "isla mujeres"
    },
    {
        "id" : 237,
        "name" : "istanbul"
    },
    {
        "id" : 236,
        "name" : "istanbul"
    },
    {
        "id" : 294,
        "name" : "ixtapa"
    },
    {
        "id" : 295,
        "name" : "ixtepec"
    },
    {
        "id" : 239,
        "name" : "izmir"
    },
    {
        "id" : 296,
        "name" : "jalapa"
    },
    {
        "id" : 179,
        "name" : "jerez"
    },
    {
        "id" : 1778,
        "name" : "jersey"
    },
    {
        "id" : 53,
        "name" : "kalamata"
    },
    {
        "id" : 54,
        "name" : "kalymnos island national airport"
    },
    {
        "id" : 55,
        "name" : "karpathos"
    },
    {
        "id" : 56,
        "name" : "kasos island"
    },
    {
        "id" : 57,
        "name" : "kastelorizo"
    },
    {
        "id" : 58,
        "name" : "kavala"
    },
    {
        "id" : 240,
        "name" : "kayseri"
    },
    {
        "id" : 59,
        "name" : "kefalonia"
    },
    {
        "id" : 60,
        "name" : "kithira"
    },
    {
        "id" : 241,
        "name" : "konya"
    },
    {
        "id" : 61,
        "name" : "kos"
    },
    {
        "id" : 357,
        "name" : "krakow"
    },
    {
        "id" : 297,
        "name" : "la calera"
    },
    {
        "id" : 180,
        "name" : "la coruna"
    },
    {
        "id" : 181,
        "name" : "la gomera"
    },
    {
        "id" : 148,
        "name" : "lajes"
    },
    {
        "id" : 159,
        "name" : "lanzarote"
    },
    {
        "id" : 182,
        "name" : "la palma del condado"
    },
    {
        "id" : 62,
        "name" : "larisa"
    },
    {
        "id" : 20,
        "name" : "larnaca"
    },
    {
        "id" : 298,
        "name" : "las bajadas"
    },
    {
        "id" : 299,
        "name" : "lazaro cardenas"
    },
    {
        "id" : 63,
        "name" : "lemnos"
    },
    {
        "id" : 184,
        "name" : "leon"
    },
    {
        "id" : 301,
        "name" : "leon"
    },
    {
        "id" : 300,
        "name" : "leon airport"
    },
    {
        "id" : 64,
        "name" : "leros"
    },
    {
        "id" : 137,
        "name" : "les angades"
    },
    {
        "id" : 65,
        "name" : "lesbos"
    },
    {
        "id" : 302,
        "name" : "licenciado intl"
    },
    {
        "id" : 149,
        "name" : "lisbon"
    },
    {
        "id" : 185,
        "name" : "lleida-alguaire"
    },
    {
        "id" : 303,
        "name" : "loreto airport"
    },
    {
        "id" : 304,
        "name" : "los cabos"
    },
    {
        "id" : 186,
        "name" : "los llanos"
    },
    {
        "id" : 129,
        "name" : "luqa"
    },
    {
        "id" : 31,
        "name" : "luxor"
    },
    {
        "id" : 145,
        "name" : "madeira"
    },
    {
        "id" : 187,
        "name" : "madrid"
    },
    {
        "id" : 194,
        "name" : "majorca"
    },
    {
        "id" : 242,
        "name" : "malatya"
    },
    {
        "id" : 305,
        "name" : "manzanillo"
    },
    {
        "id" : 243,
        "name" : "mardin"
    },
    {
        "id" : 138,
        "name" : "marrakech"
    },
    {
        "id" : 32,
        "name" : "marsa alam intl"
    },
    {
        "id" : 190,
        "name" : "matacan"
    },
    {
        "id" : 306,
        "name" : "matamoros"
    },
    {
        "id" : 307,
        "name" : "mazatlan"
    },
    {
        "id" : 191,
        "name" : "melilla"
    },
    {
        "id" : 188,
        "name" : "menorca"
    },
    {
        "id" : 33,
        "name" : "mersa matruh"
    },
    {
        "id" : 308,
        "name" : "mexicali"
    },
    {
        "id" : 309,
        "name" : "mexico cty"
    },
    {
        "id" : 66,
        "name" : "milos"
    },
    {
        "id" : 310,
        "name" : "minatitlan"
    },
    {
        "id" : 917,
        "name" : "monastir"
    },
    {
        "id" : 311,
        "name" : "monclova"
    },
    {
        "id" : 312,
        "name" : "monterrey"
    },
    {
        "id" : 192,
        "name" : "montilla"
    },
    {
        "id" : 193,
        "name" : "moron"
    },
    {
        "id" : 313,
        "name" : "mulege"
    },
    {
        "id" : 202,
        "name" : "murcia"
    },
    {
        "id" : 67,
        "name" : "mykonos"
    },
    {
        "id" : 139,
        "name" : "nador"
    },
    {
        "id" : 68,
        "name" : "naxos"
    },
    {
        "id" : 360,
        "name" : "nevada"
    },
    {
        "id" : 1775,
        "name" : "new york"
    },
    {
        "id" : 314,
        "name" : "nogales"
    },
    {
        "id" : 150,
        "name" : "nordela"
    },
    {
        "id" : 315,
        "name" : "nueva casas grandes"
    },
    {
        "id" : 151,
        "name" : "oporto"
    },
    {
        "id" : 11,
        "name" : "osijek airport"
    },
    {
        "id" : 140,
        "name" : "ouarzazate"
    },
    {
        "id" : 316,
        "name" : "palenque"
    },
    {
        "id" : 195,
        "name" : "pamplona"
    },
    {
        "id" : 21,
        "name" : "paphos"
    },
    {
        "id" : 352,
        "name" : "paris"
    },
    {
        "id" : 69,
        "name" : "paros"
    },
    {
        "id" : 70,
        "name" : "patras araxos"
    },
    {
        "id" : 71,
        "name" : "philippos airport"
    },
    {
        "id" : 152,
        "name" : "pico island"
    },
    {
        "id" : 317,
        "name" : "piedras negras"
    },
    {
        "id" : 318,
        "name" : "pinotepa nnl"
    },
    {
        "id" : 319,
        "name" : "playa carmen"
    },
    {
        "id" : 2,
        "name" : "plovdiv"
    },
    {
        "id" : 320,
        "name" : "pochutla"
    },
    {
        "id" : 358,
        "name" : "porto"
    },
    {
        "id" : 153,
        "name" : "porto santo"
    },
    {
        "id" : 34,
        "name" : "port said airport"
    },
    {
        "id" : 351,
        "name" : "prague"
    },
    {
        "id" : 72,
        "name" : "preveza"
    },
    {
        "id" : 196,
        "name" : "puerto de santa maria"
    },
    {
        "id" : 321,
        "name" : "puerto escondido"
    },
    {
        "id" : 322,
        "name" : "puerto juarez"
    },
    {
        "id" : 197,
        "name" : "puertollano"
    },
    {
        "id" : 323,
        "name" : "puerto penasco"
    },
    {
        "id" : 1759,
        "name" : "puerto plata"
    },
    {
        "id" : 324,
        "name" : "puerto vallarta"
    },
    {
        "id" : 12,
        "name" : "pula"
    },
    {
        "id" : 1758,
        "name" : "punta cana"
    },
    {
        "id" : 325,
        "name" : "punta chivato"
    },
    {
        "id" : 326,
        "name" : "punta colorada"
    },
    {
        "id" : 327,
        "name" : "queretaro airport"
    },
    {
        "id" : 141,
        "name" : "rabat"
    },
    {
        "id" : 328,
        "name" : "rejon"
    },
    {
        "id" : 354,
        "name" : "reykjavik"
    },
    {
        "id" : 73,
        "name" : "rhodes"
    },
    {
        "id" : 13,
        "name" : "rijeka"
    },
    {
        "id" : 1761,
        "name" : "rimini"
    },
    {
        "id" : 329,
        "name" : "rodriguez intl"
    },
    {
        "id" : 355,
        "name" : "rome"
    },
    {
        "id" : 199,
        "name" : "ronda"
    },
    {
        "id" : 200,
        "name" : "sabadell"
    },
    {
        "id" : 244,
        "name" : "sakirpasa"
    },
    {
        "id" : 330,
        "name" : "salina cruz"
    },
    {
        "id" : 331,
        "name" : "saltillo"
    },
    {
        "id" : 74,
        "name" : "samos"
    },
    {
        "id" : 245,
        "name" : "samsun-carsamba"
    },
    {
        "id" : 332,
        "name" : "san cristobal airport"
    },
    {
        "id" : 333,
        "name" : "san felipe"
    },
    {
        "id" : 201,
        "name" : "san fernando"
    },
    {
        "id" : 334,
        "name" : "san ignacio"
    },
    {
        "id" : 246,
        "name" : "sanliurfa airport"
    },
    {
        "id" : 335,
        "name" : "san luis potosi"
    },
    {
        "id" : 336,
        "name" : "san luis rio colorado"
    },
    {
        "id" : 203,
        "name" : "san pablo"
    },
    {
        "id" : 337,
        "name" : "san quintin"
    },
    {
        "id" : 204,
        "name" : "santa cruz"
    },
    {
        "id" : 338,
        "name" : "santa lucia"
    },
    {
        "id" : 154,
        "name" : "santa maria"
    },
    {
        "id" : 205,
        "name" : "santander"
    },
    {
        "id" : 339,
        "name" : "santa rosalia"
    },
    {
        "id" : 206,
        "name" : "santiago"
    },
    {
        "id" : 75,
        "name" : "santorini"
    },
    {
        "id" : 1760,
        "name" : "sardinia"
    },
    {
        "id" : 35,
        "name" : "sharm el sheikh"
    },
    {
        "id" : 1763,
        "name" : "sicily"
    },
    {
        "id" : 76,
        "name" : "sitia"
    },
    {
        "id" : 247,
        "name" : "sivas"
    },
    {
        "id" : 77,
        "name" : "skiathos"
    },
    {
        "id" : 78,
        "name" : "skiros"
    },
    {
        "id" : 3,
        "name" : "sofia"
    },
    {
        "id" : 79,
        "name" : "spetsai island"
    },
    {
        "id" : 16,
        "name" : "split"
    },
    {
        "id" : 80,
        "name" : "syros island"
    },
    {
        "id" : 36,
        "name" : "taba int"
    },
    {
        "id" : 340,
        "name" : "tajin"
    },
    {
        "id" : 208,
        "name" : "talaveral la real"
    },
    {
        "id" : 341,
        "name" : "tamuin"
    },
    {
        "id" : 142,
        "name" : "tangiers"
    },
    {
        "id" : 342,
        "name" : "tapachula international"
    },
    {
        "id" : 343,
        "name" : "tehuacan"
    },
    {
        "id" : 211,
        "name" : "tenerife"
    },
    {
        "id" : 210,
        "name" : "tenerife"
    },
    {
        "id" : 209,
        "name" : "tenerife north"
    },
    {
        "id" : 344,
        "name" : "tepic"
    },
    {
        "id" : 81,
        "name" : "thessaloniki"
    },
    {
        "id" : 345,
        "name" : "tizimin"
    },
    {
        "id" : 212,
        "name" : "torrejon afb"
    },
    {
        "id" : 213,
        "name" : "torremolinos"
    },
    {
        "id" : 248,
        "name" : "trabzon"
    },
    {
        "id" : 346,
        "name" : "tulum"
    },
    {
        "id" : 347,
        "name" : "uruapan"
    },
    {
        "id" : 214,
        "name" : "valencia"
    },
    {
        "id" : 215,
        "name" : "valladolid"
    },
    {
        "id" : 249,
        "name" : "van"
    },
    {
        "id" : 1757,
        "name" : "varadero"
    },
    {
        "id" : 4,
        "name" : "varna"
    },
    {
        "id" : 356,
        "name" : "venice"
    },
    {
        "id" : 216,
        "name" : "vigo"
    },
    {
        "id" : 348,
        "name" : "villa constitucion"
    },
    {
        "id" : 82,
        "name" : "volos"
    },
    {
        "id" : 349,
        "name" : "xoxocotlan"
    },
    {
        "id" : 17,
        "name" : "zadar"
    },
    {
        "id" : 18,
        "name" : "zagreb"
    },
    {
        "id" : 350,
        "name" : "zamora"
    },
    {
        "id" : 83,
        "name" : "zante"
    },
    {
        "id" : 217,
        "name" : "zaragoza"
    }
]}
export function getRegion(id) {
    for(const x of geoRegions()){
        if(x.id == id){
            return x.name;
        }
    }
    return ''
}