import React, { FunctionComponent, useState } from 'react';
import './SummaryCard.scss';
import { camelCase, startCase } from 'lodash';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

export interface ToggleProps {
  title: string;
  icon: string;
  data: {
    reference?: string;
    bookingDate?: string;
    passengerNames?: string;
    name?: string;
    destination?: string;
    duration?: string;
    checkIn?: string;
    checkOut?: string;
    roomType?: string;
    board?: string;
  };
}

type Keys =
  | 'reference'
  | 'bookingDate'
  | 'passengerNames'
  | 'name'
  | 'destination'
  | 'duration'
  | 'checkIn'
  | 'checkOut'
  | 'roomType'
  | 'board';

export const SummaryCard: FunctionComponent<ToggleProps> = ({ title, icon, data }: ToggleProps) => {


  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  return (
    <div style={{borderColor: configuration.border_colour_2}} className='summary-card-wrapper'>
      <div className='summary-card-wrapper--header'>
        <img src={icon} alt='Section icon' />
        <span className={'card-title'}>{title}</span>
      </div>

      <div className='summary-card-wrapper--body'>
        <div className='summary-card-list'>
          {Object.keys(data).map((key: any, i) => {
            const itemKey: Keys = key;
            return (
              <div key={`${key}-${i}`} className='summary-card-list--item'>
                <span>{startCase(key)}</span>
                <span className='summary-card-value'>{data[itemKey]}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
