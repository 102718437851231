import { useEffect, useMemo, useState } from 'react';
import { BaseLayout } from '../../components/BaseLayout';
import { useLocation } from 'react-router-dom';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function BookingFailedPage() {
  const query = useQuery();
  const [status, setStatus] = useState<'payment-failed' | 'booking-failed' | string>('');

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  useEffect(() => {
    sessionStorage.removeItem('session_cr');
    if (query.get('error')) {
      setStatus(query.get('error') || '');
    }
  }, []);

  return (
    <BaseLayout
      onSearchButtonClick={() => null}
      isBookingConfirmation={true}
      isBookingConfirmed={0}
      userToken={userToken}
      userConfiguration={configuration}
    >
      <div className='booking-confirmed-page booking-confirmed-page--failed'>
        <div className='booking-confirmed-page-wrapper'>
          <div className='booking-confirmed-page-wrapper--header'>
            <p className='booking-title booking-title--normal'>
              We&apos;re sorry. Your booking could not be completed.
            </p>
            <p className='booking-emil booking-emil--failed'>
              {status === 'payment-failed' ? (
                <>
                  We could not process your payment.
                  <br />
                  Please try again or <a href='https://www.haystravel.co.uk/contact'>
                    contact us
                  </a>{' '}
                  for further support.
                </>
              ) : status === 'booking-failed' ? (
                <>
                  Our system encountered a problem.
                  <br />
                  Please try again or <a href='https://www.haystravel.co.uk/contact'>
                    contact us
                  </a>{' '}
                  for further support.
                </>
              ) : (
                <>
                  Please try again or <a href='https://www.haystravel.co.uk/contact'>contact us</a>{' '}
                  for further support.
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
