import React, { FunctionComponent, useState, useCallback } from 'react';
import { noop } from 'lodash';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import CustomMarker from './CustomMarker';
import './MapModal.scss';

export type GoogleMapType = google.maps.Map;
export interface Coordinates {
  lat: number;
  lng: number;
}

export interface Place {
  id: string;
  title: string;
  position: Coordinates;
  price?:{total: number;pricePerPerson: number;}
  images?: { url: string }[];
}

interface MapProps {
  activePlace?: Place;
  center?: Coordinates;
  places?: Place[];
  onMarkerClick?: (place: Place) => void;
  hotelName?: string;
  hotelLocation?: string;
  resort?: any;
  rating?: number;
  tripadvisorRating?: number;
  tripadvisorReviews?: string;
  price?: number;
  shouldShowInfoBox?: boolean;
  isTotalPriceFlag?: boolean;
  hideInfo?: boolean;
}

const containerStyle = {
  width: '100%',
  height: '100%',
  maxHeight: '500px',
  borderRadius: '6px',
};

const Map: FunctionComponent<MapProps> = ({
  activePlace,
  places,
  center,
  onMarkerClick = noop,
  hotelName,
  hotelLocation,
  resort,
  rating,
  tripadvisorRating,
  tripadvisorReviews,
  price,
  shouldShowInfoBox,
  isTotalPriceFlag,
  hideInfo = false,
}: MapProps) => {
  const [map, setMap] = useState<GoogleMapType | undefined>();

  const onMarkerClickHandler = (place: Place) => {
    onMarkerClick(place);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  const onLoad = useCallback((map: GoogleMapType) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(undefined);
  }, []);

  const getMarkerStatus = (place: Place) => {
    if (activePlace) {
      return place.id === activePlace.id;
    }

    return places?.length === 1;
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName='test-map'
      center={center}
      zoom={14}
      onLoad={onLoad}
      clickableIcons={false}
      onUnmount={onUnmount}
      options={{
        fullscreenControl: false,
        streetViewControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
      }}
    >
      {activePlace &&
 
     
            <CustomMarker
              hideInfo={hideInfo}
              isTotalPriceFlag={isTotalPriceFlag}
              shouldShowInfoBox={shouldShowInfoBox}
              map={map}
              isActive={getMarkerStatus(activePlace)}
              key={activePlace.title}
              place={activePlace}
              hotelName={hotelName || ''}
              resortName={''}
              regionName={''}
              destinationName={''}
              rating={rating}
              tripadvisorRating={tripadvisorRating}
              tripadvisorReviews={tripadvisorReviews}
              onClick={(place) => onMarkerClickHandler(place)}
            />
       
      }
    </GoogleMap>
  ) : null;
};

export default Map;
