import { LoadingOverlay } from '../LoadingOverlay/LoadingOverlay';
import { Modal } from '../Modal';
import { PanelLoader } from '../PanelLoader/PanelLoader';

type LoadingModalProps = {
  isModalOpen: boolean;
};

export function LoadingModal({ isModalOpen }: LoadingModalProps) {
  const modalHeader = () => (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>Sort &amp; Filter</h5>
    </div>
  );

  const modalFooter = () => {
    return <div className={'modal-footer-wraper'} />;
  };

  return (
    <Modal
      footer={modalFooter()}
      header={modalHeader()}
      mobileHeading={'Sort & Filter'}
      isOpen={isModalOpen}
    >
      <div className='filters-wrapper-mobile'>
        <PanelLoader title='Please wait...' />
      </div>
    </Modal>
  );
}
