import React, { FunctionComponent } from 'react';
import { noop } from 'lodash';
import { AutocompleteProps } from '../Autocomplete';
import Select, { StylesConfig } from 'react-select';
import '../Autocomplete/Autocomplete.scss';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

const Duration: FunctionComponent<AutocompleteProps> = ({
  options,
  onChange = noop,
}: AutocompleteProps) => {


  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const customStyles: StylesConfig = {
    control: () => ({
      display: 'none',
    }),
    menu: () => ({
      height: 'auto',
      width: '100%',
      zIndex: 1,
      boxSizing: 'border-box',
      border: 'solid 1px ' + configuration.border_colour_2,
      borderRadius: '4px',
      marginTop: 0,
      color: configuration.text_colour,
      backgroundColor: 'white',
      position: 'absolute',
      top: 6,
      '@media (max-width: 768px)': {
        border: 'none',
      },
    }),
  };

  const handleSelectChange = (option: any) => {
    // TODO Fix typing
    onChange(option);
  };

  return (
    <Select
      closeMenuOnSelect={true}
      menuIsOpen={true}
      styles={customStyles}
      options={options}
      placeholder={'Duration'}
      onChange={handleSelectChange}
    />
  );
};

export default Duration;
