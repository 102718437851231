import { useState } from 'react';

import { SearchBar } from '../components/SearchBar';

export function WidgetLander() {

  return (
    <SearchBar
      isBoardType={false}
    />
  );
}
