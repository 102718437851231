/* eslint-disable */
export function geoDestinations() {
    return  [

        {
            "id" : 186,
            "name" : "balearics"
        },
        {
            "id" : 21,
            "name" : "bulgaria"
        },
        {
            "id" : 187,
            "name" : "canaries"
        },
        {
            "id" : 206,
            "name" : "channel islands"
        },
        {
            "id" : 84,
            "name" : "croatia"
        },
        {
            "id" : 191,
            "name" : "cuba"
        },
        {
            "id" : 48,
            "name" : "cyprus"
        },
        {
            "id" : 201,
            "name" : "czech republic"
        },
        {
            "id" : 192,
            "name" : "dominican republic"
        },
        {
            "id" : 58,
            "name" : "egypt"
        },
        {
            "id" : 190,
            "name" : "florida"
        },
        {
            "id" : 196,
            "name" : "france"
        },
        {
            "id" : 195,
            "name" : "gambia"
        },
        {
            "id" : 199,
            "name" : "germany"
        },
        {
            "id" : 78,
            "name" : "greece mainland"
        },
        {
            "id" : 188,
            "name" : "greek islands"
        },
        {
            "id" : 202,
            "name" : "hungary"
        },
        {
            "id" : 203,
            "name" : "iceland"
        },
        {
            "id" : 90,
            "name" : "india"
        },
        {
            "id" : 193,
            "name" : "italy"
        },
        {
            "id" : 126,
            "name" : "malta"
        },
        {
            "id" : 130,
            "name" : "mexico"
        },
        {
            "id" : 114,
            "name" : "morocco"
        },
        {
            "id" : 198,
            "name" : "netherlands"
        },
        {
            "id" : 204,
            "name" : "poland"
        },
        {
            "id" : 152,
            "name" : "portugal"
        },
        {
            "id" : 207,
            "name" : "republic of ireland"
        },
        {
            "id" : 205,
            "name" : "romania"
        },
        {
            "id" : 60,
            "name" : "spain"
        },
        {
            "id" : 172,
            "name" : "tunisia"
        },
        {
            "id" : 174,
            "name" : "turkey"
        },
        {
            "id" : 189,
            "name" : "united arab emirates"
        },
        {
            "id" : 197,
            "name" : "united kingdom"
        },
        {
            "id" : 200,
            "name" : "usa"
        }
    ]}


export function getDestination(id) {
    for(const x of geoDestinations()){
        if(x.id == id){
            return x.name;
        }
    }
    return ''
}