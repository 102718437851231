import React, { FunctionComponent, useState } from 'react';
import './SortBy.css';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

interface SortByProps {
  title: string;
  variants: { label: string; value: string | number }[];
  defaultValue?: number;
  size?: 'small' | 'medium' | 'large';
  callback?: (value: number) => void;
}

export const SortBy: FunctionComponent<SortByProps> = ({
  title,
  variants,
  defaultValue = 0,
  size,
  callback,
}: SortByProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  const [sortByValue, setSortByValue] = useState(defaultValue.toString());

  const selectSortBy = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortByValue(event.target.value);

    if (callback) {
      // const index = variants.findIndex((item) => item === event.target.value);
      callback(parseInt(event.target.value));
    }
  };

  // const onCheckboxChange = (index: number) => {
  //   setSortByValue();

  //   if (callback) {
  //     const sortIndex = variants.findIndex((item) => item === variants[index]);
  //     callback(sortIndex);
  //   }
  // };

  return (
    <div className={`sort-by-wrapper sort-by--${size}`}>
      <div  className='sort-by-component'>
        <span style={{color: configuration.text_colour}} className={`sort-by-title sort-by-title--${size}`}>{title}</span>
        <select
          style={{borderColor: configuration.border_colour_2, color: configuration.text_colour}}
          className={`sort-by-select sort-by-select--${size}`}
          onChange={(event) => selectSortBy(event)}
          value={sortByValue}
          name='sortByOptions'
          id='sortByOptions'
        >
          {variants.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>
      {/* <div className='sort-by-mobile'>
        <p className={`sort-by-title sort-by-title--${size}`}>{title}</p>
        {variants.map((item, index) => {
          return (
            <div className={`sort-by-checkbox sort-by-checkbox--${size}`} key={index}>
              <input
                onChange={() => onCheckboxChange(index)}
                type='checkbox'
                id={item}
                name={item}
                checked={item === sortByValue}
              />
              <label htmlFor={item}>{item}</label>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};
