import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './FiltersSidebar.scss';
import './FilterSection.scss';
import { Toggle } from '../Toggle/Toggle';
import { FilterSection } from '../FilterSection/FilterSection';
import { Modal } from '../Modal';
import { Button } from '../Button/Button';
import { camelCase, filter, uniqueId } from 'lodash';
import { SortSection } from '../FilterSection/SortSection';
import star from '../assets/star.svg';
import starDefault from '../assets/icons/default/03/Star.svg'

import trP from '../assets/tr-.5.svg';
import tr1 from '../assets/tr-1.svg';
import tr1P from '../assets/tr-1.5.svg';
import tr2 from '../assets/tr-2.svg';
import tr2P from '../assets/tr-2.5.svg';
import tr3 from '../assets/tr-3.svg';
import tr3P from '../assets/tr-3.5.svg';
import tr4 from '../assets/tr-4.svg';
import tr4P from '../assets/tr-4.5.svg';
import tr5 from '../assets/tr-5.svg';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';



interface FiltersSidebarProps {
  isModalOpen: boolean;
  isTotalPriceFlag: boolean;
  onBoardChange: (board: number) => void;
  onRatingChange: (rating: number) => void;
  onTripRatingChange: (tripRating: number) => void;
  onDestChange: (tripRating: number) => void;
  onRegionChange: (tripRating: number) => void;
  onResortChange: (tripRating: number) => void;
  onTotalPriceChange: (val: any) => void;
  toggleModal?: () => void;
  onSortChange: (val: number) => void;
  sortOptions: { label: string; value: string | number }[];
  activeOption: number;
  boards: any;
  ratings: any;
  tripRatings: any;
  selectedBoards: any;
  selectedRatings: any;
  selectedTripRatings: any;
  destinations? : any;
  regions? :any;
  resorts?: any;
  pakResorts? : any;
  selectedDestinations: number[];
  selectedRegions: number[];
  selectedResorts: number[];
}

export const FiltersSidebar: FunctionComponent<FiltersSidebarProps> = ({
  isModalOpen = false,
  onBoardChange,
  onRatingChange,
  onTripRatingChange,
  onDestChange,
  onRegionChange,
  onResortChange,
  isTotalPriceFlag,
  onTotalPriceChange,
  sortOptions,
  onSortChange,
  toggleModal,
  activeOption,
  boards,
  ratings,
  tripRatings,
  destinations,
  regions,
  resorts,
  pakResorts,
  selectedDestinations,
  selectedRegions,
  selectedResorts,
  selectedBoards,
  selectedRatings,
  selectedTripRatings,
}: FiltersSidebarProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  const [toggleValue, setToggleValue] = useState<boolean>(false);

  const starsItems = [
    {
      title: '',
      numberItems: '',
      isChecked: false,
    },
    {
      title: '',
      numberItems: '',
      isChecked: false,
    },
    {
      title: '',
      numberItems: '',
      isChecked: false,
    },
    {
      title: '',
      numberItems: '',
      isChecked: false,
    },
    {
      title: '',
      numberItems: '',
      isChecked: false,
    },
  ];

  const modalHeader = () => (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>Sort &amp; Filter</h5>
    </div>
  );

  const modalFooter = () => {
    return (
      <div className={'modal-footer-wraper'}>
          <Button label={'Close'} primary backgroundColor={configuration.button_colour} borderColor={configuration.button_colour} onClick={toggleModal} />
      </div>
    );
  };

  const getChangedFilters = (filters: any) => {
    const tempObj: any = {};
    Object.keys(filters).map((key: any) => {
      tempObj[key] = filters[key].filter((i: any) => i.isChecked);
      return tempObj[key];
    });
    return tempObj;
  };



  // const filterChanged = (type: any, item: any) => {
  //   const filterName = camelCase(type);

  //   state[filterName] = state[filterName].map((tempItem: any) => {
  //     const isSelectedItem = tempItem.title === item.title;
  //     if (isSelectedItem) {
  //       tempItem.isChecked = !tempItem.isChecked;
  //     }
  //     return tempItem;
  //   });

  //   setState(state);

  //   onFiltersChange(getChangedFilters(state));
  // };
  const xDests = [];
  if (destinations != null) {
    for (let i = 0; i < destinations.length; i++) {
      const x = destinations[i];

      let disabled = false;
      if (x.count == 0) {
        disabled = true;
      }

      let chk = false;
      for(let l=0; l < selectedDestinations.length; l++){
        if(selectedDestinations[l] == x.result){
          chk = true;
          break;
        }
      }
      
      let xName = x.title.split('-')[1];
      if(xName.toLowerCase() == 'usa'){
        xName = xName.toUpperCase();
      }

      const xDest = (
        <Fragment key={uniqueId()}>
          <div className={'filter-item'}>
            <input
              disabled={disabled}
              className={'filter-item--checkbox'}
              type='checkbox'
              onChange={(e) => onDestChange(x.result)}
              value={x.result}
              checked={chk}
            />
            <span style={{textTransform:'capitalize'}} className={'filter-item--title'}>{xName}</span>
            <span className={'filter-item--numberItems'}>{x.count}</span>
          </div>
        </Fragment>
      );
      xDests.push(xDest);
    }
  }
  const xRegions = [];
  if (regions != null) {


    const sortedG = [];

    for (let i = 0; i < regions.length; i++) {
      const x = regions[i];
      const dName = x.title.split('-')[3];
      if(sortedG.indexOf(dName) == -1){
        sortedG.push(dName);
      }
    }

    for(const sg of sortedG){
      
      xRegions.push(<div style={{textTransform:'capitalize',display: 'block'}} className={'filter-item filter-item-subtitle'}>{sg}</div>)

      for (let i = 0; i < regions.length; i++) {
        const x = regions[i];

        const dName = x.title.split('-')[3];

        if(dName != sg){
          continue
        }

        let disabled = false;
        if (x.count == 0) {
          disabled = true;
        }
  
        let chk = false;
        for(let l=0; l < selectedRegions.length; l++){
          if(selectedRegions[l] == x.result){
            chk = true;
            break;
          }
        }
  
        const xName = x.title.split('-')[1];
  
        const xG = (
          <Fragment key={uniqueId()}>
            <div className={'filter-item'}>
              <input
                disabled={disabled}
                className={'filter-item--checkbox'}
                type='checkbox'
                onChange={(e) => onRegionChange(x.result)}
                value={x.result}
                checked={chk}
              />
              <span style={{textTransform:'capitalize'}} className={'filter-item--title'}>{xName}</span>
              <span className={'filter-item--numberItems'}>{x.count}</span>
            </div>
          </Fragment>
        );
        xRegions.push(xG);
      }
    }


  }


  const xResorts = [];
  if (resorts != null) {



    const sortedS = [];

    for (let i = 0; i < resorts.length; i++) {
      const x = resorts[i];
      const gName = x.title.split('-')[3];
      if(sortedS.indexOf(gName) == -1){
        sortedS.push(gName);
      }
    }

    for(const ss of sortedS){

      xResorts.push(<div style={{textTransform:'capitalize',display: 'block'}} className={'filter-item filter-item-subtitle'}>{ss}</div>)

      for (let i = 0; i < resorts.length; i++) {

        const x = resorts[i];

        const gName = x.title.split('-')[3];

        if(gName != ss){
          continue
        }

 
  
        let disabled = false;
        if (x.count == 0) {
          disabled = true;
        }
  
        let chk = false;
        for(let l=0; l < selectedResorts.length; l++){
          if(selectedResorts[l] == x.result){
            chk = true;
            break;
          }
        }
  
        const xName = x.title.split('-')[1];
  
        const xResort = (
          <Fragment key={uniqueId()}>
            <div className={'filter-item'}>
              <input
                disabled={disabled}
                className={'filter-item--checkbox'}
                type='checkbox'
                onChange={(e) => onResortChange(x.result)}
                value={x.result}
                checked={chk}
              />
              <span style={{textTransform:'capitalize'}} className={'filter-item--title'}>{xName}</span>
              <span className={'filter-item--numberItems'}>{x.count}</span>
            </div>
          </Fragment>
        );
        xResorts.push(xResort);
      }

    }


  }

  const xPakResorts = [];
  if (pakResorts != null) {
    for (let i = 0; i < pakResorts.length; i++) {
      const x = pakResorts[i];

      let disabled = false;
      if (x.count == 0) {
        disabled = true;
      }

      let chk = false;
      if (selectedResorts.indexOf(x.result) != -1) {
        chk = true;
      }

      const xPakResort = (
        <Fragment key={uniqueId()}>
          <div className={'filter-item'}>
            <input
              disabled={disabled}
              className={'filter-item--checkbox'}
              type='checkbox'
              onChange={(e) => onResortChange(x.result)}
              value={x.result}
              checked={chk}
            />
            <span className={'filter-item--title'}>{x.title}</span>
            <span className={'filter-item--numberItems'}>{x.count}</span>
          </div>
        </Fragment>
      );
      xPakResorts.push(xPakResort);
    }
  }

  const xBoards = [];
  if (boards != null) {
    for (const x of boards) {
      let disabled = false;
      if (x.count == 0) {
        disabled = true;
      }

      let title = '';
      switch (x.result) {
        case 0:
          title = 'Room Only';
          break;
        case 1:
          title = 'Self Catering';
          break;
        case 2:
          title = 'Bed & Breakfast';
          break;
        case 3:
          title = 'Half Board';
          break;
        case 4:
          title = 'Full Board';
          break;
        case 5:
          title = 'All Inclusive';
      }

      let chk = false;
      if (selectedBoards.indexOf(x.result) != -1) {
        chk = true;
      }

      const xBoard = (
        <Fragment key={uniqueId()}>
          <div className={'filter-item'}>
            <input
              disabled={disabled}
              className={'filter-item--checkbox'}
              type='checkbox'
              // onChange={(e) => onFiltersChange(x.result, 'bb', e.target.checked)}
              onChange={(e) => onBoardChange(x.result)}
              value={x.result}
              checked={chk}
            />
            <span className={'filter-item--title'}>{title}</span>
            <span className={'filter-item--numberItems'}>{x.count}</span>
          </div>
        </Fragment>
      );
      xBoards.push(xBoard);
    }
  }

  let starIco = starDefault;
  if(configuration.icon_set == 'blue'){
    starIco = star;
  }

  const xRatings = [];
  if (ratings != null) {
    for (let i = ratings.length - 1; i >= 0; i--) {
      const x = ratings[i];

      let disabled = false;
      if (x.count == 0) {
        disabled = true;
      }

      let chk = false;
      if (selectedRatings.indexOf(x.result) != -1) {
        chk = true;
      }

      const xRating = (
        <Fragment key={uniqueId()}>
          <div className={'filter-item'}>
            <input
              disabled={disabled}
              className={'filter-item--checkbox'}
              type='checkbox'
              onChange={(e) => onRatingChange(x.result)}
              value={x.result}
              checked={chk}
            />
            <div className='stars'>
              {Array(x.result)
                .fill(null)
                .map((item, starsKey) => {
                  return <img className={'star'} key={uniqueId()} src={starIco} alt='' />;
                })}
            </div>
            <span className={'filter-item--numberItems'}>{x.count}</span>
          </div>
        </Fragment>
      );
      xRatings.push(xRating);
    }
  }

  const xTripRatings = [];
  if (tripRatings != null) {
    for (let i = tripRatings.length - 1; i >= 0; i--) {
      const x = tripRatings[i];

      let disabled = false;
      if (x.count == 0) {
        disabled = true;
      }

      let taImg = null;
      if (x.result == '0.5') {
        taImg = <img key={uniqueId()} className={'fullrate'} src={trP} alt='circle' />;
      } else if (x.result == '1') {
        taImg = <img key={uniqueId()} className={'fullrate'} src={tr1} alt='circle' />;
      } else if (x.result == '1.5') {
        taImg = <img key={uniqueId()} className={'fullrate'} src={tr1P} alt='circle' />;
      } else if (x.result == '2') {
        taImg = <img key={uniqueId()} className={'fullrate'} src={tr2} alt='circle' />;
      } else if (x.result == '2.5') {
        taImg = <img key={uniqueId()} className={'fullrate'} src={tr2P} alt='circle' />;
      } else if (x.result == '3') {
        taImg = <img key={uniqueId()} className={'fullrate'} src={tr3} alt='circle' />;
      } else if (x.result == '3.5') {
        taImg = <img key={uniqueId()} className={'fullrate'} src={tr3P} alt='circle' />;
      } else if (x.result == '4') {
        taImg = <img key={uniqueId()} className={'fullrate'} src={tr4} alt='circle' />;
      } else if (x.result == '4.5') {
        taImg = <img key={uniqueId()} className={'fullrate'} src={tr4P} alt='circle' />;
      } else if (x.result == '5') {
        taImg = <img key={uniqueId()} className={'fullrate'} src={tr5} alt='circle' />;
      }

      let chk = false;
      if (selectedTripRatings.indexOf(x.result) != -1) {
        chk = true;
      }

      const xTripRating = (
        <Fragment key={uniqueId()}>
          <div className={'filter-item'}>
            <input
              disabled={disabled}
              className={'filter-item--checkbox'}
              type='checkbox'
              onChange={(e) => onTripRatingChange(x.result)}
              value={x.result}
              checked={chk}
            />
            <div>{taImg}</div>
            <span className={'filter-item--numberItems'}>{x.count}</span>
          </div>
        </Fragment>
      );
      xTripRatings.push(xTripRating);
    }
  }

  let desCon = null;
  if(xDests.length > 0){
    desCon = <div className='filter-section'>
    <div className='filter-wrapper'>
      <div className='filter-wrapper--title'>Destinations</div>
      <div className='filter-wrapper--filters-wrapper'>{xDests}</div>
    </div>
  </div>
  }

  let regionCon = null;
  if(xRegions.length > 0){
    regionCon = <div className='filter-section'>
    <div className='filter-wrapper'>
      <div className='filter-wrapper--title'>Regions</div>
      <div className='filter-wrapper--filters-wrapper'>{xRegions}</div>
    </div>
  </div>
  }

  let resortCon = null;
  if(xResorts.length > 0){
    resortCon = <div className='filter-section'>
    <div className='filter-wrapper'>
      <div className='filter-wrapper--title'>Resorts</div>
      <div className='filter-wrapper--filters-wrapper'>{xResorts}</div>
    </div>
  </div>
  }

  let pakResortsCon = null;
  if(xPakResorts.length > 0){
    pakResortsCon = <div className='filter-section'>
    <div className='filter-wrapper'>
      <div className='filter-wrapper--title'>Resorts</div>
      <div className='filter-wrapper--filters-wrapper'>{xPakResorts}</div>
    </div>
  </div>
  }


  
  return (
    <>
      <div style={{color: configuration.text_colour}} className='filters-wrapper-desktop'>
        <div className='filter-section'>
          <Toggle
            onToggleChange={(v) => onTotalPriceChange(v)}
            value={isTotalPriceFlag}
            size={'large'}
            firstLabel={'Price Per Person'}
            secondLabel={'Total Price'}
            backgroundColor={configuration.button_colour_secondary}
            color={configuration.button_text_colour}
          />
        </div>

        {desCon}
        {regionCon}
        {resortCon}
        {pakResortsCon}

        <div className='filter-section'>
          <div className='filter-wrapper'>
            <div className='filter-wrapper--title'>Board Type</div>
            <div className='filter-wrapper--filters-wrapper'>{xBoards}</div>
          </div>
        </div>

        <div className='filter-section'>
          <div className='filter-wrapper'>
            <div className='filter-wrapper--title'>Star Rating</div>
            <div className='filter-wrapper--filters-wrapper'>{xRatings}</div>
          </div>
        </div>

        <div className='filter-section'>
          <div className='filter-wrapper'>
            <div className='filter-wrapper--title'>Tripadvisor</div>
            <div className='filter-wrapper--filters-wrapper'>{xTripRatings}</div>
          </div>
        </div>

        {/* <div className='filter-section'>
          <FilterSection
            title={'Board Type'}
            type={'string'}
            items={boards}
            onChange={(type, item) => filterChanged(type, item)}
          />
        </div>

        <div className='filter-section'>
          <FilterSection
            title={'Star Rating'}
            type={'stars'}
            items={ratings}
            onChange={(type, item) => filterChanged(type, item)}
          />
        </div>

        <div className='filter-section'>
          <FilterSection
            title={'Tripadvisor'}
            type={'circle'}
            items={tripRatings}
            onChange={(type, item) => filterChanged(type, item)}
          />
        </div> */}
      </div>

      <Modal
        additionalBodyClassName='mobile-filters'
        footer={modalFooter()}
        header={modalHeader()}
        mobileHeading={'Sort & Filter'}
        isOpen={isModalOpen}
        toggle={toggleModal}
      >
        <div className='filters-wrapper-mobile'>
          <div className='filter-section'>
            <Toggle
              onToggleChange={(v) => {
                setToggleValue(v);
                onTotalPriceChange(v);
              }}
              value={toggleValue}
              size={'small'}
              firstLabel={'Price Per Person'}
              secondLabel={'Total Price'}
              backgroundColor={configuration.button_colour_secondary}
              color={configuration.button_text_colour}
            />
          </div>

          <div className='filter-section'>
            <SortSection
              activeOption={activeOption}
              onSortChange={onSortChange}
              sortOptions={sortOptions}
            />
          </div>

          {desCon}
        {regionCon}
        {resortCon}
        {pakResortsCon}
          
          <div className='filter-section'>
            <div className='filter-wrapper'>
              <div className='filter-wrapper--title'>Board Type</div>
              <div className='filter-wrapper--filters-wrapper'>{xBoards}</div>
            </div>
          </div>

          <div className='filter-section'>
            <div className='filter-wrapper'>
              <div className='filter-wrapper--title'>Star Rating</div>
              <div className='filter-wrapper--filters-wrapper'>{xRatings}</div>
            </div>
          </div>

          <div className='filter-section'>
            <div className='filter-wrapper'>
              <div className='filter-wrapper--title'>Tripadvisor</div>
              <div className='filter-wrapper--filters-wrapper'>{xTripRatings}</div>
            </div>
          </div>

        </div>
      </Modal>
    </>
  );
};
