import { FC, useState, useEffect } from 'react';
// import { ISelectPayment, IDeposit, IFullPayment, IMonthlyPayments } from './Interfaces';
import './SelectPayment.scss';

import CreditCardIcon from '../assets/creditcard.svg';
import CreditCardIconDefault from '../assets/icons/default/08/ops.svg';

import { Button } from '../Button';
import dayjs from 'dayjs';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { Basket } from '../../hooks/types'

interface MonthlyPaymentProps {
  iniPayment: number;
  instalments: {
    price: number;
    date: Date;
  }[];
  selected: boolean;
  onClick: () => void;
  config: any;
}

const MonthlyPayments: FC<MonthlyPaymentProps> = ({ iniPayment, instalments, selected, onClick, config }) => {

  let s = {borderColor: config.jrny.border_colour};
  if(selected){
    s = {borderColor: config.button_colour}
  }

  return (
    <div
      style={s}
      className={`select-payment-container-child ${
        selected ? 'select-payment-container-child-active' : ''
      }`}
    >
      <h4 className='select-payment-title'>
        <span className='select-payment-title-text'>Pay monthly by Direct Debit</span>
      </h4>
      <div className='select-payment-grid-50-50'>
        <div>
          <div className='select-payment-breakdown select-payment-breakdown-active'>
            <div style={{backgroundColor: config.jrny.payment_option_colour}} className='select-payment-circle select-payment-circle-active'>
              {instalments.length !== 0 && instalments.length !== 1 && (
                <div  style={{backgroundColor: config.jrny.payment_option_colour}} className='select-payment-circle-line select-payment-circle-line-active' />
              )}
            </div>
            <div className='select-payment-breakdown-title'>
              <b>Pay today</b>
            </div>

            <div className='select-payment-breakdown-price'>£{iniPayment.toFixed(2)}</div>
          </div>

          {instalments.map((instalment, index) => {
            return (
              <div className='select-payment-breakdown' key={'instalment' + index}>
                <div style={{backgroundColor: config.jrny.payment_option_future_colour}} className='select-payment-circle'>
                  {instalments.length !== index + 1 && (
                    <div className='select-payment-circle-line select-payment-circle-line' />
                  )}
                </div>
                <div className='select-payment-breakdown-title select-payment-breakdown-title-with-date'>
                  <b>
                    {index + 1 === instalments.length ? 'Final Payment' : `Instalment ${index + 1}`}
                  </b>
                  <div className='select-payment-breakdown-date'>
                    {dayjs(instalment.date).format('DD/MM/YY')}
                  </div>
                </div>
                <div className='select-payment-breakdown-price'>
                  {' '}
                  £{instalment.price.toFixed(2)}{' '}
                </div>
              </div>
            );
          })}
        </div>
        <div className='select-payment-button-container'>
          <Button
            isSelected={selected}
            onClick={onClick}
            overrideClassNames={`select-payment-button ${
              selected ? 'select-payment-button-active' : ''
            }`}
            label={`${selected ? 'Selected' : 'Select'}`}
            color={config.button_text_secondary_colour}
          />
        </div>
      </div>
    </div>
  );
};

interface DepositPaymentProps {
  difference: number;
  deposit: number;
  onClick?: () => void;
  selected: boolean;
  date?: string;
  config: any;
}

const DepositPayment: FC<DepositPaymentProps> = ({ deposit, difference, selected, onClick, date, config }) => {

  let s = {borderColor: config.jrny.border_colour};
  if(selected){
    s = {borderColor: config.button_colour}
  }

  return (
    <div
      style={s}
      className={`select-payment-container-child ${
        selected ? 'select-payment-container-child-active' : ''
      }`}
    >
      <h4 className='select-payment-title'>
        <span className='select-payment-title-text'>Low Deposit</span>
      </h4>
      <div className='select-payment-grid-50-50'>
        <div>
          <div className='select-payment-breakdown'>
            <div style={{backgroundColor: config.jrny.payment_option_colour}} className='select-payment-circle select-payment-circle-active'>
              <div style={{backgroundColor: config.jrny.payment_option_colour}} className='select-payment-circle-line select-payment-circle-line-active' />
            </div>

            <div className='select-payment-breakdown-title'>
              <b>Pay today</b>
            </div>

            <div className='select-payment-breakdown-price'>£{deposit.toFixed(2)}</div>
          </div>
          <div className='select-payment-breakdown'>
            <div style={{backgroundColor: config.jrny.payment_option_future_colour}} className='select-payment-circle' />
            <div className='select-payment-breakdown-title'>
              <b>Final payment</b>
              {date && (
                <div className='select-payment-breakdown-date'>
                  {dayjs(date).format('DD/MM/YY')}
                </div>
              )}
            </div>

            <div className='select-payment-breakdown-price'> £{difference.toFixed(2)} </div>
          </div>
        </div>
        <div>
          <div className='select-payment-button-container'>
            <Button
              isSelected={selected}
              onClick={onClick}
              overrideClassNames={`select-payment-button ${
                selected ? 'select-payment-button-active' : ''
              }`}
              label={`${selected ? 'Selected' : 'Select'}`}
              color={config.button_text_secondary_colour}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface FullPaymentProps {
  total: number;
  onClick?: () => void;
  selected: boolean;
  config: any;
}

const FullPayment: FC<FullPaymentProps> = ({ total, selected, onClick, config }) => {

  let s = {borderColor: config.jrny.border_colour};
  if(selected){
    s = {borderColor: config.button_colour}
  }

  return (
    <div id='selectPaymentFormFullOp'    style={s}
      className={`select-payment-container-child ${
        selected ? 'select-payment-container-child-active' : ''
      }`}
    >
      <h4 className='select-payment-title'>
        <span className='select-payment-title-text'>Pay in full</span>
      </h4>
      <div className=' select-payment-grid-50-50'>
        <div className='select-payment-breakdown'>
          <div style={{backgroundColor: config.jrny.payment_option_colour}} className='select-payment-circle select-payment-circle-active'></div>
          <div className='select-payment-breakdown-title'>
            <b>Full payment</b>
          </div>

          <div className='select-payment-breakdown-price'>£{total.toFixed(2)}</div>
        </div>
        <div className='select-payment-button-container'>
          <Button
            isSelected={selected}
            onClick={onClick}
            overrideClassNames={`select-payment-button ${
              selected ? 'select-payment-button-active' : ''
            }`}
            label={`${selected ? 'Selected' : 'Select'}`}
            color={config.button_text_secondary_colour}
          />
        </div>
      </div>
    </div>
  );
};

interface SelectPaymentProps {
  payment: {
    instalments: boolean;
    payInFull: boolean;
    deposit: boolean;
  };
  basket: Basket;
  instalments: {
    price: number;
    date: Date;
  }[];
  onPriceChange?: (price: number, paymentMethod: number) => void;
  defPayMethod?: number;
}

  const SelectPayment: FC<SelectPaymentProps> = ({ payment, basket, instalments, onPriceChange, defPayMethod=3}) => {

  const [selectedPayment, setSelectedPayment] = useState<number>(defPayMethod);

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  useEffect(() => {
    setSelectedPayment(defPayMethod)
  }, [defPayMethod])

  const deposit = basket.deposit_price || -1;

  const setSelected = (paymentMethod: number, price?: number) => {
    setSelectedPayment(paymentMethod);
    if (onPriceChange && price) onPriceChange(price, paymentMethod);
  };

  let cardIco = CreditCardIconDefault;
  if(configuration.icon_set == 'blue'){
    cardIco = CreditCardIcon;
  }


  return (
    <div>
      <div id='selectPaymentForm' style={{borderColor: configuration.jrny.border_colour}} className='select-payment-container'>
        <h4 className='select-payment-title'>
          <img className='select-payment-icon' src={cardIco} height='20' width='20' />
          <span className='select-payment-title-text'>Payment Options</span>
        </h4>

        {payment.instalments && (
          <MonthlyPayments
            iniPayment={deposit}
            selected={selectedPayment === 1}
            onClick={() => {
              setSelected(1, instalments?.[0].price);
            }}
            instalments={instalments || []}
            config={configuration}
          />
        )}

        {deposit != -1 && (
          <DepositPayment
            deposit={deposit}
            onClick={() => {
              setSelected(2, deposit);
            }}
            selected={selectedPayment === 2}
            difference={basket.price_deposit_diff || 0}
            date={basket.deposit_due}
            config={configuration}
          />
        )}

        <FullPayment
          onClick={() => {
            setSelected(3, basket.price);
          }}
          selected={selectedPayment === 3}
          total={basket.price || 0}
          config={configuration}
        />
      </div>
      <div className='select-payment-whitespace' />
    </div>
  );
};

export { SelectPayment };
