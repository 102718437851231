import React, { FunctionComponent } from 'react';
import cn from 'classnames';
import './Board.scss';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

export interface BoardProps {
  type: string;
  priceInfo?: string;
  selected?: boolean;
  onClick?: () => void;
}

export const Board: FunctionComponent<BoardProps> = ({
  type,
  priceInfo,
  selected = false,
  onClick,
}: BoardProps) => {

  const {
    RootStore: {
      configuration
    },
  } = useStores() as { RootStore: RootStore };
  
  const bgStyle = {backgroundColor: '', borderColor: ''};
  const tColor = {backgroundColor: '', color: ''}
  if(selected){
    bgStyle.backgroundColor = configuration.jrny.selected_button_colour;
    bgStyle.borderColor =  configuration.jrny.selected_button_border_colour;
    tColor.color = configuration.jrny.selected_button_text_colour;
    tColor.backgroundColor = configuration.button_colour_secondary;
  }

  return (
    <div style={bgStyle} className={cn('board', { selected: selected })} onClick={onClick}>
      <div className='board-type'>{type}</div>
      <div className='board-info'>
        {selected ? (
          <span style={tColor} className='price-bold yellow'>Selected</span>
        ) : (
          <div>
            {priceInfo && (
              <>
                <span className='price-bold'>{priceInfo}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
