import React, { FunctionComponent, useState, useEffect } from 'react';
import { noop } from 'lodash';
import { Modal } from '../Modal';
import Map, { Place, Coordinates } from './Map';
import './MapModal.scss';
import { HolidayCard } from '../HolidayCard';
import { Toggle } from '../Toggle/Toggle';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import { ImageSlider } from '../ImageSlider/ImageSlider';
import { Offer } from '../../hooks/types'


interface MapModalProps {
  offer: Offer
  shouldShowInfoBox?: boolean;
  isOpen: boolean;
  searchParams: URLSearchParams;
  isTotalPriceFlag?: boolean;
  callback?: any;
  toggle?: () => void;
  setNewOffer?: (name: string) => void;
  onTotalPriceChange?: (v: boolean) => void;
  offerPrices?: {
    offerPrice: number;
    offerPricePerPerson: number;
  };
}

const MapModal: FunctionComponent<MapModalProps> = ({
  offer,
  shouldShowInfoBox = false,
  searchParams,
  isOpen,
  isTotalPriceFlag = true,
  toggle = noop,
  onTotalPriceChange = noop,
  callback,
}: MapModalProps) => {

  // const getOfferById = (id: string) => {
  //   return offers?.find((offer: any) => {
  //     return offer.id === id;
  //   });
  // };

  // const getPlaceById = (id: string) => {
  //   const place = offers?.find((offer: any) => {
  //     return offer.id === id;
  //   });

  //   return {
  //     id: place?.id,
  //     price: {
  //       total: place?.price,
  //       pricePerPerson: place?.price_per_person,
  //     },
  //     title: place?.accommodation.name,
  //     position: {
  //       lat: parseFloat(place?.accommodation.lat),
  //       lng: parseFloat(place?.accommodation.long),
  //     },
  //   };
  // };

  const [selectedPlace, setSelectedPlace] = useState<Place>({id: offer.id, title: offer.accommodation.name, position: 
  {lat: Number(offer.accommodation.lat), lng: Number(offer.accommodation.long)}, price: {total: offer.price, pricePerPerson: offer.price_per_person}, images: offer.accommodation.images   });

  const [actualOffer, setActualOffer] = useState<Offer>(offer);

  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);

  // useEffect(() => {
  //   setActualOffer(getOfferById(offerId));
  //   setSelectedPlace(getPlaceById(offerId));
  // }, [offerId]);

  // useEffect(() => {
  //   if (!selectedPlace) return;

  //   if (callback) {
  //     const prices = callback(offer);

  //     setActualOffer({
  //       ...offer,
  //       price: {
  //         total: prices?.offerPrice,
  //         pricePerPerson: prices?.offerPricePerPerson,
  //       },
  //     });
  //   } else {
  //     setActualOffer({
  //       ...offer,
  //       price: {
  //         total: offer?.price,
  //         pricePerPerson: offer?.price_per_person,
  //       },
  //     });
  //   }
  // }, [selectedPlace]);

if(actualOffer == null){
  return null;
}

  // const allPlaces = offers?.map((offer: any, i: number) => {
  //   return {
  //     id: offer.id,
  //     price: isTotalPriceFlag ? offer.price : offer.price_per_person,
  //     title: offer.accommodation.name,
  //     position: {
  //       lat: parseFloat(offer.accommodation.lat),
  //       lng: parseFloat(offer.accommodation.long),
  //     },
  //   };
  // });

  // const onMapLinkClickHandler = (id: string) => {
  //   setSelectedPlace(getOfferById(id));
  //   toggle();
  // };

  const modalHeader = (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>Map View</h5>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      additionalBodyClassName='map-modal-content'
      header={modalHeader}
      isMapModal
    >
      <div className='map-modal-content-wrapper'>
        {selectedPlace && (
          <>
            <div className={`map-modal-sidebar ${isMobile ? 'mobile' : ''}`}>
              <Toggle
                onToggleChange={(v) => onTotalPriceChange(v)}
                value={isTotalPriceFlag}
                size={'large'}
                firstLabel={'Price Per Person'}
                secondLabel={'Total Price'}
              />

              {/* {actualOffer?.accommodation && actualOffer?.accommodation.images?.length > 0 && (
                <ImageSlider images={actualOffer.accommodation.images} />
              )} */}

              <HolidayCard
                offer={actualOffer}
                isRec={false}
                isMap
                onMapLinkClick={() => {return}}
                searchParams={searchParams}
                id={actualOffer?.id}
                isTotalPriceFlag={isTotalPriceFlag}
                selectedBoards={null}
                onOfferClick={() => {
                  return;
                }}
                onShowOfferClick={() => {
                  return;
                }}
                additionalOffersVisible={false}
              />
            </div>

            <Map
              isTotalPriceFlag={isTotalPriceFlag}
              activePlace={selectedPlace}
              shouldShowInfoBox={shouldShowInfoBox}
              places={[]}
              center={selectedPlace.position}
              hotelName={actualOffer.accommodation.name}
              hotelLocation={actualOffer.accommodation?.address}
              resort={actualOffer.accommodation.resort}
              rating={actualOffer.accommodation.rating}
              tripadvisorRating={actualOffer.accommodation?.trip_advisor_rating}
              tripadvisorReviews={'100'}
              price={actualOffer?.price}
              onMarkerClick={(place: Place) => setSelectedPlace(place)}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default MapModal;
