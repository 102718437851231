import React, { FunctionComponent } from 'react'
import { TripadvisorVotes } from './TripadvisorVotes'
import { Rating } from '../HolidayCard/Rating'

import './TripadvisorVotesList.scss'

interface Vote {
  type: string
  value: number
}

interface TripadvisorVotesListProps {
  votesData: Vote[]
  locationScore: number
  cleanScore: number
  serviceScore: number
  valueScore: number
}

export const TripadvisorVotesList: FunctionComponent<TripadvisorVotesListProps> = ({
  votesData,
  locationScore,
  cleanScore,
  serviceScore,
  valueScore
}: TripadvisorVotesListProps) => {
  const totalVotes = votesData.reduce((accumulator, vote) => accumulator + vote.value, 0)
  const pixelsPerScore = 200 / totalVotes

  let locationSec = null;
  if(locationScore != 0){
    locationSec = <div className='tripadvisor-votes-score-list-item'>
      <span className='item-name'>Location</span>
      <Rating type='circle' rating={locationScore} />
    </div>
  }
  let cleanSec = null;
  if(cleanScore != 0){
    cleanSec = <div className='tripadvisor-votes-score-list-item'>
      <span className='item-name'>Cleanliness</span>
      <Rating type='circle' rating={cleanScore} />
    </div>
  }
  let serviceSec = null;
  if(serviceScore != 0){
    serviceSec = <div className='tripadvisor-votes-score-list-item'>
      <span className='item-name'>Service</span>
      <Rating type='circle' rating={serviceScore} />
    </div>
  }
  let valueSec = null;
  if(valueScore != 0){
    valueSec = <div className='tripadvisor-votes-score-list-item'>
      <span className='item-name'>Value</span>
      <Rating type='circle' rating={valueScore} />
    </div>
  }

  return (
    <div className='tripadvisor-votes-list-wrapper'>
      <div className='tripadvisor-votes-score-list left'>
        {locationSec}
        {cleanSec}
        {serviceSec}
        {valueSec}
      </div>
      <div className='tripadvisor-votes-score-list right'>
        {votesData.map((vote: Vote) => {
          return (
            <div key={vote.type} className='tripadvisor-votes-score-list-item'>
              <span className='item-name'>{vote.type}</span>
              <TripadvisorVotes votes={vote.value} width={pixelsPerScore * vote.value} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
