import {SelectOption} from '../hooks/types'

export function valueToOption(value: string | null, label: string | null = null) {

    if(value == null){
        return null;
    }

    if(label == null){
        label = value;
    }

    return {value: value, label: label} as SelectOption;

}