import React, { FunctionComponent, useState, useEffect } from 'react';
import { FlightDetails } from '../HolidaySummary/FlightDetails';
import './SelectFlights.scss';
import { Button } from '../Button';
import cn from 'classnames';
import { Pagination } from '../Pagination/Pagination';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { addProduct, removeProduct } from '../../hooks/useRequest';
import { Flight, Basket} from '../../hooks/types';
import { LoaderSpinnerSmall } from '../Loader/jLoader'

interface SelectFlightsProps {
  sessionId: string;
  title: string;
  titleImage: string;
  flights: Flight[];
  selectedFlight: Flight;
  basketData: Basket;
  onSelect?: (flight: Flight) => void;
}

const SelectFlights: FunctionComponent<SelectFlightsProps> = ({
  sessionId,
  title,
  titleImage,
  flights = [],
  selectedFlight,
  basketData,
  onSelect,
}: SelectFlightsProps) => {

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  const [buttonLoad, setButtonLoad] = useState(false);
  const [selectingFlight, setSelecting] = useState({'id': -1});

  const [selected, setSelected] = useState<Flight>();
  const [paginationPage, setPaginationPage] = useState(0);
  

  useEffect(() => {
    setSelected(selectedFlight);
  }, [selectedFlight])

  const by = 3;

  let selectedPrice = 0;
  flights.map((values, index) => {


    let isSelected = false;

    if(selected != null){
      if(values.id == selected.id){
        isSelected = true;
      }
    }


    if (isSelected) {
      if (values.price_per_person != null) {
        selectedPrice = values.price_per_person;
      }
    }
  });

  async function selectFlight(flight: Flight){

    setSelected(flight);

    if (flight.id && sessionId) {

      setSelecting({'id': flight.id || -1})

      setButtonLoad(true);
      if(basketData != null){
 
        for(const bp of basketData.products)
        {
          if(bp.product_type.id == 3){
            await removeProduct(userToken, sessionId, bp.id, bp.product_type.id)
          }    
          if(bp.product_type.id == 2){
            await removeProduct(userToken, sessionId, bp.id, bp.product_type.id)
          }
        }
      }

      await addProduct(userToken, sessionId, flight.id, 1, true);

      if (onSelect != null) {
        onSelect(flight);
      }
      
      setButtonLoad(false);
      setSelecting({'id': -1})
    }
  }

  if(selected == null){
    return null;
  }

  return (
    <div className={'select-flights-wrapper'}>
      <div className='select-flights-wrapper--header'>
        <img src={titleImage} alt='' />
        <span>{title}</span>
      </div>
      <div className='select-flights-wrapper--body'>
        <div className='select-flights-list'>
          {flights
            .slice(paginationPage * 3 || 0, paginationPage * 3 + by)
            .map((values, index) => {
              let diff = 0;
              let strDiff = '';

              let isSelected = false;
              if(values.id == selected.id){
                isSelected = true;
              }

              if (values.price) {
                if (selectedPrice > values.price_per_person) {
                  diff = selectedPrice - values.price_per_person;
                  strDiff = `- £${diff.toFixed(2)}`;
                } else if (selectedPrice < values.price_per_person) {
                  diff = values.price_per_person - selectedPrice;
                  strDiff = `+ £${diff.toFixed(2)}`;
                } else {
                  diff = 0;
                  strDiff = '';
                }
              }

              let diffV = null;
              if (strDiff != '') {
                diffV = (
                  <div className='airline-order-section__price'>
                    <span style={{color: configuration.jrny.price_colour}}className={'save-price'}>{`${strDiff}`}</span>
                    <span style={{color: configuration.jrny.price_colour}} className={'save-label'}>{'Per Person'}</span>
                  </div>
                );
              }

              const selStyle = {borderColor: ''};
              if(isSelected){
                selStyle.borderColor = configuration.jrny.selected_button_border_colour;
              }

              let btn = <Button
                      hoverStyle={true}
                      isSelected={isSelected}
                      isTransparent={false} 
                      overrideClassNames={`flight-button ${
                        selected ? 'flight-button-active' : ''
                      }`}
                      label={isSelected ? 'Selected' : 'Select'}
                      onClick={() => {
                        selectFlight(values);
                      }}
                      color={configuration.button_text_secondary_colour}
                      disabled={isSelected}
                    />;

              if(buttonLoad){

                if(values.id == selectingFlight.id){
                  btn = <div className='flight-button' style={{display:'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: configuration.jrny.selected_button_colour,
                  borderColor: configuration.jrny.selected_button_border_colour, color: configuration.jrny.selected_button_text_colour}}>{LoaderSpinnerSmall()}</div>
                }else{
                  btn = <Button
                  hoverStyle={true}
                  isSelected={isSelected}
                  isTransparent={false} 
                  overrideClassNames={`flight-button ${
                    selected ? 'flight-button-active' : ''
                  }`}
                  label={isSelected ? 'Selected' : 'Select'}
                  onClick={() => {
                    selectFlight(values);
                  }}
                  color={configuration.button_text_secondary_colour}
                  disabled={true}
                />;
                }
              }
              


              return (
                <div
                  style={selStyle}
                  className={cn('select-flights-list--item', {
                    'selected-airline': isSelected,
                  })}
                  key={values.id + 'select-flights-row'}
                >
                  <div className='airline-wrapper'>
                    <div className={'airline-item-wrapper'} key={index}>
            

                        <FlightDetails
                        flightType={'Outbound'}
                        airline={values.airline}
                        flightNumber={values.number}
                        departureTime={values.departing}
                        departurePlace={values.departure_airport.name}
                        arrivalPlace={values.arrival_airport.name}
                        arrivalTime={values.arrival}
                        outBound={true}
                        config={configuration}
                        ></FlightDetails>
                      <hr className='solid'></hr>
                

                        <FlightDetails
                        flightType={'Inbound'}
                        airline={values.airline}
                        flightNumber={values.return_number}
                        departureTime={values.return_departing}
                        departurePlace={values.arrival_airport.name}
                        arrivalPlace={values.departure_airport.name}
                        arrivalTime={values.return_arrival}
                        outBound={false}
                        config={configuration}
                        ></FlightDetails>
                    
                    </div>
                  </div>
                  <div
                    className={cn('airline-order-section', {
                      'selected-airline': isSelected
                    })}
                  >
                    {isSelected ? (
                      <div className='airline-order-section--save'></div>
                    ) : (
                      <div style={{color: configuration.price_colour}} className='airline-order-section--save'>{diffV}</div>
                    )}

                    {btn}
                  </div>
                </div>
              );
            })}
        </div>
        {flights.length > 3 && (
          <div className='select-flights-align-center'>
            <Pagination
              by={by}
              count={flights.length}
              onChange={(val) => {
                setPaginationPage(val);
              }}
              keyValue='pagination-flights'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectFlights;