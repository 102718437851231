import React, { FunctionComponent } from 'react';
import './HolidayCard.scss';
import NameSection from './NameSection/NameSection';
import HolidayDetails from './HolidayDetails/HolidayDetails';
import HolidayCardPrice from './HolidayCardPrice/HolidayCardPrice';
import dayjs from 'dayjs';
import { ImageSlider } from '../ImageSlider/ImageSlider';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import {Offer} from '../../hooks/types';

export interface Room {
  board_description: string;
  id?: number;
  board?: number;
  room_number?: number;
}

export interface Flight {
  departure_airport: {
    code: string;
    name: string;
  };
  id: number;
  departing: string;
  outbound_departure?: string;
}

interface HolidayCardProps {
  id: string;
  offer: Offer
  isTotalPriceFlag: boolean;
  searchParams: URLSearchParams;
  selectedBoards: any;
  onMapLinkClick: () => void;
  onOfferClick: (id: string) => void;
  onShowOfferClick: (id: string) => void;
  isMap: boolean;
  isRec: boolean
  additionalOffersVisible: boolean;
}

const HolidayCard: FunctionComponent<HolidayCardProps> = ({
  id,
  offer,
  onMapLinkClick,
  onOfferClick,
  onShowOfferClick,
  isTotalPriceFlag,
  searchParams,
  selectedBoards,
  isMap = false,
  isRec = false,
  additionalOffersVisible = false,
}: HolidayCardProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  const generateDateValue = () => {
    const nightString = + offer.duration > 1 ? 'nights' : 'night';

    let departingValue = offer.flights[0].departing;

    // if (Array.isArray(flights)) {
    //   if (flights?.length > 0) {
    //     departingValue = flights[0].departing;
    //   }
    // } else {
    //   if (flights) {
    //     departingValue = offer.outbound_departure;
    //   }
    // }

    // if (flightData) {
    //   if (!Array.isArray(flightData)) {
    //     const flight: any = Array.from(flightData);
    //     departingValue = flight.outbound_departure;
    //   } else {
    //     if (flights && flights?.length > 0) {
    //       departingValue = flightData ? flightData[0].departing : new Date();
    //     }
    //   }
    // }

    return `${dayjs(departingValue).format('D MMM YYYY')} - ${offer.duration} ${nightString}`;
  };

  let cardClass = 'holiday-card-wrapper';
  // No deposit info
  if(offer.deposit_price == -1){
    cardClass = 'holiday-card-wrapper ndp'
  }

  let ppAvail = false;
  if(offer.payment_plan_available == 1){
    ppAvail = true;
  }

  let imgs:any[] = [];
  if(offer.accommodation.images != null){
    imgs = offer.accommodation.images;
  }

  return (
    <div id={id} className={cardClass}>



      <ImageSlider isRec={isRec} images={imgs} />

      <div style={{borderColor: configuration.card_border_colour}} className='holiday-card-wrapper--details'>

        <NameSection
          isMap={isMap}
          onMapLinkClick={onMapLinkClick}
          destination={offer.accommodation.resort.regions[0].destinations[0].name}
          region={offer.accommodation.resort.regions[0].name}
          resort={offer.accommodation.resort.name}
          name={offer.accommodation.name}
          rating={Number(offer.accommodation.rating)}
          tripadvisorRating={offer.accommodation.trip_advisor_rating}
          tripadvisorReviewCount={offer.accommodation.trip_advisor_num_reviews}
        />
    
        <HolidayDetails date={generateDateValue()} config={configuration} rooms={offer.rooms} 
        flight={offer.flights[0]} />

        <HolidayCardPrice
          id={id}
          offer={offer}
          searchParams={searchParams}
          selectedBoards={selectedBoards}
          isTotalPriceFlag={isTotalPriceFlag}
          onOfferClick={onOfferClick}
          onShowOffers={onShowOfferClick}
          visible={additionalOffersVisible}
        />
      
          

      </div>
    </div>
  );
};

export default HolidayCard;
