import React, { FunctionComponent, useEffect, useState } from 'react'
import { Rating } from '../HolidayCard/Rating'
import { TripadvisorLogo } from './TripadvisorLogo'
import { TripadvisorHotelInfo } from './TripadvisorHotelInfo'
import { TripadvisorVotesList } from './TripadvisorVotesList'
import { ReadMore } from '../ReadMore'
import {getTripAdvsiorData} from '../../hooks/useRequest'

import './TripadvisorReviews.scss'
import { RootStore } from '../../stores/RootStore'
import { useStores } from '../../hooks/useStore'

interface TripadvisorReviewsProps {
  hotelId: number
  hotelName: string
  tripadvisorRating: number
}

const TripadvisorReviews: FunctionComponent<TripadvisorReviewsProps> = ({
  hotelId,
  hotelName,
  tripadvisorRating,
}: TripadvisorReviewsProps) => {

  const {
    RootStore: {
      userToken,
    },
  } = useStores() as { RootStore: RootStore };

  const [reviewData, setReviewData] = useState<any>(null)

  useEffect(() => {

    getTripAdvsiorData(userToken, hotelId).then((d) => {

      if(d == null){
        return
      }
      if(d.accommodation.result == null){
        return
      }
      if(d.accommodation.result.trip_advisor == ''){
        return
      }

      const jsonReviewData = JSON.parse(d.accommodation.result.trip_advisor);
      setReviewData(jsonReviewData);

    })

  }, [])

  if(reviewData == null){
    return null;
  }

  let excellent = 0;
  if(reviewData.review_rating_count[5] != null){
    excellent = reviewData.review_rating_count[5];
  }
  let veryGood = 0;
  if(reviewData.review_rating_count[4] != null){
    veryGood = reviewData.review_rating_count[4];
  }
  let average = 0;
  if(reviewData.review_rating_count[3] != null){
    average = reviewData.review_rating_count[3];
  }
  let poor = 0;
  if(reviewData.review_rating_count[2] != null){
    poor = reviewData.review_rating_count[2];
  }  
  let terrible = 0;
  if(reviewData.review_rating_count[1] != null){
    terrible = reviewData.review_rating_count[1];
  }


  const votesData = [
    { type: 'Exellent', value: Number(excellent) },
    { type: 'Very Good', value: Number(veryGood) },
    { type: 'Average', value: Number(average) },
    { type: 'Poor', value: Number(poor) },
    { type: 'Terrible', value: Number(terrible) },
  ]

  let locationScore = 0;
  let cleanScore = 0;
  let serviceScore = 0;
  let valueScore = 0;

  if(reviewData.subratings != null){
    for(const sr of reviewData.subratings){
       if(sr.name == 'rate_location'){
        locationScore = Number(sr.value);
       }
       if(sr.name == 'rate_cleanliness'){
        cleanScore = Number(sr.value);
       }
       if(sr.name == 'rate_service'){
        serviceScore = Number(sr.value);
       }
       if(sr.name == 'rate_value'){
        valueScore = Number(sr.value);
       }

    }
  }

  let rankString = '';
  if(reviewData.ranking_data != null){
    rankString = reviewData.ranking_data.ranking_string;
  }

  let numReviews = '';
  if(reviewData.num_reviews != null){
    numReviews = reviewData.num_reviews + ' reviews';
  }

  const reviews = []
  for(const x of reviewData.reviews){
      const reviewId = x.id;
      const rating = x.rating;
      const blurb = x.text;
      const tripType = x.trip_type;
      const user = x.user.username;
      const userLocation = x.user.user_location.name;
      const title = x.title;
      const url = x.url;

      let userInfo = user;
      if(userLocation != null){
        userInfo += ', ' + userLocation;
      }

      const reviewXHTML= <div className='tripadvisor-review'>
          <h2 className='tripadvisor-votes-title'>{title}</h2>
          <div className='tripadvisor-rating-with-date'>
            <Rating type='circle' rating={rating} />
            <span>Jun 2022</span>
          </div>
          <div className='tripadvisor-review-user-info'>{userInfo}</div>
       
            {blurb} <a href={url} target='_blank' rel='noreferrer'>Read more</a>
      
          </div>  
      
      reviews.push(reviewXHTML);

  }

  return (
    <div className='tripadvisor-reviews-wrapper'>
      <div className='tripadvisor-reviews-top-section'>
        <TripadvisorLogo />
        <TripadvisorHotelInfo
          hotelName={hotelName}
          tripadvisorRating={tripadvisorRating}
          tripadvisorReviews={numReviews}
          rankString={rankString}
        />
      </div>
      <h2 className='tripadvisor-votes-title'>Tripadvisor Traveller Rating</h2>
      <TripadvisorVotesList votesData={votesData} locationScore={locationScore} cleanScore={cleanScore} serviceScore={serviceScore} valueScore={valueScore} />

      {/* List of reviews will be generic after fetching proper reviews data */}

      <div className='tripadvisor-reviews-list'>
          {reviews}
      </div>
    </div>
  )
}

export default TripadvisorReviews
