import React, { FunctionComponent, useEffect, useState } from 'react';
import cn from 'classnames';

import AirportsSelect from './AirportsSelect';
import DestinationSelect from './DestinationSelect';
import RoomsSelect from './RoomsSelect';
import DurationSelect from './DurationSelect';
import { Datepicker } from '../Datepicker';
import './SearchBar.scss';
import { Button } from '../Button';
import useMultipleMedia from '../../hooks/useMultipleMedia';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BoardTypeSelect from './BoardType';
import { noop } from 'lodash';
import { siteConfiguration } from '../../hooks/useRequest';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { useSearchProvider } from '../../context/search';

import flightHotelIcon from '../assets/flightHotel.svg';
import cityIcon from '../assets/city.svg';
import packageIcon from '../assets/pack.svg';

import { useLocationQuery } from '../../hooks/useQuery';

import search from '../../components/assets/search-icon.svg';
import searchDark from '../../components/assets/icons/dark/noun-magnifying-glass-202232-222222.svg';

import { fbqTrackSearch } from '../../hooks/fb';

import { LoaderSpinner } from '../Loader/jLoader'
import dayjs, { Dayjs } from 'dayjs';

import { SearchSelectionRoom, SearchSelections } from '../../hooks/types'

import { roomsFromPaxString } from '../../services/pax';
import { geoArrayFromString, departurePointArrayFromString } from '../../services/geo';
import { sessionSetSearchParams, sessionGetSearchParams } from '../../services/search';


interface SearchBarProps {
  isBoardType?: boolean;
  isHolidayPage?: boolean;
  isSearchPage?: boolean;
  searchItemType?: string;
  localSession?: string;
  accommodationId?: number;
  resultFilters?: any;
  onClick?: () => any;
}



const SearchBar: FunctionComponent<SearchBarProps> = ({
  isBoardType = false,
  isSearchPage = false,
  isHolidayPage = false,
  searchItemType,
  accommodationId,
  onClick
}) => {


  const {
    RootStore: {
      sessionStore: { userSession, createUserSession },
      userToken,
      configuration,
      showSearchTabs,
      searchMode,
    },
  } = useStores() as { RootStore: RootStore };

  const [searchParams] = useSearchParams();


  useEffect(() => {

    let defaultSelections: SearchSelections = { 
    duration: 7, destinations: [], regions: [], resorts: [], 
    departurePoints: [], departureDate: null, departureDateType: 0, searchType: 0, ratings: [], tripRatings: [], boards: [], rooms : [{adults: 2, children: 0, childAges: []}]}

    let prev = localStorage.getItem('prevsrch');
    if(prev != null){

      let dos = localStorage.getItem('dos');
      let use = true;
      if(dos != null){
        let d = dayjs(dos);
        let diff = dayjs().diff(d, 'days');
        if(diff > 2){
          localStorage.removeItem('prevsrch');
          localStorage.removeItem('dos');
          use = false;
        }
      }

      if(use){
        defaultSelections = JSON.parse(prev) as SearchSelections;
      }
    }


    if(searchParams != null){
      if(searchParams.get('departing') != null){
        defaultSelections.departureDate = searchParams.get('departing') + ' 00:00';
      }   
      
      if(searchParams.get('departingType') != null){
        defaultSelections.departureDateType = Number(searchParams.get('departingType'));
      }
  
      if(searchParams.get('rooms') != null){
        defaultSelections.rooms = roomsFromPaxString(searchParams.get('rooms') || '');
      }
      if(searchParams.get('duration') != null){
        defaultSelections.duration = Number(searchParams.get('duration'));
      }
  
      if(searchParams.get('destinations') != null){
        defaultSelections.destinations = geoArrayFromString(searchParams.get('destinations'));
      }
  
      if(searchParams.get('regions') != null){
        defaultSelections.regions = geoArrayFromString(searchParams.get('regions'));
      }
  
      if(searchParams.get('resorts') != null){
        defaultSelections.resorts = geoArrayFromString(searchParams.get('resorts'));
      }
  
      if(searchParams.get('departurePoints') != null){
        defaultSelections.departurePoints = departurePointArrayFromString(searchParams.get('departurePoints'));
      }
  
      if(searchParams.get('type') != null){
        defaultSelections.searchType = Number(searchParams.get('type'));
      }
    }

    if(searchMode != -1){
      defaultSelections.searchType = searchMode;
      if(searchMode == 1){
        defaultSelections.duration = 3;
      }
    }
    
    setSelectedSearchParams(defaultSelections);

    setReady(true);
  }, [searchParams])


  

  // const defaultSelections: SearchSelections = { 
  //   duration: 7, destinations: [], regions: [], resorts: [], 
  //   departurePoints: [], departureDate: null, departureDateType: 0, searchType: 0, ratings: [], tripRatings: [], boards: [], rooms : [{adults: 2, children: 0, childAges: []}]}


  const [selectedSearchParams, setSelectedSearchParams] = useState<SearchSelections>()

  const [clSearchType, setCliS] = useState<number>(0);
  const [maxGuestsMessage, setMaxGuestsMessage] = useState<string | null>(null);

  // const [searchType, setSearchType] = useState(defaultSelections.searchType);


  const [formError, setFormError] = useState<{ key: string; value: string }>();
  const [depAirVal, setDepAirVal] = useState<{ valid: boolean; message: string }>();
  const [destVal, setDestVal] = useState<{ valid: boolean; message: string }>();
  const [dateVal, setDateVal] = useState<{ valid: boolean; message: string }>();
  const [paxVal, setPaxVal] = useState<{ valid: boolean; message: string }>();

  const [ready, setReady] = useState<boolean>(false);

  const [searchAtt, setSearchAtt] = useState<boolean>(false);

  const [forgetRooms, setForgetRooms] = useState<boolean>(false);

  // const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);
  const navigate = useNavigate();
  // const { state } = useSearchProvider();
  // const { state: searchState, dispatch } = useSearchProvider();

  const query = useLocationQuery();




  // useEffect(() => {
    
    
  //   if (isHolidayPage) {
  //     setReady(true);
  //     return
  //   }

  //   const jh = userToken;
  //   let cliStype = 0;


  //   siteConfiguration('1', userToken).then((d) => {

  //     if(isSearchPage == false && isHolidayPage == false){

  //       if(d == 0){
  //         if(searchMode != -1){
  //           handleOptionSelection(searchMode)
  //           setReady(true);
  //         }
  //         else{
  //           handleOptionSelection(0);
  //         }
  //       }
  //       else if(d == 1 || d == 3){
  //         handleOptionSelection(0);
  //       }
  //       else if(d == 2){
  //         handleOptionSelection(1)
  //       }
  //       else if(d == 4){
  //         handleOptionSelection(2);
  //       }
  //     }

  //     setCliS(d);
  //     cliStype = d;
  //     setReady(true);
  //   });
    

  //   // Not sure about this ? 
  //   // if (typeof selectedSearchParams?.type !== 'undefined') {
  //   //   setOption(selectedSearchParams.type);
  //   // }

  // }, []);

  const boardOptionFromString = (x: string) => {
    switch (x.toLowerCase()) {
      case 'all inclusive':
        return { label: 'All inclusive', value: 5 };
      case 'full board':
        return { label: 'Full board', value: 4 };
      case 'half board':
        return { label: 'Half board', value: 3 };
      case 'bed & breakfast':
        return { label: 'Bed & breakfast', value: 2 };
      case 'self catering':
        return { label: 'Self catering', value: 1 };
      case 'room only':
        return { label: 'Room only', value: 0 };
    }
  };


  function setZIndex(elem: string) {
    const child = document.getElementById(elem);

    if(child == null){
      return
    }

    const parentWithClass = child.closest('.stack') as HTMLElement;
    if(parentWithClass == null){
      return
    }

    parentWithClass.style.zIndex = '999';
  }

  function handleOptionSelection(searchType: number) {

    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;

    setDepAirVal({valid: true, message: ''});
    setDestVal({valid: true, message: ''});
    setPaxVal({valid: true, message: ''});
    setDateVal({valid: true, message: ''});

    setSearchAtt(false);
    // setSearchType(searchType);
    setFormError(undefined);

    sp.searchType = searchType;

    if(searchType == 1){
      sp.duration = 3;
    }
    else{
      sp.duration = 7;
    }

    sp.destinations.length = 0;
    sp.regions.length = 0;
    sp.resorts.length = 0;
    sp.departurePoints.length = 0;
    sp.departureDate = null;
    sp.departureDateType = 0;
    sp.rooms = [{adults: 2, children: 0, childAges: []}];
    sessionStorage.removeItem('roomSelections');


    setSelectedSearchParams(sp);

    setStorage(sp);
  }

  if(selectedSearchParams == null){
    return null;
  }


  const setStorage = (selectedParams: SearchSelections) => {
    localStorage.setItem('prevsrch',JSON.stringify(selectedParams));
    localStorage.setItem('dos',dayjs().toString());
  }

  function handleDeparturePointParams(selectedValues:string[]){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.departurePoints = selectedValues;
    setSelectedSearchParams(sp);
    validateInputs(false);
    setStorage(sp);
  }

  function handleDestinationParams(selectedDestinatons: number[], selectedRegions: number[], selectedResorts: number[]){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.destinations = selectedDestinatons;
    sp.regions = selectedRegions;
    sp.resorts = selectedResorts;
    setSelectedSearchParams(sp);
    validateInputs(false);
    setStorage(sp);
  }

  function handleDurationParam(option:any){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.duration = option
    setSelectedSearchParams(sp);
    setStorage(sp);
  }

  function handleDateParam(selectedValues: string | null){

 
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.departureDate = selectedValues;
    sp.departureDateType = 0;
    setSelectedSearchParams(sp);
    validateInputs(false);
    setStorage(sp);
  }

  function handleDateMonthParam(selectedValues: string | null){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.departureDate = selectedValues;
    sp.departureDateType = 1;
    setSelectedSearchParams(sp);
    setStorage(sp);
  }


  function handleRoomsParam(selectedValues: SearchSelectionRoom[]){
    if(selectedSearchParams == null){
      return
    }
    const sp = selectedSearchParams;
    sp.rooms = selectedValues;
    setSelectedSearchParams(sp);
    setStorage(sp);
  }


  let beachLine = <></>;
  let cityLine = <></>;
  let pakLine = <></>;

  if(selectedSearchParams.searchType == 0){
    beachLine = <div className='tabTypeLine' style={{backgroundColor: configuration.text_colour, width: '100%', height: '4px', position: 'relative', top: '0.75em', borderRadius: '3px'}}>
      <></>
    </div>;
  }
  else if(selectedSearchParams.searchType == 1){
    cityLine = <div className='tabTypeLine'style={{backgroundColor: configuration.text_colour, width: '100%', height: '4px', position: 'relative', top: '0.75em', borderRadius: '3px'}}>
      <></>
    </div>;
  }
  else if(selectedSearchParams.searchType == 2){
    pakLine = <div className='tabTypeLine' style={{backgroundColor: configuration.text_colour, width: '100%', height: '4px',  position: 'relative', top: '0.75em', borderRadius: '3px'}}>
      <></>
    </div>;
  }

  const beachOption = () => {
    return <div
        className={cn('search-option', {
          'search-option--active': selectedSearchParams.searchType === 0,
        })}
        role='button'
        onClick={() => handleOptionSelection(0)}
      >
        <img src={flightHotelIcon} alt='Beach icon' width={25} />
        <p>Flight & Hotel</p>
        {beachLine}
      </div>
  }
  const cityOption = () => {
    return  <div
    className={cn('search-option', {
      'search-option--active': selectedSearchParams.searchType === 1,
    })}
    role='button'
    onClick={() => handleOptionSelection(1)}
  >
    <img src={cityIcon} alt='City icon' width={25} />
    <p>City Breaks</p>
    {cityLine}
  </div>
  }

  const pakOption = () => {
      return <div
      className={cn('search-option', {
        'search-option--active': selectedSearchParams.searchType === 2,
      })}
      role='button'
      onClick={() => handleOptionSelection(2)}
    >
      <img src={packageIcon} alt='Package icon' width={25} />
      <p>Package Holidays</p>
      {pakLine}
    </div>
  }


  const handleOnSearchClick = async () => {
    
    setSearchAtt(true);

    const valDepAir = {valid: true, message: ''};
    const valDest = {valid: true, message: ''};
    const valDate = {valid: true, message: ''};
    const valPax = {valid: true, message: ''};
    let valid = true;

    if (Object.keys(selectedSearchParams).length > 0 && selectedSearchParams.rooms) {
      const { rooms } = selectedSearchParams;
      // Loop through each room object and add the number of adults and children together
      // If the total is greater than 4 then set valid to false
      rooms.forEach((room: any) => {
        if (room.adults + room.children > 5) {
          valid = false;
        }
      });
    }
    


    if (!valid) {
      setMaxGuestsMessage(
        'You may only have a maximum of 5 guests per room. Please amend your search and try again.',
      );
    } else {
      const uid = crypto.randomUUID();
      const strSelectedSearchParams = JSON.stringify(selectedSearchParams)

      if (!isSearchPage && !isHolidayPage) {
  
        sessionStorage.removeItem('f');
        sessionStorage.removeItem('aprm');

        sessionStorage.removeItem('js1');
        sessionStorage.removeItem('js2');
        sessionStorage.removeItem('js3');
        sessionStorage.removeItem('js4');
        sessionStorage.removeItem('js5');
        sessionStorage.removeItem('shosnt');

        sessionStorage.setItem('local_session', uid);
      }

      sessionStorage.removeItem('snpOff');

      const rooms = selectedSearchParams.rooms;
      let adults = 0;
      let children = 0;
      for(const x of rooms as any){
        adults += x.adults;
        children += x.children;
      }

      let strDepDate = '';
      if(selectedSearchParams.departureDate != null){
        const depDateObj = dayjs(selectedSearchParams.departureDate)
        strDepDate = depDateObj.format('YYYY-MM-DD');
      }

      const selectedDeparturePoints:any[] = []
      if(selectedSearchParams.departurePoints != null){
        for(const a of selectedSearchParams.departurePoints){
          selectedDeparturePoints.push(a);
        }
      }
      const d: number[] = [];
      const g: number[] = [];
      const s: number[] = [];
      if(selectedSearchParams.destinations != null){
        for(const x of selectedSearchParams.destinations){
            d.push(Number(x));
        }
      }

      if(selectedSearchParams.regions != null){
        for(const x of selectedSearchParams.regions){
          g.push(Number(x));
        }
      }

      if(selectedSearchParams.resorts != null){
        for(const x of selectedSearchParams.resorts){
          s.push(Number(x));
        }
      }

      const valid = validateInputs(true);

      const strRoom = [];
      for(const x of selectedSearchParams.rooms){
        const cas: any[] = [];
        for(const ca of x.childAges){
          cas.push(ca)
        }
        strRoom.push(x.adults + '-' + cas.join(','))
      }

      const qsRooms = strRoom.join('_')
      
      let queryString = 'rooms=' + qsRooms + '&duration=' + selectedSearchParams.duration;

      if(strDepDate != ''){
        queryString += '&departing=' + strDepDate;
        queryString += '&departingType=' + selectedSearchParams.departureDateType;
      }

      if(s.length > 0){
        queryString += '&resorts=' + s.join('-'); 
      }

      if(g.length > 0){
        queryString += '&regions=' + g.join('-'); 
      }
      if(d.length > 0){
        queryString += '&destinations=' + d.join('-'); 
      }

      if(selectedDeparturePoints.length > 0){
        queryString += '&departurePoints=' + selectedDeparturePoints.join('-'); 
      }

      queryString += '&type=' + selectedSearchParams.searchType; 


      sessionSetSearchParams(selectedSearchParams);

      if (selectedSearchParams.searchType === 2) {
        
        if (valid) {
          // Create a session before moving on (different from cache)
          const session = await createUserSession(userToken);
          queryString += '&session=' + session;

          if (process.env.REACT_APP_BUILD_TARGET === 'widget') {
            window.location.href = `${configuration.search_url}/package-search?${queryString}`;
          } else {
            navigate(`/package-search?${queryString}`);
          }

          if(onClick){
            onClick();
          }

        }
      } else {
        if(valid){
          // searchAdd(da, d, g, s, params.duration.value, adults, children, rooms.length, strDepDate, uid, state.option, userToken);
          queryString += '&sort=0'; 
          if (process.env.REACT_APP_BUILD_TARGET === 'widget') {
            window.location.href = `${configuration.search_url}/search?=${queryString}`;
          } else {
            navigate(`/search?${queryString}`);
          }

          if(onClick){
            onClick();
          }
          
        }
      }
    }
  };




  const validateInputs = (searchAttempt: boolean) => {
    let valid = true;

    // const params = {
    //   ...selectedSearchParams,
    //   // ...parsedSearchParams,
    //   type: state.option,
    // };

    const valDepAir = {valid: true, message: ''};
    const valDest = {valid: true, message: ''};
    const valDate = {valid: true, message: ''};
    const valPax = {valid: true, message: ''};

    if (selectedSearchParams.searchType === 2) {

      // Package specific validations
      if (!selectedSearchParams.departurePoints || selectedSearchParams.departurePoints?.length === 0) {
        valid = false;
        valDepAir.valid = false;
        valDepAir.message = 'Please select an airport';
      }
      else{
        valDepAir.valid = true;
        valDepAir.message = '';
      }

      if (selectedSearchParams.regions.length == 0 && selectedSearchParams.resorts.length == 0) {
        valid = false;
        valDest.valid = false;
        valDest.message = 'Please select a destination';
      }
      else{
        valDest.valid = true;
        valDest.message = '';
      }

      if (!selectedSearchParams.departureDate) {
        valid = false;
        valDate.valid = false;
        valDate.message = 'Please select a date';
      }else{
        valDate.valid = true;
        valDate.message = '';
      }
  }

  // Always validate children ages (for any type of search)
  if (selectedSearchParams.rooms) {
  let noChildAge = 0;
  const rooms = selectedSearchParams.rooms as any;
  for(const x of rooms){
    const childAgesArray = x.childAges;
    for(const ca of childAgesArray){
      if(Number(ca) < 0){
        noChildAge++;
        break;
      }
    }
  }
  if(noChildAge > 0){
    valid = false;
    valPax.valid = false
    if(noChildAge == 1){
      valPax.message = 'Please select your childs age on return';
    }
    else{
      valPax.message = 'Please select your childrens ages on return';
    }
  }
  else{
    valPax.valid = true
    valPax.message = '';
  }
  }
  


  if(searchAtt || searchAttempt){
    setDepAirVal(valDepAir);
    setDestVal(valDest);
    setPaxVal(valPax);
    setDateVal(valDate);
  }

  return valid
}

  let rooms = JSON.stringify(['2']);
  let numberOfRooms = 1;
  if (selectedSearchParams) {
    if (selectedSearchParams.rooms) {
      rooms = JSON.stringify(selectedSearchParams.rooms);
      numberOfRooms = selectedSearchParams.rooms.length;
    }
  }

  // const availableParams =
  //   Object.keys(parsedSearchParams).length > 0 ? parsedSearchParams : selectedSearchParams;

  setZIndex('root');

  const sOps = [];
  if(process.env.REACT_APP_BUILD_TARGET === 'widget'){
    if(clSearchType == 0 || clSearchType == 99){
      // All options please
      sOps.push(beachOption())
      sOps.push(cityOption())
      sOps.push(pakOption())

    }
    else if(clSearchType == 3){ 
      sOps.push(beachOption())
      sOps.push(cityOption())
    }
  }
  else{
    if(clSearchType == 0 || clSearchType == 99){
      // All options please
      sOps.push(beachOption())
      sOps.push(cityOption())
      sOps.push(pakOption())

    }
    else if(clSearchType == 3){ 
      sOps.push(beachOption())
      sOps.push(cityOption())
    }
  }

  if(!showSearchTabs){
    sOps.length = 0;
  }

  let buttonIco = search;
  const buttonIcoMob = search;

  if(configuration.button_icon == 'dark'){
    buttonIco = searchDark;
  }


  if(process.env.REACT_APP_BUILD_TARGET === 'widget'){
    if(configuration.spec == '6yk1y'){;
      buttonIco = searchDark;
    }
  }


  if(!ready){

    const loader =<><div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      {LoaderSpinner()}</div></>;

    return loader;
  }




  return (
    <>
      {!isBoardType && !isSearchPage && sOps.length > 0 && (
        <div className='search-options'>
            {sOps}
        </div>
      )}

      <div className='search-bar-container'>
        <div className='collapsed-search-options'>

          <AirportsSelect
            validationError={depAirVal}
            clickApplyHandler={handleDeparturePointParams}
            accommodationId={accommodationId}
            clickedSearchButtonItemName={searchItemType}
            selectedSearchParams={selectedSearchParams}
            showIcons={true}
          />

          {!isBoardType && (
            <>
              <DestinationSelect
                validationError={destVal}
                clickApplyHandler={handleDestinationParams}
                clickedSearchButtonItemName={searchItemType}
                selectedSearchParams={selectedSearchParams}
                showIcons={true}
              />
            </>
          )}
          {!isBoardType && (
            <Datepicker
              validationError={dateVal}
              selectedParams={selectedSearchParams}
              clickedSearchButtonItemName={searchItemType}
              clickApplyHandler={handleDateParam}
              clickApplyHandlerMonth={handleDateMonthParam}
              searchItemType={searchItemType}
              showIcons={true}
            />
          )}

          <DurationSelect
            clickApplyHandler={handleDurationParam}
            clickedSearchButtonItemName={searchItemType}
            selectedSearchParams={selectedSearchParams}
            accommodationId={accommodationId}
            showIcons={true}
          />

          {isBoardType && (
            <BoardTypeSelect
              selectedSearchParams={selectedSearchParams}
              accommodationId={accommodationId}
              clickApplyHandler={() => {return}}
              showIcons={isBoardType}
            />
          )}

          <RoomsSelect
            validationError={paxVal}
            clickApplyHandler={(value) => {
              if (value) {
                handleRoomsParam(value);
                setForgetRooms(false);
              }

              if (maxGuestsMessage) {
                setMaxGuestsMessage(null);
              }
            }}
            clickedSearchButtonItemName={searchItemType}
            accommodationId={accommodationId}
            selectedSearchParams={selectedSearchParams}
            showIcons={true}
            roomNumber={numberOfRooms}
          />

          {!isBoardType && (

                    <>
                    <span className='btn'>
                    <Button label='Search' borderColor={configuration.button_colour}  
                    backgroundColor={configuration.button_colour} isSearchIcon icon={buttonIco} 
                    color={configuration.button_text_colour} primary onClick={handleOnSearchClick} />
                    </span>

                    <span className='btn--mob'>
                    <Button label='Search' borderColor={configuration.button_colour}  
                    backgroundColor={configuration.button_colour} isSearchIcon icon={buttonIcoMob} 
                    color={configuration.button_text_colour} primary onClick={handleOnSearchClick} />
                    </span></>
      
          )}
        </div>
      </div>

      {maxGuestsMessage && <p className='search-bar-error'>{maxGuestsMessage}</p>}
    </>
  );
};

export default SearchBar;
