import React, { FunctionComponent, useRef, useState, ReactNode, useEffect, FC } from 'react';
import { SearchBarInputField } from '../SearchBarInputField';
import './SearchBar.scss';
import './RoomsSelect.scss';


import { Room } from '../Room';
import { Button } from '../Button';

import personDefault from '../assets/icons/default/01/Passengers.svg';
import person from '../assets/person.svg';
import { availablePax } from '../../hooks/useRequest';

import { useSearchProvider } from '../../context/search';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

import { Modal } from '../Modal';

import { SearchSelections, SearchSelectionRoom } from '../../hooks/types'

interface IRoomSelector {
  clickApplyHandler?: () => void;
  handleApply?: (room: SearchSelectionRoom, num: number) => void;
  selectedValues: SearchSelectionRoom[];
  roomDelete: (num: number) => void;
  roomAdd: () => void;
  data?: any;
  valid?: any;
  validationMessage?: string;
  setBeforeChanges?: (rooms: any) => void;
}

const RoomSelector: FC<IRoomSelector> = ({
  clickApplyHandler,
  selectedValues,
  handleApply,
  roomDelete,
  roomAdd,
  data,
  valid=null,
  validationMessage=null,
  setBeforeChanges,
}) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [maxGuestsMessage, setMaxGuestsMessage] = useState<string | null>(null);
  const [selected, setSelected] = useState<SearchSelectionRoom[]>(selectedValues);

  useEffect(() => {

    setSelected(selectedValues);

  }, [selectedValues])

  // let largestAdult = 1;
  // let largestChildren = 1;
  // const adultRecord: Record<string, number> = {};
  // const childrenRecord: Record<string, number> = {};
  // if (data) {
  //   data.forEach((result: any) => {
  //     if (result.adults > largestAdult) largestAdult = result.adults;
  //     if (result.children > largestChildren) largestChildren = result.children;
  //     adultRecord[result.adults] = result.adults;
  //     childrenRecord[result.children] = result.children;
  //   });
  // }

  if (data == null) {
    return null;
  }

  const vals: any[] = [];
  for (const x of data) {
    vals.push({ value: x.adults, label: x.adults });
  }

  const rooms: any[] = [];

  for (let i = 0; i < selected.length; i++) {

    const selectedAdults = selected[i].adults;
    const childVals: any[] = [];
    for (const x of data) {
      // Get children for the selected adult.
      if (selectedAdults == x.adults) {
        // Always need to be able to set 0 children so add that first.
        childVals.push({ value: 0, label: 0 });
        for (let l = 1; l < x.children + 1; l++) {
          childVals.push({ value: l, label: l });
        }
        break;
      }
    }

    const rm = (
      <Room
        config={configuration}
        key={i + 'rmsel'}
        defaultValues={
          selected[i] || {
            adults: 2,
            children: 0,
            childAges: []
          }
        }
        adultsOptions={vals}
        childrenOptions={childVals}
        roomNumber={i + 1}
        removeRoom={(num) => {
          roomDelete(Number(num) - 1);
        }}
        onApply={(room: SearchSelectionRoom) => {
          if (maxGuestsMessage) {
            setMaxGuestsMessage(null);
          }

          if (handleApply) handleApply(room, i);
        }}
      />
    );
    rooms.push(rm);
  }



  let bc = configuration.button_colour;

  let titleColour =  configuration.button_colour;
  let cancelText = configuration.button_colour;
  let primButtonColour = configuration.button_colour;
  if(configuration.button_icon == 'dark'){
    bc = configuration.border_colour_2;
    titleColour =  configuration.border_colour_2;
  }

  let selStyle = {backgroundColor: configuration.button_colour, borderColor:configuration.border_colour_2};

  if(configuration.spec == '6yk1y'){
    primButtonColour = configuration.button_colour;
    cancelText = '#01447b';
    bc = '#01447b';
    titleColour =  '#01447b';
    selStyle = {backgroundColor: '#01447b', borderColor:configuration.border_colour_2};
  }

  let roomButton = null;
  if (rooms.length < 3) {
    roomButton = (
      <Button
        label='Add Room'
        hoverStyle={false}
        isTransparent
        isPlusIcon
        disabled={rooms.length >= 3}
        onClick={() => {
          roomAdd();

        }}
        color={bc}
        borderColor={bc}
      />
    );
  }

  let valMessage = null;
  if(!valid){
    valMessage = <p className='rooms-container__error'>{validationMessage}</p>;
  }

  return (
    <div style={{borderColor: configuration.border_colour_2}} className='rooms-container'>
      {rooms}
      {maxGuestsMessage && <p className='rooms-container__error'>{maxGuestsMessage}</p>}
      {valMessage}
      {roomButton}

      {clickApplyHandler && (
        <div className='rooms-container__buttons'>
          <Button
            label='Apply'
            primary
            onClick={() => {
              let valid = true;
              let msg = ''
              // Loop through each room object and add the number of adults and children together
              // If the total is greater than 4 then set valid to false
              selected.forEach((room: any) => {
                if (room.adults + room.children > 5) {
                  valid = false;
                  msg = 'You may only have a maximum of 5 guests per room. Please amend your search and try again.'
                }

                room.childAges.forEach((c: any) => {
                  if(Number(c) < 0){
                    valid = false;
                    msg = 'Please make sure all child ages are set.'
                  }
                });
              });
            

              if (!valid) {
                setMaxGuestsMessage(
                  msg,
                );
              } else {
                clickApplyHandler();
                if(setBeforeChanges){
                  setBeforeChanges(selected);
                }
              }
            }}
            backgroundColor={primButtonColour}
            borderColor={primButtonColour}

          />
        </div>
      )}
    </div>
  );
};


interface RoomsSelectProps {
  clickApplyHandler: (rooms: SearchSelectionRoom[]) => void;
  onActivate?: () => void;
  resetItemType?: (type: string) => void;
  clickedSearchButtonItemName?: string;
  selectedSearchParams: SearchSelections;
  accommodationId?: number;
  showIcons: boolean;
  roomNumber: number;
  validationError?: {valid: boolean, message: string};
  checkDate?: boolean;
}

const RoomsSelect: FunctionComponent<RoomsSelectProps> = ({
  clickApplyHandler,
  resetItemType,
  clickedSearchButtonItemName,
  selectedSearchParams,
  accommodationId,
  onActivate,
  showIcons = false,
  roomNumber = 1,
  validationError = null,  
  checkDate = true,
}) => {

  const {
    RootStore: {
      userToken,
      configuration
    },
  } = useStores() as { RootStore: RootStore };

  const [selectExpanded, setSelectExpanded] = useState<boolean>(false);
  const [roomsNumber, setRoomsNumber] = useState<number>(roomNumber);
  const { state } = useSearchProvider();

  const [selectedValues, setSelectedValues] = useState<SearchSelectionRoom[]>(selectedSearchParams.rooms);

  const [availPax, setPaxData] = useState<any>(null);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [mobValid, setMobValid] = useState<any>(null);
  const [validationMessage, setValidationMessage] = useState<any>(null);

  let duration = 7;
  let accID = null;
  const airports: any[] = [];
  const destinations: any[] = [];
  const regions: any[] = [];
  const resorts: any[] = [];
  const boards: any[] = [];
  const ratings: any[] = [];
  const tripRatings: any[] = [];


  if (selectedSearchParams != null) {
    if (selectedSearchParams.duration != null) {
      duration = selectedSearchParams.duration;
    }

    if (selectedSearchParams.boards != null) {
      for (const x of selectedSearchParams.boards) {
        boards.push(x);
      }
    }

    if (selectedSearchParams.departurePoints != null) {
      for (const x of selectedSearchParams.departurePoints) {
        airports.push(x);
      }
    }

    if (selectedSearchParams.destinations != null) {
      for (const x of selectedSearchParams.destinations) {
          destinations.push(x);
      }
    }

    if (selectedSearchParams.regions != null) {
      for (const x of selectedSearchParams.regions) {
          regions.push(x);
      }
    }

    if (selectedSearchParams.resorts != null) {
      for (const x of selectedSearchParams.resorts) {
          resorts.push(x);
      }
    }

    if (selectedSearchParams.ratings != null) {
      for (const x of selectedSearchParams.ratings) {
        ratings.push(x);
      }
    }

    if (selectedSearchParams.tripRatings != null) {
      for (const x of selectedSearchParams.tripRatings) {
        tripRatings.push(x);
      }
    }
  }

  let tripType = state.option;
  if (selectedSearchParams?.searchType != null) {
    tripType = selectedSearchParams.searchType;
  }

  if (accommodationId != null) {
    accID = accommodationId;
  }

  const selectorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedValues(selectedSearchParams.rooms);
  },[selectedSearchParams.rooms]);


  useEffect(() => {
      setRoomsNumber(roomNumber);
      if (selectedSearchParams.rooms != null) {
        setSelectedValues(selectedSearchParams.rooms);
        const bc = JSON.stringify(selectedSearchParams.rooms);
      }
  }, []);

  useEffect(() => {

    const xEl = document.getElementById('cloudlink-chat-overlay-buttons');
    if(xEl != null){
      if(selectExpanded){
      
          if(xEl != null){
          xEl.style.display = 'none';
          }
      }
      else{
          if(xEl != null){
              xEl.style.display = 'block';
          }
      }
  }

    setMobValid(null);
    setValidationMessage(null)

    let accID = null;
    if (accommodationId != null) {
      accID = accommodationId;
    }




    if (tripType == 2) {
      const x = [
        {
          adults: 1,
          children: 3,
        },
        {
          adults: 2,
          children: 3,
        },
        {
          adults: 3,
          children: 2,
        },
        {
          adults: 4,
          children: 1,
        },
      ];

      setPaxData(x);
      setLoading(false);
    } else {


      let dep = null;
      if(checkDate){
        dep = selectedSearchParams.departureDate;
      }

      setLoading(true);
      availablePax(
        userToken,
        airports,
        duration,
        dep || null,
        selectedSearchParams.departureDateType,
        destinations,
        regions,
        resorts,
        boards,
        ratings,
        tripRatings,
        selectedSearchParams?.searchType || state.option,
        accID,
      ).then((d) => {
        setPaxData(d.available_pax.result);
        setLoading(false);
      });
    }
  }, [selectExpanded]);

  useEffect(() => {
    if (clickedSearchButtonItemName === 'Rooms' && !selectExpanded) {
      if(resetItemType){
        resetItemType('');
      }
      setSelectExpanded(true);
    }
  }, [clickedSearchButtonItemName]);


  const searchBarInputFieldRef = useRef<HTMLDivElement>(null);

  const handleApplyValues = (room: SearchSelectionRoom, id: number) => {
    const x = selectedValues;
    x[id] = room;
    setSelectedValues(x);
  };

  const applyBeforeChanges = (selectedRooms: SearchSelectionRoom[]) => {

    const x = JSON.stringify(selectedRooms);
    sessionStorage.setItem('roomSelections', x);
  };


  const handleCancelButtonClick = () => {

    let beforeChanges = sessionStorage.getItem('roomSelections');
    if(beforeChanges == null){
      setSelectedValues([{ adults: 2, children: 0, childAges: [] }]);
      setRoomsNumber(1);
      clickApplyHandler([{ adults: 2, children: 0, childAges: []}]);
      setSelectExpanded(false);
      return
    }

    const bc = JSON.parse(beforeChanges) as SearchSelectionRoom[];
    setSelectedValues(bc);
    clickApplyHandler(bc);
    setRoomsNumber(bc.length);
    setSelectExpanded(false);

  };

  const handleCloseButtonClick = () => {
    
    let beforeChanges = sessionStorage.getItem('roomSelections');
    if(beforeChanges == null){
      setSelectedValues([{adults: 2, children: 0, childAges: []}]);
      setRoomsNumber(1);
      clickApplyHandler([{ adults: 2, children: 0, childAges: []}]);
      setSelectExpanded(false);
      return
    }


    const bc = JSON.parse(beforeChanges) as SearchSelectionRoom[];
    setSelectedValues(bc);
    clickApplyHandler(bc);
    setRoomsNumber(bc.length);
    setSelectExpanded(false);

  }
  const getGuestsNumber = () => {
    let adults = 0;
    let children = 0;

    for (const val in selectedValues) {
      adults += selectedValues[val].adults;
      children += selectedValues[val].children;
    }

    return `Adults: ${adults} Children: ${children}`;
  };
  
  const mobileApply = () => {

    let valid = true;
    let msg = ''
    if (Object.keys(selectedValues).length > 0) {
      const rooms = selectedValues;
      // Loop through each room object and add the number of adults and children together
      // If the total is greater than 4 then set valid to false
      rooms.forEach((room: any) => {
        if (room.adults + room.children > 5) {
          valid = false;
          msg = 'You may only have a maximum of 5 guests per room. Please amend your search and try again.'
        }

        room.childAges.forEach((c: any) => {
          if(Number(c) < 0){
            valid = false;
            msg = 'Please make sure all child ages are set.'
          }
        });

      });
    }
    setMobValid(valid);
    setValidationMessage(msg);

    if(valid){

      const x = JSON.stringify(selectedSearchParams.rooms);
      sessionStorage.setItem('roomSelections', x);
      setSelectExpanded(false);
    }

  }
  
  let paxIco = personDefault;
  if(configuration.icon_set == 'blue'){
    paxIco = person;
  }

  const icon = <img src={paxIco} width='22' alt='person' />;


  const modalHeader: ReactNode = (
    <div className='room-header-section'>
      <h5 style={{color: configuration.text_colour}} className='room-header-section-text'>Passengers</h5>
      <span className="room-header-section-close" onClick={() => handleCloseButtonClick()} role="button"></span>
    </div>
  );


  let bc = configuration.button_colour;

  let titleColour =  configuration.button_colour;
  let cancelText = configuration.button_colour;
  let primButtonColour = configuration.button_colour;
  if(configuration.button_icon == 'dark'){
    bc = configuration.border_colour_2;
    titleColour =  configuration.border_colour_2;
  }

  let selStyle = {backgroundColor: configuration.button_colour, borderColor:configuration.border_colour_2};

  if(configuration.spec == '6yk1y'){
    primButtonColour = configuration.button_colour;
    cancelText = '#01447b';
    bc = '#01447b';
    titleColour =  '#01447b';
    selStyle = {backgroundColor: '#01447b', borderColor:configuration.border_colour_2};
  }

  const modalFooter: ReactNode = (
    <div className='room-footer-section'>
      <Button label='Cancel' backgroundColor={primButtonColour} isTransparent onClick={handleCancelButtonClick} />
      <Button label='Apply' backgroundColor={primButtonColour} borderColor={primButtonColour} primary  onClick={() => mobileApply()} />
    </div>
  );

  const deleteRoom = (num: number) => {

    const rooms = selectedValues;
    rooms.splice(num, 1);

 
    setSelectedValues(rooms);
    // clickApplyHandler(rooms);
    setRoomsNumber(rooms.length);
  };

  const addRoom = () => {
    // make sure we set the default to available pax
    const availPaxR = availPax;

    // if we can set it to 2 adults because that's the common

    let newRoom = null;
    if (availPaxR != null) {
      newRoom = {
        adults: availPaxR[0].adults,
        children: 0,
        childAges: [],
      };

      for (const ap of availPaxR) {
        if (ap.adults == 2) {
          newRoom = {
            adults: 2,
            children: 0,
            childAges: [],
          };
          break;
        }
      }
    }

    if (newRoom == null) {
      return;
    }

    const rooms = selectedValues;
    rooms.push(newRoom);
   
    setSelectedValues(rooms);
    // clickApplyHandler(rooms);
    setRoomsNumber(rooms.length);
  };

  let val = true;
  if(validationError != null){
    val = validationError.valid;
  }

  return (
    <>
      {selectExpanded && (
        <div
          onClick={() => {
            handleCloseButtonClick();
          }}
          className='room-select-overlay'
        />
      )}
      <div className='rooms-select-container'>
        <SearchBarInputField
          valid={val}
          title='Passengers'
          value={getGuestsNumber()}
          focus={selectExpanded}
          configuration={configuration}
          onClick={() => {
            setSelectExpanded(!selectExpanded);
            if (onActivate) {
              onActivate();
            }
          }}
          icon={showIcons ? icon : null}
          ref={searchBarInputFieldRef}
        />
        {selectExpanded && (
          <div className='room-select-desktop-view'>
            <RoomSelector
              roomDelete={deleteRoom}
              roomAdd={addRoom}
              handleApply={handleApplyValues}
              setBeforeChanges={applyBeforeChanges}
              selectedValues={selectedValues}
              data={availPax}
              clickApplyHandler={() => {
                clickApplyHandler(selectedValues);
                setSelectExpanded(false);
              }}
            />
          </div>
        )}
      </div>
      {selectExpanded && (
      <div className='room-select-mobile-view'>


    <Modal
          isOpen={true}
        >

        <div className='room-select-mobile-con'>
          {modalHeader}
          <RoomSelector
            roomDelete={deleteRoom}
            roomAdd={addRoom}
            selectedValues={selectedValues}
            handleApply={handleApplyValues}
            data={availPax}
            valid={mobValid}
            validationMessage={validationMessage}
          />
          {modalFooter}
          </div>         
         </Modal>
       </div>  
      )}
    </>
  );
};

export default RoomsSelect;
