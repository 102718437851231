import { LoaderSpinner } from '../Loader/Loader';
import './SectionLoader.scss';

type SectionLoaderProps = {
  title: string;
};

export function SectionLoader({ title }: SectionLoaderProps) {
  return (
    <div className='section-loader'>
      <LoaderSpinner />
      <p>{title}</p>
    </div>
  );
}
