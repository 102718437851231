import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { noop } from 'lodash';
// import { observer } from 'mobx-react';
import { Tabs, TabPanel } from 'react-tabs';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { HolidayPageLoader } from '../../components/HolidayPageLoader/';

import { BaseLayout } from '../../components/BaseLayout';
import {
  AccommodationOffersByMonth,
  availableMonthsHoliday,
  getOfferById,
  offers,
  offerPaymentPlan,
  accommodationAdd
} from '../../hooks/useRequest';

import { PriceDatepicker } from '../../components/PriceDatepicker';
import { HolidaySummary } from '../../components/HolidaySummary';
import { HolidayDetails } from '../../components/HolidayDetails';
import Refine  from '../../components/SearchBar/Refine';
import { Toggle } from '../../components/Toggle/Toggle';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';
import { ModalWithTabs } from '../../components/ModalWithTabs';
import { useModal } from '../../hooks/useModal';

import { SearchSelections, SearchSelectionRoom } from '../../hooks/types'

import { roomsFromPaxString, roomsToString, roomsToStringURL } from '../../services/pax';

import { viewItem } from '../../services/ggle'

import { getBoardParamsString, getRoomsParamsString, getDurationParamString, getDeparturePointsParamsString, getDepartingParamsString, sessionSetSearchParams, sessionGetSearchParams } from '../../services/search';

import { selectItems } from '../../services/ggle';

// Styles
import './HolidayPage.scss';
import { useSearchProvider } from '../../context/search';

import {fbqTrackView, fbqIniCheckout} from '../../hooks/fb'

import { Offer } from '../../hooks/types'

interface AccommodationOffers {
  id: string;
  flights: [
    {
      departing: string;
    },
  ];
  price: number;
  price_per_person: number;
}

interface DatepickerDataStructure {
  [key: string]: number;
}

interface DatepickerIdsStructure {
  [key: string]: string;
}

export function HolidayPage() {
  const {
    RootStore: {
      sessionStore: { createUserSession },
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };



  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlParams = useParams();

  const paramCount = searchParams.size;

  let offerid = ''
  if(searchParams.get('oid') != null){
    offerid = searchParams.get('oid') || ''
  }
  else{
    const x = 500;
  }

  let rooms = searchParams.get('rooms') || '2-';

  let selDeparturePoints: string[] = [];
  const qsDepPoints = searchParams.get('departurePoints');
  if(qsDepPoints != null){
    for(const x of qsDepPoints.split('-')){
        selDeparturePoints.push(x)
    }
  }

  let selBoards: number[] = [];
  const qsBaords = searchParams.get('boards');
  if(qsBaords != null){
    for(const x of qsBaords.split('-')){
      selBoards.push(Number(x))
    }
  }

  let duration = 7;
  if(searchParams.get('duration') != null){
    duration = Number(searchParams.get('duration'));
  }

  let searchType = -1;
  if(searchParams.get('type') != null){
    searchType = Number(searchParams.get('type'));
  }

  let departing = searchParams.get('departing');

  let accid = urlParams.accid

  if(accid == null){
    const a = searchParams.get('accid');
    if(a != null){
      accid = a;
    }
  }



  // const [selectedSearchParams, setSelectedSearchParams] = useState<any>(null);
  // const [selectedRooms, setSelectedRooms] = useState<string>('');
  // const [parsedSearchParams, setParsedParams] = useState<any>(null);

  const [selectedId, setSelectedId] = useState<string>(offerid);

  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);

  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs());

  // const [selectedMonthAndYear, setSelectedMonthAndYear] = useState<string>('');
  const [isTotalPrice, setIsTotalPrice] = useState<boolean>(false);

  const [iniLoad, setIniLoad] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<number>();
  const { state } = useSearchProvider();
  const [availableMonths, setAvailableMonths] = useState<any>();

  const [paymentPlan, setOfferPaymentPlan] = useState<any>();
  // const [accommodationResponse, setAccommodationResponse] = useState<Offer[]>([]);
  const [datepickerData, setDatepickerData] = useState<any>({
    totalPrices: {},
    prices: {},
    ids: {},
  });

  const [monthChangePrices, setMonthChangePrices] = useState<any>([])
  // save an original copy of accomidation that doesn't reload.
  const [accommodationOriginal, setAccommodationOriginal] = useState<any>(false);
  const [isOpen, toggle] = useModal(false);
  const [selectLoading, setSelectLoading] = useState(false);

  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    sessionStorage.removeItem('session_cr');
  }, [])


  useEffect(() => {

    async function ini() {

      if(accid == null){
        return null;
      }

      // if(departing == null){
      //   return null;
      // }

      let strDep = '';
      if(departing != null){
        strDep = departing + ' 00:00';
      }

      const aid = Number(accid);

      let offer;
      if(searchType == -1){
        offer = await offers(userToken, 0, selDeparturePoints,[],[], [], strDep, duration, rooms, 0, [], selBoards, 0, [], [], 0, aid, undefined, 1);

          if(offer.offers.result.length > 0){
            const url_i = getUri(offer.offers.result[0]);
            navigate(`${url_i}?duration=${duration}&departingType=1&type=${0}`, {replace: true});
          }
        
        else{
          offer = await offers(userToken, 1, selDeparturePoints,[],[], [], strDep, 3, rooms, 0, [], selBoards, 0, [], [], 0, aid, undefined, 1);
          const url_i = getUri(offer.offers.result[0]);

 
            if(offer.offers.result.length > 0){
              searchType = 1;
              duration = 3;
              navigate(`${url_i}?duration=${3}&departingType=1&type=${1}`, {replace: true});
            }
          
        }
      }
      else{
        offer = await offers(userToken, searchType, selDeparturePoints,[],[], [], strDep, duration, rooms, 0, [], selBoards, 0, [], [], 0, aid, undefined, 1);
      }



      if(offer.offers.result.length == 0){
        return null;
      }

      const o = offer.offers.result[0];
      setSelectedOffer(o);

      const availMonthsData =await availableMonthsHoliday(userToken, rooms, selDeparturePoints, duration, selBoards, aid, 0);
      setAvailableMonths(availMonthsData.available_months.result);

      const offerDepartureDate = o.flights[0].departing.split(' ')[0] + ' 00:00';
      const gridOfferData = await AccommodationOffersByMonth(userToken, aid, offerDepartureDate, rooms, duration, selDeparturePoints, selBoards);

      const response = gridOfferData.accommodation_offers_by_month && gridOfferData.accommodation_offers_by_month.result;

      const prices = generateDatepickerData(response);
      const combinedTotal = { ...prices.totalPrices };
      const combinedPrices = { ...prices.prices };
      const combinedIds = { ...prices.ids };

      setDatepickerData({ totalPrices: combinedTotal, prices: combinedPrices, ids: combinedIds });         
      
      setAccommodationOriginal(o.accommodation);
      setIniLoad(true);

      accommodationAdd(aid, '', 0);

      const cin = dayjs(o.flights[0].arrival).format('YYYY-MM-DD');
      const cout = dayjs(o.flights[0].return_departing).format('YYYY-MM-DD');

      setSelectedDate(dayjs(o.flights[0].departing))

      updatePricePlan(o.id);

      fbqTrackView(o.accommodation.resort.name, o.accommodation.resort.regions[0].name, o.accommodation.resort.regions[0].destinations[0].name, 
        cin, cout, o.accommodation.id, o.rooms[0].adults, o.rooms[0].children)

      viewItem(o);
    }
    ini();
  }, []);


  // useEffect(() => {
  //   if(monthChangePrices.length == 0){
  //     return;
  //   }
  //   const x = Object.entries(monthChangePrices.prices);
  //   if(x.length > 0){
  //     const newDate = dayjs(x[0][0]);
  //     selectedNewDate(newDate);
  //   }

  // }, [monthChangePrices])

  const getCheapestOfferMonth = async (tryDate: string, departurePoints : string[], duration: number, boards: number[], rooms: string) => {
    const aid = Number(accid);
    let cheapestMonth = null;
    let cheapestOffer = null;
    let offerData = await offers(userToken, searchType, departurePoints,[],[], [], tryDate, duration, rooms, 0, [], boards, 1, [], [], 0, aid, undefined, 1);
    if(offerData.offers.count == 0){
      offerData = await offers(userToken, searchType, departurePoints,[],[], [], null as any, duration, rooms, 0, [], boards, 1, [], [], 0, aid, undefined, 1);
      if(offerData.offers.result.length > 0){
        cheapestMonth = dayjs(offerData.offers.result[0].flights[0].departing).format('YYYY-MM-DD 00:00')
        cheapestOffer = offerData.offers.result[0];
        setShowAlert(true);
      }
    }
    else{
      cheapestMonth = dayjs(offerData.offers.result[0].flights[0].departing).format('YYYY-MM-DD 00:00')
      cheapestOffer = offerData.offers.result[0];
      setShowAlert(false);
    }
    return cheapestOffer;
  }

  const tryFindOfferByDate = (desiredDate: string, offers: Offer[]) => {

    const strDes = dayjs(desiredDate).format('YYYY-MM-DD');

    for(const o of offers){
      const strDep = dayjs(o.flights[0].departing).format('YYYY-MM-DD');
      if(strDes == strDep){
        return o;
      }
    }

    return offers[0]
  }

  const changeMonth = async (value: any) => {

      if(selectedOffer == null){
        return
      }
      setSelectLoading(true);

      const parseDate = dayjs(value).format('YYYY-MM-01 00:00')
      const response = await setGrid(userToken, selectedOffer, parseDate, selDeparturePoints, rooms, duration, selBoards, generateDatepickerData, setDatepickerData, setMonthChangePrices);

      const strRooms = getRoomsParamsString(searchParams, false);
      const strDuration = getDurationParamString(searchParams);
      const strDeparturePoints = getDeparturePointsParamsString(searchParams);
      const strBoards = getBoardParamsString(searchParams);
      
      const uriDate = dayjs(value).format('YYYY-MM-01')
      const url_i = getUri(selectedOffer);

      const offer = await getOfferById(userToken, response[0].id, rooms);

      const o = offer.offer_by_id.result;
      setSelectedOffer(o);
      setSelectedDate(dayjs(o.flights[0].departing))
      await updatePricePlan(o.id);
      setSelectLoading(false);
      navigate(`${url_i}?${strRooms}${strDuration}${strDeparturePoints}${strBoards}&departing=${uriDate}&departingType=1&type=${searchType}`, {replace: true});
  }

  const changeDeparturePoints = async (selectedPoints : string[]) => {

    if(accid == null){
      return null;
    }
    const aid = Number(accid);

    if(selectedOffer == null){
      return
    }

    setSelectLoading(true);
    const strRooms = getRoomsParamsString(searchParams, false);
    const strDuration = getDurationParamString(searchParams);
    const strBoards = getBoardParamsString(searchParams);
    const departing = getDepartingParamsString(searchParams);
    let parseDate = '';
    if(departing != null && departing != ''){
       parseDate = dayjs(departing).format('YYYY-MM-01 00:00');
    }

    let departurePoints = '';
    if(selectedPoints.length > 0){
        departurePoints = '&departurePoints=' + selectedPoints.join('-');
    }

    const response = await setGrid(userToken, selectedOffer, parseDate, selectedPoints, rooms, duration, selBoards, generateDatepickerData, setDatepickerData, setMonthChangePrices);

    const uriDate = dayjs(departing).format('YYYY-MM-01')
    const url_i = getUri(selectedOffer);

    const desOffer = tryFindOfferByDate(selectedOffer.flights[0].departing, response)

    const offer = await getOfferById(userToken, desOffer.id, rooms);
    const o = offer.offer_by_id.result;
    setSelectedOffer(o);
    setSelectedDate(dayjs(o.flights[0].departing))
    await updatePricePlan(o.id);
    setSelectLoading(false);
    navigate(`${url_i}?${strRooms}${strDuration}${departurePoints}${strBoards}&departing=${uriDate}&departingType=1&type=${searchType}`, {replace: true});
  }

  const changeDuration = async (d : number) => {

    if(selectedOffer == null){
      return
    }
    setSelectLoading(true);
    const strRooms = getRoomsParamsString(searchParams, false);
    const departurePoints = getDeparturePointsParamsString(searchParams);
    const strBoards = getBoardParamsString(searchParams);
    const departing = getDepartingParamsString(searchParams);
    let parseDate = '';
    if(departing != null && departing != ''){
       parseDate = dayjs(departing).format('YYYY-MM-01 00:00');
    }


    const response = await setGrid(userToken, selectedOffer, parseDate, selDeparturePoints, rooms, d, selBoards, generateDatepickerData, setDatepickerData, setMonthChangePrices);

    const uriDate = dayjs(departing).format('YYYY-MM-01')
    const url_i = getUri(selectedOffer);

    const desOffer = tryFindOfferByDate(selectedOffer.flights[0].departing, response)

    const offer = await getOfferById(userToken, desOffer.id, rooms);
    const o = offer.offer_by_id.result;
    setSelectedOffer(o);
    setSelectedDate(dayjs(o.flights[0].departing))
    await updatePricePlan(o.id);
    setSelectLoading(false);
    navigate(`${url_i}?${strRooms}${'&duration=' + d}${departurePoints}${strBoards}&departing=${uriDate}&departingType=1&type=${searchType}`, {replace: true});
  }

  const changeBoard = async (bb : number[]) => {

    if(selectedOffer == null){
      return
    }
    setSelectLoading(true);
    const strRooms = getRoomsParamsString(searchParams, false);
    const strDuration = getDurationParamString(searchParams);
    const departurePoints = getDeparturePointsParamsString(searchParams);
    const departing = getDepartingParamsString(searchParams);
    let parseDate = '';
    if(departing != null && departing != ''){
       parseDate = dayjs(departing).format('YYYY-MM-01 00:00');
    }
    
    let strBB = '';
    if(bb.length > 0){
      strBB = '&boards=' + bb.join('-');
    }

    const response = await setGrid(userToken, selectedOffer, parseDate, selDeparturePoints, rooms, duration, bb, generateDatepickerData, setDatepickerData, setMonthChangePrices);

    const uriDate = dayjs(departing).format('YYYY-MM-01')
    const url_i = getUri(selectedOffer);

    const desOffer = tryFindOfferByDate(selectedOffer.flights[0].departing, response)

    const offer = await getOfferById(userToken, desOffer.id, rooms);
    const o = offer.offer_by_id.result;
    setSelectedOffer(o);
    setSelectedDate(dayjs(o.flights[0].departing))
    await updatePricePlan(o.id);
    setSelectLoading(false);
    navigate(`${url_i}?${strRooms}${strDuration}${departurePoints}${strBB}&departing=${uriDate}&departingType=1&type=${searchType}`, {replace: true});
  }

  const changeRooms = async (selectedRooms : SearchSelectionRoom[]) => {

    if(selectedOffer == null){
      return
    }
    setSelectLoading(true);
    const strDuration = getDurationParamString(searchParams);
    const departurePoints = getDeparturePointsParamsString(searchParams);
    const strBoards = getBoardParamsString(searchParams);
    const departing = getDepartingParamsString(searchParams);
    let parseDate = '';
    if(departing != null && departing != ''){
       parseDate = dayjs(departing).format('YYYY-MM-01 00:00');
    }
    const strRooms = roomsToStringURL(selectedRooms);

    const response = await setGrid(userToken, selectedOffer, parseDate, selDeparturePoints, strRooms, duration, selBoards, generateDatepickerData, setDatepickerData, setMonthChangePrices);
    const uriDate = dayjs(departing).format('YYYY-MM-01')
    const url_i = getUri(selectedOffer);

    const desOffer = tryFindOfferByDate(selectedOffer.flights[0].departing, response)

    const offer = await getOfferById(userToken, desOffer.id, rooms);
    const o = offer.offer_by_id.result;
    setSelectedOffer(o);
    setSelectedDate(dayjs(o.flights[0].departing))
    await updatePricePlan(o.id);
    setSelectLoading(false);
    navigate(`${url_i}?${'rooms=' + strRooms}${strDuration}${departurePoints}${strBoards}&departing=${uriDate}&departingType=1&type=${searchType}`, {replace: true});
  }

  const updatePricePlan  = async(offerId: any) => {
    const opd = await offerPaymentPlan(userToken, offerId, rooms);
    if(opd == null){
      setOfferPaymentPlan(null);
      return;
    }
    if(opd.offer_payment_plan.result == null){
      setOfferPaymentPlan(null);
      return;
    }
    setOfferPaymentPlan(opd.offer_payment_plan.result);
  }


  const generateDatepickerData = (offers: any) => {

    const addedDays: any[] = [];
    const datepickerStructure: {
      totalPrices: DatepickerDataStructure;
      prices: DatepickerDataStructure;
      ids: DatepickerIdsStructure;
    } = {
      prices: {},
      ids: {},
      totalPrices: {},
    };
    offers &&
      offers.forEach((offer: AccommodationOffers) => {
        const key: string = offer.flights[0].departing.split(' ')[0];

        if(addedDays.indexOf(key) == -1)
        {
          datepickerStructure.totalPrices[key] = Number(offer['price'].toFixed(0));
          datepickerStructure.prices[key] = Number(offer['price_per_person'].toFixed(0));
          datepickerStructure.ids[key] = offer.id;
          addedDays.push(key);
        }


      });

    return datepickerStructure;
  };

  const onHolidaySelect = () => {

    if(selectedOffer == null){
      return;
    }

    const formattedString = dayjs(selectedDate).format('YYYY-MM-DD');
    sessionStorage.setItem('lastHolidayPage', window.location.href)
    if (datepickerData.ids[formattedString]) {

      // const options: string | null = searchParams.get('params');
      // const strRooms = JSON.stringify(selectedSearchParams.rooms);
      // const params = queryString.stringify({
      //   // eslint-disable-next-line camelcase
      //   offer_id: selectedId,
      //   rooms: strRooms,
      //   dr: selectedSearchParams?.duration?.value
      // });

      // sessionStorage.removeItem('js1');
      // sessionStorage.removeItem('js2');
      // sessionStorage.removeItem('js3');
      // sessionStorage.removeItem('js4');
      // sessionStorage.removeItem('js5');
      // sessionStorage.removeItem('shosnt');

      // create the session here.
      createUserSession(userToken).then((sessionId) => {


        const o = selectedOffer;
        const cin = dayjs(o.flights[0].arrival).format('YYYY-MM-DD');
        const cout = dayjs(o.flights[0].return_departing).format('YYYY-MM-DD');
        fbqIniCheckout(o.accommodation.resort.name, o.accommodation.resort.regions[0].name, o.accommodation.resort.regions[0].destinations[0].name, 
          cin, cout, o.accommodation.id, o.rooms[0].adults, o.rooms[0].children)


        const rms = roomsFromPaxString(rooms) as SearchSelectionRoom[];

        sessionStorage.setItem('departure_code', o.flights[0].departure_airport.code);
        sessionStorage.setItem('arrival_code', o.flights[0].arrival_airport.code);
        sessionStorage.setItem('departing', o.flights[0].departing);
        sessionStorage.setItem('arrival', o.flights[0].arrival);
        sessionStorage.setItem('selectedAccommodationID', o.accommodation.id.toString());
        sessionStorage.setItem('selectedBoard',  o.rooms[0].board.toString());

        const sp = sessionGetSearchParams();
        if(sp != null){
          sp.duration = duration;
          sp.rooms = roomsFromPaxString(rooms)
          sp.searchType = searchType;
          sessionSetSearchParams(sp);
        }
        else{
          const selections: SearchSelections = { 
            duration: 7, destinations: [], regions: [], resorts: [], 
            departurePoints: [], departureDate: null, departureDateType: 0, searchType: 0, ratings: [], tripRatings: [], boards: [], rooms : [{adults: 2, children: 0, childAges: []}]}
  
          selections.duration = duration;
          selections.rooms = roomsFromPaxString(rooms)
          selections.searchType = searchType;
          sessionSetSearchParams(selections);
        }
        navigate(`/select?session=${sessionId}`);
        selectItems([o]);
        
      });
      
    }
  };

  const toggleChanged = (val: boolean) => {
    setIsTotalPrice(val);
  };


  let isRec = false;
  if(accommodationOriginal){
    for(const x of accommodationOriginal.lists){
       if(x == configuration.rec_acc){
        isRec = true;
        break;
       }
    }
  }

  const selectedNewDate = async (d: dayjs.Dayjs) => {

    setSelectLoading(true);
    const formattedString = d.format('YYYY-MM-DD');
    const oid = datepickerData.ids[formattedString];

    const offer = await getOfferById(userToken, oid, rooms);

    const strRooms = getRoomsParamsString(searchParams, false);
    const strDuration = getDurationParamString(searchParams);
    const departurePoints = getDeparturePointsParamsString(searchParams);
    const strBoards = getBoardParamsString(searchParams);
    const parseDate = dayjs(d).format('YYYY-MM-01 00:00')
    
    const uriDate = dayjs(d).format('YYYY-MM-DD')
    const url_i = getUri(offer.offer_by_id.result);


    const o = offer.offer_by_id.result;
    setSelectedOffer(o);
    setSelectedDate(dayjs(o.flights[0].departing))
    await updatePricePlan(o.id);
    setSelectLoading(false);
    navigate(`${url_i}?${strRooms}${strDuration}${departurePoints}${strBoards}&departing=${uriDate}&departingType=1&type=${searchType}`, {replace: true});
  };

  // // stop the images from reloading;
  // useEffect(() => {
  //   // if (images && images.length === 0) {
  //   //   const img = accommodationOriginal.images
  //   //     ? accommodationOriginal.images
  //   //     : [];
  //   //   setImages(img);
  //   // }

  //   // Need to do this on ini load (but not here otherwise we will hit the engine every time the user selects an offer in the grid)

  //   if (selectedOffer?.offer_by_id.result?.flights && !iniLoad) {
  //     // setSelectedMonthAndYear(new Date(flights[0].departing));
  //     setSelectedDate(dayjs(selectedOffer?.offer_by_id.result?.flights[0].departing));
  //     setIniLoad(true);
  //   }
  // }, [selectedOffer]);

  const onLinkClickHandler = (tab: number) => {
    setActiveTab(tab);
    toggle();
  };

  const baseLoader = <BaseLayout userToken={userToken} userConfiguration={configuration} ><div className='holiday-page-layout'><HolidayPageLoader config={configuration} /></div></BaseLayout>;

  // if(selectedSearchParams == null){
  //     return (baseLoader);
  // }  
  
  // if(selectedRooms == null){
  //   return (baseLoader);
  // }

  if(selectedId == null){
    return (baseLoader);
  }

  if(selectedOffer == null){
    return (baseLoader);
  }

  if(!iniLoad){
    return (baseLoader);
  }

  let alertBox = null;
  if(showAlert){
    alertBox = <div className='alert'>
    We've changed the calendar month due to a lack of availability. Use the refine your holiday options to explore alternative options.
  </div>;
  }
  


  return (
    <BaseLayout userToken={userToken} userConfiguration={configuration} >
      <div className='holiday-page-layout'>
          <>
            <Tabs>
              <TabPanel>
                <div className={'holiday-page-wrapper'}>
                  <div className='card-section'>
                    <HolidayDetails
                      isRec={isRec}
                      hotelName={selectedOffer.accommodation.name}
                      rating={Number(selectedOffer.accommodation.rating)}
                      resortName={selectedOffer.accommodation.resort.name}
                      regionName={selectedOffer.accommodation.resort.regions[0].name}
                      destinationName={selectedOffer.accommodation.resort.regions[0].destinations[0].name}
                      tripadvisorRating={selectedOffer.accommodation.trip_advisor_rating}
                      tripadvisorReviews={''}
                      images={selectedOffer.accommodation.images}
                      onLinkClick={onLinkClickHandler}
                    />
                  </div>
                  <div className='main-wrapper'>
                    <div className='component-wrapper'>
                      <p className={'component-wrapper--title'}>Refine your holiday</p>
                      <div className={'component-wrapper--component'}>
                        <Refine
                          accommodationId={selectedOffer.accommodation.id}
                          onDeparturePointChange={(x) => changeDeparturePoints(x)}
                          onDurationChange={(x) => changeDuration(x)}
                          onBoardChange={(x) => changeBoard(x)}
                          onRoomChange={(x) => changeRooms(x)}
                        />
                      </div>
                    </div>
                    <div className='component-wrapper'>
                      <p className={'component-wrapper--title'}>Price Calendar</p>

                        {alertBox}

                      <div className={'component-wrapper--component'}>
                        <div className='holiday-price-datepicker'>

                         

                          <PriceDatepicker
                            searchType={0}
                            value={selectedDate}
                            datePrices={
                              isTotalPrice
                                ? datepickerData.totalPrices
                                : datepickerData.prices
                            }
                            disable={selectLoading}
                            availableMonths={availableMonths}
                            onChange={(val) => selectedNewDate(val)}
                            onMonthChange={(val) => {
                              changeMonth(val);
                            }}
                          />
                          <Toggle
                            firstLabel={'Price Per Person'}
                            secondLabel={'Total Price'}
                            value={isTotalPrice}
                            onToggleChange={(val: boolean) => toggleChanged(val)}
                            backgroundColor={configuration.button_colour_secondary}
                            color={configuration.button_text_colour}
                          />
                          <div className='info-section'>
                            <span className={'cheapest'}>= Cheapest Price</span>
                            <span className='selected'>= Selected</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='summary-section'>
                    <HolidaySummary
                      offer={selectedOffer}
                      selectedSearchParams={searchParams}
                      headerTitle={'Holiday Summary'}
                      isFetchingData={false}
                      pricePlan={paymentPlan}
                      onHolidaySelect={() => onHolidaySelect()}
                    />
                  </div>
                </div>
              </TabPanel>
              {/* <TabPanel> */}
              {/*  <p>Content for Hotel Only</p>*/}
              {/* </TabPanel> */}
            </Tabs>


              <ModalWithTabs
                title='More Information'
                isOpen={isOpen}
                toggle={toggle}
                activeTabNumber={activeTab}
                center={{
                  lat: parseFloat(accommodationOriginal.lat),
                  lng: parseFloat(accommodationOriginal.long),
                }}
                places={[
                  {
                    id: selectedId,
                    title: selectedOffer.accommodation.name,
                    images: selectedOffer.accommodation.images,
                    position: {
                      lat: parseFloat(accommodationOriginal.lat),
                      lng: parseFloat(accommodationOriginal.long),
                    },
                  },
                ]}
                hotelId={selectedOffer.accommodation.id}
                hotelName={selectedOffer.accommodation.name}
                rating={Number(selectedOffer.accommodation.rating)}
                tripadvisorRating={selectedOffer.accommodation.trip_advisor_rating}
                price={selectedOffer.price}
                images={selectedOffer.accommodation.images}
                resortName={selectedOffer.accommodation.resort.name}
                regionName={selectedOffer.accommodation.resort.regions[0].name}
                destinationName={selectedOffer.accommodation.resort.regions[0].destinations[0].name}
              />

          </>
 
      </div>
    </BaseLayout>
  );
}

export default HolidayPage;

function getUri(selectedOffer: Offer) {


  const url_dest = selectedOffer.accommodation.resort.regions[0].destinations[0].name.replaceAll(' ', '-').toLowerCase();
  const url_region = selectedOffer.accommodation.resort.regions[0].name.replaceAll(' ', '-').toLowerCase();
  const url_resort = selectedOffer.accommodation.resort.name.replaceAll(' ', '-').toLowerCase();
  const acc_name = selectedOffer.accommodation.name.replaceAll(' ', '-').toLowerCase();

  const url_i = `/flight-and-hotel/${url_dest}/${url_region}/${url_resort}/${acc_name}/${selectedOffer.accommodation.id}`;

  return url_i;
}

async function setGrid(userToken: string, selectedOffer: Offer, parseDate: string, selDeparturePoints: string[], rooms: string, duration: number, selBoards: number[], generateDatepickerData: (offers: any) => { totalPrices: DatepickerDataStructure; prices: DatepickerDataStructure; ids: DatepickerIdsStructure; }, setDatepickerData: React.Dispatch<any>, setMonthChangePrices: React.Dispatch<any>) {
  const gridOfferData = await AccommodationOffersByMonth(userToken, selectedOffer?.accommodation.id, parseDate, rooms, duration, selDeparturePoints, selBoards);

  const response = gridOfferData.accommodation_offers_by_month && gridOfferData.accommodation_offers_by_month.result;

  const prices = generateDatepickerData(response);
  const combinedTotal = { ...prices.totalPrices };
  const combinedPrices = { ...prices.prices };
  const combinedIds = { ...prices.ids };
  setDatepickerData({ totalPrices: combinedTotal, prices: combinedPrices, ids: combinedIds });
  setMonthChangePrices(prices);
  return response;
}

