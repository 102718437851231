import React, { FunctionComponent, useEffect, useState } from 'react';
import './Menu.scss';
import { IMenuOptions, MenuItem, MenuColor, Icons } from './Interfaces';
import { MenuChild } from '../MenuChild/MenuChild';
import CloseIconWhite from '../assets/close-icon-white.svg';
import { MenuData } from './MenuData';

const Menu: FunctionComponent<IMenuOptions> = ({ items = MenuData.items, onClose }) => {
  const [activeChildMenu, setActiveChildMenu] = useState<Record<number, boolean>>({});

  const [width, setWidth] = useState<number>(window.innerWidth);

  // function handleWindowSizeChange() {
  //   setWidth(window.innerWidth);
  // }
  // useEffect(() => {
  //   window.addEventListener('resize', handleWindowSizeChange);
  //   return () => {
  //     window.removeEventListener('resize', handleWindowSizeChange);
  //   };
  // }, []);

  const isMobile = width <= 575;

  return (
    <div className='Menu--fullscreen-popover'>
      <div className='Menu--container'>
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (onClose) onClose();
          }}
          className='Close--Icon--Popover'
        >
          <img
            onClick={(e) => {
              e.stopPropagation();
              if (onClose) onClose();
            }}
            src={CloseIconWhite}
            width={40}
            height={40}
          />
        </div>
        {items?.map((item: MenuItem, index) => {
          return (
            <div
              className={`Menu--Item-Container  Menu--Item${
                activeChildMenu[index] ? '-child-open' : '-child--closed'
              }`}
              key={`Menu-${item.title}`}
              onClick={() => {
                if (item.onClick) item.onClick();
                if (item.menuChildren) {
                  const active = {
                    [index]: !activeChildMenu[index],
                  };
                  setActiveChildMenu({ ...active });
                }
              }}
            >
              <div
                className={`Menu--Item Menu--Item-${item.color || MenuColor.WHITE} Menu--Item-${
                  item.icon ? 'with-icon' : 'no-icon'
                } Menu--Item${activeChildMenu[index] ? '-child-open' : '-child--closed'}`}
              >
                {item.icon && (
                  <img
                    className='Menu--Item-icon'
                    width={30}
                    height={30}
                    src={item.icon}
                    alt={`${item.title}`}
                  />
                )}
                <span className='Menu--Item-title'>
                  {item.title}
                  {item.menuChildren && (
                    <img
                      className={`Menu--Item-chevron Menu--Item-chevron${
                        activeChildMenu[index] ? '-child-open' : '-child--closed'
                      }`}
                      width={15}
                      height={15}
                      src={activeChildMenu[index] ? Icons.DOWN_ARROW : Icons.DOWN_ARROW_WHITE}
                      alt={`${item.title}`}
                    />
                  )}
                </span>
              </div>
              {activeChildMenu[index] && item.menuChildren && (
                <MenuChild isMobile={isMobile} items={item.menuChildren} />
              )}
              {!item.hideDivider && <div className='Menu--Item-divider' />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { Menu };
