import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import { SearchBarInputField } from '../SearchBarInputField';
import './SearchBar.scss';
import './BoardType.scss';
import { useModal } from '../../hooks/useModal';
import { Modal } from '../Modal';
import { Option } from '../Autocomplete';
import { Button } from '../Button';


import kitchen from '../assets/food.svg';
import kitchenDefault from '../assets/icons/default/05/Food.svg';

import useMultipleMedia from '../../hooks/useMultipleMedia';

import { useOutsideClick } from '../../hooks/useOutsideClick';

import BoardSelector  from '../../components/BoardSelector/BoardSelector';


import { compact } from 'lodash';
import { useSearchProvider } from '../../context/search';
import { MobileAutoComplete } from '../MobileAutoComplete/MobileAutoComplete';
import { RootStore } from '../../stores/RootStore';
import { useStores } from '../../hooks/useStore';

import { SearchSelections } from '../../hooks/types'

import { roomsToString } from '../../services/pax';
import { boardFromID } from '../../services/acc';

interface BoardTypeProps {
  clickApplyHandler: (boards: number[]) => void;
  onActivate?: () => void;
  selectedSearchParams: SearchSelections;
  accommodationId?: number;
  showIcons: boolean;
  checkDate?: boolean;
}

// const testOptions = [
//   { value: 0, label: 'Room Only' }
// ];

const BoardTypeSelect: FunctionComponent<BoardTypeProps> = ({
  clickApplyHandler,
  onActivate,
  selectedSearchParams,
  accommodationId,
  showIcons = false,
  checkDate = true,
}) => {
  // const [isOpen, toggle] = useModal(false);
  
  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  const [options, setOptions] = useState<Option[]>([]);
  const [data, setData] = useState<any>();

  const [selectExpanded, setSelectExpanded] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<number[]>(selectedSearchParams.boards); // TODO Fix typing

  const [selectedCount, setSelectedCount] = useState<number>(selectedSearchParams.boards.length);
  const { state } = useSearchProvider();


  let rooms = JSON.stringify(['2']);
  let duration = 7;
  let accID = 0;
  const airports: string[] = [];
  const destinations: number[] = [];
  const regions: number[] = [];
  const resorts: number[] = [];
  const boards: number[] = [];
  const ratings: number[] = [];
  const tripRatings: number[] = [];

  if (selectedSearchParams != null) {
    if (selectedSearchParams.rooms != null) {
      rooms = roomsToString(selectedSearchParams.rooms);
    }

    if (selectedSearchParams.duration != null) {
      duration = selectedSearchParams.duration;
    }

    if (selectedSearchParams.boards != null) {
      for (const x of selectedSearchParams.boards) {
        boards.push(x);
      }
    }

    if (selectedSearchParams.departurePoints != null) {
      for (const x of selectedSearchParams.departurePoints) {
        airports.push(x);
      }
    }

    if (selectedSearchParams.destinations != null) {
      for (const x of selectedSearchParams.destinations) {
          destinations.push(x);
      }
    }

    if (selectedSearchParams.regions != null) {
      for (const x of selectedSearchParams.regions) {
          regions.push(x);
      }
    }

    if (selectedSearchParams.resorts != null) {
      for (const x of selectedSearchParams.resorts) {
          resorts.push(x);
      }
    }

    if (selectedSearchParams.ratings != null) {
      for (const x of selectedSearchParams.ratings) {
        ratings.push(x);
      }
    }

    if (selectedSearchParams.tripRatings != null) {
      for (const x of selectedSearchParams.tripRatings) {
        tripRatings.push(x);
      }
    }
  }

  let tripType = state.option;
  if (selectedSearchParams?.searchType != null) {
    tripType = selectedSearchParams.searchType;
  }

  if (accommodationId != null) {
    accID = accommodationId;
  }

  const selectorRef = useRef<HTMLDivElement>(null);

  useOutsideClick([selectorRef], () => {
    setSelectExpanded(false);
  })


  useEffect(() => {

    const jh = selectedSearchParams.duration;

  }, [selectedSearchParams.duration]);
  // useEffect(() => {
  //   let accID = null;
  //   if (accommodationId != null) {
  //     accID = accommodationId;
  //   }
  //   let rooms = '2';
  //   let duration = 7;
  //   const airports: any[] = [];
  //   const destinations: number[] = [];
  //   const regions: number[] = [];
  //   const resorts: number[] = [];
  //   const boards: any[] = [];
  //   const ratings: any[] = [];
  //   const tripRatings: any[] = [];

  //   const departureDate = getDepartureDateFromParams(selectedSearchParams, 'value');
  //   const departureType = getDepartureDateFromParams(selectedSearchParams, 'label');

  //   // if (selectedSearchParams != null) {
  //   //   if (selectedSearchParams.rooms != null) {
  //   //     rooms = JSON.stringify(roomsToString(selectedSearchParams.rooms));
  //   //   }

  //   //   if (selectedSearchParams.duration != null) {
  //   //     duration = selectedSearchParams.duration.value;
  //   //   }

  //   //   if (selectedSearchParams.boardType != null) {
  //   //     for (const x of selectedSearchParams.boardType) {
  //   //       boards.push(x.value);
  //   //     }
  //   //   }

  //   //   if (selectedSearchParams.airports != null) {
  //   //     for (const x of selectedSearchParams.airports) {
  //   //       airports.push(x.value);
  //   //     }
  //   //   }

  //   //   if (selectedSearchParams.destinations != null) {
  //   //     for (const x of selectedSearchParams.destinations) {
  //   //       if (x.type == 'destination') {
  //   //         destinations.push(x.value);
  //   //       } else if (x.type == 'region') {
  //   //         regions.push(x.value);
  //   //       } else {
  //   //         resorts.push(x.value);
  //   //       }
  //   //     }
  //   //   }

  //   //   if (selectedSearchParams.ratings != null) {
  //   //     for (const x of selectedSearchParams.ratings) {
  //   //       ratings.push(x.value);
  //   //     }
  //   //   }

  //   //   if (selectedSearchParams.trip_ratings != null) {
  //   //     for (const x of selectedSearchParams.trip_ratings) {
  //   //       tripRatings.push(x.value);
  //   //     }
  //   //   }
  //   // }

  //   availableBoards(
  //     userToken,
  //     rooms,
  //     airports,
  //     duration,
  //     ratings,
  //     tripRatings,
  //     departureDate,
  //     departureType,
  //     regions,
  //     resorts,
  //     destinations,
  //     selectedSearchParams.type || state.option,
  //     accID,
  //   ).then((d) => {
  //     setData(d);
  //     if (selectedSearchParams.boardType != null) {
  //       setSelectedValues(selectedSearchParams.boardType);
  //     }
  //   });

  //   // if (selectedSearchParams.boardType != null) {
  //   //   handleApplyValues(selectedSearchParams.boardType);
  //   // }
  // }, [selectedSearchParams]);

  // useEffect(() => {
  //   if (data == null) {
  //     return;
  //   }
  //   const bb = [];
  //   for (const x of data.available_boards.result) {
  //     let lbl = '';
  //     const bbCode = x.result;
  //     lbl = boardFromID(bbCode);
  //     bb.push({ value: bbCode, label: lbl });
  //   }
  //   setOptions(bb);
  // }, [data]);

  const isMobile = useMultipleMedia(['(max-width: 768px)'], [true], false);
  const searchBarInputFieldRef = useRef<HTMLDivElement>(null);

  const handleApplyValues = (selectedBoards: any) => {
    setSelectedValues(selectedBoards);
    clickApplyHandler(selectedBoards);
    setSelectedCount(selectedBoards.length)
  };

  const handleClose = () => {
    setSelectExpanded(false);
  }

  let kitchenIco = kitchenDefault;
  if(configuration.icon_set == 'blue'){
    kitchenIco = kitchen;
  }


  const icon = <img src={kitchenIco} width='22' alt='arrow-down' />;

  const modalHeader = (
    <div className='modal-mobile-header'>
      <h5 className='modal-mobile-header-text'>Board Type</h5>
    </div>
  );

  const modalFooter = (
    <div className='modal-mobile-footer'>
      <Button
        label='Cancel'
        isTransparent
        onClick={() => {
          setSelectExpanded(false);
        }}
        backgroundColor={configuration.button_colour}
      />
      <Button
        label='Apply'
        primary
        onClick={() => {
          handleApplyValues(selectedValues);
          setSelectExpanded(false);
        }}
        backgroundColor={configuration.button_colour}
      />
    </div>
  );

  const getSelectedValuesLabel = () => {
    const selectedValuesLength = selectedValues && selectedValues.length;

    if (!selectedValuesLength) {
      return 'Any';
    }

    if (selectedValuesLength === 1) {
      return `${boardFromID(selectedValues[0])}`;
    }

    return `${boardFromID(selectedValues[0])} and ${selectedValuesLength - 1} more options`;
  };

  let dep = null;
  if(checkDate){
    dep = selectedSearchParams.departureDate;
  }

  return (
    <>
      {selectExpanded && (
        <div
          onClick={() => {
            setSelectExpanded(false);
          }}
          className='board-select-overlay'
        ></div>
      )}
      <div ref={selectorRef} className='search-bar-input-field-autocomplete board-type-wrapper'>
        <SearchBarInputField
          title='Board Type'
          value={getSelectedValuesLabel()}
          onClick={() => {
            setSelectExpanded(!selectExpanded);

            if (onActivate) {
              onActivate();
            }
          }}
          icon={showIcons ? icon : null}
          focus={selectExpanded}
          configuration={configuration}
          ref={searchBarInputFieldRef}
          selectedCount={selectedCount}
        />

        <BoardSelector isOpen={selectExpanded} rooms={rooms} 
        selectedDeparturePoints={selectedSearchParams.departurePoints} 
        duration={duration}
      selectedBoards={boards} ratings={ratings} tripRatings={tripRatings} departureDate={dep}
      departureType={selectedSearchParams.departureDateType} tripType={selectedSearchParams.searchType} selectedDestinations={destinations} accommodationID={accommodationId} 
      selectedRegions={regions} selectedResorts={resorts} 
      onChange={(option) => handleApplyValues(option)} 
      onClose={() => handleClose()}    />
       

      </div>
    </>
  );
};

export default BoardTypeSelect;
