import { FunctionComponent, useState, useEffect } from 'react';
// import { IBasket } from './Interfaces';
// import { ImageCarousel } from '../ImageCarousel';
import './Basket.scss';
import TripAdvisorLogo from '../assets/tripadvisor-logo.png';
import { Rating } from '../HolidayCard/Rating';
import dayjs from 'dayjs';
import { FlightDetails } from '../HolidaySummary/FlightDetails';
import { OfferSupplement, PackageRoom, Product } from '../../hooks/types';
import { useSearchParams } from 'react-router-dom';
import { PaymentOptions } from '../PaymentOptions';
import { uniqueId } from 'lodash';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { removeBasketDiscount } from '../../hooks/useRequest';
import { ImageSlider } from '../ImageSlider/ImageSlider';


import luggage from '../assets/suitcase.svg';
import luggageDefault from '../assets/icons/default/03/Handluggage.svg';

import bag from '../assets/bag.svg';
import bagDefault from '../assets/icons/default/03/Bag.svg';

import bus from '../assets/bus.svg';
import busDefault from '../assets/icons/default/03/Transfer.svg';

import atol from '../assets/atol.svg';
import atolDefault from '../assets/icons/default/03/Atol.svg';

import { sessionGetSearchParams } from '../../services/search';
import { adultCountFromRooms, childCountFromRooms, childCountFromRoomsExInfants } from '../../services/pax';

import { Basket as BasketData, Accommodation, Package, SearchSelectionRoom } from '../../hooks/types';


interface IPrice {
  price: number;
}
const RenderPrice: FunctionComponent<IPrice> = ({ price }) => {


  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  return (
    <div className='basket-standout-price-container'>
      <span className='basket-standout-price-title'>Total price:</span>
      <span style={{color: configuration.jrny.basket.price_colour}} className='basket-standout-price'>£{price.toFixed(2)}</span>
    </div>
  );
};


interface PackageBasketProps {
  sessionId: string;
  pricePlan: any;
  children?: any;
  basketData: BasketData;
  selectedPackage: Package;
  selectedRooms: PackageRoom[];
  selectedAccommodation: Accommodation;
  searchedRooms: SearchSelectionRoom[];
}

export const PackageBasket: FunctionComponent<PackageBasketProps> = ({
  sessionId,
  pricePlan = null,
  selectedPackage,
  selectedRooms,
  selectedAccommodation,
  children,
  basketData,
  searchedRooms
}) => {


  const [searchParams] = useSearchParams();
  const [showMore, setShowMore] = useState<boolean>(false);

  const [basket, setBasket] = useState<BasketData>(basketData);

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };


  useEffect(() => {
    setBasket(basketData);
  }, [basketData]);


  const formatToDate = (outboundDate: string, inboundDate: string): string => {
    const format = 'ddd DD MMM YYYY';
    const inboundDateFormat = dayjs(inboundDate).format(format);
    const outboundDateFormat = dayjs(outboundDate).format(format);
  
    const dr = searchParams.get('dr');
    const drN = Number(dr);

    return `${outboundDateFormat} - ${inboundDateFormat} (${selectedPackage.duration} night${
      selectedPackage.duration  > 1 ? 's' : ''
    })`;
  };

  
  const roomSections = () => {
    const roomSecs = [];
    for (const sr of selectedRooms) {

      let childrenCount = sr.children + sr.infants;
      let childStr = '';
      if (childrenCount > 0) {
        if (childrenCount == 1) {
          childStr = '1 child';
        } else {
          childStr = childrenCount + ' children';
        }
      }
      roomSecs.push(
        <div style={{color: configuration.text_colour, borderColor: configuration.jrny.basket.border_colour}} className='basket-rooms__room' key={`room-${sr.room_number}`}>
          <b className='basket-rooms__room-title'>Room {sr.room_number}</b>
          <p>
            {sr.adults} Adults {childStr} - {sr.description}
          </p>
          <p>{sr.board_description}</p>
        </div>,
      );
    }

    return roomSecs;
  }


  const flightSections = () => {

    if(selectedPackage.flight == null){
      return null;
    }

    return( <>
    {<div style={{borderColor: configuration.jrny.basket.seperator_colour}} className='basket-divider'></div>}
    <div className='basket-inbound-flights'>    
          <FlightDetails
            flightType={'Outbound'}
            airline={selectedPackage.flight.airline}
            flightNumber={selectedPackage.flight.outbound_number}
            departureTime={selectedPackage.flight.outbound_departure}
            departurePlace={selectedPackage.flight.departure_point.name}
            arrivalPlace={selectedPackage.flight.arrival_airport.name}
            arrivalTime={selectedPackage.flight.outbound_arrival}
            outBound={true}
            config={configuration}
          />
    </div>
    {<div style={{borderColor: configuration.jrny.basket.seperator_colour}} className='basket-divider'></div>}
    <div className='basket-inbound-flights'>
          <FlightDetails
            flightType={'Inbound'}
            airline={selectedPackage.flight.airline}
            flightNumber={selectedPackage.flight.inbound_number}
            departureTime={selectedPackage.flight.inbound_departure}
            departurePlace={selectedPackage.flight.arrival_airport.name}
            arrivalPlace={selectedPackage.flight.departure_point.name}
            arrivalTime={selectedPackage.flight.inbound_arrival}
            outBound={false}
            config={configuration}
          />
    </div>
  </>)
  }


  let isPackage = false;
  let packageDiscount = 0;
  if(basket != null){
    for(const x of basket.products){
      if(x.product_type.id == 5){
        isPackage = true;

        if(x.promo_discount > 0){
          packageDiscount = x.promo_discount;
        }

        break;
      }
  
    }
  }

  let tripDiv = null;
  if(selectedAccommodation.trip_advisor_rating != 0){
    tripDiv =   <div>
    <img className='basket-rating-trip-logo' src={TripAdvisorLogo} height={15} width={20} />
    <Rating type='circle' rating={selectedAccommodation.trip_advisor_rating || -1} />
    </div>;
  }

  let packageDiscSec = null;
  let pak = null;


    const adultCount = adultCountFromRooms(searchedRooms);
    const childCount = childCountFromRooms(searchedRooms);
    const travellerCount = adultCount + childCount;

    const childCountExInfs = childCountFromRoomsExInfants(searchedRooms);
    const travellerExInfs = adultCount + childCountExInfs;
    const perPerSonPrice = basket.price / travellerExInfs;
 
    if(packageDiscount > 0){
      packageDiscSec = <div className='basket-discounts'>
          <div className='basket-discount'>
            <p className='basket-discount__label'>
              Promo Code Applied:
              <br />
              <span className='basket-discount__code'>Rain Cheque</span> 
            </p>

            <p className='basket-discount__amount'>
              {`-£${packageDiscount.toFixed(2)}`}
            </p>
          </div>

    </div>
    }


    let luggageIco = luggageDefault;
    let bagIco = bagDefault;
    let busIco = busDefault;
    let atolIco = atolDefault;
    if(configuration.icon_set == 'blue'){
      luggageIco = luggage;
      bagIco = bag;
      busIco = bus;
      atolIco = atol;
    }
    pak = <div className=''>

    <div className='basket-extras_package_extra'>
      <img src={bagIco} alt='' />
      <div className='basket-extras_package_extra-item'>
        <span>
          {travellerExInfs
            ? `(${travellerExInfs}) 22kg Bag Allowance`
            : '22kg Bag Allowance'}
        </span>
      </div>
    </div>

    <div className='basket-extras_package_extra'>
      <img src={luggageIco} alt='' />
      <div className='basket-extras_package_extra-item'>
        <span>
          {travellerExInfs
            ? `(${travellerExInfs}) 10kg Hand Baggage`
            : '10kg Hand Baggage'}
        </span>
      </div>
    </div>

    <div className='basket-extras_package_extra'>
      <img src={busIco} alt='' />
      <div className='basket-extras_package_extra-item'>
        <span>Coach Transfers</span>
      </div>
    </div>

    <div className='basket-extras_package_extra'>
      <img src={atolIco} alt='' />
      <div className='basket-extras_package_extra-item'>
        <span>ATOL Protection</span>
      </div>
    </div>

  </div>
  // }


  const arate = Number(selectedAccommodation.rating);

  let dateSec = formatToDate(selectedPackage.flight.outbound_departure, selectedPackage.flight.inbound_departure)
  

  const selectedRoomsSection = roomSections();

  return (
    <>
      <div style={{color: configuration.text_colour, borderColor: configuration.jrny.basket.border_colour}} className='basket-container'>
        {/* <ImageCarousel images={destination.images || []} /> */}
        <ImageSlider images={selectedAccommodation.images || []} />

        <h4 className='basket-title'>{selectedAccommodation.name}</h4>
        <div className='basket-subtitle'>{selectedAccommodation.resort.regions[0].destinations[0].name}, {selectedAccommodation.resort.regions[0].name}, {selectedAccommodation.resort.name}</div>

        <div className='basket-container-rating'>
          <div>
            <Rating type='star' rating={arate} />
          </div>
          {tripDiv}
        </div>

        <div className='basket-container-date-formatted basket-container-generic-text'>
          {dateSec}
        </div>

        {/* <div className='basket-container-generic-text'>{destination.roomType}</div>
        <div className='basket-container-generic-text'>{basket.board?.type}</div> */}


        <div className='basket-container-generic-text basket-container-generic-lastchild'>
          <div className='basket-rooms'>{selectedRoomsSection}</div>
        </div>

        {flightSections()}

        
        <div className='basket-extras'>
          {pak}

        </div>



        {basket && basket.discounts?.length > 0 && (
          <div className='basket-discounts'>
            {basket.discounts.map((discount: any) => {
              const type = discount.type === 0 ? 'amount' : 'percent';

              return (
                <div key={uniqueId()} className='basket-discount'>
                  <p className='basket-discount__label'>
                    Promo Code Applied:
                    <br />
                    <span className='basket-discount__code'>({discount.code})</span>{' '}
                  </p>
                  <p style={{color: configuration.jrny.basket.price_colour}} className='basket-discount__amount'>
                    {`-£${discount.calculated_amount.toFixed(2)}`}
                  </p>
                </div>
              );
            })}
          </div>
        )}

  
        {/* Show the price per person */}
        {basket.price_per_person && (
          <div className='basket-standout-price-container'>
            <span className='basket-standout-price-title basket-standout-price-title-large'>
              Price per person
            </span>
            <span style={{color: configuration.jrny.basket.price_colour}} className='basket-standout-price basket-standout-price-xl'>
              £{perPerSonPrice.toFixed(2)}
            </span>
          </div>
        )}

        {basket.price && (
          <>
            <RenderPrice price={basket.price} />

            <div className='basket-payment-options'>
              <PaymentOptions
                depositPrice={basket.deposit_price}
                depositPerPerson={basket.deposit_price_per_person}
                depositDue={basket.deposit_due}
                pricePlan={pricePlan}
                live={true}
              />
            </div>
          </>
        )}

        <div className='basket-standout-price-container'>
          <div style={{color: configuration.jrny.basket.reference_colour}} className='basket-container-center'>
            Reference : {basket.customer_reference}
          </div>
        </div>

        {children && <div style={{ padding: '20px 10px' }}>{children}</div>}
      </div>
    </>
  );
};
