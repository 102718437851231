import React, { FunctionComponent } from 'react';
import './HolidayPageLoader.scss';


interface LoaderProps {
  config?: any
}

const HolidayPageLoader: FunctionComponent <LoaderProps> = ({config=null}) => {

  const sbc = {borderColor: config.skeleton_colour};
  const sbg = {backgroundColor: config.skeleton_colour, borderColor: config.skeleton_colour};


  const RefineSelect = () => (
    <div className='select-section gradient-animation'>
      <div style={sbg} className='select-option'></div>
      <div style={sbg} className='select-option'></div>
      <div style={sbg} className='select-option'> </div>
      <div style={sbg} className='select-option'></div>
    </div>
  );

  const FakeHoliday = () => (
    <div className='gradient-animation'>
      <div style={sbg} className='fake-paragraph fake-paragraph-s'></div>
      <div style={sbg} className='fake-paragraph fake-paragraph-m'></div>
      <div style={sbg} className='fake-paragraph fake-paragraph-m'></div>
      <div style={sbg} className='fake-image'></div>
      <div style={sbg} className='one-line-fake-paragraph'>
        <div style={sbg} className='fake-paragraph fake-paragraph-s'></div>
        <div style={sbg} className='fake-paragraph fake-paragraph-xs'></div>
        <div style={sbg} className='fake-paragraph fake-paragraph-xs'></div>
      </div>
    </div>
  );


  return(<div className='holiday-page-layout'>
    <div className='holiday-page-loader'>
    <div className={'holiday-page-wrapper'}>
      <div className='card-section'>
        <FakeHoliday />
      </div>

      <div className='main-wrapper'>
        <div className='component-wrapper'>
          <div className={'component-wrapper--component'}>
          <div style={{width:'200px', marginLeft:'0px'}} className='fake-paragraph fake-paragraph-m'></div>
              <RefineSelect />
          </div>
        </div>      
        <div className='component-wrapper'>
          <div className={'component-wrapper--component'}>
          <div style={{width:'200px', marginLeft:'0px', marginTop:'16px'}}  className='fake-paragraph fake-paragraph-m'></div>
              <div style={sbg} className='fake-calendar'></div>
          </div>
        </div>
      </div>


      <div className='summary-section' style={{marginTop: '30px'}}>
          <div className='holiday-card-container' style={{width: '100%', height: '500px'}}></div>
      </div>

    </div>
    </div>
  </div>)
};

export default HolidayPageLoader;
