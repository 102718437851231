import React, { FunctionComponent } from 'react';
import '../../pages/PackagePage/PackagePageLoader.scss';

interface LoaderProps {
  config?: any
}




const PackagePageLoader: FunctionComponent <LoaderProps> = ({config=null}) => {

  const sbc = {borderColor: config.skeleton_colour};
  const sbg = {backgroundColor: config.skeleton_colour, borderColor: config.skeleton_colour};


  return(

        <>

          <div className='pak-fake gradient-animation'>
        
         

              <div style={{ width: '100%' }}>
            <div style={sbg} className='fake-heading' />
            <div style={sbg} className='fake-paragraph fake-paragraph-l'></div>
            <div style={sbg} className='fake-paragraph fake-paragraph-m'></div>
            <div style={sbg} className='fake-paragraph fake-paragraph-s'></div>
            </div>

      

            <div style={sbg} className='fake-image' />


            <div style={{borderColor: config.border_colour_2}} className='package-page-hotel-detail'>
              
                <div style={sbc} className='package-page-hotel-detail fake-detail'>
                <div style={sbg} className='fake-heading' />
                <div style={sbg} className='fake-paragraph' />
                <div style={sbg} className='fake-paragraph' />
                <div style={sbg} className='fake-paragraph' />
                <div style={sbg} className='fake-paragraph' />
                <div style={sbg} className='fake-paragraph fake-paragraph-xl' />

                <div className='fake-detail__spacer' />

                <div style={sbg} className='fake-paragraph' />
                <div style={sbg} className='fake-paragraph' />
                <div style={sbg} className='fake-paragraph' />
                <div style={sbg} className='fake-paragraph' />
                <div style={sbg} className='fake-paragraph fake-paragraph-xl' />

                <div style={sbg} className='fake-detail__divider' />

              </div>


            </div>
          </div>

          <div className='summary-section gradient-animation' style={{marginTop: '30px'}}>
          <div className='load-holiday-card-container' style={{width: '100%', height: '700px'}}></div>
      </div>

          </>
     
  )


};

export default PackagePageLoader;
