import queryString from 'query-string';
import dayjs from 'dayjs';
import { noop } from 'lodash';
import { useEffect, useState } from 'react';
import Map from '../../components/MapModal/Map';

import { BaseLayout } from '../../components/BaseLayout';
import { getPackageDetails, packagePaymentPlan, accommodationAdd, addProducts } from '../../hooks/useRequest';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { Rating } from '../../components/HolidayCard/Rating';
import { TripadvisorReviews } from '../../components/Tripadvisor';

import tripLogo from '../../components/assets/tripadvisor.svg';

import hotelIcon from '../../components/assets/hotel.svg';
import hotelIconDefault from '../../components/assets/icons/default/03/Hotel.svg';

import locationIcon from '../../components/assets/location.svg';
import locationIconDefault from '../../components/assets/icons/default/03/Map.svg';

import tripadvisorIcon from '../../components/assets/tripadvisor_logo.svg';

import { ImageSlider } from '../../components/ImageSlider/ImageSlider';

import '../../components/HolidayCard/NameSection/NameSection.scss';
import './PackagePage.scss';
import { TabsData } from '../../components/ModalWithTabs/ModalWithTabs';
import SimpleTabs from '../../components/ModalWithTabs/SimpleTabs';
import PackagePageLoader from './PackagePageLoader';
import { useSearchParams, useNavigate } from 'react-router-dom';

import {fbqTrackView, fbqIniCheckout} from '../../hooks/fb';

import { Package } from '../../hooks/types';

import { packgeProductIds } from '../../services/search';

import PackageHolidaySummary from '../../components/HolidaySummary/PackageHolidaySummary';

type PackagePageProps = {
  onLogin?: () => void;
  openMenu?: () => void;
};

export function PackagePage({ onLogin = noop, openMenu = noop }: PackagePageProps) {

  const [searchParams] = useSearchParams();

  const s = searchParams.get('session');
  const id = searchParams.get('package');
  const selectedProds = packgeProductIds(searchParams);

  const [sessionId] = useState<string>(s || '');

  const [loading, setLoading] = useState<boolean>(true);
  const [holidayPackage, setHolidayPackage] = useState<Package>();
  const [activeTabNumber, setActiveTab] = useState<number>(0);

  const [paymentPlan, setPackagePayPlan] = useState<any>();

  const navigate = useNavigate();

  const {
    RootStore: {
      userToken,
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  useEffect(() => {
    
    // const loadPackage = async () => {
    //   if (!userSession.sessionId) {
    //     const browserSession = sessionStorage.getItem('session_id');

    //     if (!browserSession) {
    //       await createUserSession(userToken);
    //     } else {
    //       setUserSession(browserSession);
    //     }
    //   }

    //   await fetchPackage();
    // };

    const fetchPackage = async () => {

      sessionStorage.removeItem('session_cr');

      if (sessionId && id) {
        const response = (await getPackageDetails(
          userToken,
          sessionId,
          parseInt(id),
          selectedProds,
        ));

        if (response?.package.error) {
          alert('Unable to load package..');
          return console.log('Unable to load package..', response.package.error);
        }

        setHolidayPackage(response.package.result);

        let totaldepPrice = 0;
        let totalRmPrice = 0;
        for (const r of response.package.result.rooms) {
          totalRmPrice += r.price;
          totaldepPrice += r.deposit_price;
        }

        const pakPayPlan = (await packagePaymentPlan(
          userToken,
          sessionId,
          parseInt(id),
          selectedProds,
        )) as any;

        if (pakPayPlan != null) {
          setPackagePayPlan(pakPayPlan.package_payment_plan.result);
        }

        let localSesion = sessionStorage.getItem('local_session')
        if(localSesion == null){
          localSesion = crypto.randomUUID();
        }

        accommodationAdd(response.package.result.accommodation.id, localSesion, 2);
        
        const o = response.package.result;
        const cin = dayjs(o.flight.outbound_arrival).format('YYYY-MM-DD');
        const cout = dayjs(o.flight.inbound_departure).format('YYYY-MM-DD');
        fbqTrackView(o.accommodation.resort.name, o.accommodation.resort.regions[0].name, o.accommodation.resort.regions[0].destinations[0].name, 
          cin, cout, o.accommodation.id, o.rooms[0].adults, o.rooms[0].children)

      }

      setLoading(false);
    };

    fetchPackage();
  }, []);


  const selectOffer = async() => {

    // sessionStorage.removeItem('js1');
    // sessionStorage.removeItem('js2');
    // sessionStorage.removeItem('js3');
    // sessionStorage.removeItem('js4');
    // sessionStorage.removeItem('js5');
    // sessionStorage.removeItem('shosnt');

    const o = holidayPackage;
    if(o != null){

      sessionStorage.setItem('selectedAccommodationID', o.accommodation.id.toString())

      sessionStorage.setItem('selectedPackageProds', selectedProds.join('-'))

      const cin = dayjs(o.flight.outbound_arrival).format('YYYY-MM-DD');
      const cout = dayjs(o.flight.inbound_departure).format('YYYY-MM-DD');
      fbqIniCheckout(o.accommodation.resort.name, o.accommodation.resort.regions[0].name, o.accommodation.resort.regions[0].destinations[0].name, 
        cin, cout, o.accommodation.id, o.rooms[0].adults, o.rooms[0].children)
    }

    if(id != null){
      sessionStorage.setItem('selectedPackageID', id.toString());
    }

    navigate(`/package-select/?session=${sessionId}`);
  };


  const TARatings = ({
    tripAdvisor,
    selectedPackage,
  }: {
    tripAdvisor: string;
    selectedPackage: Package;
  }) => {
    if (tripAdvisor === '') {
      return <p>There are no reviews for this hotel.</p>;
    }

    const tripData = JSON.parse(tripAdvisor);

    return (
      <TripadvisorReviews
        hotelId={selectedPackage.accommodation.id}
        hotelName={selectedPackage.accommodation.name}
        tripadvisorRating={parseFloat(tripData.rating)}
      />
    );
  };

  const Ratings = ({ tripAdvisor, hotelRating }: { tripAdvisor: string; hotelRating: number }) => {

    if(tripAdvisor == ''){
      return null;
    }
    
    const tripData = JSON.parse(tripAdvisor);

    return (
      <div className='package-page-ratings'>
        <div className='tripadvisor-rating'>
          <img src={tripLogo} alt='' className='trip-logo' />
          <Rating type={'circle'} rating={tripData.rating} />
          <span className='review-details'>{tripData.num_reviews} reviews</span>
        </div>

        <div className='rating'>
          <Rating type='star' rating={hotelRating} />
        </div>
      </div>
    );
  };

  const ShowLoading = () => {
    return(<BaseLayout userToken={userToken} userConfiguration={configuration} >
      <div className='package-page'><div className='package-page-wrapper'>
      <PackagePageLoader config={configuration} />
        </div></div></BaseLayout>)
  }

  if(holidayPackage == null){
    return ShowLoading();
  } 

  if(loading){
    return ShowLoading();
  }

  let hotelIco = hotelIconDefault;
  let mapIco = locationIconDefault
  if(configuration.icon_set == 'blue'){
    hotelIco = hotelIcon;
    mapIco = locationIcon;
  }

  const tabs = [
    { name: 'Hotel Information', icon: hotelIco },
    { name: 'Map', icon: mapIco },
    { name: 'Tripadvisor', icon: tripadvisorIcon },
  ];

  const HotelInformationContent = (
    <>
      <h2 className='package-page-title'>Description</h2>

      <div className='package-page-hotel-detail__copy'>{holidayPackage.accommodation.detail}</div>

      <div className='package-page-hotel-divider' />

      {/* <div>
        <h2>Facilities</h2>

        <ul className='package-page-hotel-facilities-list'>
          <li>24-hour front desk</li>
          <li>Free Wi-Fi</li>
          <li>Swimming pool</li>
          <li>Restaurant</li>
          <li>Fitness center</li>
          <li>Spa</li>
          <li>Parking</li>
          <li>Room service</li>
          <li>Bar/lounge</li>
          <li>Business center</li>
          <li>Concierge service</li>
          <li>Laundry service</li>
          <li>Conference facilities</li>
          <li>Airport shuttle</li>
          <li>Childcare services</li>
          <li>Tennis court</li>
          <li>Golf course</li>
          <li>Currency exchange</li>
          <li>Gym</li>
          <li>Banquet hall</li>
          <li>Indoor pool</li>
          <li>Outdoor pool</li>
          <li>Jacuzzi</li>
          <li>Sauna</li>
          <li>Gift shop</li>
          <li>Beauty salon</li>
          <li>Babysitting</li>
          <li>Car rental</li>
          <li>Valet parking</li>
          <li>Convenience store</li>
        </ul>
      </div> */}
    </>
  );

  const MapContent = (
    <Map
      hideInfo
      shouldShowInfoBox={false}
      key={holidayPackage?.accommodation.id}
      places={[
        {
          id: holidayPackage?.accommodation.id ? holidayPackage?.accommodation.id.toString() : '',
          title: holidayPackage?.accommodation.name || '',
          images: holidayPackage?.accommodation.images || [],
          position: {
            lat: parseFloat(holidayPackage?.accommodation.lat || ''),
            lng: parseFloat(holidayPackage?.accommodation.long || ''),
          },
        },
      ]}
      center={{
        lat: parseFloat(holidayPackage?.accommodation.lat || ''),
        lng: parseFloat(holidayPackage?.accommodation.long || ''),
      }}
      hotelName={holidayPackage?.accommodation.name || ''}
      hotelLocation={holidayPackage?.accommodation.resort.name}
      rating={holidayPackage?.accommodation.rating
      }
      // tripadvisorRating={tripadvisorRating}
      // tripadvisorReviews={tripadvisorReviews}
      price={0}
      // onMarkerClick={(place: Place) => setSelectedPlace(place)}
      onMarkerClick={() => console.log('marker clicked')}
    />
  );

  const TripadvisorReviewsContent = holidayPackage ? (
    <>
      <TARatings
        tripAdvisor={holidayPackage?.accommodation.trip_advisor || ''}
        selectedPackage={holidayPackage}
      />
    </>
  ) : null;

  const tabsData: TabsData = {
    tabsList: tabs,
    tabsContent: [HotelInformationContent, MapContent, TripadvisorReviewsContent],
  };


  return (
    <BaseLayout userToken={userToken} userConfiguration={configuration} >
      <div className='package-page'>
        <div className='package-page-wrapper'>

              <div>
                <header className='hwt-header package-page-header'>
                  <div>
                    <h1 className='package-page-title'>{holidayPackage?.accommodation.name}</h1>

                    {holidayPackage?.accommodation?.resort && (
                      <p style={{color: configuration.text_colour_secondary}} className='package-page-location location'>
                        {holidayPackage.accommodation.resort.name}
                      </p>
                    )}
                  </div>

                  <div className='name-section-wrapper'>
               
                      <Ratings
                        tripAdvisor={holidayPackage?.accommodation.trip_advisor}
                        hotelRating={Number(holidayPackage?.accommodation.rating)}
                      />
                  
                  </div>
                </header>

                <ImageSlider
                  images={holidayPackage?.accommodation.images || []}
                  height={600}
                  imgW={-1}
                  imgH={-1}
                  gap='1rem'
                />

                <div style={{borderColor: configuration.border_colour_2}} className='package-page-hotel-detail'>
                  <SimpleTabs
                    activeTabNumber={activeTabNumber}
                    tabsData={tabsData}
                    onTabChange={setActiveTab}
                  />
                </div>
              </div>

              <aside className='package-page-aside'>
                <PackageHolidaySummary
                  offer={holidayPackage}
                  selectedSearchParams={searchParams}
                  isPackage
                  headerTitle='Holiday Summary'
                  // Need replacing with the correct data
                  onHolidaySelect={() => selectOffer()}
                  isFetchingData={false}
                  pricePlan={paymentPlan}
                />
              </aside>
         
        </div>
      </div>
    </BaseLayout>
  );
}
