import React, { FunctionComponent, useEffect, useState, ReactNode } from 'react';

import {
    availableBoards
  } from '../../hooks/useRequest';

import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { Button } from '../Button';
import tick from '../../components/assets/tick.svg';

import { boardFromID } from '../../services/acc'

import { Modal } from '../Modal';

export const LoaderSpinner = () => {

    const {
      RootStore: {
        configuration,
      },
    } = useStores() as { RootStore: RootStore };
  
  
    let bs = configuration.button_colour;
  if(configuration.spec == '6yk1y'){;
    bs = '#01447b';
  }
  
    return (
      <div style={{borderColor: bs + ' transparent transparent transparent'}} className='loader-ring'>
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}} />
        <div style={{borderColor: bs + ' transparent transparent transparent'}}/>
        <div style={{borderColor: bs +  ' transparent transparent transparent'}} />
      </div>
    );
  };



interface BoardSelectorProps {
    isOpen: boolean;
    rooms: string, 
    selectedBoards: number[],
    duration: any, 
    ratings: number[], 
    tripRatings: number[], 
    departureDate: any, 
    departureType: any,
    tripType: any,
    selectedDestinations: number[],
    selectedRegions: number[],
    selectedResorts: number[],
    selectedDeparturePoints: string[],
    accommodationID: number | null | undefined,
    onClose: () => void;
    onChange: (options: number[]) => void;
  }
  
  const BoardSelector: FunctionComponent<BoardSelectorProps> = ({
    isOpen = false,
    rooms, 
    selectedBoards,
    duration=7, 
    ratings, 
    tripRatings, 
    departureDate=null, 
    departureType=0,
    tripType=0,
    selectedDestinations,
    selectedRegions,
    selectedResorts,
    selectedDeparturePoints,
    accommodationID = null,
    onClose,
    onChange
  }) => {
    
    const {
        RootStore: {
          userToken,
          configuration,
        },
      } = useStores() as { RootStore: RootStore };



    const [options, setOptions] = useState<any>(null);
    const [lastRun, setLastRun] = useState<any>(null);

    const [isFetching, setIsFetching] = useState(false);

    const [selectedSession, setSelectedSession] = useState<any>([]);
    const [selectedTops, setSelectedTops] = useState<any>([]);
    const [removedSession, setRemovedSession] = useState<any>([]);

    //a local state allows for instant update on selections
    const [selectedBBs, setSelectedBoards] = useState<number[]>(selectedBoards);

    useEffect(() =>{
        const jh = 500;

    }, [])
    useEffect(() =>{


        const xEl = document.getElementById('cloudlink-chat-overlay-buttons');
        if(xEl != null){
            if(isOpen){
            
                if(xEl != null){
                xEl.style.display = 'none';
                }
            }
            else{
                if(xEl != null){
                    xEl.style.display = 'block';
                }
            }
        }
        
        if(!isOpen){
            setSelectedSession([]);
            setRemovedSession([]);
            return
        }

        const sd = selectedDestinations.join('-');
        const sg = selectedRegions.join('-');
        const ss = selectedResorts.join('-');
        const sr = ratings.join('-');
        const str = tripRatings.join('-');
        const sdp = selectedDeparturePoints.join('-');


        const params = { rooms, duration, ratings : sr, 
            tripRatings : str, departureDate, departureType, tripType, selectedDestinations : sd, selectedRegions : sg, 
            selectedResorts : ss, selectedDeparturePoints : sdp }

 
        if(lastRun != null){
            if(lastRun.rooms == rooms && lastRun.duration == duration && lastRun.selectedDeparturePoints == sdp
                && lastRun.ratings == sr && lastRun.tripRatings == str && lastRun.departureType == departureType && lastRun.tripType == tripType
                && lastRun.selectedDestinations == sd && lastRun.selectedRegions == sg  && lastRun.selectedResorts == ss){
                return;
            }
        }

        setIsFetching(true);

        availableBoards(userToken, rooms, selectedDeparturePoints, duration, ratings, tripRatings, departureDate, 
            departureType, selectedRegions, selectedResorts, selectedDestinations, tripType, accommodationID).then((d) => {
            setOptions(d.available_boards.result);
            setLastRun(params)
            setIsFetching(false);
        })

    }, [isOpen])

    const handleCloseButtonClick = () => {
        setSelectedSession([]);
        setRemovedSession([]);
        onClose();
    }

    const handleCancelButtonClick = () => {
        const xa = selectedBoards;
        const newxa = [];
        for(const sd of xa){
            let w = true;
            for(const x of selectedTops){
                if(sd == x){
                    w = false;
                    break;
                }
            }
            if(w){
                newxa.push(sd);
            }
        }

        for(const sd of removedSession){
            // let w = false;
            // for(const x of removedSession){
            //     if(sd.value == x.value){
            //         w = true;
            //         break;
            //     }
            // }
    
            newxa.push(sd);
            
        }

        onChange(newxa)

        setSelectedSession([]);
        setSelectedTops([]);
        setRemovedSession([]);
        
        onClose();

    }


    const selectOption = (bid: number) => {
        let xa = selectedBoards;

        const ss = selectedSession;
        const rs = removedSession
        const st = selectedTops;

        const ie = xa.indexOf(bid);
        if(ie!= -1){
            xa.splice(ie , 1)
        }
        else{
            xa.push(bid);
            ss.push(bid);
            st.push(bid);
        }

        onChange(xa)
        setSelectedSession(ss);
        setRemovedSession(rs);
        setSelectedTops(st);
        setSelectedBoards(xa);
    }





    if(!isOpen){
        return null;
    }

    let bc = configuration.button_colour;

    let titleColour =  configuration.button_colour;
    let cancelText = configuration.button_colour;
    let primButtonColour = configuration.button_colour;
    if(configuration.button_icon == 'dark'){
      bc = configuration.border_colour_2;
      titleColour =  configuration.border_colour_2;
    }

    let selStyle = {backgroundColor: configuration.button_colour, borderColor:configuration.border_colour_2};
  
    if(configuration.spec == '6yk1y'){
      primButtonColour = configuration.button_colour;
      cancelText = '#01447b';
      bc = '#01447b';
      titleColour =  '#01447b';
      selStyle = {backgroundColor: '#01447b', borderColor:configuration.border_colour_2};
    }

    const modalHeader: ReactNode = (
        <div className='dest-header-section'>
          <h5 style={{color: configuration.text_colour}} className='dest-header-section-text'>Board Type</h5>
          <span className="dest-header-section-close" onClick={() => handleCloseButtonClick()} role="button"></span>
        </div>
      );

    const modalFooter: ReactNode = (
    <div className='dest-footer-section'>
        <Button label='Cancel'  backgroundColor={primButtonColour} isTransparent onClick={() => handleCancelButtonClick()} />
        <Button label='Apply' backgroundColor={primButtonColour} borderColor={primButtonColour} primary onClick={() => handleCloseButtonClick()} />
    </div>
    );
    

    const loader =<><div className='destsel-desktop-view'><div style={{borderColor: configuration.border_colour_2, height: '400px', display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
    <div style={{width:'100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <LoaderSpinner></LoaderSpinner>
    </div>
</div></div><div className='destsel-mobile-view'><div className='datesel-mobile-con'>{modalHeader}<div style={{display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
    <div style={{width:'100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <LoaderSpinner></LoaderSpinner>
    </div>
</div></div></div></>;

    if(isFetching){
        return(loader);
    }

    if(options == null){
        return(loader);
    }


    // return(loader);

   


    const boards: number[] = [];
    const htmlDests = [];

    const htmlSelDests =[];

    for(const x of options){
        // depPoints.push({'value': x.code, 'name': x.name, 'type': 'airports', 'hide': false,});
        boards.push(x.result);
    }



    // depPoints.sort((a, b) => {
    //     if(a.name.toLowerCase() < b.name.toLowerCase()){
    //         return -1;
    //     }
    //     if(a.name.toLowerCase() > b.name.toLowerCase()) {
    //         return 1;
    //     }
    //     return 0;
    // })

    const chkStyle = {width: '24px', height: '24px', borderStyle:'solid', borderWidth: '1px', borderRadius: '2px', cursor: 'default', borderColor: '#99b4ca'};

    const lblStyle = {marginLeft: '10px', cursor:'pointer', color: configuration.text_colour};
    const chkInputStyle = {height: '0px', width: '0px', display: 'none'};
    const optionDivStyle = {paddingTop: '12px', paddingBottom: '12px', display:'flex', alignItems:'center'}


    const spanSelected = <span style={{display: 'flex', width: '24px', height: '24px', color: 'white', alignItems: 'center', justifyContent: 'center'}}><img src={tick} style={{color: 'white'}} height='20' width='20' /></span>



    // if(searchValue.trim() == ''){
    //     let isAnySelected = false;
    //     let anyTick = null;
    //     if(selectedDeparturePointCodes.length == 0){
    //         isAnySelected = true;
    //         anyTick = spanSelected
    //     }
    //     htmlDests.push(<div style={optionDivStyle}>
    //         <input onChange={(e) => setAny()} style={chkInputStyle} type="checkbox" id={'chkdstanyda'} name={'chkdstanyda'} checked={isAnySelected} />
    //         <label style={chkStyle} htmlFor={'chkdstanyda'}>{anyTick}</label>
    //         <label style={lblStyle} htmlFor={'chkdstanyda'}>{'Any airport'}</label>
    //     </div>)
    // }


    for(const x of boards){

        let selTick = null;
        let selected = false;
        if(selectedBBs.indexOf(x) > -1){
            selTick = spanSelected
            selected = true;
        }

        let isSessionSel = false;
        for(const ss of selectedSession){
            if(ss == x){
                isSessionSel = true;
            }
        }

        const bname = boardFromID(x);

        if(selected && !isSessionSel){
            htmlSelDests.push(<div style={optionDivStyle}>
                <input onChange={(e) => {selectOption(x)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x} name={'chkdst' + x} value={x} checked={selected} />
                <label style={chkStyle} htmlFor={'chkdst' + x}>{selTick}</label>
                <label style={lblStyle} htmlFor={'chkdst' + x}>{bname}</label>
            </div>)
        }
        else{

            htmlDests.push(<div style={optionDivStyle}>
                    <input onChange={(e) => {selectOption(x)}} style={chkInputStyle} type="checkbox" id={'chkdst' + x} name={'chkdst' + x} value={x} checked={selected}  />
                    <label style={chkStyle} htmlFor={'chkdst' + x}>{selTick}</label>
                    <label style={lblStyle} htmlFor={'chkdst' + x} >{bname}</label>
            </div>)
            
        }
    }
    
    let inputPlace = 'Search departure airports';
    if(selectedDeparturePoints.length > 0){
        inputPlace = '+ Add another';
    }



    return(<><div className='destsel-desktop-view'><div style={{borderColor: configuration.border_colour_2, height: '400px', display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
        <div style={{width:'100%'}}>
            {htmlSelDests}
            {htmlDests}
        </div>
    </div>
    </div>
    <div className='destsel-mobile-view'>

    <Modal
          isOpen={true}
        >
            
            <div className='datesel-mobile-con'>
            {modalHeader}
            <div style={{borderColor: configuration.border_colour_2, display: 'flex', overflow: 'auto', textTransform: 'capitalize'}} className='destsel-container'>
            <div style={{width:'100%'}}>
                {htmlSelDests}
                {htmlDests}
            </div>
            </div>
            {modalFooter}
            </div>    

    </Modal>
    
    </div></>)



  };

  export default BoardSelector;