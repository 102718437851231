import { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { Room } from '../HolidayCard';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';
import { Button } from '../Button';

import RoomIcon from '../assets/room.svg';
import RoomIconDefault from '../assets/icons/default/05/Room.svg'

import { Package, PackageRoom, RoomGroup } from '../../hooks/types';
import { addProducts } from '../../hooks/useRequest';
import { LoaderSpinnerSmall } from '../Loader/jLoader'

type PackageRoomSelectProps = {
  sessionId:string;
  roomGroups: any;
  selectedRooms: PackageRoom[];
  selectedBoard: number;
  onSelect: (packageRoom: PackageRoom[]) => void;
  totalPax: number;
}


export function PackageRoomSelection({sessionId, roomGroups, selectedRooms, 
  selectedBoard, onSelect, totalPax}: PackageRoomSelectProps) {


    const [selection, setSelection] = useState<any>(selectedRooms);
    const [buttonLoad, setButtonLoad] = useState(false);
    const [selectingRoom, setSelecting] = useState({'room': -1, 'id': -1});
    // const [selection, setSelection] = useState<any>(selectedRooms);
    const {
      RootStore: {
        userToken,
      },
    } = useStores() as { RootStore: RootStore };


    const compareRoomPrice = (selectedRoomPrice: number, room: PackageRoom ) => {
      let diff = 0;
      let strDiff = '';
  
      const price = selectedRoomPrice / totalPax;
      const roomPricePerPax = room.price / totalPax;
  
      if (room.price_per_person != null) {
        if (price > roomPricePerPax) {
          diff = Number((price - roomPricePerPax).toFixed(2));
          strDiff = '- £' + diff.toFixed(2);
        } else if (price < roomPricePerPax) {
          diff = Number((roomPricePerPax - price).toFixed(2));
          strDiff = '+ £' + diff.toFixed(2);
        } else {
          diff = 0;
          strDiff = '';
        }
      }
      return strDiff;
    };

    // const compareRoomPrice = (selectedRoomFullPrice: any ,selectedRoomPrice: any, room: any, paxCount: any) => {
    //   let diff = 0;
    //   let strDiff = '';
    //   if (room.price_per_person != null) {
    //     if (selectedRoomPrice > room.price_per_person) {
    //       // diff = Number((selectedRoomPrice - room.price_per_person).toFixed(2));
    //       // strDiff = '- £' + diff.toFixed(2);

    //       diff = Number((selectedRoomFullPrice - room.price).toFixed(2))
    //       diff = diff / paxCount


    //       strDiff = '- £' + diff.toFixed(2);

    //     } else if (selectedRoomPrice < room.price_per_person) {

    //       // diff = Number((room.price_per_person - selectedRoomPrice).toFixed(2));
    //       // strDiff = '+ £' + diff.toFixed(2);

    //       diff = Number((room.price - selectedRoomFullPrice).toFixed(2));
    //       diff = diff / paxCount;

    //       strDiff = '+ £' + diff.toFixed(2);

    //     } else {
    //       diff = 0;
    //       strDiff = '';
    //     }
    //   }
    //   return strDiff;
    // };
  
    const {
      RootStore: {
        configuration
      },
    } = useStores() as { RootStore: RootStore };
  
    // async function presetRooms() {
    //   if (userSession.sessionId) {
    //     const presetRooms = [];
  
    //     for await (const group of groups) {
    //       await addProduct(userSession.sessionId, group.rooms[0].id, 0, false);
    //       presetRooms.push(group.rooms[0]);
    //     }
  
    //     setSelection(presetRooms);
  
    //     const basketResponse = await getBasket(userSession.sessionId);
    //     setBasket({ ...basket, ...{ basketData: basketResponse } });
    //   }
    // }
  
    // useEffect(() => {
    //   // if (preSelectedRooms?.length === 0) {
    //   //   presetRooms();
    //   // }
    // }, []);
  
    useEffect(() => {
    //   async function updateRoomsByBoard() {
    //     if (userSession.sessionId) {
    //       const res = (await getAllRoomsFromBasket(userSession.sessionId)) as BasketRoomsResult;
    //       const newRooms = res.basket_rooms.result;
  
    //       setSelection(newRooms);
    //     }
    //   }
  
    //   updateRoomsByBoard();
    const jh = 500;
    }, [selectedBoard]);
  
    async function handleSelection(group: RoomGroup, room: PackageRoom, roomIndex: number) {
      
      if (sessionId == null) {
        return;
      }

      if(room.id == null){
        return
      }

      setSelecting({'room': room.room_number || -1, 'id': room.id || -1})
      setButtonLoad(true);

      const selectRooms = [];
      const prods: number[] = [];
      for (const sr of selectedRooms) {
        if (sr.room_number == room.room_number) {
          // await addProduct(userSession.sessionId, room.id, 5, false);
          prods.push(room.id);
          // changd room , add it to the array
          selectRooms.push(room);
        }
        else{
          // not a change keep it (just add it back to array to stick in the state)
          selectRooms.push(sr)
          prods.push(sr.id);
          // await addProduct(userSession.sessionId, sr.id, 5, false);
        }
      }
      
      await addProducts(userToken, sessionId, prods, 5, true);

      if (onSelect != null) {
        onSelect(selectRooms);
      }      
      
      setButtonLoad(false);
      setSelecting({'room': -1, 'id': -1})
   
    }


    if(roomGroups == null){
      return null;
    }

    let adlts = 0;
    let chld = 0;
    for(const sr of selectedRooms){
      adlts += sr.adults;
      chld += sr.children;
    }

    let roomIco = RoomIconDefault;
    if(configuration.icon_set == 'blue'){
      roomIco = RoomIcon;
    }




    return (
      <div>
        {roomGroups.map((group : any, index: any) => {
          let selectedPrice = 0;
          let selectedFullPrice = 0;    
          group.rooms.map((room: any) => {

       
            const selectedRoom = selectedRooms?.find(
              (item : any) => item && item.room_number === room.room_number,
            );
  
            if (room.id === selectedRoom?.id) {
              if (room.price_per_person != null) {
                selectedPrice = room.price_per_person;
                selectedFullPrice = room.price;
              }
            }
          });


   

          const paxC = adlts + chld;
  
          return (
            <div className='accomodation-container' key={uniqueId()} style={{ marginBottom: '1rem' }}>
              <h4 className='accomodation-title'>
                <img className='accomodation-icon' src={roomIco} height='20' width='20' />
                <span className='accomodation-title-text'>{`Room ${index + 1}`}</span>
              </h4>
  
              {group.rooms.map((room: any) => {
                const selectedRoom = selectedRooms?.find(
                  (item: any) =>
                    item &&
                    item.board === selectedBoard &&
                    item.id === room.id &&
                    item.room_number === room.room_number,
                );
  
                const diff = compareRoomPrice(selectedFullPrice ,room);
  
                const priceStyle = {color: ''}
                if(configuration != null){
                  priceStyle.color = configuration.button_colour;
                }
                
                let diffV = null;
                if (diff != '') {
                  diffV = (
                    <span style={priceStyle} className='accomodation-container-price__value'>
                      {diff} <br />{' '}
                      <span className='accomodation-container-per-person'>Per Person</span>
                    </span>
                  );
                }
  


                // let isSelected = false;
                // const selStyle = {borderColor: '', color: configuration.text_colour};
                // if(selectedRoom){
                //   isSelected = true;
                //   selStyle.borderColor = configuration.jrny.selected_button_border_colour;
                // }

                let isSelected = false;
                const selStyle = {borderColor: '', color: configuration.text_colour};
                if(selectedRoom){
                  isSelected = true;
                  selStyle.borderColor = configuration.jrny.selected_button_border_colour;
                }

                let btn = <Button
                      hoverStyle={true}
                      isSelected={isSelected}
                      overrideClassNames={`accomodation-button ${
                        selectedRoom ? 'accomodation-button-active' : ''
                      }`}
                      isTransparent={false}
                      label={selectedRoom ? 'Selected' : 'Select'}
                      onClick={() => handleSelection(group, room, index)}
                      color={configuration.button_text_secondary_colour}
                      disabled={isSelected}
                    />

              if(buttonLoad){

                if(room.room_number == selectingRoom.room && room.id == selectingRoom.id){
                  btn = <div className='accomodation-button' style={{display:'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: configuration.jrny.selected_button_colour,
                  borderColor: configuration.jrny.selected_button_border_colour, color: configuration.jrny.selected_button_text_colour}}>{LoaderSpinnerSmall()}</div>
                }else{
                  btn =  <Button
                  hoverStyle={true}
                  isSelected={isSelected}
                  overrideClassNames={`accomodation-button ${
                    selectedRoom ? 'accomodation-button-active' : ''
                  }`}
                  isTransparent={false}
                  label={selectedRoom ? 'Selected' : 'Select'}
                  onClick={() => handleSelection(group, room, index)}
                  color={configuration.button_text_secondary_colour}
                  disabled={true}
                />
                }
              }

                return (
                  <div
                    style={selStyle}
                    key={uniqueId()}
                    className={`accomodation-container-child ${
                      selectedRoom ? 'accomodation-container-child-active' : ''
                    }`}
                  >
                    <div>
                      <h4 className='accomodation-container-title'>
                        {room.description} ({room.board_description})
                      </h4>
                      <span className='accomodation-container-room'>{room.board_description}</span>
                    </div>
  
                    <div className='accomodation-container-price'>{diffV}</div>
  
                    <div>
                      {btn}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
  