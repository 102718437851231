import React, { FunctionComponent, useState, useEffect } from 'react';
import { InfoBox, OverlayView } from '@react-google-maps/api';
import cn from 'classnames';
import { Place, GoogleMapType } from './Map';
import { HolidayTopDetails } from '../HolidayDetails';
import { ImageSlider } from '../ImageSlider/ImageSlider';

import './MapModal.scss';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

interface CustomMarkerProps {
  place: Place;
  isActive?: boolean;
  map?: GoogleMapType;
  hotelName: string;
  resortName: string;
  regionName: string;
  destinationName: string;
  rating?: number;
  tripadvisorRating?: number;
  tripadvisorReviews?: string;
  price?:
    | number
    | {
        total: number;
        pricePerPerson: number;
      };
  shouldShowInfoBox?: boolean;
  onClick: (place: Place) => void;
  isTotalPriceFlag?: boolean;
  handleViewDeal?: (place: Place) => void;
  hideInfo?: boolean;
}

const CustomMarker: FunctionComponent<CustomMarkerProps> = ({
  place,
  isActive = false,
  hotelName,
  resortName,
  regionName,
  destinationName,
  rating,
  tripadvisorRating,
  tripadvisorReviews,
  price,
  shouldShowInfoBox = false,
  isTotalPriceFlag = false,
  handleViewDeal,
  onClick,
  hideInfo = false,
}: CustomMarkerProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [active, setActive] = useState<boolean>(isActive);

  const onMarkerClickHandler = (place: Place) => {
    setActive(true);
    onClick(place);
  };

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  // useOutsideClick(innerRef, outsideClickCallback);

  let salePrice = place.title;



  if (isTotalPriceFlag) {
    salePrice = `£${Math.round(place.price?.total as number)}`;
  } else {
    salePrice = `£${Math.round(place.price?.pricePerPerson as number)}pp`;
  }
  

  let pinCol = 'white';
  if(active){
    pinCol = configuration.container_colour;
  }

  return (
    <div className='custom-marker-wrapper'>
      <OverlayView position={place.position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <div className={cn('custom-marker', { active: active })}>
          <button
            style={{backgroundColor: pinCol, borderColor: configuration.container_colour}}
            className='marker-button'
            onClick={() => onMarkerClickHandler(place)}
            type='button'
          >
            {salePrice}
          </button>
          <div className='triangle-left'>
            <div className='inner-triangle'></div>
          </div>
          <div className='shadow'></div>

          {active && shouldShowInfoBox && !hideInfo && (
            <InfoBox
              position={place.position as any}
              options={{ closeBoxMargin: '0', alignBottom: true }}
            >
              <>
                <button
                  className='map-view__close map-view__close--info'
                  aria-label='Close Map Information Box'
                  onClick={() => setActive(false)}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={3}
                    stroke='currentColor'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                  </svg>
                </button>

                <div className='custom-info-window-content'>
                  {place?.images && place.images.length > 0 && (
                    <div className='info-window-image'>
                      <ImageSlider images={place.images} />
                    </div>
                  )}

                  <div className='info-box-information-wrapper'>
                    <HolidayTopDetails
                      hotelName={hotelName || ''}
                      rating={rating || 5}
                      resortName={resortName}
                      regionName={regionName}
                      destinationName={destinationName}
                      tripadvisorRating={tripadvisorRating ? tripadvisorRating : 0}
                    />

                    {price && <span className='price'>{salePrice}</span>}

                    {handleViewDeal && (
                      <div className='info-box-button-wrapper'>
                        <button
                          className='button button--medium button--primary'
                          onClick={() => handleViewDeal(place)}
                        >
                          View Deal
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </InfoBox>
          )}
        </div>
      </OverlayView>
    </div>
  );
};

export default React.memo(CustomMarker);
