import React, { FunctionComponent } from 'react';
import '../SummaryCard.scss';
import { camelCase } from 'lodash';
import { useStores } from '../../../hooks/useStore';
import { RootStore } from '../../../stores/RootStore';
import { Flight } from '../../../hooks/types';
import dayjs from 'dayjs';

export interface FlightsProps {
  title: string;
  icon: string;
  flight: Flight;
}

export const FlightsCard: FunctionComponent<FlightsProps> = ({
  title,
  icon,
  flight
}: FlightsProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };
  
  // const generateDateRanges = (data: any, outboundAirline: string) => {
  //   return data.split('-').map((item: string, index: number) => {
  //     return (
  //       <>
  //         <div key={`${camelCase(item)}-${index}`} className='summary-card-list--item'>
  //           <span className={'light-title'}>{!index ? outboundAirline : ''}</span>
  //           <span>{item}</span>
  //         </div>
  //         {!index && (
  //           <div key={`${camelCase(item)}-separator`} className=' separator'>
  //             <span> </span>
  //             <span>-</span>
  //           </div>
  //         )}
  //       </>
  //     );
  //   });
  // };

  const outboundAirline = flight.airline + ' ' + flight.number;
  const inboundAirline = flight.return_airline + ' ' + flight.return_number;
  
  
  const outboundDeparting = dayjs(flight.departing).format('HH:mm') + ' ' + flight.departure_airport.name;
  const outboundArrival =  dayjs(flight.arrival).format('HH:mm') + ' ' + flight.arrival_airport.name;

  const inboundDeparting =  dayjs(flight.return_departing).format('HH:mm') + ' ' + flight.arrival_airport.name;
  const inboundArrival =  dayjs(flight.return_arrival).format('HH:mm') + ' ' + flight.departure_airport.name;

  return (
    <div style={{borderColor: configuration.border_colour_2}} className='summary-card-wrapper'>
      <div className='summary-card-wrapper--header'>
        <img src={icon} alt='Section icon' />
        <span className={'card-title'}>{title}</span>
      </div>

      <div className='summary-card-wrapper--body'>
        <div className='summary-card-list'>
          <div className='summary-card-list--item'>
            <span>Outbound</span>
            <span>{dayjs(flight.departing).format('ddd DD MMM YYYY')}</span>
          </div>

          <div className='summary-card-list--item'>
            <span className={'light-title'}>{outboundAirline}</span>
            <span>{outboundDeparting}</span>
          </div>

          <div className=' separator'>
             <span> </span>
             <span>-</span>
          </div>

          <div className='summary-card-list--item'>
          <span className={'light-title'}></span>
            <span>{outboundArrival}</span>
          </div>
  

          <div className='summary-card-list--item'>
            <span>Inbound</span>
            <span>{dayjs(flight.return_departing).format('ddd DD MMM YYYY')}</span>
          </div>
          {/* {generateDateRanges(flightsData.inboundDateRange, flightsData.inboundAirline)} */}
        
          <div className='summary-card-list--item'>
            <span className={'light-title'}>{inboundAirline}</span>
            <span>{inboundDeparting}</span>
          </div>

          <div className=' separator'>
             <span> </span>
             <span>-</span>
          </div>

        <div className='summary-card-list--item'>
            <span className={'light-title'}></span>
            <span>{inboundArrival}</span>
          </div>

        </div>
      </div>
    </div>
  );
};
