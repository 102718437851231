import React, { FunctionComponent, useState, useEffect } from 'react';
import './Toggle.scss';
import cn from 'classnames';
import { useStores } from '../../hooks/useStore';
import { RootStore } from '../../stores/RootStore';

export interface ToggleProps {
  firstLabel: string;
  secondLabel: string;
  value?: boolean;
  backgroundColor?: string;
  color?: string;
  size?: 'small' | 'medium' | 'large';
  onToggleChange: (s: boolean) => void;
}

export const Toggle: FunctionComponent<ToggleProps> = ({
  firstLabel,
  secondLabel,
  backgroundColor,
  color,
  size = 'large',
  value = false,
  onToggleChange,
}: ToggleProps) => {

  const {
    RootStore: {
      configuration,
    },
  } = useStores() as { RootStore: RootStore };

  const [toggleState, setToggleState] = useState(value);

  useEffect(() => {
    if (value != null) {
      setToggleState(value);
    }
  }, [value]);

  const toggle = () => {
    setToggleState(!toggleState);
    onToggleChange(!toggleState);
  };


  const fStyle = {backgroundColor: '', color: ''}
  const sStyle = {backgroundColor: '', color: ''}
  if(!toggleState){
    fStyle.backgroundColor = configuration.toggle_on_colour;
    fStyle.color = configuration.button_text_colour;
  }
  else{
    sStyle.backgroundColor = configuration.toggle_on_colour;
  }

  fStyle.color = configuration.button_text_colour;
  return (
    <div
      className={`hwt-toggle hwt-toggle--${size}`}
      style={{ backgroundColor: configuration.toggle_colour}}
      onClick={() => toggle()}
    >
      <span style={fStyle} className={cn({ selected: !toggleState })}>{firstLabel}</span>
      <span style={sStyle} className={cn({ selected: toggleState })}>{secondLabel}</span>
    </div>
  );
};
